import React from "react";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const EntriesTableRow = ({
  row,
  index,
  setEntryIndex,
  toggleDelete,
  toggleEditEntries,
  setDeleteId,
  currentEvent,
  sortedCategories,
  setHoveredRowIndex,
  hoveredRowIndex,
  toggleOverride,
  setShowNewRow,
}) => {
  const tags = Array.isArray(row?.special_tags) ? row.special_tags : [];

  return (
    <>
      <tr
        id={`${index}`}
        className="border-y border-white/10 hover:bg-[#94a4fd]/20 cursor-pointer relative whitespace-nowrap"
        onClick={(e) => {
          e.stopPropagation();
          setShowNewRow(row);
        }}
        // onMouseLeave={handleRowMouseLeave}
      >
        <td className="flex items-center">
          {capitalizeFirstLetter(row?.entry_name)}
        </td>
        <td>{capitalizeFirstLetter(row?.age_division)}</td>
        <td>{capitalizeFirstLetter(row?.classification)}</td>
        <td>{capitalizeFirstLetter(row?.genre)}</td>
        <td>{capitalizeFirstLetter(row?.entryType)}</td>
        {/* <td>{row?.special_tags}</td> */}

        <td>
          <p className="max-w-[200px] whitespace-nowrap truncate">
            {(tags?.length === 0 || !tags) && "N/A"}
            {(tags?.length > 0 || tags) &&
              tags?.map((tag, index) => {
                return index !== row?.special_tags?.length - 1
                  ? `${capitalizeFirstLetter(tag)}, `
                  : ` ${capitalizeFirstLetter(tag)}`;
              })}
          </p>
        </td>
        <td className="flex items-center">
          <img
            alt="arrow"
            src="/images/CalendarBlank.png"
            className="w-6 h-6"
          />
          {row?.last_update}
        </td>
        <td>
          <div
            className={`flex items-center ${
              row?.music_url ? "text-green-300" : "text-red-400"
            } `}
          >
            <div
              className={`w-1 h-1 rounded-full ${
                row?.music_url ? "bg-green-300" : "bg-red-400"
              }`}
            ></div>
            {row?.music_url ? "No issues" : "Music not uploaded"}
          </div>
        </td>
      </tr>
    </>
  );
};

export default EntriesTableRow;
