/* eslint-disable react-hooks/exhaustive-deps */
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import arrangeEntriesByCategories from "utils/arrangeEntriesByCategories";
import findIndexByProperty from "utils/findIndexByProperty";
import findLockedEntryLetter from "utils/findLockedEntryLetter";

const ParentStudent = () => {
  const compId = localStorage.getItem("compId") || "";

  const { status, data: compData } = useFirestoreQueryDoc(
    "competitions",
    compId
  );
  const { event_name, competitionName } = useParams();

  const currentEvent = compData?.events?.filter(
    (item) => item?.event_name === event_name
  )[0];

  const startTime = moment(currentEvent?.days[0].startTime)
    .local()
    .format("MMM D");

  const endTime = moment(
    currentEvent?.days[currentEvent?.days?.length - 1].endTime
  )
    .local()
    .format("MMM D");

  function removeDuplicates(array) {
    if (array) {
      const uniqueArray = [];
      const seenObjects = new Set();

      for (const obj of array) {
        // Serialize the object to JSON to use it as a unique identifier
        const serializedObj = JSON.stringify(obj);

        if (!seenObjects.has(serializedObj)) {
          seenObjects.add(serializedObj);
          uniqueArray.push(obj);
        }
      }

      return uniqueArray;
    }
  }

  //to get all entries
  const { data: allEntries } = useQueryAllSubCollectionDocs(
    "competitions",
    compId,
    "entries"
  );

  //to get all categories
  const { data: allCategories } = useQueryAllSubCollectionDocs(
    "competitions",
    compId,
    "categories"
  );

  const eventEntries = allEntries?.filter(
    (each) => each?.event_uid === currentEvent?.event_uid
  );

  const eventCategories = allCategories
    ?.filter(
      (each) =>
        each?.event_uid === currentEvent?.event_uid && each?.type === "category"
    )
    ?.sort((a, b) => a?.sort_id - b?.sort_id);

  const arrangedEntries = removeDuplicates(
    arrangeEntriesByCategories(eventCategories, eventEntries)
  );

  const allPerfromed = arrangedEntries?.filter((x) => x?.performance_ready);

  const onStage = allPerfromed[allPerfromed?.length - 1];

  const onDeck = arrangedEntries
    ?.filter(
      (x) =>
        x?.checkedIn &&
        !x?.performance_ready &&
        !x?.is_scratched &&
        x?.entry_name !== onStage?.entry_name
    )
    ?.slice(0, 5);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  //=====================================================================to get entry count
  const eventCategories2 = allCategories?.filter(
    (cat) => cat?.event_uid === currentEvent?.event_uid
  );
  let sortedCategories = eventCategories2?.sort(
    (a, b) => a?.sort_id - b?.sort_id
  );

  const categories = sortedCategories?.filter((l) => l?.type === "category");

  const allEntriesWithoutLocked = arrangeEntriesByCategories(
    categories,
    allEntries
  )?.filter((k) => k?.locked_entry === false);

  const allEntriesWithLocked = arrangeEntriesByCategories(
    categories,
    allEntries
  );

  function entryCount(itm) {
    const allEntriesBeforeLocked = allEntriesWithLocked
      ?.slice(
        0,
        findIndexByProperty(allEntriesWithLocked, "doc_id", itm?.doc_id)
      )
      ?.filter((m) => m?.locked_entry === false);

    if (itm?.locked_entry) {
      return `${
        allEntriesBeforeLocked?.length === 0
          ? 1
          : allEntriesBeforeLocked?.length
      }${findLockedEntryLetter(allEntriesWithLocked, itm)}`;
    } else {
      return (
        findIndexByProperty(allEntriesWithoutLocked, "doc_id", itm?.doc_id) + 1
      );
    }
  }

  return (
    <>
      <div className="w-full min-h-screen text-white/80 px-5 pt-[50px] bg-[#1c1c1c] flex flex-col transition-all duration-700">
        {/* section holding the awards and entries */}
        <div className="w-full text-[.9em] pt-3 mb-20 overflow-x-auto overflow-y-hidden">
          {status === "loading" ? (
            <div className="w-full h-[150px] flex justify-center items-center gap-6 mb-auto text-white/80">
              <PulseLoader color="#94a4fd" size={10} />
            </div>
          ) : (
            <h2 className="text-center mb-4">
              {capitalizeFirstLetter(currentEvent?.event_name)}{" "}
              {currentEvent?.year.split(" to ")[0]} |{" "}
              {currentEvent?.days?.length === 1
                ? `${startTime}, ${currentEvent?.year}`
                : `${startTime} - ${endTime} ${currentEvent?.year}`}
            </h2>
          )}
          <div className="lg:min-w-[1200px]">
            <button
              onClick={goBack}
              className="px-5 py-1 bg-white/80 hover:bg-white/50 text-black rounded-lg text-[.75rem] md:text-[1rem] mb-8 absolute top-4 left-3"
            >
              Back
            </button>
            <div className="min-h-fit grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-10 gap-5">
              <div className="w-full">
                <div className="w-full p-3 bg-[#282929] my-4 rounded-lg text-center font-bold">
                  <h2 className="text-3xl">ON STAGE</h2>
                </div>
                {onStage ? (
                  <div className="w-full flex flex-col flex-wrap text-[.75rem] sm:text-[.85rem]">
                    <div className="w-full h-[150px] bg-[#282929] font-bold text-[2.5rem] rounded-lg flex justify-center items-center mb-2">
                      {/* #{entryCount(onStage)} */}#
                      {currentEvent?.locked
                        ? onStage?.locked_entry_count
                        : entryCount(onStage)}
                    </div>
                    <div className="w-full flex gap-4 border-b border-white/10 p-3 justify-center">
                      {/* <p className=""></p> */}
                      <p className="">{onStage?.studio_letter}</p>
                      <p className="whitespace-nowrap">
                        {capitalizeFirstLetter(onStage?.entry_name)}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="w-full h-[150px] border border-white/20 rounded-lg flex justify-center items-center bg-[#282929]">
                    No entry currently performing
                  </div>
                )}
              </div>
              <div className="w-full">
                <div className="w-full p-3 bg-[#282929] my-4 rounded-lg text-center font-bold">
                  <h2 className="text-3xl">ON DECK</h2>
                </div>
                {eventEntries && eventEntries?.length > 0 ? (
                  <div className="w-full flex flex-col flex-wrap text-[.75rem] sm:text-[.85rem]">
                    {onDeck?.map((entry, index) => (
                      <div
                        key={index}
                        className="w-full flex gap-4 border-b border-white/10 p-3 justify-center"
                      >
                        <p className="">
                          #
                          {currentEvent?.locked
                            ? entry?.locked_entry_count
                            : entryCount(entry)}
                        </p>
                        <p className="">{entry?.studio_letter}</p>
                        <p className="whitespace-nowrap">
                          {capitalizeFirstLetter(entry?.entry_name)}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="w-full h-[150px] border border-white/20 rounded-lg flex justify-center items-center bg-[#282929]">
                    No entries currently on Deck
                  </div>
                )}
              </div>
              <div className="w-full">
                <div className="w-full p-3 bg-[#282929] my-4 rounded-lg text-center font-bold">
                  <h2 className="text-3xl">NOTIFICATIONS</h2>
                </div>
                <div className="w-full flex flex-col flex-wrap text-[.75rem] sm:text-[.85rem]">
                  {eventEntries
                    ?.filter((entry) => entry?.is_scratched)
                    ?.map((entry, index) => (
                      <div
                        key={index}
                        className="w-full flex gap-4 border-b border-white/10 p-3 justify-center"
                      >
                        <p className="">{entry?.entry_name}</p>
                        <p>
                          #
                          {currentEvent?.locked
                            ? entry?.locked_entry_count
                            : entryCount(entry)}
                        </p>
                        <p className="whitespace-nowrap">Scratched</p>
                      </div>
                    ))}
                  {eventEntries?.length === 0 && (
                    <div className="w-full h-[150px] border border-white/20 rounded-lg flex justify-center items-center bg-[#282929]">
                      No entries scratched
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* button for watch and cancel */}
            <div className="flex justify-center gap-4 mt-8">
              <button className="px-8 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[0.85rem] hover:opacity-75 mt-5">
                Watch stream
              </button>
              <button
                onClick={() => {
                  navigate(
                    `/competitions/${competitionName}/${event_name}/top-scores`
                  );
                }}
                className="px-8 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[0.85rem] hover:opacity-75 mt-5"
              >
                Top 10
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParentStudent;
