/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import ScrollToTop from "./ScrollToTop";
import { useParams } from "react-router-dom";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import React, { useEffect, useState } from "react";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "services/firebase";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import PrintScoresPage from "./PrintScoresPage";

const PrintScoresPageCont = () => {
  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );
  const { compId } = danceStudioCompData.value;

  const { event_name: name } = useParams();

  const event_name = name || localStorage.getItem("event_name") || "";
  //to get realtime events from firestore
  const { data } = useQueryAllDocs("competitions");
  const currentComp = data?.filter((item) => item?.compId === compId)[0];
  const currentEvent = currentComp?.events?.filter(
    (item) => item.event_name?.replace(/ /g, "-") === event_name
  )[0];

  //======================================================to get scores
  const [scoresData, setScoresData] = useState([]);
  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, "competitions", compId, "scores")),
      (snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data());
        setScoresData(data);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [compId]);

  const eventScores = scoresData?.filter(
    (item) => item?.event_name === currentEvent?.event_name?.trim()
  );

  //to get competition name
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { data: studioData } = useFirestoreQueryDoc("studio_owners", user?.uid);

  const { data: allCategories } = useQueryAllSubCollectionDocs(
    "competitions",
    compId,
    "categories"
  );

  const eventCate = allCategories?.filter(
    (cat) =>
      cat?.event_uid === currentEvent?.event_uid && cat?.type === "category"
  );

  //to get all entries
  const { data: allEntries } = useQueryAllSubCollectionDocs(
    "competitions",
    compId,
    "entries"
  );

  const myEntries = allEntries?.filter(
    (each) =>
      each?.event_uid === currentEvent?.event_uid &&
      each?.studio_selected[0] === studioData?.studioName
  );

  let sorted = eventCate?.sort((a, b) => a?.sort_id - b?.sort_id);

  const sortedCategories = sorted?.filter((category) =>
    myEntries?.some((entry) => entry.category_name === category.category_name)
  );

  const calculateAverageScore = (arr, key) => {
    if (Array.isArray(arr)) {
      const filteredArray = arr?.filter((itm) => itm?.technique);
      const array = key === "technique" ? filteredArray : arr;
      const totalSum = array?.reduce((sum, obj) => sum + Number(obj[key]), 0);
      const average = totalSum / array?.length;
      const averageFormatted = Number(average)?.toFixed(2);

      return averageFormatted;
    }
  };

  function getDeduction(arr) {
    const lastCharacters = arr?.map((str) => {
      if (str?.length > 0) {
        return Number(str?.slice(-1));
      } else {
        return "";
      }
    });

    const ded = lastCharacters?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    return ded;
  }

  const calculateTotalScore = (entry) => {
    const scores = eventScores?.filter(
      (item) =>
        item?.doc_id === entry?.doc_id &&
        item?.event_name === currentEvent?.event_name?.trim()
    );

    const allDeductions = scores
      ?.flatMap((obj) => obj.deductions)
      ?.filter((item) => item !== "none");

    const sum =
      [
        Number(calculateAverageScore(scores, "technique")),
        Number(calculateAverageScore(scores, "creativity")),
        Number(calculateAverageScore(scores, "staging")),
        Number(calculateAverageScore(scores, "execution")),
        Number(calculateAverageScore(scores, "performance")),
      ]?.reduce((accumulator, currentValue) => accumulator + currentValue, 0) -
      getDeduction(allDeductions);

    const truncatedScore = sum?.toFixed(2);

    return isNaN(truncatedScore) ? "- -" : truncatedScore;
  };

  const calculateHighestScore = (categoryEntries) => {
    const filteredEntries = categoryEntries?.filter(
      (x) => x?.is_scratched === false
    );

    let highestScore = 0;

    filteredEntries?.forEach((entry) => {
      const scores = eventScores?.filter(
        (item) =>
          item?.doc_id === entry?.doc_id &&
          item?.event_name === currentEvent?.event_name?.trim()
      );

      const totalScoreRaw = scores?.reduce(
        (sum, score) => sum + Number(score?.total || 0),
        0
      );

      const totalScore = totalScoreRaw / scores?.length;

      if (totalScore > highestScore) {
        highestScore = totalScore;
      }
    });

    return highestScore;
  };

  const calculatePosition = (entry, categoryEntries) => {
    const filteredEntries = categoryEntries?.filter(
      (x) => x?.is_scratched === false
    );
    const highestScore = calculateHighestScore(filteredEntries);

    // console.log("highestScore", entry?.entry_name, highestScore);
    const entryScore = calculateTotalScore(entry);
    // Sort the scores in descending order
    const sortedScores = filteredEntries
      .map((entry) => calculateTotalScore(entry))
      .sort((a, b) => b - a);

    // Find the position of the current entry's score in the sorted scores array
    const position = sortedScores?.indexOf(entryScore) + 1;

    if (!highestScore || entryScore === "- -") {
      return "- -";
    } else if (entryScore === highestScore) {
      return `1st`;
    } else if (position === 1) {
      return `1st`;
    } else if (position === 2) {
      return `2nd`;
    } else if (position === 3) {
      return `3rd`;
    } else {
      return `${position}th`;
    }
  };

  const { data: allOrgs } = useQueryAllDocs("organizers");
  const currentOrg = allOrgs?.filter((item) => item?.uid === compId)[0];

  const divisionsRaw = sortedCategories?.map(
    (obj) => obj.category_name?.split("~")[0]
  );

  const divisions = removeDuplicates(divisionsRaw);

  function removeDuplicates(array) {
    if (array) {
      const uniqueArray = [];
      const seenObjects = new Set();

      for (const obj of array) {
        // Serialize the object to JSON to use it as a unique identifier
        const serializedObj = JSON.stringify(obj);

        if (!seenObjects.has(serializedObj)) {
          seenObjects.add(serializedObj);
          uniqueArray.push(obj);
        }
      }

      return uniqueArray;
    }
  }

  return (
    <>
      {/* the invoice page overlay */}
      <section className="w-full h-screen fixed top-0 left-0">
        <PrintScoresPage
          currentEvent={currentEvent}
          divisions={divisions}
          myEntries={myEntries}
          eventScores={eventScores}
          calculatePosition={calculatePosition}
          allEntries={allEntries}
          allCategories={allCategories}
          awards={currentOrg?.awards}
          calculateTotalScore={calculateTotalScore}
          studioData={studioData}
          calculateAverageScore={calculateAverageScore}
          sortedCategories={sortedCategories}
          currentComp={currentComp}
        />
      </section>

      <ScrollToTop />
    </>
  );
};

export default PrintScoresPageCont;
