import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import ScrollToTop from "./ScrollToTop";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import sessionAwards from "./dummyData/sessionAwards.json";
import SessionAwardsCard from "./components/SessionAwardsCard";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { useState } from "react";
import { PulseLoader } from "react-spinners";
import AddEventContainer from "./components/AddEventContainer";

const SessionAwards = () => {
  const { addEvent, openNotifsBoard, currentEvent } = useSelector(
    (state) => state.eventManagement
  );

  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { status, data } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "reports"
  );

  const [selectedRow, setSelectedRow] = useState(null);

  function closeAll() {
    selectedRow !== null && setSelectedRow(null);
  }

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}

      {/* event details page */}
      {!addEvent && (
        <div
          onClick={closeAll}
          className={`w-full min-h-screen text-white/80 px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px]  ${
            openNotifsBoard ? "lg:pr-[310px]" : "lg:pr-[32px]"
          } pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col transition-all duration-700`}
        >
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/90 text-[1rem] mb-6">
              <h2>
                {capitalizeFirstLetter(currentEvent?.event_name)}{" "}
                {currentEvent?.year.split(" to ")[0]}
              </h2>
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            <div className="w-full lg:max-w-[60%] md:min-w-[450px] mb-auto text-white/80">
              {status === "loading" ? (
                <div className="w-full h-[200px] border border-white/10 rounded-lg flex justify-center items-center">
                  <PulseLoader color="#94a4fd" size={15} />
                </div>
              ) : (
                <div className="w-full flex flex-col gap-7">
                  {sessionAwards?.map((item, index) => {
                    return (
                      <SessionAwardsCard
                        item={item}
                        key={index}
                        index={index}
                        allPresets={data}
                        selectedRow={selectedRow}
                        setSelectedRow={setSelectedRow}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>

          <ScrollToTop />
          <Footer />
        </div>
      )}

      {/* the add event pop up page */}

      {addEvent && <AddEventContainer />}
    </>
  );
};

export default SessionAwards;
