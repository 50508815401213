import { useSelector } from "react-redux";
import { toggleNotifsBoard } from "../../../redux/danceStudio/danceStudioSlice";
import { useDispatch } from "react-redux";
import NotificationsContainer from "./NotificationsContainer";
import { collection, getDocs, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "services/firebase";

const RightSidebar = () => {
  const { openNotifsBoard } = useSelector((state) => state.danceStudio);
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { notifications } = useSelector((state) => state.danceStudio);

  //=============================================will be moved to services later
  async function markAllNotificationsAsRead() {
    try {
      const notificationsRef = collection(db, "notifications");
      const querySnapshot = await getDocs(notificationsRef);

      const updatePromises = querySnapshot.docs
        .filter((doc) => doc.data().user_id === user?.uid)
        .map((doc) => updateDoc(doc.ref, { read: true }));

      await Promise.all(updatePromises);

      console.log("Notifications marked as read successfully!");
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  }

  async function deleteNotificationsByUserId() {
    try {
      const notificationsRef = collection(db, "notifications");
      const querySnapshot = await getDocs(notificationsRef);

      const deletePromises = querySnapshot.docs
        .filter((doc) => doc.data().user_id === user?.uid)
        .map((doc) => deleteDoc(doc.ref));

      await Promise.all(deletePromises);

      console.log("Notifications deleted successfully!");
    } catch (error) {
      console.error("Error deleting notifications:", error);
    }
  }

  return (
    <>
      {/* on desktop and larger screens */}
      {/* on desktop and larger screens */}
      {/* on desktop and larger screens */}

      <div className="w-[280px] h-screen px-6 py-7 bg-[#1c1c1c] fixed right-0 top-0 border-l-2 border-[#282929] overflow-y-auto z-20 hidden lg:block slide">
        {/* notifs*/}
        <div className="w-full mb-6">
          <h1 className="text-white">Notifications</h1>

          {notifications?.length > 0 && (
            <>
              <p
                onClick={markAllNotificationsAsRead}
                className="text-white/60 mt-3 text-end cursor-pointer text-[.75rem] hover:underline hover:text-[#94a4fd]"
              >
                Mark all as read
              </p>
              <p
                onClick={deleteNotificationsByUserId}
                className="text-white/60 mt-1 text-end cursor-pointer text-[.75rem] hover:underline hover:text-[#94a4fd]"
              >
                Delete all
              </p>
            </>
          )}

          {/* notifs container */}
          <div className="w-full mt-4">
            <NotificationsContainer />
          </div>
        </div>

        {/* activities */}
        {/* <div className="w-full mb-6">
          <h1 className="text-white">Activities</h1>

          <div className="w-full mt-4">
            {activityData &&
              activityData.map((item) => {
                return <ActivityCard key={item?.id} item={item} />;
              })}
          </div>
        </div> */}
      </div>

      {/* on mobile, small and medium screens */}
      {/* on mobile, small and medium screens */}
      {/* on mobile, small and medium screens */}

      {openNotifsBoard && (
        <div className="w-[76%] sm:w-[300px] h-screen px-6 py-7 bg-[#1c1c1c] fixed right-0 top-0 border-l-2 border-[#282929] overflow-y-auto z-30 sm:z-20 block lg:hidden slide">
          {/* {/* notifs */}
          <div className="w-full mb-6">
            <div className="flex items-center justify-between">
              <h1 className="text-white">Notifications</h1>
              <div
                onClick={() => dispatch(toggleNotifsBoard())}
                className="w-6 h-6 flex justify-center items-center bg-white/80 rounded-full cursor-pointer"
              >
                <img
                  alt="user"
                  src="/images/icons8-close-50.png"
                  className="w-4 h-4"
                />
              </div>
            </div>
            <p className="text-white/60 text-[.75rem] mt-4 text-end hover:underline hover:text-[#94a4fd]">
              Mark all as read
            </p>

            {/* {/* notifs container  */}
            <div className="w-full mt-4">
              <NotificationsContainer />

              {/* {notifsData &&
                notifsData.map((item, index) => {
                  return <NotifsCard item={item} key={index} />;
                })} */}
            </div>
          </div>
        </div>
      )}
      {openNotifsBoard && (
        <div
          onClick={() => dispatch(toggleNotifsBoard())}
          className="w-full h-screen block sm:hidden fixed left-0 top-0 bg-black/60 z-20 appear"
        ></div>
      )}
    </>
  );
};

export default RightSidebar;
