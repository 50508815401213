export default function findLockedEntryLetter(arr, itm) {
  const idx = arr?.findIndex((item) => item === itm);

  if (idx >= 6 && checkPreviousItems(arr, idx, 6)) {
    return "g";
  } else if (idx >= 5 && checkPreviousItems(arr, idx, 5)) {
    return "f";
  } else if (idx >= 4 && checkPreviousItems(arr, idx, 4)) {
    return "e";
  } else if (idx >= 3 && checkPreviousItems(arr, idx, 3)) {
    return "d";
  } else if (idx >= 2 && checkPreviousItems(arr, idx, 2)) {
    return "c";
  } else if (idx >= 1 && checkPreviousItems(arr, idx, 1)) {
    return "b";
  } else {
    return "a";
  }
}

function checkPreviousItems(arr, idx, numItems) {
  for (let i = idx - 1; i >= idx - numItems; i--) {
    if (!arr[i] || arr[i]?.locked_entry !== true) {
      return false;
    }
  }
  return true;
}
