import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearSearchResult,
  setCompetitionsData,
} from "redux/danceStudio/danceStudioSlice";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { Timestamp } from "firebase/firestore";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const SearchResultTray = ({
  openNotifsBoard,
  currentPage,
  searchResults,
  searchTerm,
  clearSearch,
  compData,
  selectedComps,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  // console.log("searchResults", searchResults);

  const [unsubscribed, setUnsubscribed] = useState("");
  const handleViewComp = (item) => {
    if (selectedComps?.includes(item?.competitionName)) {
      let modifiedItem = { ...item }; // Create a new object by spreading the properties of `item`

      if (modifiedItem.timestamp instanceof Timestamp) {
        modifiedItem.timestamp = modifiedItem.timestamp.toDate().toISOString();
      }

      dispatch(setCompetitionsData(modifiedItem));
      navigate(`/studio/event-selection`);
      clearSearch();
    } else {
      setUnsubscribed(item?.competitionName);
    }
  };

  useEffect(() => {
    if (!searchTerm) {
      setUnsubscribed("");
    }
  }, [searchTerm]);

  return (
    <div
      className={`w-full h-screen bg-[#1c1c1c]/90 fixed top-[64px] md:top-[80px] left-0 text-white/80 sm:pl-[202px] lg:pl-[252px] pr-[2px] overflow-auto ${
        openNotifsBoard ? "lg:pr-[310px]" : "lg:pr-[32px]"
      }`}
    >
      <div className="w-full min-h-[300px] bg-[#1c1c1c] rounded-b-xl shadow-lg flex flex-col overflow-auto border-b border-white/20">
        <div className="border-b border-[#282929] p-5 text-white uppercase flex justify-between">
          <h2>
            {currentPage?.includes("/competition")
              ? "Competition Search"
              : "Event search"}
          </h2>
          <div
            onClick={clearSearch}
            className="w-6 h-6 flex justify-center items-center bg-white/80 rounded-full cursor-pointer"
          >
            <img
              alt="user"
              src="/images/icons8-close-50.png"
              className="w-4 h-4"
            />
          </div>
        </div>
        <div className="w-full flex flex-col text-[.9em] mb-auto">
          {searchResults?.length > 0 && currentPage?.includes("/event") ? (
            searchResults.map((event, index) => {
              const accessArray = event?.access?.filter(
                (itm) => itm?.studio_id === user?.uid
              );
              return (
                <div
                  key={index}
                  className={`w-full p-5 flex gap-3 items-center ${
                    accessArray?.length === 0
                      ? "cursor-not-allowed hover:bg-white/20"
                      : "cursor-pointer hover:bg-[#94a4fd]/40"
                  }`}
                  onClick={() => {
                    if (accessArray?.length !== 0) {
                      navigate(
                        `/studio/event/${event?.event_name.replace(/ /g, "-")}`
                      );
                      clearSearch();
                      dispatch(clearSearchResult());
                    }
                  }}
                >
                  {capitalizeFirstLetter(event.event_name)}
                  {accessArray?.length === 0 ? (
                    <img
                      alt="arrow"
                      src="/images/LockSimple.png"
                      className="w-4 h-4"
                    />
                  ) : (
                    <img
                      alt="arrow"
                      src="/images/LockSimpleOpen.png"
                      className="w-4 h-4"
                    />
                  )}
                </div>
              );
            })
          ) : searchResults.length > 0 &&
            currentPage?.includes("/competition") ? (
            searchResults.map((event, index) => (
              <div
                key={index}
                className="w-full p-5 cursor-pointer hover:bg-[#94a4fd]/40 relative"
                onClick={() => {
                  handleViewComp(event);
                  dispatch(clearSearchResult());
                }}
              >
                {capitalizeFirstLetter(event.competitionName)}
                {unsubscribed === event.competitionName && (
                  <p className="absolute bottom-[2px] left-5 text-red-400 text-[.75em]">
                    Subscribe to this competition to view *
                  </p>
                )}
              </div>
            ))
          ) : (
            <p className="text-center pt-[80px] text-white/60 text-[1rem]">
              No results for keyword "
              <span className="font-bold">{searchTerm}</span>"
            </p>
          )}
        </div>
        {searchResults.length > 0 && (
          <h1 className="border-t border-[#282929] p-5 text-[#94a4fd] text-[.9em]">
            All results for "<span className="font-bold">{searchTerm}</span>"
          </h1>
        )}
      </div>
    </div>
  );
};

export default SearchResultTray;
