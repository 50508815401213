/* eslint-disable react-hooks/exhaustive-deps */
import { useFetchScheduleDocs } from "components/hooks/useFetchScheduleDocs";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import {
  addEntry,
  clearSuccess,
  deleteAllRoutines,
} from "redux/routineScheduling/routineSchedulingSlice";
import { restoreDeleteEntryService } from "services/RoutineScheduleService";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const DeletedView = ({ dropDown, toggleOpen, entryTypes }) => {
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { currentEvent } = useSelector((state) => state.eventManagement);

  const { addEntryPending } = useSelector((state) => state.routineScheduling);

  const { deleteAllRoutinesPending, deleteAllRoutinesSuccess } = useSelector(
    (state) => state.routineScheduling
  );

  const dispatch = useDispatch();

  //=================================================================================to get deleted categories

  //to get all categories
  // const { status, data: allCategories } = useQueryAllSubCollectionDocs(
  //   "competitions",
  //   user?.uid,
  //   "deleted_categories"
  // );

  // const eventCategoriesRaw =
  //   allCategories?.filter(
  //     (cat) => cat?.event_uid === currentEvent?.event_uid
  //   ) || [];

  // const eventCategories = removeDuplicateCategories(eventCategoriesRaw);

  //to get all entries
  const { data: allEntries } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "deleted_entries"
  );

  const deletedEntriesEvent = allEntries?.filter(
    (x) => x?.event_uid === currentEvent?.event_uid
  );

  //to get all categories
  const { status, data: eventCategories } = useFetchScheduleDocs(
    "competitions",
    user?.uid,
    "categories",
    "event_uid",
    currentEvent?.event_uid
  );

  let sortedCategories = eventCategories?.sort(
    (a, b) => a?.sort_id - b?.sort_id
  );

  //to get all existing entries
  const { data: allEntriesReal } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "entries"
  );

  const existingEventEntries = allEntriesReal?.filter(
    (x) => x?.event_uid === currentEvent?.event_uid
  );

  //to get all existing entries
  const { data: allDancers } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "dancers"
  );

  // console.log("allDancers", allDancers);

  const [deleteAll, setDeleteAll] = useState(false);
  function toggleDelete() {
    setDeleteAll((prev) => !prev);
  }

  useEffect(() => {
    if (deleteAllRoutinesSuccess?.success) {
      dispatch(clearSuccess());
      toggleDelete();
    }
  }, [deleteAllRoutinesSuccess]);

  // function removeDuplicateCategories(arrayOfObjects) {
  //   const uniqueCategories = new Set();
  //   const resultArray = [];

  //   for (const obj of arrayOfObjects) {
  //     const categoryName = obj.category_name;

  //     if (!uniqueCategories.has(categoryName)) {
  //       uniqueCategories.add(categoryName);
  //       resultArray.push(obj);
  //     }
  //   }

  //   return resultArray;
  // }

  //========================================================to restore

  const handleRestore = async () => {
    const formData = {
      ...dropDown,
      id: generateUID(),
      sort_id: assignSortId(allEntries),
      entry_count: assignSortId(allEntries),
    };
    dispatch(
      addEntry({
        formData,
        entryTypes,
        currentEvent,
        compId: user?.uid,
        uniqueId: generateUniqueId(),
        sort_id: assignSortId(),
        event_id: currentEvent?.event_name,
      })
    );
    await restoreDeleteEntryService({
      compId: user?.uid,
      doc_id: dropDown?.doc_id,
    });
  };

  function generateUniqueId() {
    const timestamp = new Date().getTime().toString();
    const randomNum = Math.floor(Math.random() * 900000000000) + 100000000000;
    const uniqueId = `${timestamp}${randomNum}`;
    return uniqueId.substring(0, 15);
  }

  function generateUID() {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uid = "";
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      uid += chars.charAt(randomIndex);
    }
    return uid;
  }

  const assignSortId = (array) => {
    if (array && Array.isArray(array) && array.length > 0) {
      const maxSortId = Math.max(...array.map((obj) => obj?.sort_id || 0));
      const newSortId = maxSortId + 1;

      return newSortId;
    } else {
      // If the allEntries array is empty or not valid, start with sort_id 1
      return 1;
    }
  };

  return (
    <>
      {sortedCategories?.length > 0 && (
        <p
          onClick={toggleDelete}
          className="w-fit text-[.85rem] underline text-right my-3 cursor-pointer hover:text-red-400"
        >
          Delete All
        </p>
      )}
      <div className="w-full overflow-x-auto">
        {status === "loading" && (
          <div className="w-full h-[200px] border border-white/20 rounded-lg flex justify-center items-center">
            <PulseLoader color="#94a4fd" size={10} />
          </div>
        )}
        {sortedCategories?.length > 0 &&
          status === "success" &&
          sortedCategories?.map((item, index) => {
            const myEntries = allEntries?.filter(
              (entr) => entr?.category_name === item?.category_name
            );
            return (
              <div
                key={index}
                className={`w-full text-white/80 bg-[#1c1c1c] flex flex-col transition-all duration-700 ${
                  myEntries?.length === 0 && "hidden"
                }`}
              >
                {" "}
                <div className="w-full flex justify-center"></div>
                <div className="w-full">
                  <div className="min-w-[500px] lg:min-w-[900px]">
                    <div className="w-full p-3 bg-[#282929] md:text-center font-bold ">
                      <h2 className="">
                        {capitalizeFirstLetter(item?.category_name)}
                      </h2>
                    </div>

                    {myEntries?.map((entry, index) => {
                      const duplicate = existingEventEntries?.filter(
                        (x) =>
                          x?.entry_name?.toLowerCase() ===
                          entry?.entry_name?.toLowerCase()
                      );
                      const dancerExists = entry?.dancers_selected?.map(
                        (dancer) => {
                          const exists = allDancers?.filter(
                            (x) => x?.id === dancer?.id
                          )[0];
                          return exists;
                        }
                      );

                      const validate =
                        dancerExists?.length ===
                          entry?.dancers_selected?.length &&
                        duplicate?.length === 0
                          ? true
                          : false;

                      return (
                        <div key={index} className="relative">
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              validate && toggleOpen(entry);
                            }}
                            className={`w-full flex p-3 border-b border-white/10 hover:bg-white/20 text-[.85rem] ${
                              validate ? "cursor-pointer" : "cursor-not-allowed"
                            }`}
                          >
                            <div className="flex gap-[50px] mr-auto">
                              <p className="whitespace-nowrap min-w-[120px]">
                                {capitalizeFirstLetter(entry?.entry_name)}
                              </p>
                            </div>
                            <div className="w-[50%] flex gap-[50px]">
                              <p className="">A</p>
                              <p
                                className={`mr-auto whitespace-nowrap w-[350px] px-2 rounded-md relative ${
                                  entry?.dancers_selected?.length >= 2
                                    ? "truncate hover:bg-[#94a4fd]/40 cursor-pointer"
                                    : entry?.dancers_selected?.length >= 2
                                    ? "truncate"
                                    : ""
                                }`}
                              >
                                {entry?.dancers_selected?.map(
                                  (dancer, index) => (
                                    <React.Fragment key={index}>
                                      {index > 0 && ", "}
                                      <span>
                                        {capitalizeFirstLetter(
                                          dancer?.first_name
                                        )}{" "}
                                        {capitalizeFirstLetter(
                                          dancer?.last_name
                                        )}
                                      </span>
                                    </React.Fragment>
                                  )
                                )}
                              </p>
                            </div>
                          </div>
                          {dropDown?.doc_id === entry?.doc_id && (
                            <ul className="w-[200px] px-3 py-2 border border-white/30 rounded-lg">
                              <li
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRestore();
                                }}
                                className="p-1 hover:bg-white/20 cursor-pointer text-[.85rem]"
                              >
                                {addEntryPending
                                  ? "Restoring..."
                                  : "Restore Entry"}
                              </li>
                            </ul>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        {status === "success" && deletedEntriesEvent?.length === 0 && (
          <div className="w-full h-[200px] border border-white/10 text-white/50 rounded-xl flex justify-center items-center">
            <h4>Nothing yet...</h4>
          </div>
        )}
      </div>

      {deleteAll && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/80 flex justify-center mod">
          <div className="w-full sm:w-[450px] h-fit p-5 bg-[#282929] rounded-lg flex flex-col gap-5 justify-center items-center scale shadow-lg box">
            <div className="w-full text-[.85em] sm:text-[1rem] text-white pb-3 border-b border-[#c5c4c4]/50 relative">
              <p>Confirm Delete all?</p>
              <div
                onClick={() => toggleDelete()}
                className="w-5 h-5 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-[-10px] md:top-0 right-[-10px] md:right-3"
              >
                <img
                  alt="user"
                  src="/images/icons8-close-50.png"
                  className="w-3 h-3"
                />
              </div>
            </div>
            <button
              onClick={() => {
                dispatch(
                  deleteAllRoutines({
                    compId: user?.uid,
                    event_uid: currentEvent?.event_uid,
                    currentEvent,
                  })
                );
              }}
              className="w-full px-2 py-3 rounded-lg bg-red-500 hover:bg-red-500/60 text-white"
              disabled={deleteAllRoutinesPending}
            >
              {deleteAllRoutinesPending ? "Deleting..." : "Delete All"}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default DeletedView;
