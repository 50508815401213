import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import EventSelectionStudio from "components/DanceStudio/EventSelectionStudio";
import {
  EventSelection,
  EventDetails,
  Overview,
  OverviewDetails,
  Registrations,
  Tabulator,
  GrandAward,
  TopScorer,
  Invoice,
  UserManagement,
} from "components/EventManagement";
import {
  DanceGenre,
  Eligibility,
  Login,
  OrganizerRegistration,
  Pricing,
  Scheduling,
  Scoring,
} from "components/Organizer";
import {
  CompetitionSelectEmcee,
  EmceeDashboard,
  EmceeLogin,
  EmceeRegistration,
  EventSelectEmcee,
  StageManagerLogin,
  StageManagerRegistration,
} from "components/emcee";
import { StudioRegistration, StudioInfo } from "components/DanceStudio";
import Home from "components/Organizer/Home";
import EventDetailsStudio from "components/DanceStudio/EventDetailsStudio";
import Dancers from "components/DanceStudio/Dancers";
import Entries from "components/DanceStudio/Entries";
import Scores from "components/DanceStudio/Scores";
import DancersFee from "components/DanceStudio/DancersFee";
import AuthGuard from "services/AuthGuard";
import { useSelector } from "react-redux";
import CompetitionSelectionStudio from "components/DanceStudio/CompetitionSelectionStudio";
import { useAuth } from "components/hooks/useAuth";
import NotFound from "components/404/NotFound";
import "./utils/checkbox.css";
import RoutineScheduler from "components/EventManagement/RoutineScheduler";
import StageManager from "components/EventManagement/StageManager";
// import Dnd from "components/Dnd";
import { JudgeRegistration } from "components/Judge";
import JudgeLogin from "components/Judge/JudgeLogin";
import JudgeQualifications from "components/Judge/JudgeQualifications";
import JudgeDashboard from "components/Judge/JudgeDashboard";
import CompetitionSelectJudge from "components/Judge/CompetitionSelectJudge";
import EventSelectJudge from "components/Judge/EventSelectJudge";
import SessionAwards from "components/EventManagement/SessionAwards";
import OverallAwardsReport from "components/EventManagement/OverallAwardsReport";
import GrandAwardsReport from "components/EventManagement/GrandAwardsReport";
import CustomReports from "components/EventManagement/CustomReports";
import AwardLabels from "components/EventManagement/AwardLabels";
import Settings from "components/DanceStudio/Settings";
import Musics from "components/DanceStudio/Musics";
import CompetitonSettings from "components/EventManagement/CompetitonSettings";
import InvoicePageContainer from "components/EventManagement/InvoicePageContainer";
import ReceiptPageContainer from "components/EventManagement/ReceiptPageContainer";
import ForgotPsw from "components/Authentication/ForgotPsw";
import OverviewStudio from "components/DanceStudio/OverviewStudio";
import ReceiptStudioContainer from "components/DanceStudio/ReceiptStudioContainer";
import ScheduleStudio from "components/DanceStudio/ScheduleStudio";
import StudioOwners from "components/EventManagement/StudioOwners";
import UserDancers from "components/EventManagement/UserDancers";
import UserJudges from "components/EventManagement/UserJudges";
import UserEmcees from "components/EventManagement/UserEmcees";
import UserStageManager from "components/EventManagement/UserStageManager";
import PrintScoresPageCont from "components/DanceStudio/PrintScoresPageCont";
import Competitions from "components/Media/Competitions";
import ParentStudent from "components/Media/ParentStudent";
import EventsPick from "components/Media/EventsPick";
import TopScorers from "components/Media/TopScorers";
import PrintScheduleCont from "components/DanceStudio/PrintScheduleCont";
import EditSessionsPage from "components/EventManagement/components/EditSessionsPage";
import GlobalSettings from "components/EventManagement/GlobalSettings";
import Contact from "components/DanceStudio/Contact";
import PasswordResetConfirmation from "components/Organizer/PasswordResetConfirmation";
import Music from "components/EventManagement/Music";
import RegDancers from "components/EventManagement/RegDancers";
import EventScoreVisibility from "components/EventManagement/EventScoreVisibility";
import EventStudioCode from "components/EventManagement/EventStudioCode";
import DivisionRankVisibility from "components/EventManagement/DivisionRankVisibility";
import TopScorersByGenreSettings from "components/EventManagement/TopScoresByGenreSettings";
import ScoreVisibilityGlobal from "components/EventManagement/ScoreVisibilityGlobal";
import DivRankVisibilityGlobal from "components/EventManagement/DivRankVisibilityGlobal";
import TopScorersSettingsGlobal from "components/EventManagement/TopScorersSettingsGlobal";
import DefaultScoringValues from "components/EventManagement/DefaultScoringValues";
import StudioCodeGlobal from "components/EventManagement/StudioCodeGlobal";
import EmailNotVerified from "components/Authentication/EmailNotVerified";
import DancerSFeeDetails from "components/DanceStudio/DancerSFeeDetails";
import DancersFeePageContainer from "components/DanceStudio/DancersFeePageContainer";
import RoutineScheduler2 from "components/EventManagement/RoutineScheduler2";
import PrintRoutineCont from "components/EventManagement/PrintRoutineCont";
import MoveCategories from "components/EventManagement/MoveCategories";

function App() {
  useAuth();
  const isAuthenticated = useSelector(
    (state) => state.persistedReducer.auth.user
  );
  const formCompleted = useSelector(
    (state) => state.persistedReducer.auth.user?.formCompleted
  );
  const organizerCustomClaim = isAuthenticated?.role === "organizer";
  const studioCustomClaim = isAuthenticated?.role === "danceStudioOwner";

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/forgot-password" element={<ForgotPsw />} />
        <Route path="/email-not-verified" element={<EmailNotVerified />} />

        <Route
          path="/password-reset-success"
          element={<PasswordResetConfirmation />}
        />

        <Route path="/login" element={<Login />} />
        {/* <Route path="/dnd" element={<Dnd />} /> */}

        <Route path="/competitions" element={<Competitions />} />
        <Route path="/competitions/:competitionName" element={<EventsPick />} />
        <Route
          path="/competitions/:competitionName/:event_name/top-scores"
          element={<TopScorers />}
        />
        <Route
          path="/competitions/:competitionName/:event_name/parent-student"
          element={<ParentStudent />}
        />

        {/* judge screen */}
        <Route path="/judge-registration" element={<JudgeRegistration />} />
        <Route path="/judge-login" element={<JudgeLogin />} />
        {/* <Route path="/judge-qualifications" element={<JudgeQualifications />} /> */}
        <Route
          path="/judge-competitions"
          element={<CompetitionSelectJudge />}
        />
        <Route
          path="/judge/:competitionName/:event_name"
          element={<JudgeDashboard />}
        />
        <Route path="/judge/:competitionName" element={<EventSelectJudge />} />
        <Route
          path="/judge/:competitionName/qualifications"
          element={<JudgeQualifications />}
        />

        {/* emcee screen */}
        <Route path="/emcee-registration" element={<EmceeRegistration />} />
        <Route path="/emcee-login" element={<EmceeLogin />} />
        {/* stage-manager screen */}
        <Route
          path="/stage-registration"
          element={<StageManagerRegistration />}
        />
        <Route path="/stage-login" element={<StageManagerLogin />} />
        <Route
          path="/emcee-competitions"
          element={<CompetitionSelectEmcee />}
        />
        <Route
          path="/emcee/:competitionName/:event_name"
          element={<EmceeDashboard />}
        />
        <Route
          path="/stage-manager/:competitionName/:event_name"
          element={<StageManager />}
        />
        <Route path="/emcee/:competitionName" element={<EventSelectEmcee />} />

        <Route
          path="/organizer"
          element={
            isAuthenticated && organizerCustomClaim ? (
              formCompleted ? (
                // <OrganizerRegistration />
                <Navigate to="/organizer/event-selection" replace />
              ) : (
                <Navigate to="/organizer/genre" replace />
              )
            ) : (
              // <Navigate to="/login" replace />
              <OrganizerRegistration />
            )
          }
        />

        <Route
          path="/studio"
          element={
            isAuthenticated && studioCustomClaim ? (
              formCompleted ? (
                // <OrganizerRegistration />
                <Navigate to="/studio/competition-selection" replace />
              ) : (
                <Navigate to="/info" replace />
              )
            ) : (
              // <Navigate to="/login" replace />
              <StudioRegistration />
            )
          }
        />
        {/* Protect all other organizer routes */}
        <Route
          path="/organizer/*"
          element={
            isAuthenticated && organizerCustomClaim ? (
              <AuthGuard>
                {formCompleted ? (
                  <>
                    <Route
                      path="event-selection"
                      element={<EventSelection />}
                    />
                    <Route
                      path="global-settings"
                      element={<GlobalSettings />}
                    />
                    <Route
                      path="global-settings/score-visibility-global"
                      element={<ScoreVisibilityGlobal />}
                    />
                    <Route
                      path="global-settings/div-visibility-global"
                      element={<DivRankVisibilityGlobal />}
                    />
                    <Route
                      path="global-settings/global-top-scorers"
                      element={<TopScorersSettingsGlobal />}
                    />
                    <Route
                      path="global-settings/default-scoring-values"
                      element={<DefaultScoringValues />}
                    />
                    <Route
                      path="global-settings/studio-code-global"
                      element={<StudioCodeGlobal />}
                    />
                    <Route
                      path="global-settings/accounting-invoice-settings"
                      element={<Invoice />}
                    />
                    <Route
                      path="event/:event_name/competition-settings"
                      element={<CompetitonSettings />}
                    />
                    <Route
                      path="event/:event_name/competition-settings/event-scores-visibility"
                      element={<EventScoreVisibility />}
                    />
                    <Route
                      path="event/:event_name/competition-settings/event-studio-code"
                      element={<EventStudioCode />}
                    />
                    <Route
                      path="event/:event_name/competition-settings/div-rank-visibility"
                      element={<DivisionRankVisibility />}
                    />
                    <Route
                      path="event/:event_name/competition-settings/genre-top-scorers"
                      element={<TopScorersByGenreSettings />}
                    />
                    <Route
                      path="event/:event_name/user-management"
                      element={<UserManagement />}
                    />
                    <Route
                      path="event/:event_name/studio-owners"
                      element={<StudioOwners />}
                    />
                    <Route
                      path="event/:event_name/dancers"
                      element={<UserDancers />}
                    />
                    <Route
                      path="event/:event_name/judges"
                      element={<UserJudges />}
                    />
                    <Route
                      path="event/:event_name/all-emcees"
                      element={<UserEmcees />}
                    />
                    <Route
                      path="event/:event_name/all-stage-managers"
                      element={<UserStageManager />}
                    />

                    <Route
                      path="event/:event_name"
                      element={<EventDetails />}
                    />

                    <Route
                      path="event/:event_name/award"
                      element={<GrandAward />}
                    />
                    <Route
                      path="event/:event_name/top-score"
                      element={<TopScorer />}
                    />
                    <Route
                      path="event/:event_name/registrations"
                      element={<Registrations />}
                    />
                    <Route
                      path="event/:event_name/registrations-dancers"
                      element={<RegDancers />}
                    />
                    <Route path="event/:event_name/music" element={<Music />} />

                    <Route
                      path="event/:event_name/tabulator"
                      element={<Tabulator />}
                    />
                    <Route
                      path="event/:event_name/schedule"
                      element={<RoutineScheduler2 />}
                    />
                    <Route
                      path="event/:event_name/schedule-move-category"
                      element={<MoveCategories />}
                    />
                    <Route
                      path="event/:event_name/report-session-awards"
                      element={<SessionAwards />}
                    />
                    <Route
                      path="event/:event_name/report-session-awards/:presetUid"
                      element={<EditSessionsPage />}
                    />
                    <Route
                      path="event/:event_name/report-overall-awards"
                      element={<OverallAwardsReport />}
                    />
                    <Route
                      path="event/:event_name/report-grand-awards"
                      element={<GrandAwardsReport />}
                    />
                    <Route
                      path="event/:event_name/report-custom-reports"
                      element={<CustomReports />}
                    />
                    <Route
                      path="event/:event_name/report-award-labels"
                      element={<AwardLabels />}
                    />
                    <Route
                      path="event/:event_name/accounting-overview"
                      element={<Overview />}
                    />
                    <Route
                      path="event/:event_name/accounting-overview/:studio_id"
                      element={<OverviewDetails />}
                    />
                    <Route
                      path="event/:event_name/accounting-overview/:studio_id/invoice"
                      element={<InvoicePageContainer />}
                    />
                    <Route
                      path="event/:event_name/accounting-overview/:studio_id/receipt/:doc_id"
                      element={<ReceiptPageContainer />}
                    />
                    <Route
                      path="event/:event_name/schedule/print"
                      element={<PrintRoutineCont />}
                    />
                    <Route path="*" element={<NotFound />} />
                  </>
                ) : (
                  <>
                    <Route path="/genre" element={<DanceGenre />} />
                    <Route path="/eligibility" element={<Eligibility />} />
                    <Route path="scoring" element={<Scoring />} />
                    <Route path="scheduling" element={<Scheduling />} />
                    <Route path="pricing" element={<Pricing />} />
                  </>
                )}
              </AuthGuard>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />

        <Route path="/studio" element={<StudioRegistration />} />
        {/* Protect all other studio routes */}
        <Route
          path="/studio/*"
          element={
            isAuthenticated && studioCustomClaim ? (
              <AuthGuard>
                {formCompleted ? (
                  <>
                    <Route
                      path="competition-selection"
                      element={<CompetitionSelectionStudio />}
                    />
                    <Route
                      path="event-selection"
                      element={<EventSelectionStudio />}
                    />
                    <Route
                      path="event/:event_name"
                      element={<EventDetailsStudio />}
                    />
                    <Route
                      path="event/:event_name/dancers"
                      element={<Dancers />}
                    />
                    <Route
                      path="event/:event_name/entries"
                      element={<Entries />}
                    />
                    <Route
                      path="event/:event_name/schedule"
                      element={<ScheduleStudio />}
                    />
                    <Route
                      path="event/:event_name/accounting-overview"
                      element={<OverviewStudio />}
                    />
                    <Route
                      path="event/:event_name/dancers-fees"
                      element={<DancersFee />}
                    />
                    <Route
                      path="event/:event_name/dancers-fees/:dancer_uid"
                      element={<DancerSFeeDetails />}
                    />
                    <Route
                      path="event/:event_name/dancers-fees/:dancer_uid/invoice"
                      element={<DancersFeePageContainer />}
                    />
                    <Route
                      path="event/:event_name/accounting-overview/:studio_id/receipt/:doc_id"
                      element={<ReceiptStudioContainer />}
                    />
                    <Route
                      path="event/:event_name/scores"
                      element={<Scores />}
                    />
                    <Route
                      path="event/:event_name/scores/print"
                      element={<PrintScoresPageCont />}
                    />
                    <Route
                      path="event/:event_name/schedule/print"
                      element={<PrintScheduleCont />}
                    />
                    <Route
                      path="event/:event_name/musics"
                      element={<Musics />}
                    />
                    <Route path="settings" element={<Settings />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="*" element={<NotFound />} />
                  </>
                ) : (
                  // <Navigate to="/studio" replace />
                  <Route path="info" element={<StudioInfo />} />
                )}
              </AuthGuard>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
