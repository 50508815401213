/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { clearSuccess, editAccount } from "redux/danceStudio/danceStudioSlice";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";
// import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import Spinner from "utils/Spinner";

const SettingsStudioOwner = ({ status, studioData }) => {
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { editAccountSuccess, editAccountPending } = useSelector(
    (state) => state.danceStudio
  );

  const [detailsData, setDetailsData] = useState({
    ownerFirstName: studioData?.ownerFirstName,
    ownerLastName: studioData?.ownerLastName,
    // email: studioData?.email,
    // studioName: studioData?.studioName,
  });

  useEffect(() => {
    if (studioData) {
      setDetailsData({
        ownerFirstName: studioData?.ownerFirstName,
        ownerLastName: studioData?.ownerLastName,
        // email: studioData?.email,
        // studioName: studioData?.studioName,
      });
    }
  }, [studioData]);
  // console.log("detailsData", detailsData);

  function handleChange(e) {
    const { value, id } = e.target;
    setDetailsData((prev) => {
      return {
        ...prev,
        [id]: value,
      };
    });
  }

  const [edit, setEdit] = useState(false);
  function toggleEdit() {
    setEdit((prev) => !prev);
    setDetailsData({
      ownerFirstName: studioData?.ownerFirstName,
      ownerLastName: studioData?.ownerLastName,
      // email: studioData?.email,
      // studioName: studioData?.studioName,
    });
  }

  const handleKeyDownText = (e) => {
    const allowedCharacters = /^[a-zA-Z']*$/;
    const key = e.key;

    if (!allowedCharacters.test(key) && key !== "Backspace") {
      e.preventDefault();
    }
  };

  const dispatch = useDispatch();
  function handleSubmit(e) {
    e.preventDefault();
    if (detailsData?.ownerFirstName && detailsData?.ownerLastName) {
      dispatch(editAccount({ uid: user?.uid, detailsData }));
    }
  }

  useEffect(() => {
    if (editAccountSuccess?.success) {
      setEdit(false);
      setTimeout(() => {
        dispatch(clearSuccess());
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 2000);
    }
  }, [editAccountSuccess]);

  return (
    <div>
      <h1 className="w-full border-b pb-2 font-bold border-white/30 mb-3">
        Studio Owner
      </h1>
      <div className="w-full flex bg-white/10 p-3 rounded-lg mb-3">
        First name:{" "}
        <div className="font-medium pl-2">
          {" "}
          {status === "loading" ? (
            <Spinner />
          ) : (
            <div>
              {edit ? (
                <input
                  type="text"
                  id="ownerFirstName"
                  value={detailsData?.ownerFirstName}
                  onChange={handleChange}
                  onKeyDown={(e) => handleKeyDownText(e)}
                  className="w-full px-2 rounded-md outline-none border border-[#94a4fd] bg-inherit"
                />
              ) : (
                detailsData?.ownerFirstName
              )}
            </div>
          )}
        </div>
      </div>
      <div className="w-full flex bg-white/10 p-3 rounded-lg mb-3">
        Last name:{" "}
        <div className="font-medium pl-2">
          {" "}
          {status === "loading" ? (
            <Spinner />
          ) : edit ? (
            <input
              type="text"
              id="ownerLastName"
              value={detailsData?.ownerLastName}
              onChange={handleChange}
              onKeyDown={(e) => handleKeyDownText(e)}
              className="w-full px-2 rounded-md outline-none border border-[#94a4fd] bg-inherit"
            />
          ) : (
            <div>{detailsData?.ownerLastName}</div>
          )}{" "}
        </div>
      </div>
      <div className="w-full flex bg-white/10 p-3 rounded-lg mb-3">
        Email:{" "}
        <div className="font-medium pl-2">
          {" "}
          {status === "loading" ? (
            <Spinner />
          ) : (
            <div>{studioData?.email.toLowerCase()}</div>
          )}
        </div>
      </div>
      <div className="w-full flex bg-white/10 p-3 rounded-lg mb-3">
        Studio name:{" "}
        <div className="font-medium pl-2">
          {" "}
          {status === "loading" ? (
            <Spinner />
          ) : (
            <div>{studioData?.studioName}</div>
          )}
        </div>
      </div>

      {editAccountSuccess?.success && (
        <p className="text-[#94a4fd] ">Successfully editted!</p>
      )}
      <div className="flex gap-2 justify-end">
        <button
          onClick={toggleEdit}
          disabled={editAccountPending}
          className="px-4 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[.85rem] hover:opacity-75"
        >
          {edit ? "Cancel Edit" : "Edit Details"}
        </button>

        {edit && (
          <button
            onClick={handleSubmit}
            disabled={editAccountPending}
            className="px-4 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[.85rem] hover:opacity-75"
          >
            {editAccountPending ? (
              <p className="flex gap-2 items-center">
                Submitting.. <ButtonLoadingSpinner />
              </p>
            ) : (
              "Submit"
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default SettingsStudioOwner;
