import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  toggleEditEvent,
  toggleOffEventEditted,
  editEvent,
} from "redux/eventManagement/eventManagement";

const EditEventForm = ({ eventToEdit }) => {
  const { editEventSuccess, editEventPending } = useSelector(
    (state) => state.eventManagement
  );
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  //to format time stamp
  const timestamp = new Date().getTime();
  function formatTimeStamp(timestamp) {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const month = date.toLocaleString("default", { month: "long" });
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedTime = `${hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }, ${month} ${day}, ${year}`;
    return formattedTime;
  }

  const { data } = useQueryAllDocs("competitions");
  const organizerComp = data?.filter((item) => item?.compId === user?.uid)[0];

  const [formData, setFormData] = useState({
    old_event_name: eventToEdit?.event_name,
    event_name: eventToEdit?.event_name,
    days: eventToEdit?.days,
    year: eventToEdit?.year,
    city: eventToEdit?.city,
    address: eventToEdit?.address,
    venue: eventToEdit?.venue || "",
    tax_rate: eventToEdit?.tax_rate || "0",
    state: eventToEdit?.state || "",
    postal_code: eventToEdit?.postal_code || "",
    country: eventToEdit?.country || "",
    studios_registered: eventToEdit?.studios_registered,
    estimated_entries: eventToEdit?.estimated_entries,
    entries_submitted: eventToEdit?.entries_submitted,
    favorite: eventToEdit?.favorite,
    timestamp: eventToEdit?.timestamp,
    isMusicDownload: eventToEdit?.isMusicDownload,
    isRegistrationOpen: eventToEdit?.isRegistrationOpen,
    musicSubmittedCount: eventToEdit?.musicSubmittedCount,
    editted: formatTimeStamp(timestamp),
    event_uid: eventToEdit?.event_uid,
    scores_visibility: eventToEdit?.scores_visibility,
    studio_visibility: eventToEdit?.studio_visibility,
    genre_visibility:
      eventToEdit?.genre_visibility || organizerComp?.danceGenres || {},
    access: eventToEdit?.access,
    live_update: eventToEdit?.live_update,
    update_timestamp: eventToEdit?.update_timestamp || "",
    rank_visibility: eventToEdit?.rank_visibility,
  });

  // console.log("formData", formData);

  const currentYear = Number(
    formData?.timestamp?.split(", ")[
      formData?.timestamp?.split(", ")?.length - 1
    ]
  );

  //to handle form submit
  const [validationError, setValidationError] = useState("");
  const [editError, setEditError] = useState(false);

  function handleSubmitEdit(e) {
    e.preventDefault();
    const old_days = eventToEdit?.days;
    const new_days = formData?.days;

    const isEqual =
      old_days?.length === new_days?.length &&
      old_days?.every((item, index) => item === new_days[index]);
    if (
      formData?.event_name === eventToEdit?.event_name &&
      formData?.year === eventToEdit?.year &&
      formData?.venue === eventToEdit?.venue &&
      formData?.city === eventToEdit?.city &&
      formData?.address === eventToEdit?.address &&
      formData?.state === eventToEdit?.state &&
      formData?.country === eventToEdit?.country &&
      formData?.postal_code === eventToEdit?.postal_code &&
      formData?.tax_rate === eventToEdit?.tax_rate &&
      formData?.estimated_entries === eventToEdit?.estimated_entries &&
      isEqual
    ) {
      setEditError(true);
    } else if (
      !formData?.event_name ||
      formData.days?.length < 1 ||
      formData.year?.includes("undefined") ||
      !formData?.city ||
      !formData?.address ||
      !formData?.venue ||
      !formData?.state ||
      !formData?.country ||
      !formData?.postal_code ||
      !formData?.tax_rate
    ) {
      setValidationError("Fill all fields and add at least one day");
    } else if (Number(formData?.year) < currentYear) {
      setValidationError(`Start date cannot be before ${currentYear}`);
    } else {
      const data = { ...formData, event_name: formData?.event_name?.trim() };

      dispatch(editEvent({ data, uid: user?.uid }));
    }
  }

  const dispatch = useDispatch();

  const [dates, setDates] = useState(eventToEdit?.days);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  // const [selectedEndDate, setSelectedEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
    setValidationError("");
    setAddDayErr("");
  };
  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
    setValidationError("");
    setAddDayErr("");
  };

  const handleStartDateChange = (event) => {
    setSelectedStartDate(event.target.value);
    setValidationError("");
    setAddDayErr("");
    setEditError(false);
  };

  const [addDayErr, setAddDayErr] = useState("");

  const handleAddDatesClick = (e) => {
    e.preventDefault();
    const currentDate = new Date().toISOString().split("T")[0]; // Get current date in 'YYYY-MM-DD' format

    if (
      selectedStartDate !== "" &&
      startTime !== "" &&
      endTime !== "" &&
      Number(startTime?.replace(/:/g, "")) < endTime?.replace(/:/g, "") &&
      selectedStartDate?.split("-")[0]?.length < 5 &&
      selectedStartDate >= currentDate // Check if selectedStartDate is today or in the future
    ) {
      // Check if selectedStartDate is already added
      if (dates.length > 0) {
        const lastStartDate = new Date(dates[dates.length - 1].startTime)
          .toISOString()
          .split("T")[0];
        const selectedStartDateWithoutTime = new Date(selectedStartDate)
          .toISOString()
          .split("T")[0];

        if (selectedStartDateWithoutTime === lastStartDate) {
          setAddDayErr("A day with this start date is already added.");
          return;
        }
      }

      //convert start time to iso string
      const startTimeIsoString = new Date(
        `${selectedStartDate}T${startTime}:00Z`
      ).toISOString();

      //convert end time to iso string
      const endTimeIsoString = new Date(
        `${selectedStartDate}T${endTime}:00Z`
      ).toISOString();

      setDates([
        ...dates,
        {
          // startDate: selectedStartDate,
          startTime: startTimeIsoString,
          endTime: endTimeIsoString,
        },
      ]);
      setSelectedStartDate("");
      setStartTime("");
      setEndTime("");
      setValidationError("");
      setAddDayErr(""); // Clear any previous error message
    } else if (!selectedStartDate) {
      setAddDayErr("Add a start date");
    } else if (!startTime) {
      setAddDayErr("Add a start time");
    } else if (!endTime) {
      setAddDayErr("Add an end time");
    } else if (
      Number(startTime?.replace(/:/g, "")) >= endTime?.replace(/:/g, "")
    ) {
      setAddDayErr("End time cannot be earlier or equal to start time");
    } else if (selectedStartDate?.split("-")[0]?.length > 4) {
      setAddDayErr(`Invalid year: "${selectedStartDate?.split("-")[0]}"`);
    } else if (selectedStartDate < currentDate) {
      setAddDayErr("Selected date should be today or in the future");
    }
  };

  const handleRemoveDateClick = (dateToRemove) => {
    setDates(dates?.filter((date) => date !== dateToRemove));
    setEditError(false);
  };
  // console.log("formData", formData);

  useEffect(() => {
    setFormData((prev) => {
      return {
        ...prev,
        days: dates,
        year: dates[0]?.startTime?.split("-")[0],
      };
    });
  }, [dates]);

  function handleFormChange(event) {
    const { id, value } = event.target;
    setEditError(false);
    setValidationError("");
    setFormData((prev) => {
      return {
        ...prev,
        [id]: value || value.trim(),
      };
    });
  }

  const [success, setSuccess] = useState(false);
  useEffect(() => {
    if (editEventSuccess) {
      setSuccess(true);
      const timeoutId = setTimeout(() => {
        setSuccess(false);
        dispatch(toggleOffEventEditted());
        dispatch(toggleEditEvent());
        window.location.reload();
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editEventSuccess]);

  return (
    <>
      <form className="">
        <div className="w-full sm:min-w-[400px] lg:w-[60%] md:min-w-[550px] min-h-[600px] bg-[#282929] rounded-xl p-4 sm:p-7 mx-auto flex flex-col gap-8">
          <h2 className="text-white font-medium text-center text-[1.25rem]">
            Edit
          </h2>
          <div>
            <div
              className={`w-full lg:w-[80%] p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500`}
            >
              <label
                htmlFor="event_name"
                className="text-[.75rem] text-white/50"
              >
                Event Name
              </label>
              <input
                type="text"
                id="event_name"
                onChange={handleFormChange}
                value={formData.event_name}
                className="bg-[#0c0c0d] outline-none text-white"
                placeholder="E.g. Versastyle Ottawa"
              />
            </div>
          </div>

          <div>
            <div
              className={`w-full lg:w-[80%] p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                !formData?.venue &&
                "border border-red-500/70"
              }`}
            >
              <label htmlFor="venue" className="text-[.75rem] text-white/40">
                Venue
              </label>
              <input
                type="text"
                id="venue"
                onChange={handleFormChange}
                value={formData?.venue}
                className="bg-[#0c0c0d] outline-none text-white"
                placeholder="E.g. Scotia Bank Theatre"
              />
            </div>
          </div>

          <div>
            <div
              className={`w-full lg:w-[80%] p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                !formData?.address &&
                "border border-red-500/70"
              }`}
            >
              <label htmlFor="address" className="text-[.75rem] text-white/50">
                Address
              </label>
              <input
                type="text"
                id="address"
                onChange={handleFormChange}
                value={formData.address}
                className="bg-[#0c0c0d] outline-none text-white"
                placeholder="E.g. 123 Fake St."
              />
            </div>
          </div>
          <div className="w-full lg:w-[80%] flex lg:flex-row flex-col flex-wrap gap-3 justify-between ">
            <div
              className={`w-full lg:w-[46%] lg:min-w-[180px] p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError && !formData?.city && "border border-red-500/70"
              }`}
            >
              <label htmlFor="city" className="text-[.75rem] text-white/40">
                City
              </label>
              <input
                type="text"
                id="city"
                onChange={handleFormChange}
                value={formData.city}
                className="bg-[#0c0c0d] outline-none text-white"
                placeholder="E.g. Ottawa"
              />
            </div>
            <div
              className={`w-full lg:w-[46%] lg:min-w-[180px] p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                !formData?.state &&
                "border border-red-500/70"
              }`}
            >
              <label htmlFor="state" className="text-[.75rem] text-white/40">
                State/province
              </label>
              <input
                type="text"
                id="state"
                onChange={handleFormChange}
                value={formData.state}
                className="bg-[#0c0c0d] outline-none text-white"
                placeholder="E.g. Ontario"
              />
            </div>
          </div>
          <div className="w-full lg:w-[80%] flex lg:flex-row flex-col flex-wrap gap-3 justify-between">
            <div
              className={`w-full lg:w-[46%] lg:min-w-[180px] p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                !formData?.postal_code &&
                "border border-red-500/70"
              }`}
            >
              <label
                htmlFor="postal_code"
                className="text-[.75rem] text-white/40"
              >
                Postal code
              </label>
              <input
                type="text"
                id="postal_code"
                onChange={handleFormChange}
                value={formData.postal_code}
                className="bg-[#0c0c0d] outline-none text-white"
                placeholder="M1M M2M"
              />
            </div>
            <div
              className={`w-full lg:w-[46%] lg:min-w-[180px] p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                !formData?.country &&
                "border border-red-500/70"
              }`}
            >
              <label htmlFor="country" className="text-[.75rem] text-white/40">
                Country
              </label>
              <input
                type="text"
                id="country"
                onChange={handleFormChange}
                value={formData.country}
                className="bg-[#0c0c0d] outline-none text-white"
                placeholder="E.g. Canada"
              />
            </div>
          </div>

          <div className="w-full lg:w-[80%] flex lg:flex-row flex-col flex-wrap gap-3 ">
            <div
              className={`w-full lg:w-[45%] lg:min-w-[180px] p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                !formData?.estimated_entries &&
                "border border-red-500/70"
              }`}
            >
              <label
                htmlFor="estimated_entries"
                className="text-[.75rem] text-white/40"
              >
                Max # of Total Entries
              </label>
              <input
                type="number"
                id="estimated_entries"
                onChange={handleFormChange}
                value={formData?.estimated_entries}
                className="bg-[#0c0c0d] outline-none text-white"
                placeholder="E.g. 900"
              />
            </div>

            <div
              className={`w-full lg:w-[45%] lg:min-w-[180px] p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                !formData?.tax_rate &&
                "border border-red-500/70"
              }`}
            >
              <label htmlFor="tax_rate" className="text-[.75rem] text-white/40">
                Tax rate
              </label>
              <input
                type="number"
                id="tax_rate"
                onChange={handleFormChange}
                value={formData?.tax_rate}
                className="bg-[#0c0c0d] outline-none text-white"
                placeholder="E.g. 15%"
              />
            </div>
          </div>
          <div>
            {/* <p className="text-white mb-3">Day {dates?.length + 1}</p> */}
            {/* <p className="text-[.85rem] text-white/50 mb-2">
              NOTE: Add dates in order of start to finish
            </p> */}
            <div className="w-full flex flex-col gap-3">
              <div className="w-full md:w-1/2 flex flex-col gap-3">
                <div
                  className={`w-full p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                    addDayErr === "Add a start date" &&
                    "border border-red-400/70"
                  }`}
                >
                  <label
                    htmlFor="start"
                    className="text-[.75rem] text-white/40"
                  >
                    Start Date
                  </label>
                  <div className="flex gap-2 items-center mt-1">
                    <input
                      type="date"
                      id="start"
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      className="w-full bg-[#0c0c0d] outline-none text-white mr-auto"
                      placeholder="Select date & time"
                    />
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/2 flex flex-col gap-3">
                <div
                  className={`w-full p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                    addDayErr === "Add a start time" &&
                    "border border-red-400/70"
                  }`}
                >
                  <label
                    htmlFor="startTime"
                    className="text-[.75rem] text-white/40"
                  >
                    Start time:
                  </label>
                  <input
                    type="time"
                    id="startTime"
                    value={startTime}
                    onChange={handleStartTimeChange}
                    className="bg-[#0c0c0d] outline-none text-white w-full mt-1"
                  />
                </div>

                <div
                  className={`w-full p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 relative ${
                    addDayErr === "Add an end time" &&
                    "border border-red-400/70"
                  }`}
                >
                  <label
                    htmlFor="endTime"
                    className="text-[.75rem] text-white/40"
                  >
                    End time:
                  </label>
                  <input
                    type="time"
                    id="endTime"
                    value={endTime}
                    onChange={handleEndTimeChange}
                    className="bg-[#0c0c0d] outline-none text-white w-full mt-1"
                  />
                </div>
              </div>
            </div>

            {addDayErr && (
              <p className="text-red-400 text-[.75rem] mt-2">{addDayErr}</p>
            )}
            <button
              onClick={handleAddDatesClick}
              className="text-[.9rem] px-5 py-1 mt-3 mb-4 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/80 text-[#1c1c1c] transition-all duration-300"
            >
              Add Day{" "}
            </button>
            <br />

            {dates
              ?.slice()
              ?.sort((a, b) => {
                // Parse dates and compare them
                const dateA = new Date(a?.startTime);
                const dateB = new Date(b?.startTime);
                return dateA - dateB;
              })
              ?.map((date, index) => (
                <div
                  key={index}
                  className="w-fit text-white/80 text-[.85rem] bg-[#0c0c0d] pl-3 pr-7 py-4 my-2 rounded-xl flex flex-col flex-wrap gap-2 relative"
                >
                  <p className="leading-[.75rem]">
                    Date:{" "}
                    <span className="font-bold">
                      {date?.startTime?.split("T")[0]}
                    </span>
                  </p>
                  <p className="leading-[.75rem]">
                    Time:{" "}
                    <span className="font-bold">
                      {`${moment(date?.startTime)
                        .utc()
                        .format("HH:mm")} - ${moment(date?.endTime)
                        .utc()
                        .format("HH:mm")}`}
                    </span>
                  </p>
                  <div
                    onClick={() => handleRemoveDateClick(date)}
                    className="w-4 h-4 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-1 right-1"
                  >
                    <img
                      alt="user"
                      src="/images/icons8-close-50.png"
                      className="w-3 h-3"
                    />
                  </div>
                </div>
              ))}
            {validationError && (
              <div className="w-full text-red-400 text-[.85rem] border border-red-500 bg-red-400/10 mt-5 px-3 py-2 rounded-lg">
                {validationError}
              </div>
            )}
            {editError && (
              <div className="w-full text-red-400 text-[.85rem] border border-red-500 bg-red-400/10 mt-5 px-3 py-2 rounded-lg">
                There have been no edits made
              </div>
            )}
            {/* <button className="text-[.85rem] px-5 py-1 mt-3 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300">
              Add Day
            </button> */}
          </div>
        </div>
        <div className="w-full sm:w-[500px] lg:w-[60%] mx-auto mt-7 text-end">
          <button
            onClick={(e) => {
              e.preventDefault();
              dispatch(toggleEditEvent());
            }}
            className="text-[.85rem] px-5 py-1 mr-4 rounded-lg bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
          >
            Discard
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleSubmitEdit(e);
              // closeEventAddedMod();
            }}
            className="text-[.9rem] px-5 py-1 rounded-lg bg-[#94a4fd] hover:bg-[#94a4fd]/80 text-[#1c1c1c] transition-all duration-300"
          >
            {editEventPending ? "Editing..." : "Save Changes"}
          </button>
        </div>
      </form>

      {/* //the event editted successfully modal */}
      {success && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-4 md:py-8 md:px-10 bg-[#282929] rounded-lg flex gap-5 justify-start items-center scale shadow-lg relative">
            <div
              onClick={() => {
                dispatch(toggleOffEventEditted());
                dispatch(toggleEditEvent());
              }}
              className="w-5 h-5 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-3 right-3"
            >
              <img
                alt="user"
                src="/images/icons8-close-50.png"
                className="w-3 h-3"
              />
            </div>
            <img
              alt="success"
              src="/images/icons8-checkmark-64.png"
              className="w-10 h-10"
            />
            <p className="text-[1rem] text-white">
              Successfully editted Event:{" "}
              <span className="font-bold">{formData.event_name}</span>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default EditEventForm;
