import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  addJudgeCriteria,
  removeJudgeCriteria,
} from '../../../redux/organizerRegistration/organizerSlices';
import RemoveImg from '../../../assets/remove.png';
import { toast } from 'react-toastify';

const judgesCriteriaSchema = Yup.object().shape({
  judgesCriteria: Yup.string().required('judgesCriteria is required'),
  judgesPercent: Yup.string().required('Required'),
  shortCode: Yup.string()
    .required('Short code is required')
    .matches(/^[^0-9]*$/, 'short code should not contain numbers'),
});

const JudgesCriteria = () => {
  const dispatch = useDispatch();
  const { judgeCriteria } = useSelector((state) => state.persistedReducer);

  const [judgesTag, setJudgesTag] = useState({
    isTieBreaker: false,
    requiresQualification: false,
  });

  const handleJudgesTagChange = (event) => {
    const { id, checked } = event.target;
    setJudgesTag((prevTag) => ({
      ...prevTag,
      [id]: checked,
    }));
  };

  const formik = useFormik({
    initialValues: {
      judgesCriteria: '',
      judgesPercent: '',
      shortCode: '',
    },
    validationSchema: judgesCriteriaSchema,

    onSubmit: (values, { resetForm }) => {
      if (formik.isValid) {
        const lowercaseValues = {
          ...values,
          judgesCriteria: values.judgesCriteria.toLowerCase(),
          shortCode: values.shortCode.toLowerCase(),
          isTieBreaker: judgesTag.isTieBreaker,
          requiresQualification: judgesTag.requiresQualification,
        };

        const totalPercentage = judgeCriteria.reduce(
          (sum, { judgesPercent }) => sum + parseInt(judgesPercent),
          0
        );
        if (totalPercentage + parseInt(lowercaseValues.judgesPercent) > 100) {
          toast.error('Total percentage must not be greater than 100');
        } else {
          dispatch(addJudgeCriteria(lowercaseValues));

          resetForm();
          setJudgesTag({
            isTieBreaker: false,
            requiresQualification: false,
          });
        }
      }
    },
  });

  // check if the judgesCriteria or shortCode already exists
  const index = judgeCriteria.findIndex(
    (criteria) =>
      criteria.judgesCriteria.toLowerCase() ===
        formik.values.judgesCriteria.toLowerCase() ||
      criteria.shortCode.toLowerCase() === formik.values.shortCode.toLowerCase()
  );

  if (index !== -1) {
    if (
      judgeCriteria[index].judgesCriteria.toLowerCase() ===
      formik.values.judgesCriteria.toLowerCase()
    ) {
      formik.errors.judgesCriteria = 'Judges Criteria already exists';
    }
    if (
      judgeCriteria[index].shortCode.toLowerCase() ===
      formik.values.shortCode.toLowerCase()
    ) {
      formik.errors.shortCode = 'Short Code already exists';
    }
  }

  const handleRemove = (index) => {
    dispatch(removeJudgeCriteria(index));
  };

  return (
    <div className="w-full mt-8 form-control sm:mt-6">
      <label className="label w-[75%]">
        <span className="mb-2 text-base font-medium label-text text-[#696F79]">
          What are your judging criteria, the percentage value and short code
          for each?
        </span>
      </label>
      <div className="flex flex-row flex-wrap items-baseline px-1 mb-[0.75rem]">
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col w-full lg:w-[75%] md:w-[75%] mr-4"
        >
          <div className="flex flex-col md:flex-row items-center justify-between w-full gap-3">
            <div className="w-full flex flex-col gap-2">
              <input
                name="judgesCriteria"
                type="text"
                placeholder="Judging Criteria"
                onChange={formik.handleChange}
                value={formik.values.judgesCriteria}
                className={`input input-bordered border-[#959FB1] h-[38px] w-full md:w-[100%] text-sm font-medium text-[#9FA8B8] dark:text-white mr-[7px] placeholder-[#8692A6] ${
                  formik.errors.judgesCriteria &&
                  formik.touched.judgesCriteria &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {formik.errors.judgesCriteria &&
                formik.touched.judgesCriteria && (
                  <span className="text-xs text-red-500">
                    {formik.errors.judgesCriteria}
                  </span>
                )}
            </div>
            <div className="w-full flex flex-col gap-2">
              <input
                type="text"
                name="judgesPercent"
                placeholder="%"
                onChange={formik.handleChange}
                value={formik.values.judgesPercent}
                className={`input input-bordered border-[#959FB1] h-[38px] w-full md:w-[100%] text-sm font-medium text-[#9FA8B8] dark:text-white mr-[7px] placeholder-[#8692A6] ${
                  formik.errors.judgesPercent &&
                  formik.touched.judgesPercent &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {formik.errors.judgesPercent && formik.touched.judgesPercent && (
                <span className="text-xs text-red-500">
                  {formik.errors.judgesPercent}
                </span>
              )}
            </div>
            <div className="w-full flex flex-col gap-2">
              <input
                type="text"
                name="shortCode"
                placeholder="Short Code"
                onChange={formik.handleChange}
                value={formik.values.shortCode}
                className={`input input-bordered border-[#959FB1] h-[38px] w-full md:w-[100%] text-sm font-medium text-[#9FA8B8] dark:text-white mr-[7px] placeholder-[#8692A6] ${
                  formik.errors.shortCode &&
                  formik.touched.shortCode &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {formik.errors.shortCode && formik.touched.shortCode && (
                <span className="text-xs text-red-500">
                  {formik.errors.shortCode}
                </span>
              )}
            </div>

            <button
              type="submit"
              // check if the form is valid before submitting
              className="max-h-[3rem] bg-[#1565D8] rounded-[5px] text-white capitalize h-[31px] px-[4rem] ml-2 md:px-9 text-[1rem]"
            >
              add
            </button>
          </div>
          <div className="w-full mt-4 md:mt-[1rem] flex md:flex-row flex-wrap justify-start gap-3 xs:flex-col">
            <div className="flex items-center xs:mx-4">
              <input
                type="checkbox"
                id="isTieBreaker"
                className="mr-2 w-4 h-4"
                checked={judgesTag.isTieBreaker}
                onChange={handleJudgesTagChange}
              />
              <label
                htmlFor="isTieBreaker"
                className="text-[#696F79] text-base font-medium"
              >
                Used to break ties
              </label>
            </div>
            <div className="flex items-center xs:mx-4">
              <input
                type="checkbox"
                id="requiresQualification"
                className="mr-2 w-4 h-4"
                checked={judgesTag.requiresQualification}
                onChange={handleJudgesTagChange}
              />
              <label
                htmlFor="requiresQualification"
                className="text-[#696F79] text-base font-medium"
              >
                Requires judges qualification
              </label>
            </div>
          </div>
        </form>
      </div>
      <div className="flex flex-row flex-wrap w-fit justify-start items-center py-2 rounded-xl cursor-pointer">
        {judgeCriteria.map((type, index) => (
          <div
            key={index}
            className="flex flex-row flex-wrap w-fit mb-[8px] mr-[10px] bg-[#FFE600] items-center p-[10px] py-2 rounded-md cursor-pointer"
          >
            <span className="text-sm font-medium text-[#8692A6] ">
              {`${type.judgesCriteria}, ${type.judgesPercent}%, ${
                type.shortCode
              },
              ${type.isTieBreaker ? 'Used to break ties' : ''}, ${
                type.requiresQualification
                  ? 'Requires judges qualification'
                  : ''
              }
              `}
            </span>
            <img
              src={RemoveImg}
              alt="remove"
              className="h-4 w-4 ml-[10px]"
              onClick={() => handleRemove(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default JudgesCriteria;
