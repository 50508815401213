import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Components
import ImgWrap from '../UI/ImgWrapper';
import Container from '../UI/Container';
import Logo from '../../assets/Logo.png';
import Button from '../Form/Button';
import CustomTag from './Scheduling/CustomTag';
import SplitCategory from './Scheduling/SplitCategory';

import { toast } from 'react-toastify';

const Scheduling = () => {
  const navigate = useNavigate();

  const [splitCategories, setSplitCategories] = useState('');

  const goToPreviousPage = () => {
    navigate('/organizer/scoring');
  };

  const goToNextPage = () => {
    // check if splitCategories is not empty if empty show toast and return otherwise navigate to the next page
    if (!splitCategories) {
      toast.info('Please enter values for splitCategories');
      return;
    }
    navigate('/organizer/pricing');
  };

  return (
    <>
      <div className="flex flex-row flex-wrap h-[100vh] w-full">
        <div className="lg:flex hidden lg:w-[40%]">
          <ImgWrap className="bg-[url('../public/Frame7.png')]" />
        </div>
        <div className="lg:w-[60%] w-full">
          <Container className="flex flex-wrap lg:px-[6%]">
            <div className="flex flex-row justify-between w-full lg:justify-end">
              <div className="block lg:hidden">
                <Link to="/">
                  <img
                    src={Logo}
                    alt="Logo"
                    className="cursor-pointer w-[90px] h-[25px]"
                  />
                </Link>
              </div>
              <div className="flex flex-col items-end">
                <span className="text-sm font-medium text-[#BDBDBD]">
                  STEP 05/06
                </span>
                <h4 className="text-base font-semibold text-[#8692A6]">
                  Organizer Registration
                </h4>
              </div>
            </div>

            <div className="flex flex-col flex-wrap pt-[5%] w-full">
              <div className="lg:pl-[5%] mt-[3rem] md:mt-[2rem]">
                <h1 className="mb-3 text-3xl font-bold text-[#000000]">
                  Scheduling
                </h1>
                <p className="text-lg font-normal text-[#8692A6]">
                  Determining how scheduling details.
                </p>
                <div className="border-t border-base w-[60%] mt-5"></div>
              </div>
              <div className="flex flex-wrap mt-5 lg:pl-[5%]">
                {/* The segment pertaining to Break Time */}

                {/* End of the segment pertaining to Break Time */}

                {/* The segment pertaining to split category */}

                <SplitCategory
                  splitCategories={splitCategories}
                  setSplitCategories={setSplitCategories}
                />

                {/* End of the segment pertaining to split category */}

                {/* The segment pertaining to custom name */}

                <CustomTag />

                {/* End of the segment pertaining to Custom name */}

                {/* btns */}

                <div className="flex flex-row justify-end mt-[25%] mb-[15px] w-full">
                  <Button
                    text="Previous"
                    handleOnClick={goToPreviousPage}
                    className="bg-[#1565D8] border-[#1565D8] text-white rounded-[5px] btn-sm capitalize text-base font-medium mr-3 h-8"
                  />
                  <Button
                    text="Next"
                    handleOnClick={goToNextPage}
                    className="bg-[#1565D8] border-[#1565D8] text-white rounded-[5px] btn-sm capitalize text-base font-medium h-8"
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Scheduling;
