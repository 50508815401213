export default function groupObjectsByEntryType(
  inputObject,
  calculateTotalScore,
  calcAverage
) {
  const result = {};

  for (const key in inputObject) {
    if (inputObject?.hasOwnProperty(key)) {
      const originalArray = inputObject[key];

      // Create an object to store groups
      const groupedByEntryType = {
        group1: [],
        group2: [],
      };

      originalArray?.forEach((obj) => {
        const entryType = obj?.entryType;

        if (entryType === "solo" || entryType === "duo/trio") {
          groupedByEntryType?.group1?.push(obj);
        } else {
          groupedByEntryType?.group2?.push(obj);
        }
      });

      const groupedArray = [
        calcAverage(
          groupedByEntryType?.group1
            ?.map((obj) => calculateTotalScore(obj))
            ?.filter((x) => x !== "- -")
            ?.sort((a, b) => b - a)
            ?.slice(0, 6)
        )?.toFixed(2),

        calcAverage(
          groupedByEntryType?.group2
            ?.map((obj) => calculateTotalScore(obj))
            ?.filter((x) => x !== "- -")
            ?.sort((a, b) => b - a)
            ?.slice(0, 4)
        )?.toFixed(2),
      ];

      result[key] = groupedArray;
    }
  }

  return result;
}
