const FormattingTable = ({ formData, handleInputChange }) => {
  const styles = { width: "50%", paddingLeft: "10px" };
  const headstyles = { width: "50%", padding: "10px 15px" };

  return (
    <>
      <table
        className="w-full text-[.9rem] bg-[#323232]"
        style={{ tableLayout: "fixed" }}
      >
        <thead className="text-white text-left border-b border-white/30">
          <tr>
            <th style={headstyles} className="border-x border-black/30">
              Type
            </th>
            <th style={headstyles} className="border-x border-black/30">
              Code
            </th>
          </tr>
        </thead>
        <tbody className="text-gray-300/50">
          <tr className={`relative border border-black/30`}>
            <td className="border-x border-black/30" style={styles}>
              Entry Name
            </td>
            <td className="border-x border-black/30" style={styles}>
              <p className="p-1">{formData.entry_name}</p>
              {/* <input
                type="text"
                name="entry_name"
                value={formData.entry_name}
                className="w-full outline-none bg-inherit"
                onChange={handleInputChange}
              /> */}
            </td>
          </tr>
          <tr className={`relative border border-black/30`}>
            <td className="border-x border-black/30" style={styles}>
              Entry Number
            </td>
            <td className="border-x border-black/30" style={styles}>
              <p className="p-1">{formData.entry_num}</p>

              {/* <input
                type="text"
                name="entry_num"
                value={formData.entry_num}
                className="w-full outline-none bg-inherit"
                onChange={handleInputChange}
              /> */}
            </td>
          </tr>
          <tr className={`relative border border-black/30`}>
            <td className="border-x border-black/30" style={styles}>
              Studio Code
            </td>
            <td className="border-x border-black/30" style={styles}>
              <p className="p-1">{formData.studio_code}</p>

              {/* <input
                type="text"
                name="studio_code"
                value={formData.studio_code}
                className="w-full outline-none bg-inherit"
                onChange={handleInputChange}
              /> */}
            </td>
          </tr>
          <tr className={`relative border border-black/30`}>
            <td className="border-x border-black/30" style={styles}>
              Dancers
            </td>
            <td className="border-x border-black/30" style={styles}>
              <p className="p-1">{formData.dancers}</p>

              {/* <input
                type="text"
                name="dancers"
                value={formData.dancers}
                className="w-full outline-none bg-inherit"
                onChange={handleInputChange}
              /> */}
            </td>
          </tr>
          <tr className={`relative border border-black/30`}>
            <td className="border-x border-black/30" style={styles}>
              Number of Dancers
            </td>
            <td className="border-x border-black/30" style={styles}>
              <p className="p-1">{formData.num_dancers}</p>

              {/* <input
                type="text"
                name="num_dancers"
                value={formData.num_dancers}
                className="w-full outline-none bg-inherit"
                onChange={handleInputChange}
              /> */}
            </td>
          </tr>
          <tr className={`relative border border-black/30`}>
            <td className="border-x border-black/30" style={styles}>
              Age Division
            </td>
            <td className="border-x border-black/30" style={styles}>
              <p className="p-1">{formData.age_division}</p>
              {/* <input
                type="text"
                name="age_division"
                value={formData.age_division}
                className="w-full outline-none bg-inherit"
                onChange={handleInputChange}
              /> */}
            </td>
          </tr>
          <tr className={`relative border border-black/30`}>
            <td className="border-x border-black/30" style={styles}>
              Classification
            </td>
            <td className="border-x border-black/30" style={styles}>
              <p className="p-1">{formData.classification}</p>
              {/* <input
                type="text"
                name="classification"
                value={formData.classification}
                className="w-full outline-none bg-inherit"
                onChange={handleInputChange}
              /> */}
            </td>
          </tr>
          <tr className={`relative border border-black/30`}>
            <td className="border-x border-black/30" style={styles}>
              Genre
            </td>
            <td className="border-x border-black/30" style={styles}>
              <p className="p-1">{formData.genre}</p>
              {/* <input
                type="text"
                name="genre"
                value={formData.genre}
                className="w-full outline-none bg-inherit"
                onChange={handleInputChange}
              /> */}
            </td>
          </tr>
          <tr className={`relative border border-black/30`}>
            <td className="border-x border-black/30" style={styles}>
              Entry Type
            </td>
            <td className="border-x border-black/30" style={styles}>
              <p className="p-1">{formData.entry_type}</p>
              {/* <input
                type="text"
                name="entry_type"
                value={formData.entry_type}
                className="w-full outline-none bg-inherit"
                onChange={handleInputChange}
              /> */}
            </td>
          </tr>
          <tr className={`relative border border-black/30`}>
            <td className="border-x border-black/30" style={styles}>
              Special Tag
            </td>
            <td className="border-x border-black/30" style={styles}>
              <p className="p-1">{formData.special_tag}</p>
              {/* <input
                type="text"
                name="special_tag"
                value={formData.special_tag}
                className="w-full outline-none bg-inherit"
                onChange={handleInputChange}
              /> */}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default FormattingTable;
