// import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  clearSuccess,
  editDancers,
  toggleEditDancers,
} from 'redux/danceStudio/danceStudioSlice';
import '../../../utils/checkbox.css';
import moment from 'moment';

const EditDancerForm = ({ dancerToEdit, currentEvent }) => {
  const { editDancersSuccess, editDancersPending } = useSelector(
    (state) => state.danceStudio
  );
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );
  const { compId, competitionLevel, eligibilityDivisions } =
    danceStudioCompData.value;

  // console.log("eligibilityDivisions", eligibilityDivisions);

  const dispatch = useDispatch();

  const timestamp = new Date().getTime();

  function formatTimeStamp(timestamp) {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedTime = `${hours}:${
      minutes < 10 ? '0' + minutes : minutes
    }, ${month} ${day}, ${year}`;
    return formattedTime;
  }

  // const tags = scheduling?.customTags;

  const [formData, setFormData] = useState({
    studioId: dancerToEdit?.studioId,
    studio_name: dancerToEdit?.studio_name,
    first_name: dancerToEdit?.first_name,
    last_name: dancerToEdit?.last_name,
    classification: dancerToEdit?.classification,
    date_of_birth: dancerToEdit?.date_of_birth,
    gender: dancerToEdit?.gender,
    notes: dancerToEdit?.notes,
    last_update: formatTimeStamp(timestamp),
    entries: dancerToEdit?.entries || 0,
    scheduling_status: dancerToEdit?.scheduling_status,
    dancer_uid: dancerToEdit?.dancer_uid,
  });

  // console.log('formData', formData);

  function convertDateFormat(dateString) {
    // Check if the date string is in "yyyy-mm-dd" format
    const regex = /^(\d{4})-(\d{2})-(\d{2})$/;
    if (regex.test(dateString)) {
      return dateString; // Return the date as is if it's already in the desired format
    }

    // Try to parse the date in different formats
    const dateFormats = ['M/D/YY', 'YYYY-MM-DD', 'MM-DD-YYYY'];
    for (const format of dateFormats) {
      const parsedDate = moment(dateString, format, true); // Parse the date with strict parsing
      if (parsedDate.isValid()) {
        return parsedDate.format('YYYY-MM-DD');
      }
    }

    return dateString; // Return the original date string if none of the formats match
  }

  //to handle form submit
  const [validationError, setValidationError] = useState(false);
  const [editError, setEditError] = useState(false);
  const [dobErr, setDobErr] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();

    const today = new Date().setHours(0, 0, 0, 0);
    const dob = new Date(convertDateFormat(formData.date_of_birth)).setHours(
      0,
      0,
      0,
      0
    );

    if (
      formData?.first_name &&
      formData?.last_name &&
      formData?.classification &&
      formData?.date_of_birth &&
      formData?.gender &&
      dob <= today
    ) {
      dispatch(
        editDancers({
          formData,
          uid: user?.uid,
          compId,
          eligibilityDivisions,
          event_uid: currentEvent?.event_uid,
        })
      );
    } else if (dob >= today) {
      setDobErr(true);
    } else {
      setValidationError(true);
    }
  }

  function handleFormChange(event) {
    const { id, value } = event.target;
    setValidationError(false);
    setEditError(false);
    setDobErr(false);
    if ((id === 'first_name' || id === 'last_name') && /\d/.test(value)) {
      // Check if the value contains a number
      return; // Exit the function without updating the state
    }

    if (id === 'date_of_birth') {
      // Format the date to mm/dd/yyyy
      const [year, month, day] = value.split('-');
      const formattedDate = `${month}-${day}-${year}`;
      setFormData((prev) => {
        return {
          ...prev,
          [id]: formattedDate,
        };
      });
    } else {
      setFormData((prev) => {
        return {
          ...prev,
          [id]: value,
        };
      });
    }
  }

  // const handleCheckboxChange = (event) => {
  //   const { value, checked } = event.target;
  //   const updatedspecial_tags = checked
  //     ? [...formData.special_tags, value]
  //     : formData.special_tags.filter((checkbox) => checkbox !== value);
  //   setFormData({ ...formData, special_tags: updatedspecial_tags });
  // };

  //to close dancers added modal
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (editDancersSuccess) {
      setSuccess(true);
      const timeoutId = setTimeout(() => {
        setSuccess(false);
        dispatch(clearSuccess());
      }, 1500);
      return () => clearTimeout(timeoutId);
    }
  }, [editDancersSuccess, dispatch]);

  return (
    <>
      <form className="">
        <div className="w-full sm:w-[500px] lg:w-[60%] lg:min-w-[550px] bg-[#282929] rounded-xl p-4 sm:p-7 mx-auto flex flex-col gap-4">
          <h2 className="font-bold text-white/80">Edit Dancer</h2>
          <div className="flex md:flex-row flex-col gap-4">
            <div
              className={`w-full md:w-1/2 p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError && 'border border-red-500/60'
              }`}
            >
              <div>
                <label
                  htmlFor="first_name"
                  className="text-[.75rem] text-white/40"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="first_name"
                  onChange={handleFormChange}
                  value={formData?.first_name}
                  className="w-full bg-[#0c0c0d] outline-none text-white"
                  placeholder="E.g. John"
                />
              </div>
            </div>
            <div
              className={`w-full md:w-1/2 p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError && 'border border-red-500/60'
              }`}
            >
              <div>
                <label
                  htmlFor="last_name"
                  className="text-[.75rem] text-white/40"
                >
                  Last name
                </label>
                <input
                  type="text"
                  id="last_name"
                  onChange={handleFormChange}
                  value={formData?.last_name}
                  className="w-full bg-[#0c0c0d] outline-none text-white"
                  placeholder="E.g. Doe"
                />
              </div>
            </div>
          </div>

          <div className="flex md:flex-row flex-col gap-4">
            <div
              className={`w-full md:w-1/2 p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError && 'border border-red-500/60'
              }`}
            >
              <div>
                <label
                  htmlFor="classification"
                  className="text-[.75rem] text-white/40"
                >
                  Classification
                </label>
                <select
                  id="classification"
                  onChange={handleFormChange}
                  value={formData?.classification}
                  className="w-full bg-[#0c0c0d] outline-none text-white/80 cursor-pointer"
                >
                  <option value="DEFAULT" disabled hidden>
                    Select
                  </option>

                  {competitionLevel?.map((item, index) => {
                    return (
                      <option key={index} value={item?.levelName}>
                        {item?.levelName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div
              className={`w-full md:w-1/2 p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError && 'border border-red-500/60'
              }`}
            >
              <label
                htmlFor="date_of_birth"
                className="text-[.75rem] text-white/40"
              >
                Date of birth
              </label>
              <div className="flex gap-2 items-center mt-1">
                <input
                  type="date"
                  id="date_of_birth"
                  onChange={handleFormChange}
                  defaultValue={convertDateFormat(formData?.date_of_birth)}
                  className="w-full bg-[#0c0c0d] outline-none text-white mr-auto"
                />
              </div>
            </div>
          </div>

          <div
            className={`w-full md:w-1/2 p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
              validationError && 'border border-red-500/60'
            }`}
          >
            <label htmlFor="gender" className="text-[.75rem] text-white/40">
              Gender
            </label>
            <select
              id="gender"
              onChange={handleFormChange}
              value={formData?.gender}
              className="w-full bg-[#0c0c0d] mt-2 outline-none text-white/70 cursor-pointer"
            >
              <option value="female">Female</option>
              <option value="male">Male</option>
              <option value="transgender">Transgender</option>
              <option value="non-binary">Non-binary</option>
              <option value="prefer not to respond">
                Prefer not to respond
              </option>
            </select>
          </div>

          {/* <div className="w-full p-3 rounded-xl bg-[#0c0c0d]">
            <p className="text-[.75rem] text-white/40">Special tags</p>
            <div className="flex gap-5 md:gap-10 flex-wrap mt-2 text-white/40 text-[.75rem] checkbox-container">
              {tags?.map((value, index) => (
                <div
                  key={index}
                  className="text-white/60 text-[.75em] flex items-center"
                >
                  <input
                    type="checkbox"
                    name="checkboxes"
                    className="cursor-pointer"
                    value={value?.customTags}
                    checked={formData.special_tags.includes(value?.customTags)}
                    onChange={handleCheckboxChange}
                  />
                  {value?.customTags}
                </div>
              ))}
            </div>
          </div> */}

          <div className="w-full p-3 rounded-xl bg-[#0c0c0d]">
            <label htmlFor="notes" className="text-[.75rem] text-white/40">
              Additional Notes
            </label>
            <textarea
              id="notes"
              onChange={handleFormChange}
              value={formData?.notes}
              className="w-full bg-[#0c0c0d] outline-none text-white"
            />
          </div>

          {validationError && (
            <div className="w-full text-red-400 text-[.85rem] border border-red-500 bg-red-400/10 mt-5 px-3 py-2 rounded-xl">
              PLease fill all required fields
            </div>
          )}
          {dobErr && (
            <div className="w-full text-red-400 text-[.85rem] border border-red-500 bg-red-400/10 mt-5 px-3 py-2 rounded-xl">
              Invalid date of birth
            </div>
          )}
          {editError && (
            <div className="w-full text-red-400 text-[.85rem] border border-red-500 bg-red-400/10 mt-5 px-3 py-2 rounded-lg">
              There have been no edits made
            </div>
          )}
          <div className="w-full mx-auto mt-7 text-end">
            <button
              onClick={(e) => {
                e.preventDefault();
                dispatch(toggleEditDancers());
              }}
              className="text-[.85rem] px-5 py-1 mr-4 rounded-lg bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
            >
              Discard
            </button>
            <button
              onClick={(e) => {
                handleSubmit(e);
              }}
              disabled={editDancersPending}
              className="text-[.9rem] px-5 py-1 rounded-lg bg-[#94a4fd] hover:bg-[#94a4fd]/80 text-[#1c1c1c] transition-all duration-300"
            >
              {editDancersPending ? 'Adding...' : 'Save Changes'}
            </button>
          </div>
        </div>
      </form>

      {/* the submitting loader */}
      {success && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-5 md:py-8 md:px-10 bg-[#282929] rounded-lg flex gap-5 justify-start items-center scale shadow-lg relative">
            <img
              alt="success"
              src="/images/icons8-checkmark-64.png"
              className="w-10 h-10"
            />
            <p className="text-[1rem] text-white text-center">
              Successfully Edited Dancer:{' '}
              <span className="font-bold">
                {formData.first_name} {formData.last_name}{' '}
              </span>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default EditDancerForm;
