import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import AddEventForm from "./components/AddEventForm";
import ScrollToTop from "./ScrollToTop";
import { useParams } from "react-router-dom";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import GrandAwardCate from "./components/GrandAwardCate";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "services/firebase";
import { PulseLoader } from "react-spinners";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const GrandAward = () => {
  const { eventsData, addEvent, openNotifsBoard } = useSelector(
    (state) => state.eventManagement
  );
  const { event_name } = useParams();
  const currentEvent = eventsData.filter(
    (item) => item.event_name.replace(/ /g, "-") === event_name
  )[0];

  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { data: compData } = useFirestoreQueryDoc("competitions", user?.uid);
  const [entryTypes, setentryTypes] = useState([]);
  useEffect(() => {
    if (compData) {
      const { entryTypes } = compData;
      setentryTypes(entryTypes);
    }
  }, [compData]);

  const { status, data: allCategories } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "categories"
  );

  const eventCate = allCategories?.filter(
    (cat) => cat?.event_uid === currentEvent?.event_uid
  );
  let sortedCategories = eventCate?.sort((a, b) => a?.sort_id - b?.sort_id);

  //to get all entries
  const { data: allEntries } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "entries"
  );

  const [allScores, setAllScores] = useState([]);

  const { data } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "scores"
  );

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, "competitions", user?.uid, "scores")),
      (snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data());
        setAllScores(data);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [user?.uid]);

  useEffect(() => {
    setAllScores(data);
  }, [data]);

  // Gets the award name for an entry within a category
  const { data: organizerData } = useFirestoreQueryDoc("organizers", user?.uid);

  const awards = organizerData?.awards;
  function findMatchingAward(awards, totalScore) {
    for (let i = 0; i < awards?.length; i++) {
      const award = awards[i];
      if (totalScore >= award.minScore && totalScore <= award.maxScore) {
        return award;
      }
    }
    return null;
  }

  // Calculate average total score for an entry
  // const calculateTotalScore = (entry) => {
  //   const scores = allScores?.filter(
  //     (item) =>
  //       item?.doc_id === entry?.doc_id &&
  //       item?.event_name === currentEvent?.event_name
  //   );

  //   const totalScore = scores?.reduce(
  //     (sum, score) => sum + parseInt(score?.total || 0),
  //     0
  //   );

  //   const averageScore = totalScore / scores?.length;
  //   const decimals = 2; // Specify the desired number of decimal places
  //   const truncatedAverageScore =
  //     Math.floor(averageScore * Math.pow(10, decimals)) /
  //     Math.pow(10, decimals);
  //   return isNaN(truncatedAverageScore)
  //     ? '- -'
  //     : truncatedAverageScore?.toFixed(2);
  // };
  const calculateTotalScore = (entry) => {
    const scores = allScores?.filter(
      (item) =>
        item?.doc_id === entry?.doc_id &&
        item?.event_name === currentEvent?.event_name
    );

    const totalScore = scores?.reduce(
      (sum, score) => sum + Number(score?.total || 0),
      0
    );
    const averageScore = totalScore / scores?.length;

    const truncatedAverageScore = parseFloat(averageScore.toFixed(2));

    return isNaN(truncatedAverageScore) ? "- -" : truncatedAverageScore;
  };

  // function removeDuplicates(array) {
  //   if (array) {
  //     const uniqueArray = [];
  //     const seenObjects = new Set();

  //     for (const obj of array) {
  //       // Serialize the object to JSON to use it as a unique identifier
  //       const serializedObj = JSON.stringify(obj);

  //       if (!seenObjects.has(serializedObj)) {
  //         seenObjects.add(serializedObj);
  //         uniqueArray.push(obj);
  //       }
  //     }

  //     return uniqueArray;
  //   }
  // }

  // Calculate the highest score for a category
  const calculateHighestScore = (categoryEntries) => {
    const filteredEntries = categoryEntries?.filter(
      (x) => x?.is_scratched === false
    );
    let highestScore = 0;

    filteredEntries?.forEach((entry) => {
      const scores = allScores?.filter(
        (item) =>
          item?.doc_id === entry?.doc_id &&
          item?.event_name === currentEvent?.event_name
      );

      const totalScoreRaw = scores?.reduce(
        (sum, score) => sum + Number(score?.total || 0),
        0
      );

      const totalScore = totalScoreRaw / scores?.length;

      if (totalScore > highestScore) {
        highestScore = totalScore;
      }
    });

    return highestScore;
  };

  const calculatePosition = (entry, categoryEntries) => {
    const filteredEntries = categoryEntries?.filter(
      (x) => x?.is_scratched === false
    );
    const highestScore = calculateHighestScore(filteredEntries);
    const entryScore = calculateTotalScore(entry);

    const sortedScores = filteredEntries
      .map((entry) => calculateTotalScore(entry))
      .sort((a, b) => b - a);

    const position = sortedScores?.indexOf(entryScore) + 1;

    if (!highestScore || entryScore === "- -") {
      return "- -";
    } else if (entryScore === highestScore) {
      return `1st`;
    } else if (position === 1) {
      return `1st`;
    } else if (position === 2) {
      return `2nd`;
    } else if (position === 3) {
      return `3rd`;
    } else {
      return `${position}th`;
    }
  };

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}

      {/* event details page */}
      {!addEvent && (
        <div
          className={`w-full min-h-screen text-white/80 px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px]  ${
            openNotifsBoard ? "lg:pr-[310px]" : "lg:pr-[32px]"
          } pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col transition-all duration-700`}
        >
          {/* Heading */}
          <div className="flex gap-2 items-center text-white/90 text-[1rem] mb-6">
            <h2>
              {currentEvent?.event_name} {currentEvent?.year.split(" to ")[0]}
            </h2>
            <img alt="arrow" src="/images/ArrowDown.png" className="w-4 h-4" />
          </div>
          {/* Render the spinner if loading is true */}
          {status === "loading" && (
            <div className="flex justify-center items-center h-[400px]">
              <PulseLoader color="#94a4fd" size={15} />
            </div>
          )}
          {sortedCategories &&
            sortedCategories.length > 0 &&
            status === "success" && (
              <section className="w-full flex gap-7 justify-between">
                <div className="w-full md:min-w-[30%] min-h-[400px] text-[.9em] py-3 overflow-auto">
                  <div className="flex flex-col items-center gap-1 mb-4 text-[1.5rem] md:text-[1.75rem] font-bold">
                    <ul className="flex border py-0 px-1 cursor-pointer">
                      <li className="my-0 mx-[5px] py-0 px-[5px] border-r bg-stone-700/40 text-[19px]">
                        Entries
                      </li>
                      <li className="text-[19px]">Awards</li>
                    </ul>
                    <span className="text-[18px]">
                      {capitalizeFirstLetter(currentEvent?.event_name)}
                    </span>
                    <div className="flex gap-2 text-center cursor-pointer">
                      <img
                        src="/images/leftArrow.png"
                        alt="left icon"
                        className="w-8 h-8"
                      />
                      <span className="text-[18px]"> Session 1</span>
                      <img
                        src="/images/rightArrow.png"
                        alt="left icon"
                        className="w-8 h-8"
                      />
                    </div>
                  </div>

                  <div className="min-w-[950px] w-full">
                    {sortedCategories.map((category, index) => (
                      <GrandAwardCate
                        key={index}
                        index={index}
                        category={category}
                        allEntries={allEntries}
                        sortedCategories={sortedCategories}
                        currentEvent={currentEvent}
                        entryTypes={entryTypes}
                        calculatePosition={calculatePosition}
                        calculateTotalScore={calculateTotalScore}
                        findMatchingAward={findMatchingAward}
                        awards={awards}
                        allScores={allScores}
                      />
                    ))}
                  </div>
                </div>
              </section>
            )}

          {status === "success" && sortedCategories?.length === 0 && (
            <div className="w-full h-[200px] border border-white/10 text-white/50 rounded-xl flex justify-center items-center">
              <h4>No Awards Yet...</h4>
            </div>
          )}
        </div>
      )}
      {/* the add event pop up page */}
      {addEvent && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[330px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
              <h2>Add Event</h2>
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            {/* Add event form */}
            <div className="">
              <AddEventForm />
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}
    </>
  );
};

export default GrandAward;
