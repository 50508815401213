import { useState } from "react";
import { useDispatch } from "react-redux";
import { PulseLoader } from "react-spinners";
import {
  scratchEntry,
  unScratchEntry,
} from "redux/routineScheduling/routineSchedulingSlice";

const EmceeEntryCount = ({
  showMoveEntry,
  loading,
  toggleMoveEntry,
  categoryEntriesWithoutCurrent,
  rearrangeENtries,
  item,
  category,
  currentEvent,
  compId,
  allEntries,
  entryCount,
  rearrangeCategories,
  sortedCategories,
  setShowDropdownRow,
  handleOpenEdit,
}) => {
  const dispatch = useDispatch();

  const [jumpToValue, setJumpToValue] = useState("");
  const [jumpToError, setJumpToError] = useState(false);

  const handleInputChange = (event) => {
    const value = event.target.value;
    const filteredValue = value.replace(/[^a-zA-Z0-9]/g, "");
    setJumpToValue(filteredValue);
    setJumpToError(false);
  };

  function moveEntry() {
    const destination = allEntries?.filter((each) => {
      return (
        (currentEvent?.locked
          ? each?.locked_entry_count
          : entryCount(each)
        )?.toString() === jumpToValue
      );
    })[0];

    if (!destination) {
      setJumpToError(true);
      return;
    }

    const sourceCategory = sortedCategories?.filter(
      (x) => x?.category_name === item?.category_name
    )[0];
    const destinationCategory = sortedCategories?.filter(
      (y) => y?.category_name === destination?.category_name
    )[0];

    if (destination?.category_name === item?.category_name) {
      rearrangeENtries(item?.id, destination?.id);
    } else {
      rearrangeCategories(sourceCategory?.id, destinationCategory?.id);
    }
    toggleMoveEntry();
    setShowDropdownRow(null);
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="w-[230px] bg-[#282929] p-3 rounded-lg absolute top-0 left-0 font-normal shadow-lg z-[3]"
    >
      {showMoveEntry &&
        (loading ? (
          <div className="w-full h-[200px] flex justify-center items-center gap-6 mb-auto text-white/80">
            <PulseLoader color="#94a4fd" size={10} />
          </div>
        ) : (
          <ul className="min-h-[100px] max-h-[150px] flex flex-col overflow-y-auto overflow-x-hidden">
            <li className="w-full flex gap-2 items-center">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleMoveEntry();
                }}
                className="w-fit rounded-md bg-white/10 hover:bg-white/20 py-1 pl-2 pr-5 mb-2 text-white/60 text-[.75rem] cursor-pointer flex items-center"
              >
                <img alt="" className="w-5 h-5" src="/images/leftArrow.png" />
                Cancel
              </button>{" "}
              <p className="font-bold text-white/50">To after entry:</p>
            </li>

            <form className="w-full flex gap-3 items-center">
              <input
                type="text"
                className={`w-full p-1 rounded-md bg-white/10 outline-none ${
                  jumpToError
                    ? "border border-red-500/50"
                    : "border border-white/20"
                }`}
                placeholder="Enter #"
                value={jumpToValue}
                onChange={handleInputChange}
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  moveEntry();
                }}
                disabled={!jumpToValue}
                className={`w-fit h-fit p-2 rounded-md text-white text-[.75rem] whitespace-nowrap ${
                  jumpToValue
                    ? "bg-blue-600 hover:bg-blue-500/60"
                    : "bg-gray-600 hover:bg-gray-500/60 cursor-not-allowed"
                }`}
              >
                Move
              </button>
            </form>
            {jumpToError && (
              <p className="text-red-500 text-[.85rem] mt-1">
                No entry with entry number "{jumpToValue}"
              </p>
            )}
          </ul>
        ))}
      {!showMoveEntry && (
        <ul className="flex flex-col gap-1">
          <li
            onClick={(e) => {
              e.stopPropagation();
              toggleMoveEntry();
            }}
            className="w-full rounded-md bg-white/10 hover:bg-white/20 py-1 px-2 text-white/50 text-[.85rem]"
          >
            Move entry to after...
          </li>
          {!item?.is_scratched && (
            <li
              onClick={() => {
                handleOpenEdit(item);
              }}
              className="flex gap-2 items-center py-1 px-2 rounded-lg hover:bg-[#94a4fd]/60"
            >
              <img alt="" src="/images/editCat.png" className="w-3 h-3" /> Edit
              Entry
            </li>
          )}
          {!item?.is_scratched && (
            <li
              onClick={() =>
                dispatch(
                  scratchEntry({
                    compId,
                    doc_id: item?.doc_id,
                    currentEvent,
                  })
                )
              }
              className="flex gap-2 items-center py-1 px-2 rounded-lg hover:bg-[#94a4fd]/60"
            >
              <img alt="" src="/images/scratchCat.png" className="w-3 h-3" />{" "}
              Scratch Entry
            </li>
          )}
          {item?.is_scratched && (
            <li
              onClick={() =>
                dispatch(
                  unScratchEntry({
                    compId,
                    doc_id: item?.doc_id,
                    currentEvent,
                  })
                )
              }
              className="flex gap-2 items-center py-1 px-2 rounded-lg hover:bg-[#94a4fd]/60"
            >
              <img alt="" src="/images/scratchCat.png" className="w-3 h-3" />{" "}
              Unscratch Entry
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default EmceeEntryCount;
