/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  clearSuccess,
  deletePreset,
} from "redux/eventManagement/eventManagement";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";
import reportsNav from "../dummyData/reportsNav.json";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { useState } from "react";
import groupObjectsByEntryType from "utils/groupObjectsByEntryType";

const SessionAwardsCard = ({
  item,
  allPresets,
  selectedRow,
  setSelectedRow,
}) => {
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { deletePresetPending, deletePresetSuccess, currentEvent } =
    useSelector((state) => state.eventManagement);

  const heading = item?.title
    .split(" ")
    .filter(
      (item, index) =>
        index !== item?.title?.split(" ")?.length - 1 && item !== "Pre-Sets"
    )
    ?.join()
    ?.replace(/,/g, " ");

  const { event_name } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //to dynamically link to the respective preset page
  function linkToPreset() {
    const preset = reportsNav?.filter((linkItem) => {
      return linkItem?.target === item?.target;
    })[0];

    navigate(
      `/organizer/event/${event_name.replace(/ /g, "-")}/${preset?.link}`
    );
  }

  function getPreset() {
    if (item?.title === "Overall Pre-Sets") {
      const preset = allPresets?.filter((item) => {
        return item?.id === "overall_preset";
      })[0];
      return preset;
    } else if (item?.title === "Grand Overall Pre-Sets") {
      const preset = allPresets?.filter((item) => {
        return item?.id === "grand_preset";
      })[0];
      return preset;
    } else {
      const preset = allPresets?.filter((item) => {
        return item?.id === "custom_preset";
      })[0];
      return preset;
    }
  }

  function handleRowClick(uid) {
    setSelectedRow(uid === selectedRow ? null : uid);
  }

  function handleDelete(uid) {
    const doc =
      item?.title === "Overall Pre-Sets"
        ? "overall_preset"
        : item?.title === "Grand Overall Pre-Sets"
        ? "grand_preset"
        : "custom_preset";
    dispatch(deletePreset({ compId: user?.uid, doc: doc, preset_uid: uid }));
  }

  useEffect(() => {
    if (deletePresetSuccess?.success) {
      setSelectedRow(null);
      dispatch(clearSuccess());
    }
  }, [deletePresetSuccess]);

  const { data: allCategories } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "categories"
  );
  const { data: allEntriesRaw } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "entries"
  );
  const { status, data: allScoresRaw } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "scores"
  );

  const [allScores, setAllScores] = useState([]);
  const [allEntries, setAllEntries] = useState([]);

  useEffect(() => {
    if (status === "success") {
      setAllEntries(allEntriesRaw);
      setAllScores(allScoresRaw);
    }
  }, [status]);

  //=================================================================================to get top studio
  const calculateAverageScore = (arr, key) => {
    if (Array.isArray(arr)) {
      const filteredArray = arr?.filter((itm) => itm?.technique);
      const array = key === "technique" ? filteredArray : arr;
      const totalSum = array?.reduce((sum, obj) => sum + Number(obj[key]), 0);
      const average = totalSum / array?.length;
      const averageFormatted = Number(average)?.toFixed(2);

      return averageFormatted;
    }
  };

  function getDeduction(arr) {
    const lastCharacters = arr?.map((str) => {
      if (str?.length > 0) {
        return Number(str?.slice(-1));
      } else {
        return "";
      }
    });

    const ded = lastCharacters?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    return ded;
  }

  const calculateTotalScore = (entry) => {
    const scores = allScores?.filter(
      (item) =>
        item?.doc_id === entry?.doc_id &&
        item?.event_name?.trim() === currentEvent?.event_name?.trim()
    );

    const allDeductions = scores
      ?.flatMap((obj) => obj.deductions)
      ?.filter((item) => item !== "none");

    const sum =
      [
        Number(calculateAverageScore(scores, "technique")),
        Number(calculateAverageScore(scores, "creativity")),
        Number(calculateAverageScore(scores, "staging")),
        Number(calculateAverageScore(scores, "execution")),
        Number(calculateAverageScore(scores, "performance")),
      ]?.reduce((accumulator, currentValue) => accumulator + currentValue, 0) -
      getDeduction(allDeductions);

    const truncatedScore = parseFloat(sum?.toFixed(2));

    return isNaN(truncatedScore) ? "- -" : truncatedScore;
  };

  function groupEntriesByStudio() {
    const groupedObjects = {};

    for (const obj of allEntries) {
      const studioSelected = obj.studio_selected[0];

      // Check if a group for this studioSelected value exists, if not, create one
      if (!groupedObjects[studioSelected]) {
        groupedObjects[studioSelected] = [];
      }

      // Push the object into the corresponding group
      groupedObjects[studioSelected].push(obj);
    }

    return groupedObjects;
  }

  function calcAverage(numbers) {
    if (numbers?.length === 0) {
      return 0;
    }

    const sum = numbers?.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    const average = sum / numbers?.length;

    return average;
  }

  const groupedEntries = groupEntriesByStudio();
  function getScores() {
    const scoreObject = {};

    for (const key of Object.keys(groupedEntries)) {
      const originalArray = groupedEntries[key];

      const scoreArr = originalArray
        ?.map((obj) => calculateTotalScore(obj))
        ?.filter((x) => x !== "- -")
        ?.sort((a, b) => b - a)
        ?.slice(0, 10);

      scoreObject[key] = calcAverage(scoreArr);
    }

    return scoreObject;
  }

  // console.log("getScores()", getScores());

  // Convert the object into an array of key-value pairs
  const keyValueArray = Object.entries(getScores());

  // Sort the array in descending order based on values
  keyValueArray?.sort((a, b) => b[1] - a[1]);

  // Convert the sorted array back into an object
  const sortedObject = Object.fromEntries(keyValueArray);

  //=================================================================================to get top studio (versastyle)

  function sumArrayItems(inputObject) {
    const result = {};
    if (inputObject) {
      for (const key in inputObject) {
        if (inputObject.hasOwnProperty(key)) {
          const originalArray = inputObject[key];

          // Convert array items to numbers and calculate the sum
          const sum = originalArray.reduce(
            (acc, item) => acc + parseFloat(item),
            0
          );

          // Store the sum in the result object
          result[key] = Number(sum?.toFixed(2));
        }
      }
    }
    return result;
  }

  function removePropertiesWithZero(inputObject) {
    const result = {};

    for (const key in inputObject) {
      if (inputObject.hasOwnProperty(key)) {
        const valueArray = inputObject[key];

        const studioEntries = allEntries?.filter(
          (x) => x?.studio_selected[0] === key
        );

        // Check if the value array does not include "0.00" and has at least 20 entries
        if (!valueArray.includes("0.00") && studioEntries?.length >= 20) {
          result[key] = valueArray;
        }
      }
    }

    return result;
  }

  const groupedObjEntries = groupObjectsByEntryType(
    groupedEntries,
    calculateTotalScore,
    calcAverage
  );

  const eligibleStudios = removePropertiesWithZero(groupedObjEntries);
  const resultObj = sumArrayItems(eligibleStudios);

  // console.log("resultObj", resultObj);

  // Convert the object into an array of key-value pairs
  const keyValueArrayV = Object.entries(resultObj);

  // Sort the array in descending order based on values
  keyValueArrayV?.sort((a, b) => b[1] - a[1]);

  // Convert the sorted array back into an object
  const sortedObjectV = Object.fromEntries(keyValueArrayV);

  const [expand, setExpand] = useState(null);
  function toggleExpand(item) {
    setExpand((prev) => (prev ? null : item));
  }

  return (
    <div className="w-full min-h-[200px] flex flex-col gap-4 bg-[#282929] p-4 rounded-xl text-white overflow-x-auto sm:overflow-x-hidden lg:overflow-x-auto">
      <h4 className="font-bold text-[1.1rem]">{item?.title}</h4>

      {getPreset()?.presets?.length > 0 ? (
        <table className="w-full text-[.85rem] lg:text-[.9rem]">
          <thead className="text-white/40 text-left border-b border-white/30">
            <tr>
              <th>{heading} Name</th>
              <th>Status</th>
              <th>Number of Awards</th>
              <th></th>
            </tr>
          </thead>
          <tbody className="text-white/80 text-[.85rem] lg:text-[.9rem]">
            {getPreset()?.presets?.map((row, index) => {
              const isSelected = row?.preset_uid === selectedRow;

              const name = row?.overall_name
                ? row?.overall_name
                : row?.grand_name
                ? row?.grand_name
                : row?.custom_name;

              const scheduled = allCategories?.filter(
                (item) =>
                  item?.category_name?.toLowerCase() === name?.toLowerCase()
              )?.length;

              return (
                <React.Fragment key={index}>
                  <tr
                    className={`relative ${
                      isSelected ? "bg-[#94a4fd]/20" : ""
                    } ${
                      item?.title !== "Custom Reports" &&
                      "cursor-pointer hover:bg-[#94a4fd]/20"
                    }`}
                    onClick={() => handleRowClick(row?.preset_uid)}
                  >
                    <td>{capitalizeFirstLetter(name)}</td>
                    <td>{scheduled ? "Scheduled" : "Unscheduled"}</td>
                    <td>{row?.no_of_awards}</td>
                    {item?.title === "Custom Reports" && (
                      <td>
                        <div
                          onClick={() => toggleExpand(row)}
                          className="w-[70px] px-5 py-[2px] flex justify-center bg-gray-400 hover:bg-[#94a4fd]/80 rounded-full cursor-pointer text-[.75rem] text-black font-bold"
                        >
                          VIEW
                        </div>
                      </td>
                    )}
                    {isSelected && item?.title !== "Custom Reports" && (
                      <td className="min-w-[100px] absolute left-[-4px] top-6 mt-[-2px] z-10">
                        <div className="bg-[#282929] border border-[#94a4fd]/50 text-red-500 rounded-lg shadow-lg px-2 py-1 leading-tight">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(row?.preset_uid);
                            }}
                            className="text-[.85rem] rounded-full hover:underline transition-all duration-300 flex justify-center items-center"
                          >
                            {deletePresetPending ? (
                              <ButtonLoadingSpinner />
                            ) : (
                              "Delete Preset"
                            )}
                          </button>
                        </div>
                      </td>
                    )}
                    {/* edit */}
                    {isSelected && item?.title !== "Custom Reports" && (
                      <td className="min-w-[100px] absolute left-[100px] top-6 mt-[-2px] z-10">
                        <div className="flex items-center bg-[#282929] border border-[#94a4fd]/50 text-white rounded-lg shadow-lg px-2 py-1 leading-tight">
                          <Link
                            to={`/organizer/event/${event_name.replace(
                              / /g,
                              "-"
                            )}/report-session-awards/${row?.preset_uid}`}
                            className="text-[.85rem] rounded-full hover:underline transition-all duration-300 flex justify-center items-center"
                          >
                            Edit Preset
                          </Link>
                        </div>
                      </td>
                    )}
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="w-full h-[150px] text-white/50 text-[.9rem] border border-white/10 rounded-lg flex justify-center items-center">
          No Pre Sets Yet...
        </div>
      )}
      <button
        onClick={linkToPreset}
        className="w-fit text-[.85rem] px-5 py-1 mr-4 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300 mt-auto"
      >
        {item?.title === "Custom Reports" ? "Request" : "Create Pre Set"}
      </button>

      {expand && (
        <div
          onClick={() => toggleExpand()}
          className="fixed inset-0 py-[100px] flex justify-center bg-[#1c1c1c]/90 z-[999] text-white/80 overflow-y-auto px-3 md:px-5"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="rounded-lg p-6 w-full lg:w-1/2 min-w-[300px] h-fit min-h-[200px] mx-auto bg-[#282929] relative scale"
          >
            <div
              className="w-6 h-6 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-3 right-3"
              onClick={() => toggleExpand()}
            >
              <img
                alt="user"
                src="/images/icons8-close-50.png"
                className="w-4 h-4"
              />
            </div>
            <h2 className="font-bold mb-4">
              {capitalizeFirstLetter(expand?.custom_name)}
            </h2>
            <p className="text-white/70">
              Presenting studios that meet the eligibility criteria for
              participation.
            </p>
            <div className="mt-5 border-t border-white/20">
              {expand?.custom_name === "top studio" ? (
                <div>
                  {Object?.keys(sortedObject)?.map((x, ind) => {
                    return (
                      <div
                        key={ind}
                        className="w-full border-b border-white/20 flex gap-5 justify-center py-3 text-[.85rem]"
                      >
                        <p>{ind + 1}.</p>
                        <p className="w-[180px] font-bold">
                          {capitalizeFirstLetter(x)}
                        </p>
                        <p className="w-[50px]">
                          {Number(Object?.values(sortedObject)[ind])?.toFixed(
                            2
                          )}
                        </p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div>
                  {Object?.keys(sortedObjectV)?.map((x, ind) => {
                    return (
                      <div
                        key={ind}
                        className="w-full border-b border-white/20 flex gap-5 justify-center py-3 text-[.85rem]"
                      >
                        <p>{ind + 1}.</p>
                        <p className="w-[180px] font-bold">
                          {capitalizeFirstLetter(x)}
                        </p>
                        <p className="w-[50px]">
                          {(
                            Number(Object?.values(sortedObjectV)[ind]) / 2
                          )?.toFixed(2)}
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SessionAwardsCard;
