// import { useSelector } from "react-redux";
import { useFirestoreQueryDoc } from 'components/hooks/useFirestoreQueryDoc';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCurrentEvent } from 'redux/emcee/emceeSlice';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

const EmceeEventCard = ({ item, currentComp }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { data: userData } = useFirestoreQueryDoc('emcees', user?.uid);

  function linkToEvent() {
    navigate(
      `/${userData?.role}/${currentComp?.competitionName}/${item?.event_name}`
    );
    dispatch(setCurrentEvent(item));
  }

  return (
    <div className="w-[250px] flex flex-col gap-3 bg-[#282929] p-4 rounded-lg border border-white/10">
      {/* <img alt="user" src={"/images/logoComp.png"} className="w-8 h-8 mr-6" /> */}
      <h3 className="font-medium mr-auto text-[1.2rem]">
        {capitalizeFirstLetter(item?.event_name)}
      </h3>
      <div className="flex gap-2">
        <p
          onClick={linkToEvent}
          className="text-[#94a4fd] cursor-pointer hover:underline text-right w-full"
        >
          Select Event
        </p>
      </div>
    </div>
  );
};

export default EmceeEventCard;
