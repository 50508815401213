import React, { useCallback, useRef, useState } from "react"; // Import React, several hooks, and custom components
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

// Components
import ImgWrap from "../UI/ImgWrapper"; // Custom component
import Container from "../UI/Container"; // Custom component
import Logo from "../../assets/Logo.png";
import CheckList from "../Form/CheckList"; // Custom component
import Button from "../Form/Button"; // Custom component

import { useSelector } from "react-redux";
import {
  addGenre,
  removeGenre,
} from "redux/organizerRegistration/organizerSlices"; // Import actions from Redux store slice

const DanceGenre = () => {
  const navigate = useNavigate(); // Use navigation hook
  const dispatch = useDispatch(); // Use dispatch hook to access Redux store

  const [isEnableButton, setIsEnableButton] = useState(false); // State variable for button enablement
  const inputRef = useRef(null); // Ref for input field
  const [loading, setLoading] = useState(false); // State variable for loading indicator

  // Access dance genres from Redux store
  const { danceGenres } = useSelector((state) => state.persistedReducer);

  const validationSchema = Yup.object({
    // Define validation schema for form
    newGenre: Yup.string().required("Genre is required"),
  });

  const formik = useFormik({
    // Define form submission handler using useFormik hook
    initialValues: {
      newGenre: "",
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch(addGenre(values.newGenre)); // Dispatch action to add new genre to Redux store
      resetForm(); // Reset form values
      setIsEnableButton(false); // Disable button after submission
    },
  });

  const handleInputFocus = () => {
    // Handle focus event for input field
    setIsEnableButton(true); // Enable button when input field is focused
    inputRef.current.style.display = "block"; // Show input field when it is focused
  };

  const handleInputBlur = () => {
    // setIsEnableButton(false);
  };

  const goToNextPage = async () => {
    // Handle click event for "Next" button

    const selectedGenres = Object.keys(danceGenres);

    if (!selectedGenres.some((genre) => danceGenres[genre])) {
      // Check if at least one dance genre is selected
      toast.info("Please select at least one genre.");
      return;
    }
    setLoading(true); // Set loading indicator while navigating to next page
    navigate("/organizer/eligibility"); // Navigate to next page
  };

  const handleCheckboxChange = useCallback(
    // Handle checkbox change event
    (event) => {
      const { name, checked } = event.target;
      dispatch(checked ? addGenre(name) : removeGenre(name)); // Dispatch action to add or remove genre from Redux store
    },
    [dispatch]
  );

  const goToPreviousPage = () => {
    // Handle click event for "Previous" button
    navigate("/organizer/");
  };

  return (
    <div className="flex flex-row flex-wrap h-[100vh]">
      <div className="lg:w-[35%] lg:flex hidden">
        {/* the left content is located here -- logo and background image */}
        <ImgWrap className="bg-[url('../public/Frame4.png')]" />
        {/* Rendercustom component */}
      </div>
      <div className="lg:w-[65%] w-full">
        {/* container wrapper */}
        <Container className="flex flex-wrap">
          {" "}
          {/* Render custom component */}
          <div className="flex flex-row justify-between w-full lg:justify-end">
            <div className="block lg:hidden">
              <Link to="/">
                <img
                  src={Logo}
                  alt="Logo"
                  className="cursor-pointer w-[90px] h-[25px]"
                />
              </Link>
            </div>
            <div className="flex flex-col items-end">
              <span className="text-sm font-medium text-[#BDBDBD]">
                STEP 02/06
              </span>
              <h4 className="text-base font-semibold text-[#8692A6]">
                Organizer Registration
              </h4>
            </div>
          </div>
          <div className="lg:pl-[63px] md:pl-[50px] lg:pt-[9%] md:pt-[9%] pt-[15%]">
            <h1 className="mb-2 text-3xl font-bold text-[#000000]">
              Dance Genres
            </h1>
            <p className="text-lg font-normal text-[#8692A6] lg:w-[65%] md:w-[65%]">
              Select ALL the Dance Genres your competition offers and/or add
              your own.
            </p>
          </div>
          <div className="mt-[16px] lg:ml-[63px] md:ml-[63px] border-t border-base lg:w-[60%] md:w-[60%]"></div>
          <div className="flex flex-wrap lg:pt-[34px] pt-7 md:pt-[34px] lg:ml-[63px] md:ml-[63px] lg:w-[60%] md:w-[60%] w-full">
            <div className="relative flex flex-col flex-wrap w-full">
              <div className="flex flex-col flex-wrap justify-between gap-x-9 md:w-[500px] w-full md:h-[300px] h-fit">
                {Object.entries(danceGenres).map(([genre, value], index) => (
                  <CheckList
                    key={index}
                    id={index}
                    checked={value}
                    handCheck={handleCheckboxChange}
                    genre={genre}
                  />
                ))}

                <form onSubmit={formik.handleSubmit}>
                  <div className="flex-row mt-2 lg:flex md:flex xs:block">
                    <input
                      type="text"
                      name="newGenre"
                      id="newGenre"
                      placeholder="new genre"
                      onChange={formik.handleChange}
                      value={formik.values.newGenre}
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}
                      ref={inputRef}
                      className="input input-bordered input-sm border-[#9FA8B8] rounded-[5px] h-9 w-40 mr-3 text-sm font-medium text-[#8692A6]"
                    />
                    {formik.touched.newGenre && formik.errors.newGenre ? (
                      <div className="text-red-500">
                        {formik.errors.newGenre}
                      </div>
                    ) : null}

                    <button
                      disabled={!isEnableButton || !formik.values.newGenre}
                      type="submit"
                      // disabled={!formik.values.newGenre}
                      className="btn-xs btn bg-[#1565D8] text-white rounded-[5px] h-[31px] px-[36px] capitalize text-base font-medium"
                      onChange={formik.handleChange}
                      value={formik.values.newGenre}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
              <div className="flex flex-row lg:justify-end md:justify-start sm:justify-start mt-[25%] mb-[60px]">
                <Button
                  text="Previous"
                  loading={loading}
                  handleOnClick={goToPreviousPage} // Handle click event for "Previous" button
                  className="bg-[#1565D8] text-white rounded-[5px] btn-sm capitalize text-base font-medium mr-3 h-8"
                />
                <Button
                  loading={loading}
                  text="Next"
                  handleOnClick={goToNextPage} // Handle click event for "Next" button
                  className="bg-[#1565D8] text-white rounded-[5px] btn-sm capitalize text-base font-medium h-8 lg:mr-[-120px] md:mr-[-120px]"
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
export default DanceGenre;
