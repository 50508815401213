/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import ScrollToTop from "./ScrollToTop";
import { useNavigate, useParams } from "react-router-dom";
import ChartCard from "./components/ChartCard";
import { useEffect, useMemo } from "react";
import AddDancerForm from "./components/AddDancerForm";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { useDispatch } from "react-redux";
import { setEntriesData } from "redux/danceStudio/danceStudioSlice";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { PulseLoader } from "react-spinners";
import Spinner from "utils/Spinner";

const EventDetailsStudio = () => {
  const { addDancer, openNotifsBoard } = useSelector(
    (state) => state.danceStudio
  );

  const { event_name: name } = useParams();

  const event_name = name || localStorage.getItem("event_name") || "";
  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );
  const { compId } = danceStudioCompData.value;

  const { status, data } = useQueryAllDocs("competitions");
  const currentComp = data?.filter((item) => item?.compId === compId)[0];

  const currentEvent = currentComp?.events?.filter(
    (item) => item?.event_name.replace(/ /g, "-") === event_name
  )[0];
  //to memorize chart data keys from firestore - NOT from local persist
  const chartDataKeys = useMemo(() => {
    if (currentComp) {
      return {
        entryTypes: currentComp.entryTypes,
        eligibilityDivisions: currentComp.eligibilityDivisions,
        competitionLevel: currentComp.competitionLevel,
        danceGenres: currentComp.danceGenres,
      };
    }
    return {
      entryTypes: [],
      eligibilityDivisions: [],
      competitionLevel: [],
      danceGenres: [],
    };
  }, [currentComp]);

  //to retrieve the needed keys from chartDataKeys
  const dataArray = Object.values(chartDataKeys);
  //getting the user ID from auth
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  //to get all categories
  const { data: categories } = useQueryAllSubCollectionDocs(
    "competitions",
    compId,
    "schedule_dancers"
  );

  //to get categories of current event
  const eventCategories = categories?.filter((item) => {
    return item?.event_id === currentEvent?.event_name?.trim();
  });

  //to get all entries in all categories
  function extractEntries(eventCategories) {
    return eventCategories?.flatMap((obj) => obj.entries).flat();
  }
  const extractedEntries = extractEntries(eventCategories);

  //to get all entries unique to this dance studio
  const { status: studioStatus, data: studioData } = useFirestoreQueryDoc(
    "studio_owners",
    user?.uid
  );

  const uniqueEntries = extractedEntries?.filter((item) => {
    return item?.studio_selected[0] === studioData?.studioName;
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (studioStatus === "success") {
      dispatch(setEntriesData(uniqueEntries));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studioStatus]);

  //to get all entries
  const { data: allEntries } = useQueryAllSubCollectionDocs(
    "competitions",
    compId,
    "entries"
  );

  const eventEntries = allEntries?.filter(
    (each) =>
      each?.event_uid === currentEvent?.event_uid &&
      each?.studio_selected[0] === studioData?.studioName
  );

  const entriesWithMusic = eventEntries?.filter((item) => item?.music_url);

  //=========================================================================================to redirect back to event selection
  const navigate = useNavigate();

  const accessArray = currentEvent?.access?.filter(
    (itm) => itm?.studio_id === user?.uid
  );

  useEffect(() => {
    if (accessArray?.length === 0) {
      navigate(`/studio/event-selection`);
    }
  }, []);

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}

      {/* event details page */}
      {!addDancer && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
              <h2>
                {capitalizeFirstLetter(currentEvent?.event_name?.trim())}{" "}
                {currentEvent?.year.split(" to ")[0]}
              </h2>
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            <div className="w-full text-white/80">
              <div className="w-full flex md:flex-row flex-col gap-6">
                <div className="w-full md:w-1/2 bg-inherit rounded-xl flex gap-6">
                  <div className="text-[#1c1c1c] bg-[#E3F5FF] rounded-xl p-3 sm:p-4 lg:p-8 w-full h-fit">
                    <p className="text-[.75rem] font-bold mb-4">
                      Entries Submitted
                    </p>
                    <div className="flex flex-wrap justify-between items-center gap-2">
                      <div className="text-[1.45rem] font-bold">
                        {status === "loading" ? (
                          <Spinner />
                        ) : eventEntries?.length === undefined ? (
                          <Spinner />
                        ) : (
                          eventEntries?.length
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-[#1c1c1c] bg-[#F7F9FB] rounded-xl p-3 sm:p-4 lg:p-8 w-full  h-fit">
                    <p className="text-[.75rem] font-bold mb-4">
                      Music Submitted
                    </p>
                    <div className="flex flex-wrap justify-between items-center gap-2">
                      <div className="text-[1.45rem] font-bold">
                        {status === "loading" ? (
                          <Spinner />
                        ) : eventEntries?.length === undefined ? (
                          <Spinner />
                        ) : (
                          ` ${entriesWithMusic?.length} /
                           ${eventEntries?.length}`
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full md:w-1/2 h-fit bg-[#282929] rounded-xl flex flex-col gap-5 p-7">
                  <h2 className="font-bold">Progress</h2>
                  <p>
                    Registration:{" "}
                    <span className="font-bold">
                      {!currentEvent?.locked ? "Unlocked" : "Locked"}
                    </span>
                  </p>
                  <p>
                    Schedule:{" "}
                    <span className="font-bold">
                      {" "}
                      {currentEvent?.locked ? "Available" : "Unavailable"}
                    </span>
                  </p>
                  {/* <p>
                    Music:{" "}
                    <span className="font-bold">
                      {" "}
                      {currentEvent?.isMusicDownload
                        ? "Downloaded"
                        : "Not Downloaded"}
                    </span>
                  </p> */}
                </div>
              </div>

              <div className="w-full min-h-[400px] flex md:flex-row flex-col gap-6 mt-6 flex-wrap">
                {dataArray?.map((item, index) => {
                  return (
                    <ChartCard
                      item={item}
                      key={index}
                      index={index}
                      dataArray={dataArray}
                      status={status}
                      entriesData={eventEntries}
                      selectedStudio={studioData?.studioName}
                      currentEvent={currentEvent}
                    />
                  );
                })}
              </div>
            </div>
          </div>

          <ScrollToTop />
          <Footer />
        </div>
      )}

      {addDancer && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[330px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
              <h2>
                {currentEvent?.event_name?.trim()}{" "}
                {currentEvent?.year.split(" to ")[0]}
              </h2>{" "}
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            {/* Add event form */}
            <div className="">
              <AddDancerForm />
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}
    </>
  );
};

export default EventDetailsStudio;
