/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { PulseLoader } from "react-spinners";
import {
  addBreak,
  toggleAddSession,
} from "redux/routineScheduling/routineSchedulingSlice";

const AddSessionContainer = ({
  setBreakExists,
  setBreakNameErr,
  breakExists,
  bothExists,
  overallName,
  user,
  currentEvent,
}) => {
  const { addBreakPending } = useSelector((state) => state.routineScheduling);
  const dispatch = useDispatch();

  return (
    <div className="w-screen h-screen fixed top-0 left-0 z-[999] px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
      <div className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-start scale shadow-lg">
        <div className="w-full text-[1rem] text-white text-center pb-3 border-b border-[#c5c4c4]/50 relative">
          <p>Add Session</p>
          <div
            onClick={() => {
              setBreakExists(false);
              setBreakNameErr(false);
              dispatch(toggleAddSession());
            }}
            className="p-1 rounded-full bg-white/70 absolute right-2 top-0 cursor-pointer"
          >
            <img alt="" src="/images/icons8-close-50.png" className="w-4 h-4" />
          </div>
        </div>

        {addBreakPending ? (
          <div className="w-full min-h-[200px] flex justify-center items-center">
            <PulseLoader color="#94a4fd" size={10} />
          </div>
        ) : (
          <div className="mt-4 w-full sm:w-2/3 mx-auto flex flex-col items-center gap-4">
            {breakExists || bothExists ? (
              <p className="text-white/80 text-center w-full border border-red-400 bg-red-400/20 p-1 rounded-lg">
                You have added a break already!
              </p>
            ) : (
              <div className="w-full flex flex-col items-center gap-4">
                <div className="w-full bg-[#42125f] border border-white/20 text-white text-[.85rem]  rounded-lg p-4 text-center">
                  SESSION
                </div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      addBreak({
                        break: "session",
                        event_uid: currentEvent?.event_uid,
                        compId: user?.uid,
                        break_type: "session",
                      })
                    );
                  }}
                  className="min-w-fit text-[.9rem] px-8 py-2 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
                >
                  Add
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddSessionContainer;
