import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addBreakService,
  addScheduleCategoryService,
  addScheduleEntryService,
  deleteBreakService,
  deleteScheduleCategoryService,
  deleteScheduleEntryService,
  editScheduleCategoryService,
  getScheduleCategoryService,
  lockScheduleService,
  scratchScheduleCategoryService,
  scratchScheduleEntryService,
  unlockScheduleService,
  unscratchScheduleCategoryService,
  unscratchScheduleEntryService,
  editScoreService,
  editEntryService,
  setTaxRateService,
} from "services/organizerService";

//===============================to handle add entries async func
export const addCategory = createAsyncThunk(
  "categorySlice/addCategory",
  async (data) => {
    const result = await addScheduleCategoryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle get all entries async func
export const getCategory = createAsyncThunk(
  "categorySlice/getCategory",
  async (data, { getState, rejectWithValue }) => {
    try {
      const result = await getScheduleCategoryService(data);
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//===============================to handle delete categor async func
export const deleteCategory = createAsyncThunk(
  "categorySlice/deleteCategory",
  async (data) => {
    const result = await deleteScheduleCategoryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle edit category async func
export const editCategory = createAsyncThunk(
  "categorySlice/editCategory",
  async (data) => {
    const result = await editScheduleCategoryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle add entries async func
export const addEntries = createAsyncThunk(
  "categorySlice/addEntries",
  async (data) => {
    const result = await addScheduleEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle edit entries async func
export const editEntry = createAsyncThunk(
  "categorySlice/editEntry",
  async (data) => {
    const result = await editEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle delete entry async func
export const deleteEntries = createAsyncThunk(
  "categorySlice/deleteEntries",
  async (data) => {
    const result = await deleteScheduleEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle add break async func
export const addNewBreak = createAsyncThunk(
  "categorySlice/addNewBreak",
  async (data) => {
    const result = await addBreakService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle add break async func
export const deleteBreak = createAsyncThunk(
  "categorySlice/deleteBreak",
  async (data) => {
    const result = await deleteBreakService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle scratch async func
export const scratchEntry = createAsyncThunk(
  "categorySlice/scratchEntry",
  async (data) => {
    const result = await scratchScheduleEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle scratch async func
export const unscratchEntry = createAsyncThunk(
  "categorySlice/unscratchEntry",
  async (data) => {
    const result = await unscratchScheduleEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle scratch async func
export const scratchCategory = createAsyncThunk(
  "categorySlice/scratchCategory",
  async (data) => {
    const result = await scratchScheduleCategoryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle scratch async func
export const unscratchCategory = createAsyncThunk(
  "categorySlice/unscratchCategory",
  async (data) => {
    const result = await unscratchScheduleCategoryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle lock async func
export const lockSchedule = createAsyncThunk(
  "categorySlice/lockSchedule",
  async (data) => {
    const result = await lockScheduleService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle unlock async func
export const unlockSchedule = createAsyncThunk(
  "categorySlice/unlockSchedule",
  async (data) => {
    const result = await unlockScheduleService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle scratch async func
export const editScore = createAsyncThunk(
  "categorySlice/editScore",
  async (data) => {
    const result = await editScoreService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle scratch async func
export const saveTaxRate = createAsyncThunk(
  "categorySlice/saveTaxRate",
  async (data) => {
    const result = await setTaxRateService(data);
    console.log(result);
    return result;
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState: {
    categories: [],
    deletedCategories: [],
    editCategory: false,
    editEntry: false,
    editID: "",
    catID: "",
    editCategoryID: "",
    addBreak: false,
    addTimeBuffer: false,

    addCategorySuccess: "",
    addCategoryFail: "",
    addCategoryPending: false,

    getCategorySuccess: null,
    getCategoryFail: null,
    getCategoryPending: false,

    deleteCategorySuccess: null,
    deleteCategoryFail: null,
    deleteCategoryPending: false,

    editCategorySuccess: null,
    editCategoryFail: null,
    editCategoryPending: false,

    addEntriesSuccess: null,
    addEntriesFail: null,
    addEntriesPending: false,

    editEntrySuccess: null,
    editEntryFail: null,
    editEntryPending: false,

    deleteEntriesSuccess: null,
    deleteEntriesFail: null,
    deleteEntriesPending: false,

    addBreakSuccess: null,
    addBreakFail: null,
    addBreakPending: false,

    deleteBreakSuccess: null,
    deleteBreakFail: null,
    deleteBreakPending: false,

    addEntry: false,
    viewDeleted: false,
    addCategory: false,

    addOverallsAward: false,
    editOverallsAward: false,
    editGrandAward: false,

    addGrandAward: false,

    scratchEntrySuccess: null,
    scratchEntryFail: null,
    scratchEntryPending: false,

    unscratchEntrySuccess: null,
    unscratchEntryFail: null,
    unscratchEntryPending: false,

    scratchCategorySuccess: null,
    scratchCategoryFail: null,
    scratchCategoryPending: false,

    unscratchCategorySuccess: null,
    unscratchCategoryFail: null,
    unscratchCategoryPending: false,

    lockScheduleSuccess: null,
    lockScheduleFail: null,
    lockSchedulePending: false,

    unlockScheduleSuccess: null,
    unlockScheduleFail: null,
    unlockSchedulePending: false,

    editScoreSuccess: null,
    editScoreFail: null,
    editScorePending: false,

    updatingDocs: false,
  },
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    addNewCategory: (state, action) => {
      return {
        ...state,
        categories: [...state?.categories, action.payload],
      };
    },
    toggleAddEntry: (state) => {
      return {
        ...state,
        addEntry: !state.addEntry,
        addEntriesSuccess: null,
      };
    },
    toggleViewDeleted: (state) => {
      return {
        ...state,
        viewDeleted: !state.viewDeleted,
      };
    },
    toggleAddCategory: (state) => {
      return {
        ...state,
        addCategory: !state.addCategory,
        addCategorySuccess: null,
      };
    },

    toggleAddOverallsAwards: (state) => {
      return {
        ...state,
        addOverallsAward: !state.addOverallsAward,
        // addEntriesSuccess: null,
      };
    },

    toggleEditOverallsAwards: (state) => {
      return {
        ...state,
        editOverallsAward: !state.editOverallsAward,
        // addEntriesSuccess: null,
      };
    },

    toggleAddGrandAwards: (state) => {
      return {
        ...state,
        addGrandAward: !state.addGrandAward,
        // addEntriesSuccess: null,
      };
    },

    toggleEditGrandAwards: (state) => {
      return {
        ...state,
        editGrandAward: !state.editGrandAward,
        // addEntriesSuccess: null,
      };
    },
    closeForms: (state) => {
      return {
        ...state,
        addCategory: false,
        addEntry: false,
        lockScheduleSuccess: null,
        unlockScheduleSuccess: null,
        addOverallsAward: false,
        addGrandAward: false,
      };
    },
    addNewTimeBuffer: (state, action) => {
      return {
        ...state,
        categories: [...state?.categories, action.payload],
      };
    },
    //to toggle open and close edit entry
    toggleEditEntry: (state, action) => {
      return {
        ...state,
        editEntry: !state.editEntry,
        editID: action.payload?.editID,
        catID: action.payload?.catID,
        editEntrySuccess: null,
      };
    },

    toggleEditCategory: (state, action) => {
      return {
        ...state,
        editCategory: !state.editCategory,
        editCategoryID: action.payload,
      };
    },
    toggleAddBreak: (state) => {
      return {
        ...state,
        addBreak: !state.addBreak,
        addBreakSuccess: null,
      };
    },
    toggleAddTimeBuffer: (state) => {
      return {
        ...state,
        addTimeBuffer: !state.addTimeBuffer,
        addBreakSuccess: null,
      };
    },
    closeAddTimeBuffer: (state) => {
      return {
        ...state,
        addTimeBuffer: false,
        addBreak: false,
        addBreakSuccess: null,
      };
    },
    startUpdatingDocs: (state) => {
      return {
        ...state,
        updatingDocs: true,
      };
    },
    endUpdatingDocs: (state) => {
      return {
        ...state,
        updatingDocs: false,
      };
    },
    // deleteEntry: (state, action) => {
    //   const { categoryIndex, entryIndex } = action.payload;
    //   state.categories[categoryIndex].entries.splice(entryIndex, 1);
    // },
  },

  extraReducers: (builder) => {
    builder
      //cases for add categories
      .addCase(addCategory.pending, (state) => {
        state.addCategoryPending = true;
      })
      .addCase(addCategory.fulfilled, (state, action) => {
        state.addCategorySuccess = action.payload;
        state.addCategoryPending = false;
      })
      .addCase(addCategory.rejected, (state, action) => {
        state.addCategoryFail = action.payload;
        state.addCategoryPending = false;
      })
      //cases for get categories
      .addCase(getCategory.pending, (state) => {
        state.getCategoryPending = true;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.getCategorySuccess = action.payload;
        state.getCategoryPending = false;
        state.categories = action.payload;
      })
      .addCase(getCategory.rejected, (state, action) => {
        state.getCategoryFail = action.payload;
        state.getCategoryPending = false;
      })
      //cases for delete categories
      .addCase(deleteCategory.pending, (state) => {
        state.deleteCategoryPending = true;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.deleteCategorySuccess = action.payload;
        state.deleteCategoryPending = false;
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.deleteCategoryFail = action.payload;
        state.deleteCategoryPending = false;
      })
      //cases for edit categories
      .addCase(editCategory.pending, (state) => {
        state.editCategoryPending = true;
      })
      .addCase(editCategory.fulfilled, (state, action) => {
        state.editCategorySuccess = action.payload;
        state.editCategoryPending = false;
      })
      .addCase(editCategory.rejected, (state, action) => {
        state.editCategoryFail = action.payload;
        state.editCategoryPending = false;
      })
      //cases for add entry
      .addCase(addEntries.pending, (state) => {
        state.addEntriesPending = true;
      })
      .addCase(addEntries.fulfilled, (state, action) => {
        state.addEntriesSuccess = action.payload;
        state.addEntriesPending = false;
      })
      .addCase(addEntries.rejected, (state, action) => {
        state.addEntriesFail = action.payload;
        state.addEntriesPending = false;
      })
      //cases for edit entry
      .addCase(editEntry.pending, (state) => {
        state.editEntryPending = true;
      })
      .addCase(editEntry.fulfilled, (state, action) => {
        state.editEntrySuccess = action.payload;
        state.editEntryPending = false;
      })
      .addCase(editEntry.rejected, (state, action) => {
        state.editEntryFail = action.payload;
        state.editEntryPending = false;
      })
      //cases for delete entry
      .addCase(deleteEntries.pending, (state) => {
        state.deleteEntriesPending = true;
      })
      .addCase(deleteEntries.fulfilled, (state, action) => {
        state.deleteEntriesSuccess = action.payload;
        state.deleteEntriesPending = false;
      })
      .addCase(deleteEntries.rejected, (state, action) => {
        state.deleteEntriesFail = action.payload;
        state.deleteEntriesPending = false;
      })
      //cases for add break
      .addCase(addNewBreak.pending, (state) => {
        state.addBreakPending = true;
      })
      .addCase(addNewBreak.fulfilled, (state, action) => {
        state.addBreakSuccess = action.payload;
        state.addBreakPending = false;
      })
      .addCase(addNewBreak.rejected, (state, action) => {
        state.addBreakFail = action.payload;
        state.addBreakPending = false;
      })
      //cases for delete break
      .addCase(deleteBreak.pending, (state) => {
        state.deleteBreakPending = true;
      })
      .addCase(deleteBreak.fulfilled, (state, action) => {
        state.deleteBreakSuccess = action.payload;
        state.deleteBreakPending = false;
      })
      .addCase(deleteBreak.rejected, (state, action) => {
        state.deleteBreakFail = action.payload;
        state.deleteBreakPending = false;
      })
      //cases for scratch entry
      .addCase(scratchEntry.pending, (state) => {
        state.scratchEntryPending = true;
      })
      .addCase(scratchEntry.fulfilled, (state, action) => {
        state.scratchEntrySuccess = action.payload;
        state.scratchEntryPending = false;
      })
      .addCase(scratchEntry.rejected, (state, action) => {
        state.scratchEntryPending = false;
      })
      //cases for unscratch entry
      .addCase(unscratchEntry.pending, (state) => {
        state.unscratchEntryPending = true;
      })
      .addCase(unscratchEntry.fulfilled, (state, action) => {
        state.unscratchEntrySuccess = action.payload;
        state.unscratchEntryPending = false;
      })
      .addCase(unscratchEntry.rejected, (state, action) => {
        state.unscratchEntryFail = action.payload;
        state.unscratchEntryPending = false;
      })
      //cases for scratch category
      .addCase(scratchCategory.pending, (state) => {
        state.scratchCategoryPending = true;
      })
      .addCase(scratchCategory.fulfilled, (state, action) => {
        state.scratchCategorySuccess = action.payload;
        state.scratchCategoryPending = false;
      })
      .addCase(scratchCategory.rejected, (state, action) => {
        state.scratchCategoryFail = action.payload;
        state.scratchCategoryPending = false;
      })
      //cases for unscratch category
      .addCase(unscratchCategory.pending, (state) => {
        state.unscratchCategoryPending = true;
      })
      .addCase(unscratchCategory.fulfilled, (state, action) => {
        state.unscratchCategorySuccess = action.payload;
        state.unscratchCategoryPending = false;
      })
      .addCase(unscratchCategory.rejected, (state, action) => {
        state.unscratchCategoryFail = action.payload;
        state.unscratchCategoryPending = false;
      })
      //cases for lock schedule
      .addCase(lockSchedule.pending, (state) => {
        state.lockSchedulePending = true;
      })
      .addCase(lockSchedule.fulfilled, (state, action) => {
        state.lockScheduleSuccess = action.payload;
        state.lockSchedulePending = false;
      })
      .addCase(lockSchedule.rejected, (state, action) => {
        state.lockScheduleFail = action.payload;
        state.lockSchedulePending = false;
      })
      //cases for unlock schedule
      .addCase(unlockSchedule.pending, (state) => {
        state.unlockSchedulePending = true;
      })
      .addCase(unlockSchedule.fulfilled, (state, action) => {
        state.unlockScheduleSuccess = action.payload;
        state.unlockSchedulePending = false;
      })
      .addCase(unlockSchedule.rejected, (state, action) => {
        state.unlockScheduleFail = action.payload;
        state.unlockSchedulePending = false;
      })
      //cases for performance ready
      .addCase(editScore.pending, (state) => {
        state.editScorePending = true;
      })
      .addCase(editScore.fulfilled, (state, action) => {
        state.editScoreSuccess = action.payload;
        state.editScorePending = false;
      })
      .addCase(editScore.rejected, (state, action) => {
        state.editScoreFail = action.payload;
        state.editScorePending = false;
      });
  },
});

export const {
  // appendCategory,
  addNewCategory,
  toggleEditCategory,
  toggleAddBreak,
  toggleAddTimeBuffer,
  addNewTimeBuffer,
  deleteNewEntry,
  closeAddTimeBuffer,
  toggleEditEntry,
  toggleAddEntry,
  toggleViewDeleted,
  toggleAddCategory,
  closeForms,
  setCategories,
  toggleAddOverallsAwards,
  toggleAddGrandAwards,
  toggleEditOverallsAwards,
  startUpdatingDocs,
  endUpdatingDocs,
  toggleEditGrandAwards,
} = categorySlice.actions;

export default categorySlice.reducer;
