/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { PulseLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { ScrollToTop } from "components/EventManagement";

const Competitions = () => {
  const { status, data } = useQueryAllDocs("competitions");

  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <main className="w-full h-screen overflow-y-auto overflow-x-hidden bg-[#1C1C1C] px-3 md:px-10 py-14 relative text-white">
        <button
          onClick={goBack}
          className="px-5 py-1 bg-white/80 hover:bg-white/50 text-black rounded-lg text-[.75rem] md:text-[1rem] mb-8 absolute top-4 left-3"
        >
          Home
        </button>
        <div className="mb-auto">
          <div className="w-[70%] p-2 md:p-3 bg-[#282929] mb-10 mx-auto rounded-lg text-center font-bold">
            <h2 className="text-lg lg:text-3xl uppercase">Competitions</h2>
          </div>

          {/* Competitions container */}
          <div className="w-full md:w-full flex flex-col md:grid grid-cols-3 gap-6 mb-auto text-white/80">
            {status === "success" &&
              data?.map((item, index) => {
                function linkToEvent() {
                  navigate(`/competitions/${item?.competitionName}`);
                  // dispatch(setCurrentComp(item));
                  localStorage.setItem("compId", item?.compId);
                }
                return (
                  <div
                    key={index}
                    className="w-full flex flex-wrap items-center gap-3 bg-[#282929] p-4 rounded-lg border border-white/10"
                  >
                    <img
                      alt="user"
                      src={"/images/logoComp.png"}
                      className="w-8 h-8 mr-6"
                    />
                    <h3 className="font-medium mr-auto w-[200px] truncate">
                      {capitalizeFirstLetter(item?.competitionName)}
                    </h3>
                    <div className="flex gap-2 ml-auto">
                      <p
                        onClick={linkToEvent}
                        className="text-[#94a4fd] cursor-pointer hover:underline"
                      >
                        Select Competition
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>

          {status === "loading" && (
            <div className="w-full h-[200px] border border-white/20 rounded-lg flex justify-center items-center gap-6 mb-auto text-white/80">
              <PulseLoader color="#94a4fd" size={10} />
            </div>
          )}

          {status === "success" && data?.length === 0 && (
            <div className="w-full h-[200px] border border-white/20 rounded-lg flex justify-center items-center gap-6 mb-auto text-white/80">
              No competitions yet..
            </div>
          )}
        </div>
      </main>
      <ScrollToTop />
    </>
  );
};

export default Competitions;
