/* eslint-disable react-hooks/exhaustive-deps */
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { db } from "services/firebase";
import {
  toggleDropboard,
  toggleSearch,
  toggleNotifsBoard,
  search,
  searchCompetitions,
  clearSearchResult,
  setEventsData,
  setNotifications,
  sendVerification,
} from "../../../redux/danceStudio/danceStudioSlice";
import BreadCrumb from "./BreadCrumb";
import SearchResultTray from "./SearchResultTray";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { PulseLoader } from "react-spinners";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { getUser } from "utils/getUID";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";

const Header = () => {
  const {
    openSearch,
    openNotifsBoard,
    searchResults,
    notifications,
    sendVerificationPending,
    sendVerificationSuccess,
  } = useSelector((state) => state.danceStudio);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = location.pathname;

  function openNotifsOnLargeScreen() {
    dispatch(toggleNotifsBoard());
  }

  //to get studio name
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { status, data } = useFirestoreQueryDoc("studio_owners", user?.uid);

  //to handle event search
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchEvent = (e) => {
    setSearchTerm(e.target.value);
    dispatch(search(e.target.value));
  };

  const handleSearchComp = (e) => {
    setSearchTerm(e.target.value);
    dispatch(searchCompetitions(e.target.value));
  };

  function clearSearch() {
    setSearchTerm("");
    dispatch(toggleSearch());
  }

  const { data: compData } = useQueryAllDocs("competitions");

  const { data: studioData } = useFirestoreQueryDoc("studio_owners", user?.uid);
  const selectedComps = studioData?.selected_competitions || [];
  // console.log("selectedComps", selectedComps);
  // const competitionSelected = selectedComps.includes(item.competitionName);

  useEffect(() => {
    if (!searchTerm) {
      dispatch(clearSearchResult());
    }
  }, [searchTerm]);

  //to get realtime events from firestore
  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );

  const { data: allDocsData } = useQueryAllDocs("competitions");
  const currentComp = allDocsData?.filter(
    (item) => item?.compId === danceStudioCompData?.value?.compId
  )[0];
  const realtimeEvents = currentComp?.events;

  useEffect(() => {
    dispatch(setEventsData(realtimeEvents));
  }, [dispatch, realtimeEvents]);

  // const [notifications, setNotifications] = useState([]);
  // console.log("notifications", notifications);

  //================================================================to listen for notifs
  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, "notifications"), orderBy("timestamp", "desc")),
      (snapshot) => {
        // const data = snapshot.docs.map((doc) => doc.data());
        const notifications = snapshot.docs.map((doc) => {
          const notification = doc.data();
          return {
            ...notification,
            timestamp: notification.timestamp,
          };
        });
        const myNotifs = notifications?.filter(
          (item) => item?.user_id === user?.uid || item?.for === user?.uid
        );
        dispatch(setNotifications(myNotifs));
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  const [unreadNotifs, setUnreadNotifs] = useState(false);
  useEffect(() => {
    if (notifications) {
      const unread = notifications?.filter((item) => item?.read === false);
      unread?.length > 0 ? setUnreadNotifs(true) : setUnreadNotifs(false);
    }
  }, [notifications]);

  // console.log("unreadNotifs", unreadNotifs);

  //===========to check if all entries has existing categories
  const { event_name } = useParams();
  const currentEventRaw = currentComp?.events?.filter(
    (item) => item?.event_name.replace(/ /g, "-") === event_name
  )[0];

  const compId = danceStudioCompData?.value?.compId || "none";
  const { data: allCategories } = useQueryAllSubCollectionDocs(
    "competitions",
    compId,
    "categories"
  );
  const eventCategories = allCategories?.filter(
    (cat) => cat?.event_uid === currentEventRaw?.event_uid
  );

  const { status: entryStatus, data: allEntriesRaw } =
    useQueryAllSubCollectionDocs("competitions", compId, "entries");
  const allEntries = allEntriesRaw?.filter(
    (itm) =>
      itm?.event_uid === currentEventRaw?.event_uid &&
      itm?.studio_selected[0]?.toLowerCase() === data?.studioName?.toLowerCase()
  );

  function generateUniqueId() {
    const timestamp = new Date().getTime().toString();
    const randomNum = Math.floor(Math.random() * 900000000000) + 100000000000;
    const uniqueId = `${timestamp}${randomNum}`;
    return uniqueId.substring(0, 15);
  }

  const assignSortId = (array) => {
    if (array && Array.isArray(array) && array.length > 0) {
      const maxSortId = Math.max(...array.map((obj) => obj?.sort_id || 0));
      const newSortId = maxSortId + 1;

      return newSortId;
    } else {
      return 1;
    }
  };

  useEffect(() => {
    if (currentEventRaw && entryStatus === "success") {
      const categoryStringsEntr = allEntries?.map((x) => x?.category_name);
      const categoryStringsCate = eventCategories?.map((x) => x?.category_name);

      const noCategoryStrings = categoryStringsEntr?.filter(
        (item) => !categoryStringsCate?.includes(item)
      );

      console.log("noCategoryStrings", noCategoryStrings);

      const removeDuplicates = (strings) => {
        const uniqueStrings = Array.from(new Set(strings));
        return uniqueStrings;
      };

      const categoryCollectionRef = collection(
        db,
        "competitions",
        compId,
        "categories"
      );

      // removeDuplicates(noCategoryStrings)?.forEach((string) => {
      //   const newId = generateno_id();
      //   setDoc(doc(categoryCollectionRef, newId), {
      //     category_name: string,
      //     sort_id: assignSortId(allCategories),
      //     id: generateUniqueId(),
      //     is_scratched: false,
      //     event_uid: currentEventRaw?.event_uid,
      //     type: "category",
      //     no_id: newId,
      //   });
      // });
    }
  }, [currentEventRaw, entryStatus]);

  function generateno_id() {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uid = "";
    for (let i = 0; i < 32; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      uid += chars.charAt(randomIndex);
    }
    return uid;
  }

  //===================================================to handle email verification popup for none verified users
  const [currentUser, setCurrentUser] = useState(null);
  const [openMod, setOpenMod] = useState(false);

  useEffect(() => {
    async function checkEmailVerification() {
      try {
        const currentUser = await getUser();
        if (currentUser) {
          // User is logged in, you can use the user object here.
          setCurrentUser(currentUser);
        } else {
          console.log("User is not logged in.");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    checkEmailVerification();
  }, []);

  const [showVerif, setShowVerif] = useState(false);
  useEffect(() => {
    if (currentUser) {
      currentUser?.emailVerified ? setShowVerif(false) : setShowVerif(true);
      currentUser?.emailVerified ? setOpenMod(false) : setOpenMod(true);
    }
  }, [currentUser]);

  return (
    <>
      {showVerif && openMod && (
        <div className="w-full h-full fixed top-0 left-0 bg-black/80 flex justify-center py-[100px] px-3 z-[50]">
          {sendVerificationSuccess?.success ? (
            <div className="w-full md:w-[500px] h-fit rounded-xl bg-[#282929] px-4 py-5 flex flex-col gap-4 text-white scale">
              <h2 className="font-bold text-[1.4rem] text-center border-b border-white/80 pb-2">
                Email verification link sent successfully.
              </h2>
              <p className="text-center text-[1.2rem]">
                Check you email inbox or junk/spam to verify.
              </p>
              <p className="text-center text-white/60">
                Note: Verify your email by clicking the link sent to your inbox.
                Refresh this page to dismiss this message permanently after
                successful verification.
              </p>

              <button
                onClick={() => setOpenMod(false)}
                className="w-fit mx-auto px-5 py-1 font-medium rounded-full bg-gray-400 hover:bg-gray-400/60 text-[#1c1c1c] transition-all duration-300"
              >
                Close
              </button>
            </div>
          ) : (
            <div className="w-full md:w-[500px] h-fit rounded-xl bg-[#282929] px-4 py-5 flex flex-col gap-4 text-white scale">
              <h2 className="font-bold text-[1.4rem] text-center border-b border-white/80 pb-2">
                Your email is unverified.
              </h2>
              <p className="text-center text-[1.2rem]">
                Hello{" "}
                {studioData
                  ? `${capitalizeFirstLetter(studioData?.studioName)}`
                  : "..."}
                , your email is unverified, kindly verify now to continue.
              </p>
              <button
                onClick={() => dispatch(sendVerification())}
                disabled={sendVerificationPending}
                className="w-fit mx-auto px-5 py-1 font-medium flex items-center gap-2 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
              >
                <span className="w-full">
                  {sendVerificationPending ? "Processing..." : "Verify Email"}
                </span>
                {sendVerificationPending && <ButtonLoadingSpinner />}{" "}
              </button>
            </div>
          )}
        </div>
      )}

      {/* desktop ui */}
      {/* desktop ui */}
      {/* desktop ui */}

      <header
        className={`w-full text-white/80 py-6 sm:pl-[232px] lg:pl-[282px] pr-[32px] ${
          openNotifsBoard ? "lg:pr-[310px]" : "lg:pr-[32px]"
        }
        bg-[#1c1c1c] fixed left-0 top-0 border-b-2 border-[#282929] hidden lg:flex items-center z-10 transition-all duration-700`}
      >
        {searchTerm && (
          <SearchResultTray
            currentPage={currentPage}
            openNotifsBoard={openNotifsBoard}
            searchResults={searchResults}
            searchTerm={searchTerm}
            clearSearch={clearSearch}
            compData={compData}
            selectedComps={selectedComps}
          />
        )}
        <div className="flex lg:gap-4 md:gap-3 items-center mr-auto">
          {/* <img
            alt="user"
            src="/images/Star.png"
            className="lg:w-6 lg:h-6 md:w-4 md:h-4 cursor-pointer"
          /> */}

          {/* BreadCrumb path trail */}
          <BreadCrumb studioName={data?.studioName} />
        </div>

        {currentPage?.includes("/competition") && (
          <div className="h-[30px] flex mr-4 ml-auto">
            <div className="h-full w-fit p-1 bg-[#333333] rounded-l-lg">
              <img
                alt="user"
                src="/images/Search.png"
                className="w-5 h-5 cursor-pointer"
              />
            </div>
            <input
              type="text"
              placeholder="Search Competitions"
              value={searchTerm}
              onChange={handleSearchComp}
              className="md:w-[130px] lg:w-[250px] text-[.9em] bg-[#333333] rounded-r-lg outline-none pr-2 py-1"
            />
          </div>
        )}

        {currentPage?.includes("/event") && (
          <div className="h-[30px] flex mr-4 ml-auto">
            <div className="h-full w-fit p-1 bg-[#333333] rounded-l-lg">
              <img
                alt="user"
                src="/images/Search.png"
                className="w-5 h-5 cursor-pointer"
              />
            </div>
            <input
              type="text"
              placeholder="Search Events"
              value={searchTerm}
              onChange={handleSearchEvent}
              className="md:w-[130px] lg:w-[250px] text-[.9em] bg-[#333333] rounded-r-lg outline-none pr-2 py-1"
            />
          </div>
        )}

        <div className="flex gap-4 items-center">
          {/* <img
            alt="user"
            src="/images/Sun.png"
            className="lg:w-6 lg:h-6 md:w-5 md:h-5 cursor-pointer"
          /> */}

          {/* (currentPage.includes("/dancers") ||
            currentPage.includes("/entries") ||
            currentPage.includes("/scores")) && */}
          {!openNotifsBoard && (
            <div
              onClick={() => openNotifsOnLargeScreen()}
              className="flex gap-4 items-center md:border lg:border-0 border-[#94a4fd]/60 px-2 py-1 rounded-full md:bg-[#94a4fd]/20 lg:bg-transparent cursor-pointer"
            >
              {/* <img
              alt="user"
              src="/images/ClockCounterClockwise.png"
              className="lg:w-6 lg:h-6 md:w-4 md:h-4 cursor-pointer"
            /> */}
              <div className="relative w-fit h-fit">
                {unreadNotifs && (
                  <div className="w-3 h-3 bg-red-500 rounded-full absolute top-[-2px] right-[-2px]"></div>
                )}
                <img
                  alt="user"
                  src="/images/Bell.png"
                  className="lg:w-6 lg:h-6 md:w-4 md:h-4 cursor-pointer"
                />
              </div>
              {/* <img
              alt="user"
              src="/images/Sidebar.png"
              className="lg:w-6 lg:h-6 md:w-4 md:h-4 cursor-pointer"
            /> */}
            </div>
          )}
          {openNotifsBoard && (
            <div
              onClick={() => openNotifsOnLargeScreen()}
              className="w-6 h-6 flex justify-center items-center bg-white/80 rounded-full cursor-pointer"
            >
              <img
                alt="user"
                src="/images/icons8-close-50.png"
                className="w-4 h-4"
              />
            </div>
          )}
        </div>
      </header>

      {/* small screens ui */}
      {/* small screens ui */}
      {/* small screens ui */}

      <header className="w-full text-white/80 py-6 md:pl-[225px] lg:pl-[282px] pr-[25px] lg:pr-[332px] bg-[#1c1c1c] fixed left-0 top-0 border-b-2 border-[#282929] hidden md:flex lg:hidden items-center z-10">
        <div className="flex gap-2 items-center mr-auto">
          {/* <img
            alt="user"
            src="/images/Star.png"
            className="w-4 h-4 cursor-pointer"
          /> */}

          {/* BreadCrumb path trail */}
          {!openSearch && <BreadCrumb />}
        </div>
        {searchTerm && (
          <SearchResultTray
            currentPage={currentPage}
            openNotifsBoard={openNotifsBoard}
            searchResults={searchResults}
            searchTerm={searchTerm}
            clearSearch={clearSearch}
            compData={compData}
            selectedComps={selectedComps}
          />
        )}

        {currentPage?.includes("/competition") && openSearch && (
          <div className="h-[30px] flex mr-4 ml-auto">
            <div className="h-full w-fit p-1 bg-[#333333] rounded-l-lg">
              <img
                alt="user"
                src="/images/Search.png"
                className="w-5 h-5 cursor-pointer"
              />
            </div>
            <input
              type="text"
              placeholder="Search Competitions"
              value={searchTerm}
              onChange={handleSearchComp}
              className="sm:w-[200px] w-[130px] text-[.9em] bg-[#333333] rounded-r-lg outline-none pr-2 py-1 placeholder:text-[.75em]"
            />
          </div>
        )}

        {currentPage?.includes("/event") && openSearch && (
          <div className="h-[30px] flex mr-4 ml-auto">
            <div className="h-full w-fit p-1 bg-[#333333] rounded-l-lg">
              <img
                alt="user"
                src="/images/Search.png"
                className="w-5 h-5 cursor-pointer"
              />
            </div>
            <input
              type="text"
              placeholder="Search Events"
              value={searchTerm}
              onChange={handleSearchEvent}
              className="sm:w-[200px] w-[130px] text-[.9em] bg-[#333333] rounded-r-lg outline-none pr-2 py-1 placeholder:text-[.75em]"
            />
          </div>
        )}

        <div className="flex gap-3 items-center">
          <img
            onClick={() => dispatch(toggleSearch())}
            alt="user"
            src={
              openSearch
                ? "/images/icons8-close-white-50.png"
                : "/images/icons8-search-64.png"
            }
            className={`${openSearch ? "w-4 h-4" : "w-6 h-6"} cursor-pointer`}
          />

          <div
            onClick={() => dispatch(toggleNotifsBoard())}
            className="w-7 h-7 flex justify-center items-center border border-[#94a4fd]/60 rounded-full cursor-pointer relative"
          >
            {unreadNotifs && (
              <div className="w-3 h-3 bg-red-500 rounded-full absolute top-[-2px] right-[-2px]"></div>
            )}
            <img
              alt="user"
              src="/images/Bell.png"
              className="w-4 h-4 md:w-4 md:h-4"
            />
          </div>
        </div>
      </header>

      {/* Mobile UI */}
      {/* Mobile UI */}
      {/* Mobile UI */}

      <header className="w-full text-white/80 py-4 px-5 sm:pl-[235px] bg-[#1c1c1c] fixed left-0 top-0 border-b-2 border-[#282929] flex md:hidden items-center justify-between z-10 transition-all duration-300">
        <img
          onClick={() => dispatch(toggleDropboard())}
          alt="menu"
          src="/images/Sidebar.png"
          className={`w-7 h-8 cursor-pointer sm:hidden ${
            !openSearch ? "mr-6" : "mr-auto"
          }`}
        />

        {searchTerm && (
          <SearchResultTray
            currentPage={currentPage}
            openNotifsBoard={openNotifsBoard}
            searchResults={searchResults}
            searchTerm={searchTerm}
            clearSearch={clearSearch}
            compData={compData}
            selectedComps={selectedComps}
          />
        )}

        {!openSearch &&
          (status === "success" ? (
            <h1
              onClick={() => navigate("/studio/competition-selection")}
              className="mx-auto block sm:hidden cursor-pointer"
            >
              {capitalizeFirstLetter(data?.studioName)}
            </h1>
          ) : status === "loading" ? (
            <PulseLoader color="#94a4fd" size={10} />
          ) : null)}

        {!openSearch && (
          <div className="mr-auto hidden sm:block">
            <BreadCrumb />
          </div>
        )}

        {currentPage?.includes("/competition") && openSearch && (
          <div className="h-[30px] flex mr-4 ml-auto">
            <div className="h-full w-fit p-1 bg-[#333333] rounded-l-lg">
              <img
                alt="user"
                src="/images/Search.png"
                className="w-5 h-5 cursor-pointer"
              />
            </div>
            <input
              type="text"
              placeholder="Search Competitions"
              value={searchTerm}
              onChange={handleSearchComp}
              className="sm:w-[200px] w-[130px] text-[.9em] bg-[#333333] rounded-r-lg outline-none pr-2 py-1 placeholder:text-[.75em]"
            />
          </div>
        )}

        {currentPage?.includes("/event") && openSearch && (
          <div className="h-[30px] flex mr-4 ml-auto">
            <div className="h-full w-fit p-1 bg-[#333333] rounded-l-lg">
              <img
                alt="user"
                src="/images/Search.png"
                className="w-5 h-5 cursor-pointer"
              />
            </div>
            <input
              type="text"
              placeholder="Search Events"
              value={searchTerm}
              onChange={handleSearchEvent}
              className="sm:w-[200px] w-[130px] text-[.9em] bg-[#333333] rounded-r-lg outline-none pr-2 py-1 placeholder:text-[.75em]"
            />
          </div>
        )}

        <div className="flex gap-3 items-center">
          <img
            onClick={() => dispatch(toggleSearch())}
            alt="user"
            src={
              openSearch
                ? "/images/icons8-close-white-50.png"
                : "/images/icons8-search-64.png"
            }
            className={`${openSearch ? "w-4 h-4" : "w-6 h-6"} cursor-pointer`}
          />

          <div
            onClick={() => dispatch(toggleNotifsBoard())}
            className="w-7 h-7 flex justify-center items-center border border-[#94a4fd]/60 rounded-full cursor-pointer relative"
          >
            {unreadNotifs && (
              <div className="w-3 h-3 bg-red-500 rounded-full absolute top-[-2px] right-[-2px]"></div>
            )}
            <img
              alt="user"
              src="/images/Bell.png"
              className="w-4 h-4 md:w-4 md:h-4"
            />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
