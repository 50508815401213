import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";

export default function Button({ loading, text, className, handleOnClick, btnDisable }) {
  const buttonClassName = `btn  ${className ? className : ""}`;

  return (
    <button className={buttonClassName} onClick={handleOnClick} disabled={btnDisable}>
      <div className="flex items-center justify-center">
        <span className="flex-1 text-center">{text}</span>
        {loading && (
          <div className="flex items-center justify-center">
            <span className="mr-2">Loading...</span>
            <ButtonLoadingSpinner />
          </div>
        )}
      </div>
    </button>
  );
}
