/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import { getEvents } from "../../redux/eventManagement/eventManagement";
import ScrollToTop from "./ScrollToTop";
import { PulseLoader } from "react-spinners";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import AddEventContainer from "./components/AddEventContainer";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";

const StudioOwners = () => {
  const { addEvent, openNotifsBoard, currentEvent } = useSelector(
    (state) => state.eventManagement
  );

  //getting the user ID from auth
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEvents(user?.uid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const { status, data: organizerData } = useFirestoreQueryDoc(
    "organizers",
    user?.uid
  );

  const allStudios = organizerData?.studio_registrations;

  const [selectedStudio, setSelectedStudio] = useState(null);

  function handleSelect(item) {
    setSelectedStudio((prev) => (prev ? null : item));
  }

  const { data: studiosData } = useQueryAllDocs("studio_owners");
  const myStudio = studiosData?.filter(
    (x) => x?.uid === selectedStudio?.studio_id
  )[0];

  const myStudioAccess = currentEvent?.access?.filter(
    (y) => y?.studio_id === selectedStudio?.studio_id
  )[0];

  //to get all entries
  const { data: allEntries } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "entries"
  );

  //to get all entries
  const { data: allDancers } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "dancers"
  );

  const { data: allPayment } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "payment_history"
  );

  const myEntries = allEntries?.filter(
    (a) => a?.studio_selected[0] === selectedStudio?.studio_name
  );
  const myDancers = allDancers?.filter(
    (b) => b?.studioId === selectedStudio?.studio_id
  );

  // console.log("selectedStudio", selectedStudio);
  function getAllDancers(arr) {
    const allSelected = [];

    if (arr && Array?.isArray(arr)) {
      for (const obj of arr) {
        if (obj.dancers_selected && Array?.isArray(obj.dancers_selected)) {
          allSelected.push(...obj.dancers_selected);
        }
      }
    }

    return allSelected;
  }

  //==================================================================================tp get comp data
  const { status: compStatus, data: compData } = useFirestoreQueryDoc(
    "competitions",
    user?.uid
  );
  const [taxRate, setTaxRate] = useState(0.0);

  const [entryTypes, setentryTypes] = useState([]);
  const [pricing, setpricing] = useState([]);

  useEffect(() => {
    if (compData) {
      const { entryTypes, pricing, events } = compData;
      setentryTypes(entryTypes);
      setpricing(pricing?.pricingCategories);
      const currentEve = events?.filter(
        (itm) => itm?.event_uid === currentEvent?.event_uid
      )[0];
      setTaxRate(currentEve?.tax_rate || 0);
    }
  }, [compData, compStatus]);

  const studioEntries = allEntries?.filter(
    (each) =>
      each?.studio_selected[0] === selectedStudio?.studio_name &&
      each?.event_uid === currentEvent?.event_uid
  );

  const studioPayment = allPayment?.filter(
    (itm) =>
      itm?.studio_id === selectedStudio?.studio_id &&
      itm?.event_uid === currentEvent?.event_uid
  );

  // ====================================================================================Calculate the totalAmount owning
  const newTotalAmount = entryTypes
    ?.reduce((total, row) => {
      const rowPricingOption = pricing?.find((obj) =>
        obj.hasOwnProperty(row?.entryTypeName)
      );
      const rowEntries = studioEntries?.filter(
        (itm) => itm?.entryType === row?.entryTypeName
      );

      const flatAmount =
        rowEntries?.length > 0
          ? rowPricingOption?.[row?.entryTypeName] * rowEntries?.length
          : 0.0;

      const rowAmount =
        rowPricingOption?.selectedOption?.toLowerCase() === "flat"
          ? Number(flatAmount)
          : getAllDancers(rowEntries)?.length *
            Number(rowPricingOption?.[row?.entryTypeName]);

      return total + (rowAmount || 0);
    }, 0)
    .toFixed(2);

  function calcTotalAfterTax(amount, percentage) {
    const result = (amount * percentage) / 100;
    return result.toFixed(2);
  }

  const totalAfterTax =
    Number(newTotalAmount) + Number(calcTotalAfterTax(newTotalAmount, taxRate));

  function calculateTotalPayments(arr) {
    if (!arr || !Array?.isArray(arr)) {
      return "Invalid input";
    }

    let totalAmount = 0;

    for (const obj of arr) {
      if (obj.amount) {
        totalAmount += parseFloat(obj.amount);
      }
    }

    return totalAmount.toFixed(2);
  }

  const amountOwing = totalAfterTax - calculateTotalPayments(studioPayment);

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}
      {!addEvent && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[310px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}
            <h2 className="mb-6 text-white/80 text-[1.25rem] font-medium">
              All Studios
            </h2>
            <div className="w-full p-3 md:p-5 md:max-w-[85%] lg:max-w-[70%] md:min-w-[450px] min-h-[200px] bg-[#282929] rounded-lg flex flex-col gap-6 mb-6 text-white/80">
              <h1 className="w-full border-b pb-2 font-bold border-white/30">
                Studios
              </h1>

              <div className="w-full flex flex-col gap-3">
                {status === "loading" && (
                  <div className="w-full h-[150px] flex justify-center items-center">
                    <PulseLoader color="#94a4fd" size={10} />
                  </div>
                )}
                {allStudios?.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      onClick={() => handleSelect(item)}
                      className="w-full flex justify-between items-center bg-white/10 hover:bg-white/20 cursor-pointer p-3 rounded-lg text-[.85rem]"
                    >
                      <p>{capitalizeFirstLetter(item?.studio_name)}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {selectedStudio && (
        <div
          onClick={() => {
            handleSelect();
          }}
          className="fixed inset-0 py-[100px] flex justify-center bg-[#1c1c1c]/90 z-[999]"
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="rounded-lg p-6 w-full md:w-1/2 max-w-[700px] min-w-[300px] h-[500px] mx-auto"
          >
            <div className="bg-[#282929] rounded-lg p-6 relative scale">
              <div
                onClick={() => {
                  handleSelect();
                }}
                className="w-6 h-6 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-3 right-3"
              >
                <img
                  alt="user"
                  src="/images/icons8-close-50.png"
                  className="w-4 h-4"
                />
              </div>
              <h3 className="text-lg font-semibold mb-4 border-b border-white/30 py-2 text-white/80">
                Studio Information:{" "}
                {capitalizeFirstLetter(selectedStudio?.studio_name)}
              </h3>
              <ul className="space-y-3 text-white/80">
                <li className="w-full flex gap-3 items-center">
                  <span className="text-white/50">Studio owner:</span>
                  <span>
                    {capitalizeFirstLetter(selectedStudio?.first_name)}{" "}
                    {capitalizeFirstLetter(selectedStudio?.last_name)}
                  </span>
                </li>
                <li>
                  <span className="text-white/50">Studio name: </span>{" "}
                  <span>
                    {capitalizeFirstLetter(selectedStudio?.studio_name)}
                  </span>
                </li>
                <li>
                  <span className="text-white/50">Email: </span>{" "}
                  <span>{selectedStudio?.email}</span>
                </li>

                <li>
                  <span className="text-white/50">Address: </span>{" "}
                  <span>{myStudio?.addressLine1}</span>
                </li>
                <li>
                  <span className="text-white/50">Phone number: </span>{" "}
                  <span>{myStudio?.phone}</span>
                </li>
                <li>
                  <span className="text-white/50">Website: </span>{" "}
                  <a
                    href={myStudio?.website}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-blue-400 hover:underline"
                  >
                    {myStudio?.website}
                  </a>
                </li>

                <li>
                  <span className="text-white/50">
                    Estimated number of entries:{" "}
                  </span>
                  <span>{myStudioAccess?.estimated_entries}</span>
                </li>
                <li>
                  <span className="text-white/50"># Entries submitted: </span>
                  <span>{myEntries?.length}</span>
                </li>
                <li>
                  <span className="text-white/50"># Dancers: </span>
                  <span>{myDancers?.length}</span>
                </li>
                <li>
                  <span className="text-white/50">Amount paid: </span>
                  <span>${calculateTotalPayments(studioPayment)}</span>
                </li>
                <li>
                  <span className="text-white/50">Amount owing: </span>
                  <span>${amountOwing > 0 ? amountOwing?.toFixed(2) : 0}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {/* the add event pop up page */}

      {addEvent && <AddEventContainer />}
    </>
  );
};

export default StudioOwners;
