import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteEvent,
  toggleEditEvent,
  addToFavorites,
  removeFromFavorites,
} from "redux/eventManagement/eventManagement";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const EventCard = ({ item, index, organizerComp }) => {
  const {
    deleteEventSuccess,
    deleteEventPending,
    addToFavePending,
    removeFromFavePending,
  } = useSelector((state) => state.eventManagement);
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteEventMod, setDeleteEventMod] = useState(false);
  useEffect(() => {
    if (deleteEventSuccess) {
      setDeleteEventMod(false);
    }
  }, [deleteEventSuccess]);
  function toggleEventDeleteMod() {
    setDeleteEventMod((prev) => !prev);
  }

  const [editEventMod, setEditEventMod] = useState(false);
  function toggleEventEditMod() {
    setEditEventMod((prev) => !prev);
  }

  const [faveAlert, setFaveAlert] = useState(false);
  function addFaveAlert() {
    setFaveAlert(true);
    setTimeout(() => {
      setFaveAlert(false);
    }, 2000);
  }

  const [unFaveAlert, setUnFaveAlert] = useState(false);
  function removeFaveAlert() {
    setUnFaveAlert(true);
    setTimeout(() => {
      setUnFaveAlert(false);
    }, 2000);
  }

  function linkToEvent() {
    navigate(`/organizer/event/${item?.event_name.replace(/ /g, "-")}`);
  }

  const startTime = moment(item?.days[0].startTime).local().format("MMM D");

  const endTime = moment(item?.days[item?.days?.length - 1].endTime)
    .local()
    .format("MMM D");

  //to get all entries
  const { data: allEntries } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "entries"
  );

  const eventEntries = allEntries?.filter(
    (each) => each?.event_uid === item?.event_uid
  );

  //to get access to all organizer data - (will be moved to redux state later)
  const { data: dataOrg } = useFirestoreQueryDoc("organizers", user?.uid);
  const studioRegistrations = dataOrg?.studio_registrations;
  const studiosWithAccess = studioRegistrations?.filter((item1) =>
    item?.access?.some((item2) => item2?.studio_id === item1.studio_id)
  );
  // console.log('eventEntries', eventEntries);

  return (
    <>
      {" "}
      <div className="min-w-[200px] min-h-[260px] max-w-full bg-[#282929] pl-6 pr-4 py-4 flex flex-col rounded-lg text-white">
        <p className="w-full text-end text-[1.1rem] font-bold">
          {item?.days?.length === 1
            ? `${startTime}, ${item?.year}`
            : `${startTime} - ${endTime} ${item?.year}`}
        </p>
        <div className={`${"mb-auto"} text-[.85rem]`}>
          <p>
            {capitalizeFirstLetter(organizerComp?.competitionName)}:{" "}
            {capitalizeFirstLetter(item?.event_name)}
          </p>
          <p>{capitalizeFirstLetter(item?.venue)}</p>
          <p>{capitalizeFirstLetter(item?.address)}</p>
          <p>
            {capitalizeFirstLetter(item?.city)},{" "}
            {capitalizeFirstLetter(item?.state)}
          </p>
          <p>
            {capitalizeFirstLetter(item?.postal_code)},{" "}
            {capitalizeFirstLetter(item?.country)}
          </p>
        </div>
        {
          <div className="mt-5">
            <div className="text-[.85rem] font-light flex gap-5 items-center mb-3">
              <div className="flex gap-[5px] items-center">
                {/* <img alt="arrow" src="/images/Dot1.png" className="w-3 h-3" /> */}
                <div className="w-[6px] h-[6px] rounded-full bg-green-200"></div>
                <p>Studios Registered</p>
              </div>
              {/* <p className="">{item?.studios_registered}</p> */}
              <p className="">{studiosWithAccess?.length}</p>
            </div>
            <div className="text-[.85rem] font-light flex gap-5 items-center mb-3">
              <div className="flex gap-1 items-center">
                {/* <img alt="arrow" src="/images/Dot3.png" className="w-3 h-3" /> */}
                <div className="w-[6px] h-[6px] rounded-full bg-[#94a4fd]"></div>
                <p>Estimated Entries</p>
              </div>
              <p>{item?.estimated_entries}</p>
            </div>
            <div className="text-[.85rem] font-light flex gap-5 items-center mb-3">
              <div className="flex gap-1 items-center">
                {/* <img alt="arrow" src="/images/Dot3.png" className="w-3 h-3" /> */}
                <div className="w-[6px] h-[6px] rounded-full bg-indigo-200"></div>
                <p>Entries Submitted</p>
              </div>
              <p className="text-white/50">{eventEntries?.length}</p>
            </div>
          </div>
        }

        <div className="w-full flex flex-wrap justify-end items-center mt-2">
          {item?.favorite ? (
            <div
              onClick={() => {
                dispatch(
                  removeFromFavorites({
                    name: item?.event_name,
                    index: index,
                    uid: user?.uid,
                    event_uid: item?.event_uid,
                  })
                );
                removeFaveAlert();
              }}
              className="w-5 h-5 cursor-pointer mr-auto relative"
            >
              {unFaveAlert && (
                <div className="w-[100px] bg-[#94a4fd] px-2 py-1 rounded-md text-[#1c1c1c] font-medium text-[.7rem] absolute top-[-50px] fave opacity-0">
                  Removing from Favorites!
                </div>
              )}
              {(addToFavePending || removeFromFavePending) && unFaveAlert && (
                <div className="w-[22px] h-[22px] bg-gradient-to-b from-[#94a4fd] to-[#282929] rounded-full relative rotate">
                  <div className="w-2/3 h-2/3 bg-[#282929] rounded-full absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"></div>
                </div>
              )}
              {!addToFavePending && !removeFromFavePending && (
                <img
                  className="hover:scale-[1.2]"
                  alt="arrow"
                  src="/images/star-filled.png"
                />
              )}
            </div>
          ) : (
            <div
              onClick={() => {
                dispatch(
                  addToFavorites({
                    name: item?.event_name,
                    index: index,
                    uid: user?.uid,
                    event_uid: item?.event_uid,
                  })
                );
                addFaveAlert();
              }}
              className="w-5 h-5 cursor-pointer mr-auto relative"
            >
              {faveAlert && (
                <div className="w-[80px] bg-[#94a4fd] px-2 py-1 rounded-md text-[#1c1c1c] font-medium text-[.7rem] absolute top-[-50px] fave opacity-0">
                  Adding to Favorites!
                </div>
              )}
              {(addToFavePending || removeFromFavePending) && faveAlert && (
                <div className="w-[22px] h-[22px] bg-gradient-to-b from-[#94a4fd] to-[#282929]  rounded-full relative rotate">
                  <div className="w-2/3 h-2/3 bg-[#282929] rounded-full absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"></div>
                </div>
              )}
              {!addToFavePending && !removeFromFavePending && (
                <img
                  className="hover:scale-[1.2]"
                  alt="arrow"
                  src="/images/Star.png"
                />
              )}
            </div>
          )}
          <button
            onClick={linkToEvent}
            className="text-[.85rem] px-5 py-1 mr-4 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
          >
            Manage Event
          </button>
          <div className="w-6 h-6 flex justify-center items-center bg-white/70 rounded-full mr-4 cursor-pointer hover:scale-[1.2]">
            <img
              alt="arrow"
              src="/images/icons8-pencil-drawing-50.png"
              className="w-4 h-4"
              onClick={toggleEventEditMod}
            />
          </div>
          <img
            alt="arrow"
            src="/images/trash.png"
            className="w-5 h-5 cursor-pointer hover:scale-[1.2]"
            onClick={toggleEventDeleteMod}
          />
        </div>
      </div>
      {/* delete event modal */}
      {deleteEventMod && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-[250px] p-4 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-center scale shadow-lg">
            <p className="w-full text-[.85em] sm:text-[1rem] text-white pb-3 border-b border-[#c5c4c4]/50">
              Confirm delete event:{" "}
              <span className="font-bold">{item?.event_name}</span>?
            </p>
            <div className="w-full text-[.85em] sm:text-[1rem] text-white flex items-center gap-3 p-3 rounded-md bg-red-400/20 border border-red-500">
              <img
                alt="risk"
                src="/images/icons8-medium-risk-50.png"
                className="w-6 h-6"
              />
              <p>Note: This action is permanent and irreversible!</p>
            </div>
            <p className="text-white/70 text-[.85em] sm:text-[1rem]">
              Before you delete this event:{" "}
              <span className="font-bold">{item?.event_name}</span>, please
              confirm that this is what you intend to do. <br />
              Remember, once deleted, the event cannot be retrieved. Are you
              sure you want to proceed with the deletion?
            </p>
            <div className="mt-7">
              <button
                onClick={toggleEventDeleteMod}
                className="text-[.85rem] px-8 py-2 mr-4 rounded-md bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  dispatch(
                    deleteEvent({
                      name: item?.event_name,
                      uid: user?.uid,
                      event_uid: item?.event_uid,
                    })
                  );
                  // toggleEventDeleteMod();
                }}
                disabled={deleteEventPending}
                className="text-[.9rem] px-8 py-2 rounded-md bg-red-500 hover:bg-red-400/80 text-white transition-all duration-300"
              >
                {deleteEventPending ? "Deleting.." : "Delete"}
              </button>
            </div>
          </div>
        </div>
      )}
      {/* edit event modal */}
      {editEventMod && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-center scale shadow-lg">
            <p className="w-full text-[1rem] text-white text-center pb-3 border-b border-[#c5c4c4]/50">
              Confirm edit event:{" "}
              <span className="font-bold">{item?.event_name}</span>?
            </p>

            <p className="text-white/70">
              NOTE: editing event -{" "}
              <span className="font-bold">{item?.event_name}</span> will modify
              its existing information. <br />
              Are you sure you want to proceed with the changes?
            </p>

            <div className="mt-4">
              <button
                onClick={toggleEventEditMod}
                className="text-[.85rem] px-8 py-2 mr-4 rounded-md bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Cancel
              </button>
              <button
                onClick={() => dispatch(toggleEditEvent(item?.event_uid))}
                className="text-[.9rem] px-8 py-2 rounded-md bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EventCard;
