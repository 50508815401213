import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  addEntryType,
  removeEntryType,
} from '../../../redux/organizerRegistration/organizerSlices';
import RemoveImg from '../../../assets/remove.png';

const EntryTypeSchema = Yup.object().shape({
  entryTypeName: Yup.string()
    .required('Entry type is required')
    .matches(/^[^0-9]*$/, 'entryTypeName should not contain numbers'),
  minDancers: Yup.number()
    .required('Required')
    .positive('Must be positive')
    .integer('Must be an integer')
    .max(Yup.ref('maxDancers'), 'Min dancers must be less than Max dancers'),
  maxDancers: Yup.number()
    .required('Required')
    .positive('Must be positive')
    .integer('Must be an integer'),
  maxTime: Yup.number()
    .required('Required')
    .positive('Must be positive')
    .test(
      'is-valid-time',
      'Invalid time format',
      (value) => value === 0 || /^\d+(\.\d+)?$/.test(value)
    ),
});

const EntryType = () => {
  const dispatch = useDispatch();
  const { entryTypes } = useSelector((state) => state.persistedReducer);

  const formik = useFormik({
    initialValues: {
      entryTypeName: '',
      minDancers: '',
      maxDancers: '',
      maxTime: '',
    },
    validationSchema: EntryTypeSchema,
    onSubmit: (values, { resetForm }) => {
      // Convert entryTypeName to lowercase
      values.entryTypeName = values.entryTypeName.toLowerCase();
      // Convert decimal value to the desired format (hh:mm:ss)
      const hours = Math.floor(values.maxTime / 60);
      const minutes = Math.floor(values.maxTime % 60);
      const seconds = Math.floor((values.maxTime % 1) * 60);
      values.maxTime = `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

      dispatch(addEntryType(values));
      resetForm();
    },
  });

  // convert the time from hh:mm:ss to decimal
  const convertTimeToDecimal = (maxTime) => {
    // Parse hours, minutes and seconds from maxTime
    let [hours, minutes, seconds] = maxTime?.split(':')?.map(Number);
    // Convert hours and minutes to minutes
    let totalMinutes = hours * 60 + minutes;

    if (totalMinutes < 60) {
      // If less than 60 minutes, don't show the hours
      return `${minutes?.toString().padStart(2, '0')}:${seconds
        ?.toString()
        .padStart(2, '0')}`;
    } else {
      // If 60 minutes or more, show the hours
      return `${hours?.toString().padStart(2, '0')}:${minutes
        ?.toString()
        .padStart(2, '0')}:${seconds?.toString().padStart(2, '0')}`;
    }
  };

  // check if the entryTypeName is already added
  const index = entryTypes.findIndex(
    (entryType) => entryType.entryTypeName === formik.values.entryTypeName
  );

  // if the entryTypeName is already added don't allow to add it again and return error
  if (index !== -1) {
    formik.errors.entryTypeName = 'Entry Type Name already exists';
  }

  const handleRemove = (index) => {
    dispatch(removeEntryType(index));
  };

  return (
    <div className="w-full mt-8 form-control">
      <label className="label w-[75%]">
        <span className="mb-2 text-base font-medium label-text text-[#696F79]">
          What entry types does your competition have, how many dancers are
          required for it and what is the time limit?
        </span>
      </label>
      <div className="flex flex-row flex-wrap items-baseline px-1 mb-[0.75rem]">
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col w-full lg:w-[75%] md:w-[75%] mr-4"
        >
          <div className="flex flex-col md:flex-row items-center justify-between w-full gap-3">
            <div className="w-full flex flex-col gap-2">
              <input
                name="entryTypeName"
                placeholder="Entry Type Name"
                onChange={formik.handleChange}
                value={formik.values.entryTypeName}
                className={`input input-bordered border-[#959FB1] h-[38px] w-[100%] text-sm font-medium text-[#9FA8B8] dark:text-white mr-[7px] placeholder-[#8692A6]  ${
                  formik.errors.entryTypeName &&
                  formik.touched.entryTypeName &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {formik.errors.entryTypeName && formik.touched.entryTypeName && (
                <span className="text-xs text-red-500">
                  {formik.errors.entryTypeName}
                </span>
              )}
            </div>
            <div className="flex flex-col gap-2 md:w-[33%] xs:w-full">
              <input
                type="number"
                name="minDancers"
                placeholder="Min.#"
                onChange={formik.handleChange}
                value={formik.values.minDancers}
                className={`input input-bordered border-[#959FB1] h-[38px] w-[100%] text-sm font-medium text-[#9FA8B8] dark:text-white mr-[7px] placeholder-[#8692A6]  ${
                  formik.errors.minDancers &&
                  formik.touched.minDancers &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {
                formik.errors.minDancers && formik.touched.minDancers && (
                  <span className="text-xs text-red-500">
                    {formik.errors.minDancers}
                  </span>
                ) // check if the form is valid before submitting
              }
            </div>
            <div className="flex flex-col gap-2 md:w-[33%] xs:w-full">
              <input
                type="number"
                name="maxDancers"
                placeholder="Max.#"
                onChange={formik.handleChange}
                value={formik.values.maxDancers}
                className={`input input-bordered border-[#959FB1] h-[38px] w-[100%] text-sm font-medium text-[#9FA8B8] dark:text-white mr-[7px] placeholder-[#8692A6]  ${
                  formik.errors.maxDancers &&
                  formik.touched.maxDancers &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {
                formik.errors.maxDancers && formik.touched.maxDancers && (
                  <span className="text-xs text-red-500">
                    {formik.errors.maxDancers}
                  </span>
                ) // check if the form is valid before submitting
              }
            </div>
            <div className="flex flex-col gap-2 md:w-[33%] xs:w-full">
              <input
                type="number"
                name="maxTime"
                placeholder="Time (mm:ss)"
                onChange={formik.handleChange}
                value={formik.values.maxTime}
                className={`input input-bordered border-[#959FB1] h-[38px] w-[100%] text-sm font-medium text-[#9FA8B8] dark:text-white mr-[7px] placeholder-[#8692A6]  ${
                  formik.errors.maxTime &&
                  formik.touched.maxTime &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {
                formik.errors.maxTime && formik.touched.maxTime && (
                  <span className="text-xs text-red-500">
                    {formik.errors.maxTime}
                  </span>
                ) // check if the form is valid before submitting
              }
            </div>

            <button
              type="submit"
              // check if the form is valid before submitting
              className="max-h-[3rem] bg-[#1565D8] rounded-[5px] text-white capitalize h-[31px] px-[4rem] ml-2 md:px-9 text-[1rem]"
            >
              add
            </button>
          </div>
        </form>
      </div>
      <div className="flex flex-row flex-wrap w-fit justify-start items-center py-2 rounded-xl cursor-pointer">
        {entryTypes.map((type, index) => (
          <div
            key={index}
            className="flex flex-row flex-wrap w-fit mb-[8px] mr-[10px] bg-[#FFE600] items-center p-[10px] py-2 rounded-md cursor-pointer"
          >
            <span className="text-sm font-medium text-[#8692A6] ">
              {`${type.entryTypeName}, ${type.minDancers}-${
                type.maxDancers
              } dancers, ${convertTimeToDecimal(type.maxTime)}`}
            </span>
            <img
              src={RemoveImg}
              alt="remove"
              className="h-4 w-4 ml-[10px]"
              onClick={() => handleRemove(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default EntryType;
