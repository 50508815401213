import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { registerOrganizer } from "../../services/authenticationService";

// Components
import ImgWrap from "../UI/ImgWrapper";
import Container from "../UI/Container";
import BackIcon from "../../assets/arrow_back.png";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";
import { setVerifyState } from "redux/danceStudio/danceStudioSlice";

export default function OrganizerRegistration() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const validationSchema = Yup.object().shape({
    competitionName: Yup.string()
      .required("Competition name is required")
      .transform((value) => value.trim())
      .max(30, "Competition name should not exceed 30 characters"),
    email: Yup.string()
      .matches(EMAIL_REGEX, "Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    terms: Yup.boolean().oneOf(
      [true],
      "You must agree to the terms and conditions"
    ),
  });

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    // Set loading state to true
    setLoading(true);
    try {
      // Dispatch the registerOrganizer action and wait for the result
      const { payload } = await dispatch(registerOrganizer(values));
      // check whether the payload exists and navigate to the next page if it does exist and registration is successful else set the error message
      if (payload) {
        navigate("/organizer/genre");
        toast.success("Login to complete the registration process");
        dispatch(setVerifyState());
      } else {
        toast.error(
          "Competition Name or Email is already in use. Please choose a different one."
        );
      }
    } catch (error) {
      // Log the error message to the console
      toast.error("Registration failed:", error);
      // Set the error message in the form field
      setFieldError("email", "The email address is already in use");
    } finally {
      // Set loading state and submitting state to false
      setLoading(false);
      setSubmitting(false);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordShown((prevPasswordShown) => !prevPasswordShown);
  };

  return (
    <div className="flex flex-row flex-wrap h-[100vh]">
      <div className="lg:w-[40%] lg:flex hidden">
        <ImgWrap className="bg-[url('../public/Frame3.png')]" />
      </div>
      <div className="lg:w-[60%] w-full">
        <Container className="flex flex-col flex-wrap">
          <div className="flex xs:mb-[2rem] mb-1 flex-row justify-between w-full">
            <div
              className="flex flex-row items-center cursor-pointer"
              onClick={() => navigate("/")}
            >
              <img
                src={BackIcon}
                className="w-[9.73px] h-[16.5px] mr-[14px]"
                alt="backIcon"
              />
              <span className="text-base text-[#8692A6]">Back</span>
            </div>
            <div className="flex flex-col items-end">
              <span className="text-sm font-medium text-[#BDBDBD]">
                STEP 01/06
              </span>
              <h4 className="text-base font-semibold text-[#8692A6]">
                Organizer Registration
              </h4>
            </div>
          </div>
          <div className="sm:mt-[3rem] lg:pl-[63px] md:pl-[63px] md:pt-[9%]">
            <h1 className="mb-2 xs:text-lg sm:text-2xl md:text-3xl font-bold text-[#000000] dark:text-white">
              Create Organizer Account!
            </h1>
            <p className="md:text-lg xs:text-base font-normal text-[#8692A6] lg:w-[65%] md:w-[65%]">
              Please have your competition details ready as they will be
              required during registration
            </p>
          </div>

          <Formik
            initialValues={{
              competitionName: "",
              email: "",
              password: "",
              terms: false,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, isSubmitting, values }) => (
              <Form
                onSubmit={handleSubmit}
                className="lg:ml-[63px] md:ml-[63px] flex flex-col mt-[16px] border-t border-base lg:w-[60%] md:w-[60%]"
              >
                <div className="w-full mt-5 form-control">
                  <label className="label">
                    <span className="mb-4 text-base font-medium label-text text-[#696F79] dark:text-white">
                      Competition Name*
                    </span>
                  </label>
                  <Field
                    type="text"
                    placeholder="Competition Name"
                    className="input input-bordered opacity-100 border-[#959FB1] dark:border-white/80 w-full text-sm font-medium text-[#3d3d3d] dark:text-white placeholder-[#8692A6]"
                    name="competitionName"
                  />
                  <ErrorMessage
                    name="competitionName"
                    component="div"
                    className="text-red-500 text-sm font-medium"
                  />
                </div>

                <div className="w-full mt-5 form-control">
                  <label className="label">
                    <span className="mb-4 text-base font-medium label-text text-[#696F79] dark:text-white">
                      Email address*
                    </span>
                  </label>
                  <Field
                    type="email"
                    placeholder="Email Address"
                    className="input input-bordered opacity-100 border-[#959FB1] dark:border-white/80 w-full text-sm font-medium text-[#3d3d3d] dark:text-white placeholder-[#8692A6]"
                    name="email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 text-sm font-medium"
                  />
                </div>

                <div className="w-full mt-5 form-control">
                  <label className="label ">
                    <span className="mb-4 text-base font-medium label-text text-[#696F79] dark:text-white">
                      Password*
                    </span>
                  </label>

                  <div className="relative bg-white togglePassword">
                    <Field
                      type={passwordShown ? "text" : "password"}
                      placeholder="Password"
                      className="input input-bordered opacity-100 border-[#959FB1] dark:border-white/80 w-full text-sm  text-[#3d3d3d] dark:text-white placeholder-[#8692A6]"
                      name="password"
                    />
                    <span className="password-toggle-icon flex justify-center items-center absolute bottom-0 top-0 right-0 mr-[10px]">
                      {passwordShown ? (
                        <span
                          className="flex cursor-pointer material-symbols-outlined"
                          onClick={togglePasswordVisibility}
                        >
                          <i className="fa-regular fa-eye"></i>
                        </span>
                      ) : (
                        <span
                          className="flex cursor-pointer material-symbols-outlined"
                          onClick={togglePasswordVisibility}
                        >
                          <i className="fa-regular fa-eye-slash"></i>
                        </span>
                      )}
                    </span>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500 text-sm font-medium"
                    />
                  </div>
                </div>

                <div className="mt-6 form-control">
                  <label className="justify-start cursor-pointer label">
                    <Field
                      type="checkbox"
                      className="mr-3 text-white rounded-none bg-[#FFFFFF] checkbox border-[#1565D8] checkbox-xs "
                      name="terms"
                    />
                    <span className="text-base font-medium label-text text-[#696F79] dark:text-white">
                      I agree to terms & conditions
                    </span>
                  </label>
                  <ErrorMessage
                    name="terms"
                    component="div"
                    className="text-red-500 text-sm font-medium"
                  />
                </div>
                <div className="items-center mt-10 form-control">
                  <button
                    className="h-[3rem] bg-[#1565D8] rounded-[5px] text-white capitalize w-80 flex justify-between items-center px-4 text-center"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <span className="w-full">Register Account</span>
                    {loading && <ButtonLoadingSpinner />}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Container>
      </div>
    </div>
  );
}
