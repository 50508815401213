import { ClipLoader } from "react-spinners";
import ReactPaginate from "react-paginate";

const RoutineTopBar = ({
  loading,
  backgroundUpdateOutcome,
  sortedCategories,
  allEntries,
  pageCount,
  changePage,
  affectedItems,
  handleSaveDrag,
  fetchNextPage,
  fetchPrevPage,
  loadingCat,
  currentPage,
  total,
}) => {
  const totalParse = Math.ceil(total / 15);
  const nextCheck = currentPage === totalParse ? true : false;
  const prevCheck = currentPage <= 1 ? true : false;

  return (
    <div className="w-full relative">
      <div className="flex justify-between items-center flex-wrap gap-3 w-full bg-[#1c1c1c] p-3 border border-white/20 rounded-t-lg stickyDiv transition-all duration-300">
        {affectedItems?.length > 0 || loadingCat || loading ? (
          <button
            onClick={handleSaveDrag}
            className="w-[110px] h-fit text-[.85rem] rounded-full flex px-3 py-[3px] items-center justify-center text-black bg-[#94a4fd] hover:opacity-70 transition-all duration-300 whitespace-nowrap"
          >
            {loadingCat || loading ? (
              <ClipLoader color="#000" size={20} />
            ) : (
              "Save Changes"
            )}
          </button>
        ) : (
          <div className="flex gap-3">
            <p>
              Page <span className="font-bold">{currentPage}</span> of{" "}
              <span className="font-bold">{totalParse}</span>
            </p>
            {/* <button className="h-fit text-[.85rem] rounded-full flex gap-1 items-center text-white/80 transition-all duration-300">
              Categories
              <p className="font-bold">{sortedCategories?.length}</p>
            </button>
            <button className="h-fit text-[.85rem] rounded-full flex gap-1 items-center text-white/80 transition-all duration-300">
              Entries
              <p className="font-bold">{allEntries?.length}</p>
            </button> */}
          </div>
        )}

        <div className="flex gap-3 items-center">
          <button
            onClick={fetchPrevPage}
            disabled={prevCheck}
            className={`px-2 py-1 border border-white/20 flex gap-2 items-center rounded-lg ${
              prevCheck ? "opacity-50 cursor-not-allowed" : "hover:bg-white/20"
            }`}
          >
            <img
              alt="user"
              src="/images/leftArrow.png"
              className="w-6 h-6 rounded-lg border border-white/20 bg-white/10"
            />
            Prev
          </button>
          <button
            onClick={fetchNextPage}
            disabled={nextCheck}
            className={`px-2 py-1 border border-white/20 flex gap-2 items-center rounded-lg ${
              nextCheck ? "opacity-50 cursor-not-allowed" : "hover:bg-white/20"
            }`}
          >
            Next
            <img
              alt="user"
              src="/images/rightArrow.png"
              className="w-6 h-6 rounded-lg border border-white/20 bg-white/10"
            />
          </button>
        </div>

        {/* {loading ? (
          <div className="text-[#94a4fd] flex gap-2 items-center">
            <p>Update ongoing</p> <PulseLoader color="#94a4fd" size={7} />
          </div>
        ) : backgroundUpdateOutcome ? (
          <div
            className={`text-[.85rem] px-2 py-1 rounded-lg ${
              backgroundUpdateOutcome?.includes("successful")
                ? "text-green-400 bg-green-400/10"
                : "text-red-400 bg-red-400/10"
            }`}
          >
            {backgroundUpdateOutcome}
          </div>
        ) : affectedItems?.length > 0 ? (
          <button className="h-fit text-[.85rem] rounded-full flex px-3 py-1 items-center text-white/80 bg-[#94a4fd] transition-all duration-300">
            Save
          </button>
        ) : (
          <div className="flex gap-3">
            <button className="h-fit text-[.85rem] rounded-full flex gap-1 items-center text-white/80 transition-all duration-300">
              Categories
              <p className="font-bold">{sortedCategories?.length}</p>
            </button>
            <button className="h-fit text-[.85rem] rounded-full flex gap-1 items-center text-white/80 transition-all duration-300">
              Entries
              <p className="font-bold">{allEntries?.length}</p>
            </button>
          </div>
        )} */}

        {/* {sortedCategories?.length > 15 && (
          <div className="flex gap-2 justify-end">
            <ReactPaginate
              previousLabel={
                <img
                  alt="user"
                  src="/images/leftArrow.png"
                  className="w-6 h-6 cursor-pointer rounded-lg hover:bg-white/20 border border-white/30"
                />
              }
              nextLabel={
                <img
                  alt="user"
                  src="/images/rightArrow.png"
                  className="w-6 h-6 cursor-pointer rounded-lg hover:bg-white/20 border border-white/30"
                />
              }
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={
                "h-6 flex items-center gap-2 justify-end text-[.95rem] pag"
              }
              activeClassName={"rounded-lg bg-white/20"}
            />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default RoutineTopBar;
