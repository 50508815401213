import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addBreakService,
  addBufferService,
  addEntryService,
  deleteAllDeletedDocsService,
  deleteBreakService,
  deleteCategoryService,
  deleteEntryService,
  editEntryService,
  scratchCategoryService,
  scratchEntryService,
  unScratchCategoryService,
  unScratchEntryService,
  addPaymentHistoryService,
  deletePaymentHistoryService,
  editPaymentHistoryService,
  addPaymentInvoiceReceiptService,
  splitCategoryService,
  combineCategoryService,
} from "services/RoutineScheduleService";

const initialState = {
  addEntrySuccess: null,
  addEntryFail: null,
  addEntryPending: false,

  deleteCategorySuccess: null,
  deleteCategoryFail: null,
  deleteCategoryPending: false,

  editEntrySuccess: null,
  editEntryFail: null,
  editEntryPending: false,

  deleteEntrySuccess: null,
  deleteEntryFail: null,
  deleteEntryPending: false,

  addBreakSuccess: null,
  addBreakFail: null,
  addBreakPending: false,

  deleteBreakSuccess: null,
  deleteBreakFail: null,
  deleteBreakPending: false,

  addBufferSuccess: null,
  addBufferFail: null,
  addBufferPending: false,

  addScheduleAwardSuccess: null,
  addScheduleAwardFail: null,
  addScheduleAwardPending: false,

  deleteAllRoutinesSuccess: null,
  deleteAllRoutinesFail: null,
  deleteAllRoutinesPending: false,

  regSearchResults: [],
  regData: [],
  currentEvent: null,

  notifications: [],
  addScheduleAwardMod: false,
  addScheduleOverallMod: false,
  addSessionMod: false,

  addPaymentSuccess: null,
  addPaymentFail: null,
  addPaymentPending: false,

  deletePaymentSuccess: null,
  deletePaymentFail: null,
  deletePaymentPending: false,

  editPaymentSuccess: null,
  editPaymentFail: null,
  editPaymentPending: false,

  billingDetailSuccess: null,
  billingDetailFail: null,
  billingDetailPending: false,

  splitCategorySuccess: null,
  splitCategoryFail: null,
  splitCategoryPending: false,

  combineCategorySuccess: null,
  combineCategoryFail: null,
  combineCategoryPending: false,

  showNamesEach: null,
};

//===============================to handle add entries
export const addEntry = createAsyncThunk(
  "routineScheduling/addEntry",
  async (data) => {
    const result = await addEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle edit entries
export const editEntry = createAsyncThunk(
  "routineScheduling/editEntry",
  async (data) => {
    const result = await editEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle scratch category
export const scratchCategory = createAsyncThunk(
  "routineScheduling/scratchCategory",
  async (data) => {
    const result = await scratchCategoryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle unscratch category
export const unScratchCategory = createAsyncThunk(
  "routineScheduling/unScratchCategory",
  async (data) => {
    const result = await unScratchCategoryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle delete category
export const deleteCategory = createAsyncThunk(
  "routineScheduling/deleteCategory",
  async (data) => {
    const result = await deleteCategoryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle scratch entry
export const scratchEntry = createAsyncThunk(
  "routineScheduling/scratchEntry",
  async (data) => {
    const result = await scratchEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle unscratch entry
export const unScratchEntry = createAsyncThunk(
  "routineScheduling/unScratchEntry",
  async (data) => {
    const result = await unScratchEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle delete entry
export const deleteEntry = createAsyncThunk(
  "routineScheduling/deleteEntry",
  async (data) => {
    const result = await deleteEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle add Break
export const addBreak = createAsyncThunk(
  "routineScheduling/addBreak",
  async (data) => {
    const result = await addBreakService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle add Break
export const deleteBreak = createAsyncThunk(
  "routineScheduling/deleteBreak",
  async (data) => {
    const result = await deleteBreakService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle add buffer
export const addBuffer = createAsyncThunk(
  "routineScheduling/addBuffer",
  async (data) => {
    const result = await addBufferService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle add schedule award
export const addScheduleAward = createAsyncThunk(
  "routineScheduling/addScheduleAward",
  async (data) => {
    const result = await addBreakService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle add schedule award
export const deleteAllRoutines = createAsyncThunk(
  "routineScheduling/deleteAllRoutines",
  async (data) => {
    const result = await deleteAllDeletedDocsService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle add payment
export const addPayment = createAsyncThunk(
  "routineScheduling/addPayment",
  async (data) => {
    const result = await addPaymentHistoryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle delete payment
export const deletePayment = createAsyncThunk(
  "routineScheduling/deletePayment",
  async (data) => {
    const result = await deletePaymentHistoryService(data);
    console.log("hey", result);
    return result;
  }
);

//===============================to handle edit payment
export const editPayment = createAsyncThunk(
  "routineScheduling/editPayment",
  async (data) => {
    const result = await editPaymentHistoryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle billing for invoice and receipt
export const billingDetail = createAsyncThunk(
  "routineScheduling/billingDetail",
  async (data) => {
    const result = await addPaymentInvoiceReceiptService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle split category
export const splitCategory = createAsyncThunk(
  "routineScheduling/splitCategory",
  async (data) => {
    const result = await splitCategoryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle split category
export const combineCategory = createAsyncThunk(
  "routineScheduling/combineCategory",
  async (data) => {
    const result = await combineCategoryService(data);
    console.log(result);
    return result;
  }
);

//================= slice
export const routineSchedulingSlice = createSlice({
  name: "routineScheduling",
  initialState,
  reducers: {
    toggleAddScheduleAward: (state) => {
      return {
        ...state,
        addScheduleAwardMod: !state.addScheduleAwardMod,
      };
    },
    toggleAddScheduleOverall: (state) => {
      return {
        ...state,
        addScheduleOverallMod: !state.addScheduleOverallMod,
      };
    },
    toggleAddSession: (state) => {
      return {
        ...state,
        addSessionMod: !state.addSessionMod,
      };
    },
    clearSuccess: (state) => {
      return {
        ...state,
        addEntrySuccess: null,
        deleteCategorySuccess: null,
        editEntrySuccess: null,
        deleteEntrySuccess: null,
        addBreakSuccess: null,
        addBufferSuccess: null,
        addScheduleAwardSuccess: null,
        addScheduleAwardMod: false,
        addScheduleOverallMod: false,
        deleteAllRoutinesSuccess: null,
        addSessionMod: false,
        splitCategorySuccess: null,
        combineCategorySuccess: null,
      };
    },
    setShowNamesEach: (state, action) => {
      return {
        ...state,
        showNamesEach: state.showNamesEach ? null : action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    //to handle cases for all async functions
    builder
      //cases for getting all event
      .addCase(addEntry.pending, (state) => {
        state.addEntryPending = true;
      })
      .addCase(addEntry.fulfilled, (state, action) => {
        state.addEntrySuccess = action.payload;
        state.addEntryPending = false;
      })
      .addCase(addEntry.rejected, (state, action) => {
        state.addEntryFail = action.payload;
        state.addEntryPending = false;
      })
      .addCase(deleteCategory.pending, (state) => {
        state.deleteCategoryPending = true;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.deleteCategorySuccess = action.payload;
        state.deleteCategoryPending = false;
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.deleteCategoryFail = action.payload;
        state.deleteCategoryPending = false;
      })
      .addCase(editEntry.pending, (state) => {
        state.editEntryPending = true;
      })
      .addCase(editEntry.fulfilled, (state, action) => {
        state.editEntrySuccess = action.payload;
        state.editEntryPending = false;
      })
      .addCase(editEntry.rejected, (state, action) => {
        state.editEntryFail = action.payload;
        state.editEntryPending = false;
      })
      .addCase(deleteEntry.pending, (state) => {
        state.deleteEntryPending = true;
      })
      .addCase(deleteEntry.fulfilled, (state, action) => {
        state.deleteEntrySuccess = action.payload;
        state.deleteEntryPending = false;
      })
      .addCase(deleteEntry.rejected, (state, action) => {
        state.deleteEntryFail = action.payload;
        state.deleteEntryPending = false;
      })
      .addCase(addBreak.pending, (state) => {
        state.addBreakPending = true;
      })
      .addCase(addBreak.fulfilled, (state, action) => {
        state.addBreakSuccess = action.payload;
        state.addBreakPending = false;
      })
      .addCase(addBreak.rejected, (state, action) => {
        state.addBreakFail = action.payload;
        state.addBreakPending = false;
      })
      .addCase(deleteBreak.pending, (state) => {
        state.deleteBreakPending = true;
      })
      .addCase(deleteBreak.fulfilled, (state, action) => {
        state.deleteBreakSuccess = action.payload;
        state.deleteBreakPending = false;
      })
      .addCase(deleteBreak.rejected, (state, action) => {
        state.deleteBreakFail = action.payload;
        state.deleteBreakPending = false;
      })
      .addCase(addBuffer.pending, (state) => {
        state.addBufferPending = true;
      })
      .addCase(addBuffer.fulfilled, (state, action) => {
        state.addBufferSuccess = action.payload;
        state.addBufferPending = false;
      })
      .addCase(addBuffer.rejected, (state, action) => {
        state.addBufferFail = action.payload;
        state.addBufferPending = false;
      })
      //cases for schedule award
      .addCase(addScheduleAward.pending, (state) => {
        state.addScheduleAwardPending = true;
      })
      .addCase(addScheduleAward.fulfilled, (state, action) => {
        state.addScheduleAwardSuccess = action.payload;
        state.addScheduleAwardPending = false;
      })
      .addCase(addScheduleAward.rejected, (state, action) => {
        state.addScheduleAwardFail = action.payload;
        state.addScheduleAwardPending = false;
      })
      //cases for delete all
      .addCase(deleteAllRoutines.pending, (state) => {
        state.deleteAllRoutinesPending = true;
      })
      .addCase(deleteAllRoutines.fulfilled, (state, action) => {
        state.deleteAllRoutinesSuccess = action.payload;
        state.deleteAllRoutinesPending = false;
      })
      .addCase(deleteAllRoutines.rejected, (state, action) => {
        state.deleteAllRoutinesFail = action.payload;
        state.deleteAllRoutinesPending = false;
      })
      //cases for add payment history
      .addCase(addPayment.pending, (state) => {
        state.addPaymentPending = true;
      })
      .addCase(addPayment.fulfilled, (state, action) => {
        state.addPaymentSuccess = action.payload;
        state.addPaymentPending = false;
      })
      .addCase(addPayment.rejected, (state, action) => {
        state.addPaymentFail = action.payload;
        state.addPaymentPending = false;
      })
      //cases for delete payment history
      .addCase(deletePayment.pending, (state) => {
        state.deletePaymentPending = true;
      })
      .addCase(deletePayment.fulfilled, (state, action) => {
        state.deletePaymentSuccess = action.payload;
        state.deletePaymentPending = false;
      })
      .addCase(deletePayment.rejected, (state, action) => {
        state.deletePaymentFail = action.payload;
        state.deletePaymentPending = false;
      })
      //cases for edit payment history
      .addCase(editPayment.pending, (state) => {
        state.editPaymentPending = true;
      })
      .addCase(editPayment.fulfilled, (state, action) => {
        state.editPaymentSuccess = action.payload;
        state.editPaymentPending = false;
      })
      .addCase(editPayment.rejected, (state, action) => {
        state.editPaymentFail = action.payload;
        state.editPaymentPending = false;
      })
      //cases for billing invoice and receipt
      .addCase(billingDetail.pending, (state) => {
        state.billingDetailPending = true;
      })
      .addCase(billingDetail.fulfilled, (state, action) => {
        state.billingDetailSuccess = action.payload;
        state.billingDetailPending = false;
      })
      .addCase(billingDetail.rejected, (state, action) => {
        state.billingDetailFail = action.payload;
        state.billingDetailPending = false;
      })
      //cases for split category
      .addCase(splitCategory.pending, (state) => {
        state.splitCategoryPending = true;
      })
      .addCase(splitCategory.fulfilled, (state, action) => {
        state.splitCategorySuccess = action.payload;
        state.splitCategoryPending = false;
      })
      .addCase(splitCategory.rejected, (state, action) => {
        state.splitCategoryFail = action.payload;
        state.splitCategoryPending = false;
      })
      //cases for split category
      .addCase(combineCategory.pending, (state) => {
        state.combineCategoryPending = true;
      })
      .addCase(combineCategory.fulfilled, (state, action) => {
        state.combineCategorySuccess = action.payload;
        state.combineCategoryPending = false;
      })
      .addCase(combineCategory.rejected, (state, action) => {
        state.combineCategoryFail = action.payload;
        state.combineCategoryPending = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  clearSuccess,
  toggleAddScheduleAward,
  toggleAddScheduleOverall,
  toggleAddSession,
  setShowNamesEach,
} = routineSchedulingSlice.actions;

export default routineSchedulingSlice.reducer;
