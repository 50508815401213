/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { useNavigate, useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { PulseLoader } from "react-spinners";
import { ScrollToTop } from "components/EventManagement";

const EventsPick = () => {
  const { status, data } = useQueryAllDocs("competitions");

  const { competitionName } = useParams();
  const currentComp = data?.filter(
    (item) => item?.competitionName === competitionName
  )[0];

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <main className="w-full h-screen overflow-y-auto overflow-x-hidden bg-[#1C1C1C] px-3 md:px-10 py-14 relative text-white">
        <div className="w-full md:w-[70%] p-3 bg-[#282929] mb-10 mx-auto rounded-lg md:text-center font-bold">
          <div className=" uppercase">
            {status === "success" && (
              <h1 className="md:text-[1.5rem] text-[1.1rem] font-bold text-center w-full">
                {capitalizeFirstLetter(currentComp?.competitionName)} - Events
              </h1>
            )}
          </div>
        </div>
        <button
          onClick={goBack}
          className="px-5 py-1 bg-white/80 hover:bg-white/50 text-black rounded-lg text-[.75rem] md:text-[1rem] mb-8 absolute top-4 left-3"
        >
          Back
        </button>
        <div className="mb-auto">
          {/* Competitions container */}
          <div className="w-full md:min-w-[450px] flex flex-wrap gap-3 md:gap-4 mb-auto text-white/80 justify-center md:justify-start">
            {status === "success" &&
              currentComp?.events?.map((item, index) => {
                function linkToTopscores() {
                  navigate(
                    `/competitions/${competitionName}/${item?.event_name}/top-scores`
                  );
                }

                function linkToParent() {
                  navigate(
                    `/competitions/${competitionName}/${item?.event_name}/parent-student`
                  );
                }

                return (
                  <div
                    key={index}
                    className="w-full md:w-[250px] flex flex-col gap-3 bg-[#282929] p-4 rounded-lg border border-white/10"
                  >
                    {/* <img alt="user" src={"/images/logoComp.png"} className="w-8 h-8 mr-6" /> */}
                    <h3 className="font-medium mr-auto text-[1.2rem]">
                      {capitalizeFirstLetter(item?.event_name)}
                    </h3>
                    <div className="flex gap-3 text-[.85rem]">
                      <p
                        onClick={linkToTopscores}
                        className="text-[#94a4fd] cursor-pointer hover:underline text-right"
                      >
                        Top Scores
                      </p>

                      <p
                        onClick={linkToParent}
                        className="text-white/70 cursor-pointer hover:underline text-right"
                      >
                        Parent/Student
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>

          {status === "loading" && (
            <div className="w-full h-[200px] border border-white/20 rounded-lg flex justify-center items-center gap-6 mb-auto text-white/80">
              <PulseLoader color="#94a4fd" size={10} />
            </div>
          )}

          {status === "success" &&
            (currentComp?.events?.length === 0 || !currentComp?.events) && (
              <div className="w-full h-[200px] border border-white/20 rounded-lg flex justify-center items-center gap-6 mb-auto text-white/80">
                No events yet..
              </div>
            )}
        </div>
      </main>
      <ScrollToTop />
    </>
  );
};

export default EventsPick;
