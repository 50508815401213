import { Chart as ChartJS, Legend, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { PulseLoader } from 'react-spinners';

ChartJS.register(ArcElement, Legend, Tooltip);

const ChartCard = ({
  index,
  dataArray,
  status,
  entriesData,
  selectedStudio,
}) => {
  // functions to round the percentages into two decimal places
  const round = (value, decimals) => {
    return Math.floor(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  };

  // function to calculate the number of users for each key
  const calculateStudioUserCount = (field, filter) => {
    const filtered = entriesData?.filter(
      (entry) =>
        entry[field] === filter &&
        entry.studio_selected.includes(selectedStudio)
    );
    return filtered?.length;
  };

  const genreKeysArray = dataArray[3]
    ? Object.keys(dataArray[3]).filter((key) => dataArray[3][key] === true)
    : null;

  // Generate the chart data and options
  const chartData = {
    // show labels inside the chart for each dataset value dynamically
    labels:
      index === 0
        ? dataArray[index]?.map((item) => item?.entryTypeName)
        : index === 1
        ? dataArray[index]?.map((item) => item?.divisionName)
        : index === 2
        ? dataArray[index]?.map((item) => item?.levelName)
        : index === 3
        ? genreKeysArray?.map((item) => item.toLowerCase())
        : [],
    datasets: [
      {
        data:
          index === 0
            ? dataArray[index]?.map((item) =>
                calculateStudioUserCount('entryType', item?.entryTypeName)
              )
            : index === 1
            ? dataArray[index]?.map((item) =>
                calculateStudioUserCount('age_division', item?.divisionName)
              )
            : index === 2
            ? dataArray[index]?.map((item) =>
                calculateStudioUserCount('classification', item?.levelName)
              )
            : index === 3
            ? genreKeysArray?.map((item) =>
                calculateStudioUserCount('genre', item.toLowerCase())
              )
            : [],
        backgroundColor: [
          '#95A4FC',
          '#c3ecc5',
          '#B1E3FF',
          '#C6C7F8',
          '#27deeb',
          '#2f7bec',
          '#5fd2f5',
          '#1a86a7',
          '#f33990',
          '#8c27eb',
        ],
        hoverOffset: 4,
        borderColor: [
          '#95A4FC',
          '#c3ecc5',
          '#B1E3FF',
          '#C6C7F8',
          '#27deeb',
          '#2f7bec',
          '#5fd2f5',
          '#1a86a7',
          '#f33990',
          '#8c27eb',
        ],
      },
    ],
  };

  const chartOptions = {
    // Add your chart options here
    plugins: { legend: { display: false } },
    maintainAspectRatio: false,
    responsive: true,
    cutout: '70%',
    layout: { padding: 0 },
    elements: { arc: { borderWidth: 0 } },
    tooltips: {
      enabled: false,
    },
  };

  // Check if the dataset data is empty
  const isDataEmpty = chartData.datasets[0].data.every((data) => data === 0);
  return (
    <div className="w-full md:w-[220px] min-w-[150px] lg:w-[300px] min-h-[150px] md:min-h-[450px] md:h-fit bg-[#282929] rounded-xl flex flex-col gap-5 p-5 md:p-[2%]">
      <h2 className="font-bold">
        By{' '}
        {index === 0
          ? 'Entry Type'
          : index === 1
          ? 'Age'
          : index === 2
          ? 'Level'
          : 'Genre'}
      </h2>
      <div className="w-full flex flex-wrap gap-3 md:block">
        {status === 'loading' ? (
          <div className="w-full h-full pt-10 flex justify-center items-center">
            <PulseLoader color="#94a4fd" size={15} />
          </div>
        ) : isDataEmpty ? ( // Render fallback content when data is empty
          <div className="w-full h-[200px] border border-white/10 text-white/50 rounded-xl flex justify-center items-center p-2">
            <h4 className="text-center">
              No data yet... <br /> You have no entries added for this event
            </h4>
          </div>
        ) : (
          <>
            <div className="w-[120px] h-[150px] md:h-fit md:w-full md:mx-auto">
              <Doughnut
                data={chartData}
                options={chartOptions}
                width={150}
                height={150}
              />
            </div>
            <div className="w-1/2 md:w-fit mt-5 mx-auto">
              {index === 0 &&
                dataArray[index]
                  .map((item) => {
                    const userCount = calculateStudioUserCount(
                      'entryType',
                      item?.entryTypeName
                    );
                    const percentage = round(
                      (userCount / entriesData?.length) * 100,
                      2
                    );
                    const dotColor =
                      userCount === 0
                        ? 'transparent'
                        : chartData.datasets[0]?.backgroundColor[
                            dataArray[index]?.indexOf(item)
                          ] || '#94a4fd';

                    return {
                      item,
                      userCount,
                      percentage,
                      dotColor,
                    };
                  })
                  .sort((a, b) => b.percentage - a.percentage)
                  .map((sortedItem, index) => (
                    <div
                      key={index}
                      className="text-[.9rem] md:text-[.75em] font-light flex gap-3 items-center mb-3"
                    >
                      <div className="flex gap-1 items-center">
                        <div
                          className={`w-[6px] h-[6px] rounded-full ${
                            sortedItem.dotColor === 'transparent' &&
                            'border border-white/80'
                          }`}
                          style={{ backgroundColor: sortedItem.dotColor }}
                        ></div>
                        <p>{sortedItem.item?.entryTypeName}</p>
                      </div>
                      <p className="text-[.8em] lg:text-[.9em] font-medium whitespace-nowrap">
                        {sortedItem.userCount} ({sortedItem.percentage}%)
                      </p>
                    </div>
                  ))}
              {/* index 1 */}
              {index === 1 &&
                dataArray[index]
                  .map((item) => {
                    const userCount = calculateStudioUserCount(
                      'age_division',
                      item?.divisionName
                    );
                    const percentage = round(
                      (userCount / entriesData?.length) * 100,
                      2
                    );
                    const dotColor =
                      userCount === 0
                        ? 'transparent'
                        : chartData.datasets[0]?.backgroundColor[
                            dataArray[index]?.indexOf(item)
                          ] || '#94a4fd';

                    return {
                      item,
                      userCount,
                      percentage,
                      dotColor,
                    };
                  })
                  .sort((a, b) => b.percentage - a.percentage)
                  .map((sortedItem, index) => (
                    <div
                      key={index}
                      className="text-[.9rem] md:text-[.75em] font-light flex gap-3 items-center mb-3"
                    >
                      <div className="flex gap-1 items-center">
                        <div
                          className={`w-[6px] h-[6px] rounded-full ${
                            sortedItem.dotColor === 'transparent' &&
                            'border border-white/80'
                          }`}
                          style={{ backgroundColor: sortedItem.dotColor }}
                        ></div>
                        <p>{sortedItem.item?.divisionName}</p>
                      </div>
                      <p className="text-[.8em] lg:text-[.9em] font-medium whitespace-nowrap">
                        {sortedItem.userCount} ({sortedItem.percentage}%)
                      </p>
                    </div>
                  ))}
              {/* index 2 */}
              {index === 2 &&
                dataArray[index]
                  .map((item) => {
                    const userCount = calculateStudioUserCount(
                      'classification',
                      item?.levelName
                    );
                    const percentage = round(
                      (userCount / entriesData?.length) * 100,
                      2
                    );
                    const dotColor =
                      userCount === 0
                        ? 'transparent'
                        : chartData.datasets[0]?.backgroundColor[
                            dataArray[index]?.indexOf(item)
                          ] || '#94a4fd';

                    return {
                      item,
                      userCount,
                      percentage,
                      dotColor,
                    };
                  })
                  .sort((a, b) => b.percentage - a.percentage)
                  .map((sortedItem, index) => (
                    <div
                      key={index}
                      className="text-[.9rem] md:text-[.75em] font-light flex gap-3 items-center mb-3"
                    >
                      <div className="flex gap-1 items-center">
                        <div
                          className={`w-[6px] h-[6px] rounded-full ${
                            sortedItem.dotColor === 'transparent' &&
                            'border border-white/80'
                          }`}
                          style={{ backgroundColor: sortedItem.dotColor }}
                        ></div>
                        <p>{sortedItem.item?.levelName}</p>
                      </div>
                      <p className="text-[.8em] lg:text-[.9em] font-medium whitespace-nowrap">
                        {sortedItem.userCount} ({sortedItem.percentage}%)
                      </p>
                    </div>
                  ))}
              {index === 3 &&
                genreKeysArray
                  ?.map((item, index) => {
                    const userCount = calculateStudioUserCount(
                      'genre',
                      item.toLowerCase()
                    );
                    const percentage = round(
                      (userCount / entriesData?.length) * 100,
                      2
                    );
                    const dotColor =
                      userCount === 0
                        ? 'transparent'
                        : chartData.datasets[0]?.backgroundColor[index] ||
                          '#94a4fd';

                    return {
                      item,
                      userCount,
                      percentage,
                      dotColor,
                    };
                  })
                  .sort((a, b) => b.percentage - a.percentage)
                  .map((sortedItem, index) => (
                    <div
                      key={index}
                      className="text-[.9rem] md:text-[.75em] font-light flex gap-3 items-center mb-3"
                    >
                      <div className="flex gap-1 items-center">
                        <div
                          className={`w-[6px] h-[6px] rounded-full ${
                            sortedItem.dotColor === 'transparent' &&
                            'border border-white/80'
                          }`}
                          style={{ backgroundColor: sortedItem.dotColor }}
                        ></div>
                        <p>{sortedItem.item}</p>
                      </div>
                      <p className="text-[.8em] lg:text-[.9em] font-medium whitespace-nowrap">
                        {sortedItem.userCount} ({sortedItem.percentage}%)
                      </p>
                    </div>
                  ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChartCard;
