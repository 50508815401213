import { collection, query } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

export const useQueryAllDocs = (collectionName) => {
  const firestore = useFirestore();
  const competitionsCollection = collection(firestore, collectionName);
  const competionsQuery = query(competitionsCollection);
  const { status, data } = useFirestoreCollectionData(competionsQuery, {});

  return { status, data };
};
