import { useState } from "react";
import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import ScrollToTop from "./ScrollToTop";
import RegistrationsTable from "./components/RegitrationsTable";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import AddEventContainer from "./components/AddEventContainer";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";

const Registrations = () => {
  const { addEvent, openNotifsBoard, currentEvent } = useSelector(
    (state) => state.eventManagement
  );
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  //to get all entries
  const { data: allEntries } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "entries"
  );

  //to get all entries
  const { data: allDancers } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "dancers"
  );

  const { data: dataOrg, status } = useFirestoreQueryDoc(
    "organizers",
    user?.uid
  );
  const regData = dataOrg?.studio_registrations;

  const studiosWithAccess = regData?.filter((item1) =>
    currentEvent?.access?.some((item2) => item2?.studio_id === item1.studio_id)
  );

  // handle the search input change for entries
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredStudios, setFilteredStudios] = useState([]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const result = studiosWithAccess?.filter(
      (x) =>
        x?.studio_name?.toLowerCase()?.includes(query) ||
        x?.first_name?.toLowerCase()?.includes(query) ||
        x?.last_name?.toLowerCase()?.includes(query)
    );
    setFilteredStudios(result);
  };

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}

      {/* event details page */}
      {!addEvent && (
        <div
          className={`w-full min-h-screen text-white/80 px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px]  ${
            openNotifsBoard ? "lg:pr-[310px]" : "lg:pr-[32px]"
          } pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col transition-all duration-700`}
        >
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/90 text-[1rem] mb-6">
              <h2>
                {capitalizeFirstLetter(currentEvent?.event_name)}{" "}
                {currentEvent?.year.split(" to ")[0]}
              </h2>
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            <div className="w-full min-h-[400px]">
              <div className="w-full p-2 bg-[#282929] rounded-lg flex justify-between">
                <div className="flex gap-2 items-center"></div>
                {/* search */}
                <div
                  className={`h-[30px] flex mr-2 ml-auto border rounded-lg ${
                    searchQuery ? "border-[#94a4fd]" : "border-white/10"
                  }`}
                >
                  <div className="h-full w-fit p-1 bg-[#333333] rounded-l-lg">
                    <img
                      alt="user"
                      src="/images/Search.png"
                      className="w-5 h-5 cursor-pointer"
                    />
                  </div>
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Search"
                    className="w-[130px] text-[.9em] bg-[#333333] rounded-r-lg outline-none pr-2 py-1"
                  />
                </div>
              </div>

              {/* registration table */}
              <RegistrationsTable
                studiosWithAccess={studiosWithAccess}
                allEntries={allEntries}
                allDancers={allDancers}
                status={status}
                filteredStudios={filteredStudios}
                searchQuery={searchQuery}
              />
            </div>
          </div>

          <ScrollToTop />
          <Footer />
        </div>
      )}

      {/* the add event pop up page */}

      {addEvent && <AddEventContainer />}
    </>
  );
};

export default Registrations;
