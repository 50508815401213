import { PDFViewer } from '@react-pdf/renderer';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

const DancersFeePrint = ({
  allEntryTypes,
  pricing,
  newTotalAmountForDancer,
  taxRate,
  totalAfterTaxForDancer,
  tax,
  billingDetails,
  studioEntriesWithDancer,
}) => {
  //to get and format today's date
  function getFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  //to generate invoice number
  function generateInvNumber() {
    return Math.floor(1000 + Math.random() * 9000);
  }
  return (
    <>
      <PDFViewer style={{ width: '100%', height: '100vh' }}>
        <Document>
          <Page size="A4" style={styles.page}>
            <Text style={styles.titleInv}>INVOICE</Text>
            <Text style={styles.title}>
              {billingDetails?.name?.toUpperCase()}
            </Text>

            <View style={styles.sectionDate}>
              <Text style={styles.date}>Date: {getFormattedDate()}</Text>
              <Text style={styles.date}>Invoice#: {generateInvNumber()}</Text>
            </View>

            <View style={styles.sectionSender}>
              <Text style={styles.sender}>
                {capitalizeFirstLetter(billingDetails?.street_address1)}
              </Text>
              <Text style={styles.sender}>
                {billingDetails?.postal_code} ,
                {capitalizeFirstLetter(billingDetails?.city)},{' '}
                {capitalizeFirstLetter(billingDetails?.state_province)}
              </Text>
              <Text style={styles.sender}>
                Tel: {billingDetails?.phone_number}
              </Text>
              <Text style={styles.sender}>
                Tax Number: {billingDetails?.tax_number}
              </Text>
              <Text style={styles.sender}>{billingDetails?.website}</Text>
            </View>

            <View style={styles.sectionSender}>
              <Text style={styles.billto}>Bill To:</Text>
              <Text style={styles.sender}>
                {capitalizeFirstLetter(
                  studioEntriesWithDancer?.[0]?.dancers_selected[0]?.first_name
                )}{' '}
                {capitalizeFirstLetter(
                  studioEntriesWithDancer?.[0]?.dancers_selected[0]?.last_name
                )}
              </Text>
            </View>

            {/* the table section */}
            <View style={styles.sectionTable}>
              <View style={styles.tableRow1}>
                <Text style={styles.tableHeader}>Description</Text>
                <Text style={styles.tableHeader}>Entries</Text>
                <Text style={styles.tableHeader}>Rate</Text>
                <Text style={styles.tableHeader}>Price</Text>
                <Text style={styles.tableHeader}>Amount</Text>
              </View>

              {/* Table rows */}
              {allEntryTypes?.map((row, idx) => {
                const rowEntries = studioEntriesWithDancer?.filter(
                  (itm) =>
                    itm?.entryType === row?.entryTypeName ||
                    itm?.entryType === row?.specialEntryTypeName
                );

                let rowPricingOption;
                if (row.entryTypeName) {
                  rowPricingOption = pricing?.find((obj) =>
                    obj.hasOwnProperty(row?.entryTypeName)
                  );
                } else if (row?.specialEntryTypeName) {
                  rowPricingOption = pricing?.find((obj) =>
                    obj.hasOwnProperty(row?.specialEntryTypeName)
                  );
                }

                const flatAmount =
                  rowEntries?.length > 0
                    ? rowPricingOption?.[
                        row?.entryTypeName || row?.specialEntryTypeName
                      ] * rowEntries?.length
                    : 0.0;

                const rowAmount =
                  rowPricingOption?.selectedOption?.toLowerCase() === 'flat'
                    ? Number(flatAmount)?.toFixed(2)
                    : (
                        rowEntries?.length *
                        Number(
                          rowPricingOption?.[
                            row?.entryTypeName || row?.specialEntryTypeName
                          ]
                        )
                      ).toFixed(2);

                return (
                  <View key={idx} style={styles.tableRow}>
                    <Text style={styles.tableCell}>
                      {capitalizeFirstLetter(
                        row?.entryTypeName || row?.specialEntryTypeName
                      )}
                    </Text>
                    <Text style={styles.tableCell}>{rowEntries?.length}</Text>
                    <Text style={styles.tableCell}>
                      {rowPricingOption?.selectedOption === 'perDancer'
                        ? 'Per Dancer'
                        : 'Flat'}
                    </Text>
                    <Text style={styles.tableCell}>
                      $
                      {Number(
                        rowPricingOption?.[
                          row.entryTypeName || row.specialEntryTypeName
                        ]
                      )?.toFixed(2)}
                    </Text>
                    <Text style={styles.tableCell2}>${rowAmount}</Text>
                  </View>
                );
              })}
            </View>

            {/* the table section */}
            <View style={styles.sectionTable2}>
              <View style={styles.tableRow2}>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={{ ...styles.tableCellTotal, textAlign: 'start' }}>
                  SUBTOTAL
                </Text>
                <Text style={styles.tableCellTotal}>
                  ${newTotalAmountForDancer}
                </Text>
              </View>

              <View style={styles.tableRow2}>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={{ ...styles.tableCellTotal, textAlign: 'start' }}>
                  TAX RATE
                </Text>
                <Text style={styles.tableCellTotal}>{taxRate}%</Text>
              </View>

              <View style={styles.tableRow2}>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={{ ...styles.tableCellTotal, textAlign: 'start' }}>
                  TAX
                </Text>
                <Text style={styles.tableCellTotal}>${tax}</Text>
              </View>

              <View style={styles.tableRow2}>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCellTotal}>.</Text>
                <Text style={styles.tableCellTotal}>.</Text>
              </View>

              <View style={styles.tableRow2}>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={{ ...styles.tableCellTotal, textAlign: 'start' }}>
                  TOTAL
                </Text>
                <Text style={styles.tableCellTotal}>
                  ${totalAfterTaxForDancer?.toFixed(2)}
                </Text>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
};

export default DancersFeePrint;

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 20,
  },
  section: {
    marginTop: 10,
    marginBottom: 10,
    padding: 10,
    flexGrow: 1,
    border: '1pt solid #000000',
  },
  sectionDate: {
    width: '170px',
    marginBottom: 10,
    marginLeft: 'auto',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  titleInv: {
    fontSize: 30,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#f97316',
    textAlign: 'right',
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 5,
  },
  billto: {
    fontSize: 14,
    marginBottom: 5,
    backgroundColor: '#f97316',
    color: 'white',
    width: '50px',
    letterSpacing: '.85px',
  },
  date: {
    fontSize: '12px',
    padding: 5,
    marginTop: 8,
    borderBottom: '1pt solid #475569',
  },
  sectionSender: {
    width: '300px',
    marginBottom: 10,
    marginRight: 'auto',
  },

  sectionComment: {
    width: '300px',
    height: '100px',
    padding: '10px',
    marginBottom: 10,
    marginRight: 'auto',
    border: '1px solid black',
  },
  sender: {
    fontSize: '12px',
    letterSpacing: '.85px',
  },

  sectionTable: {
    marginTop: 10,
    border: '1pt solid #000000',
  },

  sectionTable2: {
    marginBottom: 10,
  },

  tableRow1: {
    flexDirection: 'row',
    borderBottom: '1pt solid #000000',
    alignItems: 'center',
    padding: 3,
    backgroundColor: '#f97316',
    color: 'white',
  },

  tableRow: {
    flexDirection: 'row',
    borderBottom: '1pt solid #000000',
    alignItems: 'center',
  },

  tableRow2: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  tableHeader: {
    fontSize: 10,
    fontWeight: 'bold',
    flex: 1,
    textAlign: 'center',
  },

  tableCell: {
    fontSize: 10,
    flex: 1,
    textAlign: 'center',
    padding: 5,
  },

  tableCellTotal: {
    fontSize: 10,
    flex: 1,
    textAlign: 'center',
    padding: 5,
    borderBottom: '1pt solid #9ca3af',
  },

  tableCell2: {
    fontSize: 10,
    flex: 1,
    textAlign: 'center',
    backgroundColor: '#fdba74',
    padding: 5,
  },
});
