import StudioOwnerCard from "./StudioOwnerCard";
import { useSelector } from "react-redux";
import { toggleNotifsBoard } from "../../../redux/eventManagement/eventManagement";
import { useDispatch } from "react-redux";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { collection, deleteDoc, getDocs, updateDoc } from "firebase/firestore";
import NotificationsContainer from "./NotificationsContainer";
import { db } from "services/firebase";

const RightSidebar = () => {
  const { openNotifsBoard, notifications } = useSelector(
    (state) => state.eventManagement
  );
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { data: dataOrg } = useFirestoreQueryDoc("organizers", user?.uid);
  const regData = dataOrg?.studio_registrations;

  //=============================================will be moved to services
  async function markAllNotificationsAsRead() {
    try {
      const notificationsRef = collection(db, "notifications");
      const querySnapshot = await getDocs(notificationsRef);

      const updatePromises = querySnapshot.docs
        .filter((doc) => doc.data().user_id === user?.uid)
        .map((doc) => updateDoc(doc.ref, { read: true }));

      await Promise.all(updatePromises);

      console.log("Notifications marked as read successfully!");
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  }

  async function deleteNotificationsByUserId() {
    try {
      const notificationsRef = collection(db, "notifications");
      const querySnapshot = await getDocs(notificationsRef);

      const deletePromises = querySnapshot.docs
        .filter(
          (doc) =>
            doc.data().user_id === user?.uid || doc.data().for === user?.uid
        )
        .map((doc) => deleteDoc(doc.ref));

      await Promise.all(deletePromises);

      console.log("Notifications deleted successfully!");
    } catch (error) {
      console.error("Error deleting notifications:", error);
    }
  }
  return (
    <>
      {/* on desktop and larger screens */}
      {/* on desktop and larger screens */}
      {/* on desktop and larger screens */}

      <div className="w-[280px] h-screen px-6 py-7 bg-[#1c1c1c] fixed right-0 top-0 border-l-2 border-[#282929] overflow-y-auto z-20 hidden lg:block slide">
        {/* notifs*/}
        <div className="w-full mb-6">
          <h1 className="text-white">Notifications</h1>

          {notifications?.length > 0 && (
            <>
              <p
                onClick={markAllNotificationsAsRead}
                className="text-white/60 mt-3 text-end cursor-pointer text-[.75rem] hover:underline hover:text-[#94a4fd]"
              >
                Mark all as read
              </p>
              <p
                onClick={deleteNotificationsByUserId}
                className="text-white/60 mt-1 text-end cursor-pointer text-[.75rem] hover:underline hover:text-[#94a4fd]"
              >
                Delete all
              </p>
            </>
          )}

          {/* notifs container */}
          <div className="w-full mt-4">
            <NotificationsContainer />
          </div>
        </div>

        {/* activities */}
        {/* <div className="w-full mb-6">
          <h1 className="text-white">Activities</h1>

          <div className="w-full mt-4">
            {activityData &&
              activityData.map((item) => {
                return <ActivityCard key={item?.id} item={item} />;
              })}
          </div>
        </div> */}

        {/* studio owners */}
        <div className="w-full mt-[100px]">
          <h1 className="text-white">Studio owners</h1>

          {/* notifs container */}
          <div className="w-full mt-4">
            {regData &&
              regData?.map((item, index) => {
                return <StudioOwnerCard key={index} item={item} />;
              })}
            {regData?.length === 0 && (
              <p className="text-[.75rem] w-full h-[100px] border border-white/20 rounded-lg justify-center items-center flex text-white/60">
                Syudios will appear here
              </p>
            )}
          </div>
        </div>
      </div>

      {/* on mobile, small and medium screens */}
      {/* on mobile, small and medium screens */}
      {/* on mobile, small and medium screens */}

      {openNotifsBoard && (
        <div className="w-[76%] sm:w-[300px] h-screen px-6 py-7 bg-[#1c1c1c] fixed right-0 top-0 border-l-2 border-[#282929] overflow-y-auto z-30 sm:z-20 block lg:hidden slide">
          {/* {/* notifs */}
          <div className="w-full mb-6">
            <div className="flex flex-col items-end justify-between">
              <div className="w-full flex justify-between mb-3">
                <h1 className="text-white">Notifications</h1>
                <div
                  onClick={() => dispatch(toggleNotifsBoard())}
                  className="w-6 h-6 flex justify-center items-center bg-white/80 rounded-full cursor-pointer"
                >
                  <img
                    alt="user"
                    src="/images/icons8-close-50.png"
                    className="w-4 h-4"
                  />
                </div>
              </div>
              {notifications?.length > 0 && (
                <>
                  <p
                    onClick={markAllNotificationsAsRead}
                    className="text-white/60 mt-3 text-end cursor-pointer text-[.75rem] hover:underline hover:text-[#94a4fd]"
                  >
                    Mark all as read
                  </p>
                  <p
                    onClick={deleteNotificationsByUserId}
                    className="text-white/60 mt-1 text-end cursor-pointer text-[.75rem] hover:underline hover:text-[#94a4fd]"
                  >
                    Delete all
                  </p>
                </>
              )}
            </div>

            {/* {/* notifs container  */}
            {/* notifs container */}
            <div className="w-full mt-4">
              <NotificationsContainer />
            </div>
          </div>

          {/* {/* activities  */}
          {/* <div className="w-full mb-6">
            <h1 className="text-white">Activities</h1>

            <div className="w-full mt-4">
              {activityData &&
                activityData.map((item) => {
                  return <ActivityCard key={item?.id} item={item} />;
                })}
            </div>
          </div> */}

          {/* {/* studio owners  */}
          <div className="w-full mt-[100px]">
            <h1 className="text-white">Studio owners</h1>

            {/* {/* notifs container  */}
            <div className="w-full mt-4">
              {regData &&
                regData?.map((item, index) => {
                  return <StudioOwnerCard key={index} item={item} />;
                })}
            </div>
          </div>
        </div>
      )}
      {openNotifsBoard && (
        <div
          onClick={() => dispatch(toggleNotifsBoard())}
          className="w-full h-screen block sm:hidden fixed left-0 top-0 bg-black/60 z-20 appear"
        ></div>
      )}
    </>
  );
};

export default RightSidebar;
