import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAward,
  removeAward,
} from '../../../redux/organizerRegistration/organizerSlices';
import RemoveImg from '../../../assets/remove.png';

const awardSchema = Yup.object().shape({
  awardName: Yup.string()
    .required('Award name is required')
    .matches(/^[^0-9]*$/, 'Award name should not contain numbers'),
  code: Yup.string()
    .required('Code is required')
    .matches(/^[^0-9]*$/, 'Award name should not contain numbers'),
  minScore: Yup.number()
    .required('Required')
    .min(1, 'Min Score must be 1 or more')
    .max(
      Yup.ref('maxScore'),
      'Min Score must be less than or equal to Max Score'
    ),
  maxScore: Yup.number()
    .required('Required')
    .min(
      Yup.ref('minScore'),
      'Max Score must be greater than or equal to Min Score'
    )
    .notOneOf([Yup.ref('minScore')], 'Max Score cannot be equal to Min Score'),
});

const Award = () => {
  const dispatch = useDispatch();
  const { awards } = useSelector((state) => state.persistedReducer);
  console.log(awards);

  const formik = useFormik({
    initialValues: {
      awardName: '',
      code: '',
      minScore: '',
      maxScore: '',
    },
    validationSchema: awardSchema,
    onSubmit: (values, { resetForm }) => {
      if (formik.isValid) {
        const lowerCaseValue = {
          ...values,
          awardName: values.awardName.toLowerCase(),
          code: values.code.toLowerCase(),
        };

        const isDuplicate = awards.some(
          (award) => award.awardName === lowerCaseValue.awardName
        );

        const isCodeDuplicated = awards.some(
          (award) => award.code === lowerCaseValue.code
        );

        const isDuplicateMinScore = awards.some(
          (award) => award.minScore === lowerCaseValue.minScore
        );

        const isDuplicateMaxScore = awards.some(
          (award) => award.maxScore === lowerCaseValue.maxScore
        );

        if (isDuplicate) {
          formik.setFieldError('awardName', 'Award name already exists');
        } else if (isCodeDuplicated) {
          formik.setFieldError('code', 'Code already exists');
        } else if (isDuplicateMinScore) {
          formik.setFieldError(
            'minScore',
            'Min score overlaps with an existing award'
          );
        } else if (isDuplicateMaxScore) {
          formik.setFieldError(
            'maxScore',
            'Max score overlaps with an existing award'
          );
        } else {
          dispatch(addAward(lowerCaseValue));
          resetForm();
        }
      }
    },
  });

  const handleRemove = (index) => {
    dispatch(removeAward(index));
  };

  return (
    <div className="w-full mt-8 form-control sm:mt-6">
      <label className="label w-[75%]">
        <span className="mb-2 text-base font-medium label-text text-[#696F79]">
          What are the names of your awards and what percentage value is
          required to receive it?
        </span>
      </label>
      <div className="flex flex-row flex-wrap items-baseline px-1 mb-[0.75rem]">
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col w-full lg:w-[75%] md:w-[75%] mr-4"
        >
          <div className="flex flex-col md:flex-row items-center justify-between w-full gap-3">
            <div className="w-full flex flex-col">
              <input
                name="awardName"
                type="text"
                placeholder="Award Name"
                onChange={formik.handleChange}
                value={formik.values.awardName}
                className={`input input-bordered border-[#959FB1] h-[38px] w-full md:w-[100%] text-sm font-medium text-[#9FA8B8] dark:text-white mr-[7px] placeholder-[#0f4ebb]${
                  formik.errors.awardName &&
                  formik.touched.awardName &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {formik.errors.awardName && formik.touched.awardName && (
                <span className="text-xs text-red-500">
                  {formik.errors.awardName}
                </span>
              )}
            </div>
            <div className="w-full flex flex-col">
              <input
                type="text"
                name="code"
                placeholder="Code"
                onChange={formik.handleChange}
                value={formik.values.code}
                className={`input input-bordered border-[#959FB1] h-[38px] w-full md:w-[100%] text-sm font-medium text-[#9FA8B8] dark:text-white mr-[7px] placeholder-[#8692A6] ${
                  formik.errors.code &&
                  formik.touched.code &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {formik.errors.code && formik.touched.code && (
                <span className="text-xs text-red-500">
                  {formik.errors.code}
                </span>
              )}
            </div>
            <div className="w-full flex flex-col">
              <input
                type="number"
                name="minScore"
                placeholder="min Score"
                onChange={formik.handleChange}
                value={formik.values.minScore}
                className={`input input-bordered border-[#959FB1] h-[38px] w-full md:w-[100%] text-sm font-medium text-[#9FA8B8] dark:text-white mr-[7px] placeholder-[#8692A6] ${
                  formik.errors.minScore &&
                  formik.touched.minScore &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {formik.errors.minScore && formik.touched.minScore && (
                <span className="text-xs text-red-500">
                  {formik.errors.minScore}
                </span>
              )}
            </div>
            <div className="w-full flex flex-col">
              <input
                type="number"
                name="maxScore"
                placeholder="Max Score"
                onChange={formik.handleChange}
                value={formik.values.maxScore}
                className={`input input-bordered border-[#959FB1] h-[38px] w-full md:w-[100%] text-sm font-medium text-[#9FA8B8] dark:text-white mr-[7px] placeholder-[#8692A6] ${
                  formik.errors.maxScore &&
                  formik.touched.maxScore &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {formik.errors.maxScore && formik.touched.maxScore && (
                <span className="text-xs text-red-500">
                  {formik.errors.maxScore}
                </span>
              )}
            </div>

            <button
              type="submit"
              // check if the form is valid before submitting
              className="max-h-[3rem] bg-[#1565D8] rounded-[5px] text-white capitalize h-[31px] px-[4rem] ml-2 md:px-9 text-[1rem]"
            >
              add
            </button>
          </div>
        </form>
      </div>
      <div className="flex flex-row flex-wrap w-fit justify-start items-center py-2 rounded-xl cursor-pointer">
        {awards.map((type, index) => (
          <div
            key={index}
            className="flex flex-row flex-wrap w-fit mb-[8px] mr-[10px] bg-[#FFE600] items-center p-[10px] py-2 rounded-md cursor-pointer"
          >
            <span className="text-sm font-medium text-[#8692A6] ">
              {`${type.awardName}, ${type.code}, ${type.minScore} - ${type.maxScore}`}
            </span>
            <img
              src={RemoveImg}
              alt="remove"
              className="h-4 w-4 ml-[10px]"
              onClick={() => handleRemove(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Award;
