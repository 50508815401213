import { collection, query } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

export const useQueryAllSubCollectionDocs = (
  collectionName,
  compId,
  subCollection
) => {
  const firestore = useFirestore();
  const entriesCollection = collection(
    firestore,
    collectionName,
    compId,
    subCollection
  );
  const entriesQuery = query(entriesCollection);
  const { status, data } = useFirestoreCollectionData(entriesQuery, {
    idField: "id",
  });
  return { status, data };
};
