import React from 'react';
import { Link } from 'react-router-dom';

// Img
import Logo from '../../assets/Logo.png';

export default function ImgWrapper(props) {
  return (
    <div
      className={`h-full bg-no-repeat bg-center bg-cover w-full ${
        props.className ? props.className : ''
      }`}
    >
      <div className="flex flex-start pt-[40px] pl-[40px]">
        <Link to="/">
          <img
            src={Logo}
            alt="Logo"
            className="cursor-pointer w-[90px] h-[25px]"
          />
        </Link>
      </div>
    </div>
  );
}
