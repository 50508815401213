import { useDispatch } from "react-redux";

const LockScheduleModCont = ({
  lockSchedulePending,
  toggleLockMod,
  lockSchedule,
  user,
  currentEvent,
}) => {
  const dispatch = useDispatch();

  return (
    <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
      <div className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-center scale shadow-lg">
        <p className="w-full text-[1rem] text-white text-center pb-3 border-b border-[#c5c4c4]/50">
          Confirm Lock Schedule
        </p>

        <div className="w-full text-[.85em] sm:text-[1rem] text-white flex items-center gap-3 p-3 rounded-md bg-yellow-400/20 border border-yellow-500">
          <img
            alt="risk"
            src="/images/icons8-risk-50.png"
            className="w-6 h-6"
          />
          <p>Note: This action means the event is ready to run!</p>
        </div>

        <p className="text-white/80 text-[.85em] sm:text-[1rem]">
          During the locked schedule, adding entries is still possible, but time
          and entry numbers won't auto-adjust.
        </p>

        <div className="mt-4">
          <button
            disabled={lockSchedulePending}
            onClick={toggleLockMod}
            className="text-[.85rem] px-8 py-2 mr-4 rounded-md bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
          >
            Cancel
          </button>
          <button
            disabled={lockSchedulePending}
            onClick={() => {
              // updateEntriesAfterLock(user?.uid, )
              dispatch(
                lockSchedule({
                  compId: user?.uid,
                  event_name: currentEvent?.event_name,
                })
              );
            }}
            className="text-[.9rem] px-8 py-2 rounded-md bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
          >
            {lockSchedulePending ? "Locking" : "Lock"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LockScheduleModCont;
