import moment from "moment";
import React, { useRef } from "react";
import arrangeEntriesByCategories from "utils/arrangeEntriesByCategories";
import calculateEntryStartTime from "utils/calculateEntryStartTime";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import findIndexByProperty from "utils/findIndexByProperty";
import findLockedEntryLetter from "utils/findLockedEntryLetter";
import sumTimeValues from "utils/sumTimeValues";

const ScheduleCategory = ({
  category,
  index,
  myEntriesSorted,
  sessionsBeforeCurrent,
  currentEvent,
  showNotes,
  setShowNotes,
  sortedCategories,
  allEntries,
  showNames,
  setShowNames,
}) => {
  const showNamesRef = useRef(null);

  const toggleNotes = (entry) => {
    setShowNotes(showNotes ? "" : entry);
  };

  function removeDuplicates(array) {
    const uniqueArray = [];
    const seenObjects = new Set();

    for (const obj of array) {
      // Serialize the object to JSON to use it as a unique identifier
      const serializedObj = JSON.stringify(obj);

      if (!seenObjects.has(serializedObj)) {
        seenObjects.add(serializedObj);
        uniqueArray.push(obj);
      }
    }

    return uniqueArray;
  }

  //============================================================================================new entry time calculation

  // to get all categories before current category
  const categoriesBeforeCurrent = sortedCategories?.slice(0, index) || [];

  const allBufferBeforeCurrent = categoriesBeforeCurrent?.filter(
    (cate) => cate?.type === "buffer"
  );
  const timeToAdd = allBufferBeforeCurrent?.reduce((sum, obj) => {
    if (obj.type === "buffer") {
      const bufferValue = Number(obj.buffer);
      if (!isNaN(bufferValue)) {
        return sum + bufferValue;
      }
    }
    return sum;
  }, 0);

  function addMinutesToTime(inputTime, minutesToAdd) {
    const timeMoment = moment(inputTime, "h:mm A");
    const endTime = timeMoment.clone().add(minutesToAdd, "minutes");
    const formattedEndTime = endTime.format("h:mm A");
    return formattedEndTime;
  }

  const startTime = currentEvent?.days[0]?.startTime;
  const initialTime = moment(startTime).utc().format("h:mm A");

  //to get the entry count of all entries in routine
  function entryTime(itm) {
    const idx = findIndexByProperty(
      removeDuplicates(allEntriesWithoutLocked),
      "doc_id",
      itm?.doc_id
    );
    if (idx === 0) {
      return addMinutesToTime(initialTime, timeToAdd);
    } else {
      const allEntriesBeforeCurrent = removeDuplicates(
        allEntriesWithoutLocked
      )?.slice(
        0,
        findIndexByProperty(
          removeDuplicates(allEntriesWithoutLocked),
          "doc_id",
          itm?.doc_id
        )
      );

      const allTimeBeforeCurrent = sumTimeValues(allEntriesBeforeCurrent);
      const startTime = calculateEntryStartTime(
        initialTime,
        allTimeBeforeCurrent,
        itm
      );

      return addMinutesToTime(startTime, timeToAdd);
    }
  }

  //===============================================================================to calc dynamic entry count of all entries in routine
  const categories = sortedCategories?.filter((l) => l?.type === "category");

  const allEntriesWithoutLocked = arrangeEntriesByCategories(
    categories,
    allEntries
  )?.filter((k) => k?.locked_entry === false);

  const allEntriesWithLocked = arrangeEntriesByCategories(
    categories,
    allEntries
  );

  // console.log("removeDuplicates", removeDuplicates(allEntriesWithoutLocked));

  function entryCount(itm) {
    const allEntriesBeforeLocked = allEntriesWithLocked
      ?.slice(
        0,
        findIndexByProperty(allEntriesWithLocked, "doc_id", itm?.doc_id)
      )
      ?.filter((m) => m?.locked_entry === false);

    if (itm?.locked_entry) {
      return `${
        allEntriesBeforeLocked?.length === 0
          ? 1
          : allEntriesBeforeLocked?.length
      }${findLockedEntryLetter(allEntriesWithLocked, itm)}`;
    } else {
      return (
        findIndexByProperty(allEntriesWithoutLocked, "doc_id", itm?.doc_id) + 1
      );
    }
  }

  if (category?.type === "buffer") {
    return null; // Don't render "buffer" categories
  }

  return (
    <div key={index} className="w-full relative">
      <div
        className={`w-full min-w-[900px] p-3 lg:text-center font-bold relative border-y border-gray-500/10 flex items-center ${
          category?.break_type === "session" ? "bg-[#42125f]" : "bg-[#282929] "
        } ${
          myEntriesSorted?.length === 0 &&
          category?.type === "category" &&
          "hidden"
        }`}
      >
        {category?.is_scratched && (
          <img alt="" src="/images/scratchCat.png" className="w-5 h-3" />
        )}
        <h2
          className={`${
            category?.is_scratched ? "text-white/50" : "text-white"
          } ${
            category?.type !== "category" && "uppercase"
          } flex gap-2 items-center lg:justify-center lg:mx-auto`}
        >
          {capitalizeFirstLetter(category?.category_name?.replace(/_/g, " "))}
          {category?.split && !category?.category_name?.includes("(") && (
            <span className="">(A)</span>
          )}
          {category?.type === "category" && (
            <span className="">({myEntriesSorted?.length})</span>
          )}
          {/*========================================================================= to dynamically render overalls or grand award tags */}
          {category?.break_type?.includes("overalls_") && (
            <span className="">
              {category?.break_type?.split("_")[1] === "overall"
                ? "OVERALLS"
                : category?.break_type?.split("_")[1] === "grand"
                ? "GRAND OVERALLS"
                : ""}
            </span>
          )}
          {/* to dynamically render sessions count */}
          {category?.break_type === "session" && (
            <span className="">
              {sessionsBeforeCurrent?.length === 0
                ? 1
                : sessionsBeforeCurrent?.length + 1}
            </span>
          )}
        </h2>
        {!category?.is_scratched && (
          <div className="w-full h-full absolute top-0 left-0"></div>
        )}
      </div>

      {/* entries container */}
      <div className="w-full">
        {myEntriesSorted?.map((item, idx) => {
          const time = item?.locked_entry ? "AFTER LOCK" : entryTime(item);

          const notesWithoutChoreoAndSpecial = item?.notes?.filter(
            (note) =>
              note?.type !== "Choreography Award Note" &&
              note?.type !== "judge special"
          );

          return (
            <>
              <div
                onClick={() => setShowNames(null)}
                key={idx}
                className={`w-full min-w-[900px] flex p-3 border-b border-white/10 text-[.85rem] relative ${
                  category?.is_scratched ? "text-white/50" : "text-white"
                }`}
              >
                {/* {item?.special_tags?.length > 0 && (
                <div className="absolute top-[-15px] left-0 px-3 border border-white/40 rounded-lg text-white/60 text-[.75em] flex gap-2 bg-[#1c1c1c] z-[2]">
                  {item?.special_tags?.map((tag, ind) => {
                    return (
                      <p key={ind}>
                        {tag}
                        {ind !== item?.special_tags?.length - 1 && (
                          <span className="ml-2">|</span>
                        )}
                      </p>
                    );
                  })}
                </div>
              )} */}
                {/* {item?.special_tags?.length > 0 && (
                <div className="w-[50%] bg-white/20 h-[1px] absolute top-[-6px]"></div>
              )} */}
                {item?.is_scratched && (
                  <div className="w-full h-full bg-stone-700/60 absolute top-0 left-0"></div>
                )}
                <div className="flex gap-[50px] mr-auto">
                  <img
                    alt="arrow"
                    src={`${
                      item?.is_scratched
                        ? "/images/scratchCat.png"
                        : item?.checkedIn
                        ? "/images/CheckSquareOffset.png"
                        : "/images/CheckSquareOffset2.png"
                    }`}
                    className={`w-5 ${
                      item?.is_scratched ? "h-4 mt-1" : "h-5"
                    } ${category?.is_scratched && "opacity-50 h-3"}`}
                  />
                  {/* <p
                  className={`min-w-[70px] whitespace-nowrap ${
                    item?.locked_entry && 'text-white/40 text-[.8em]'
                  }`}
                >
                  {currentEvent?.locked ? item?.locked_entry_time : time}
                </p> */}
                  <p
                    className={`min-w-[70px] whitespace-nowrap ${
                      item?.locked_entry && "text-white/40 text-[.8em]"
                    }`}
                  >
                    {currentEvent?.locked
                      ? item?.locked_entry_time || time
                      : !currentEvent?.locked && "TBD"}{" "}
                  </p>

                  <p className="min-w-[30px]">
                    {currentEvent?.locked
                      ? item?.locked_entry_count || entryCount(item)
                      : !currentEvent?.locked && "TBD"}
                  </p>
                  {/* <p className="min-w-[30px]">
                  {currentEvent?.locked ? item?.locked_entry_count : 'TBD'}
                </p> */}
                  <p className="whitespace-nowrap max-w-[250px] truncate">
                    {capitalizeFirstLetter(item?.entry_name)}
                  </p>
                </div>
                <div className="w-[45%] flex gap-[50px]">
                  <p className="">{item?.studio_letter}</p>

                  <div
                    onClick={(e) => {
                      item?.dancers_selected?.length > 1 && e.stopPropagation();
                      showNamesRef.current = item; // Use useRef to store the current item
                      setShowNames((prev) => (prev ? null : item));
                    }}
                    className={`mr-auto whitespace-nowrap w-[200px] px-2 rounded-md relative cursor-pointer ${
                      item?.dancers_selected?.length > 1 && !showNames
                        ? "truncate hover:bg-[#94a4fd]/40"
                        : item?.dancers_selected?.length > 1 && showNames
                        ? "truncate"
                        : ""
                    }`}
                  >
                    {item?.dancers_selected?.map((dancer, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && ", "}
                        <span>
                          {capitalizeFirstLetter(dancer?.first_name)}{" "}
                          {capitalizeFirstLetter(dancer?.last_name)}
                        </span>
                      </React.Fragment>
                    ))}
                  </div>
                  <div className="flex gap-1 items-center">
                    <img
                      alt="arrow"
                      src={
                        item?.isDancerStarts
                          ? "/images/PersonGreen.png"
                          : "/images/Person.png"
                      }
                      className={`w-5 h-5 ${
                        category?.is_scratched && "opacity-50"
                      }`}
                    />
                    <img
                      alt="arrow"
                      src={
                        item?.isCheckedProps
                          ? "/images/ClockGreen.png"
                          : "/images/Clock.png"
                      }
                      className={`w-5 h-5 ${
                        category?.is_scratched ? "opacity-50" : ""
                      }`}
                    />
                    <img
                      alt="runaround"
                      src={
                        item?.isRunAround
                          ? "/images/runaround-green.png"
                          : "/images/runaround.png"
                      }
                      className={`w-5 h-5 ${
                        category?.is_scratched ? "opacity-50" : ""
                      }`}
                    />
                    <img
                      alt="arrow"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item?.notes) {
                          toggleNotes(item);
                        }
                      }}
                      src={`${
                        notesWithoutChoreoAndSpecial?.length > 0
                          ? "/images/NotePencil-green.png"
                          : "/images/NotePencil.png"
                      }`}
                      className={`w-5 h-5 ${
                        category?.is_scratched ? "opacity-50" : ""
                      }`}
                    />
                  </div>
                </div>
              </div>
              {item?.id === showNames?.id &&
                showNames?.dancers_selected?.length > 1 && (
                  <ul className="w-[220px] bg-[#282929] rounded-md p-2 flex flex-col z-[3] absolute top-[50px] left-0 shadow-lg border border-white/20">
                    {showNames?.dancers_selected?.map((x, i) => {
                      return (
                        <li key={i} className="w-full border-b border-white/10">
                          {capitalizeFirstLetter(x?.first_name)}{" "}
                          {capitalizeFirstLetter(x?.last_name)}
                        </li>
                      );
                    })}
                  </ul>
                )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ScheduleCategory;
