/* eslint-disable react-hooks/exhaustive-deps */
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
} from "firebase/firestore";
import { db } from "services/firebase";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { clearSuccess } from "redux/judge/judgeSlice";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";
import DeductionCard from "./DeductionCard";
import TechniqueSlider from "./TechniqueSlider";
import CreativitySlider from "./CreativitySlider";
import StagingSlider from "./StagingSlider";
import ExecutionSlider from "./ExecutionSlider";
import PerformanceSlider from "./PerformanceSlider";
import JudgeSpecialCard from "./JudgeSpecialCard";
import ChoreoAwardCard from "./ChoreoAwardCard";
import StudioNoteCard from "./StudioNoteCard";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const SliderContainer = ({
  sliderValues,
  handleSliderChange,
  calculateTotal,
  togglePresent,
  presentScoreState,
  organizerData,
  handleSubmitScore,
  data,
  compId,
  currentEntry,
  currentEvent,
  toggleUnpresent,
  setDeficit,
  totalScore,
  deductionKeysArray,
  setDeductionKeysArray,
  toggleDeduction,
  toggleJudgeSpecial,
  toggleChoreo,
  deduction,
  judgeSpecialNote,
  choreoAwardNote,
  closeAllMod,
  toggleMessageStudio,
  messageStudio,
  setUnBlocked,
  unblocked,
  currentIndex,
  setSliderValues,
  setUpdateDefault,
  updateDefault,
  entriesArray,
  entryCount,
  allCategories,
  currentJudge,
  notSpecialProp,
  sortedCategories,
}) => {
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  // console.log(user);

  // console.log("currentEntry", currentEntry);

  function getQualification(str) {
    if (str && currentJudge) {
      const obj = currentJudge?.qualifications;
      const key = str?.toLowerCase()?.replace(/ /g, "_")?.replace(/&/g, "and");
      if (key in obj) {
        return obj[key];
      } else if (!notSpecialProp) {
        return true;
      } else {
        return null;
      }
    }
  }

  const qualified = getQualification(currentEntry?.genre);

  const { submitScorePending, submitScoreSuccess, presentScorePending } =
    useSelector((state) => state.judge);

  const awards = organizerData?.awards;

  function findMatchingAward(awards, totalScore) {
    for (let i = 0; i < awards?.length; i++) {
      const award = awards[i];
      if (totalScore >= award.minScore && totalScore <= award.maxScore) {
        return award;
      }
    }
    return { awardName: "no award" };
  }
  const matchingAward = findMatchingAward(awards, Number(calculateTotal()));

  const dispatch = useDispatch();
  useEffect(() => {
    if (submitScoreSuccess?.success) {
      const timeoutId = setTimeout(() => {
        dispatch(clearSuccess());
        block();
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitScoreSuccess]);

  const [allScores, setAllScores] = useState([]);

  useEffect(() => {
    if (data) {
      // Function to get scores from Firestore
      const getAllScores = async () => {
        const dancersCollectionRef = collection(
          db,
          "competitions",
          compId,
          "scores"
        );

        try {
          const querySnapshot = await getDocs(dancersCollectionRef);
          const documents = querySnapshot.docs.map((doc) => doc.data());
          setAllScores(documents);
        } catch (error) {
          console.log("Error fetching subcollection documents:", error);
        }
      };

      getAllScores();

      // Real-time listener for changes in scores
      const unsubscribe = onSnapshot(
        collection(db, "competitions", compId, "scores"),
        (querySnapshot) => {
          const updatedScores = querySnapshot.docs.map((doc) => doc.data());
          setAllScores(updatedScores);
        },
        (error) => {
          console.log("Error fetching subcollection documents:", error);
        }
      );

      return () => unsubscribe();
    }
  }, [data, compId, submitScoreSuccess]);

  const currentEntryScoreData = allScores?.filter(
    (item) =>
      item?.doc_id === currentEntry?.doc_id &&
      item?.event_name === currentEvent?.event_name &&
      item?.judge_id === user?.uid
  );

  const [typed, setTyped] = useState([]);

  function unblock() {
    setUnBlocked(true);
  }
  function block() {
    setUnBlocked(false);
    setTyped([]);
    setUpdateDefault(true);
  }

  const scoreReady =
    sliderValues?.technique > 0 &&
    sliderValues?.creativity > 0 &&
    sliderValues?.performance > 0 &&
    sliderValues?.staging > 0 &&
    sliderValues?.execution > 0;

  function handleSaveChanges() {
    if (scoreReady) {
      handleSubmitScore(matchingAward?.awardName);
      setTyped([]);
      setUpdateDefault(true);
    }
  }

  const [stepValue, setStepValue] = useState(0.01);
  // eslint-disable-next-line no-unused-vars
  const [defaultValue, setDefaultValue] = useState(0);

  //=================================================================to get default and increment values in realTime
  useEffect(() => {
    setUpdateDefault(true);
    setTyped([]);
  }, [currentIndex]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(doc(db, "organizers", compId)),
      (snapshot) => {
        const data = snapshot.data();
        setStepValue(data?.judge_settings?.increment_value);
        setDefaultValue(data?.judge_settings?.default_value);

        const defaultVal = data?.judge_settings?.default_value;
        if (updateDefault) {
          setSliderValues({
            technique: qualified ? defaultVal : 0,
            creativity: defaultVal,
            staging: defaultVal,
            execution: defaultVal,
            performance: defaultVal,
          });
        }
      }
    );

    return () => {
      unsubscribe();
    };
  }, [dispatch, user?.uid, currentEntry]);

  // console.log("currentEntry", currentEntry);
  const totalChecked = isNaN(totalScore) ? 0 : totalScore;

  return (
    <>
      <div className="w-full flex lg:flex-row flex-col lg:px-20 flex-wrap">
        {!currentEntry?.is_scratched && (
          <div className="min-w-fit h-fit flex flex-row lg:flex-col gap-5 pt-10 lg:pt-20 pb-7 relative mx-auto lg:mx-0">
            <img
              alt=""
              src="/images/deduction.png"
              onClick={(e) => {
                e.stopPropagation();
                toggleDeduction();
              }}
              className="w-10 h-10 cursor-pointer"
            />
            <img
              alt=""
              src="/images/award.png"
              onClick={(e) => {
                e.stopPropagation();
                toggleJudgeSpecial();
              }}
              className="w-10 h-10 cursor-pointer"
            />

            <img
              alt=""
              src="/images/zap.png"
              onClick={(e) => {
                e.stopPropagation();
                toggleChoreo();
              }}
              className="w-10 h-10 cursor-pointer"
            />

            {/* the deduction pop up*/}
            {deduction && (
              <DeductionCard
                toggleDeduction={toggleDeduction}
                organizerData={organizerData}
                currentEntryScoreData={currentEntryScoreData}
                sliderValues={sliderValues}
                setDeficit={setDeficit}
                calculateTotal={calculateTotal}
                setDeductionKeysArray={setDeductionKeysArray}
                deductionKeysArray={deductionKeysArray}
                unblocked={unblocked}
                currentIndex={currentIndex}
              />
            )}

            {/* the judge special pop up*/}
            {judgeSpecialNote && (
              <JudgeSpecialCard
                toggleJudgeSpecial={toggleJudgeSpecial}
                compId={compId}
                currentEntry={currentEntry}
                entriesArray={entriesArray}
                entryCount={entryCount}
                currentEvent={currentEvent}
                allCategories={allCategories}
                setUpdateDefault={setUpdateDefault}
                sortedCategories={sortedCategories}
              />
            )}

            {/* the choreography award note pop up*/}
            {choreoAwardNote && (
              <ChoreoAwardCard
                toggleChoreo={toggleChoreo}
                compId={compId}
                currentEntry={currentEntry}
                entriesArray={entriesArray}
                entryCount={entryCount}
                currentEvent={currentEvent}
                allCategories={allCategories}
                setUpdateDefault={setUpdateDefault}
              />
            )}
          </div>
        )}

        {currentEntry?.is_scratched && (
          <div className="w-full md:min-w-[600px] lg:min-w-[750px] h-fit bg-[#282929] p-3 md:p-5 rounded-xl mt-5 mx-auto flex flex-col gap-4 justify-center items-center text-white">
            <p className="text-[1.25rem] text-center">
              Entry "{capitalizeFirstLetter(currentEntry?.entry_name)}" is
              scratched!.
            </p>
            <div className="flex justify-center gap-3 items-center">
              Scratched entries cannot be judged.
            </div>
          </div>
        )}

        {!currentEntry?.scored && (
          <div className="w-full lg:w-fit md:min-w-[600px] lg:min-w-[750px] h-fit bg-[#282929] p-3 md:p-5 rounded-xl mt-5 mx-auto flex flex-col gap-4 justify-center items-center text-white">
            <p className="text-[1.25rem] text-center">
              Entry "{capitalizeFirstLetter(currentEntry?.entry_name)}" is not a
              "scored" entry.
            </p>
            <div className="flex justify-center gap-3 items-center">
              It cannot be judged.
            </div>
          </div>
        )}

        {!currentEntry?.is_scratched && currentEntry?.scored && (
          <div className="w-full lg:w-fit mx-auto">
            {currentEntryScoreData?.length > 0 && (
              <div className="w-full h-fit bg-[#282929] p-3 md:p-5 rounded-xl mt-5 mx-auto flex flex-col gap-4 justify-center items-center text-white">
                <p className="text-[1.25rem] text-center">
                  Entry "{capitalizeFirstLetter(currentEntry?.entry_name)}" have
                  been scored by you.
                </p>
                <div className="flex justify-center gap-3 items-center">
                  <p className="uppercase font-bold text-[1.75rem]">
                    {currentEntryScoreData[0]?.award_name}
                  </p>
                  <p className="text-[1.75rem] font-bold bg-white/20 px-6 py-1 rounded-lg">
                    {currentEntryScoreData[0]?.total}
                  </p>
                </div>
                {!unblocked && (
                  <button
                    onClick={() => {
                      unblock();
                      setUpdateDefault(false);
                    }}
                    className="px-5 py-2 mt-4 rounded-lg text-[.75rem] md:text-[1rem] bg-blue-600 hover:bg-blue-500 text-white flex justify-between gap-2 items-center whitespace-nowrap"
                  >
                    Make Changes
                  </button>
                )}
              </div>
            )}
            {
              <>
                <div className="w-full md:min-w-[600px] lg:min-w-[750px] min-h-[500px] bg-[#282929] p-3 md:p-5 rounded-xl mt-5 mx-auto text-white relative">
                  {currentEntryScoreData?.length > 0 && !unblocked && (
                    <div className="w-full h-full absolute top-0 left-0 z-[1] bg-[#282929]/50"></div>
                  )}
                  {/* technique slider */}
                  <TechniqueSlider
                    sliderValues={sliderValues}
                    handleSliderChange={handleSliderChange}
                    unblocked={unblocked}
                    currentEntryScoreData={currentEntryScoreData}
                    stepValue={stepValue}
                    typed={typed}
                    setTyped={setTyped}
                    qualified={qualified}
                    notSpecialProp={notSpecialProp}
                  />

                  {/* creativity slider */}
                  <CreativitySlider
                    sliderValues={sliderValues}
                    handleSliderChange={handleSliderChange}
                    unblocked={unblocked}
                    currentEntryScoreData={currentEntryScoreData}
                    stepValue={stepValue}
                    typed={typed}
                    setTyped={setTyped}
                  />

                  {/* staging slider */}
                  <StagingSlider
                    sliderValues={sliderValues}
                    handleSliderChange={handleSliderChange}
                    unblocked={unblocked}
                    currentEntryScoreData={currentEntryScoreData}
                    stepValue={stepValue}
                    typed={typed}
                    setTyped={setTyped}
                  />

                  {/* execution slider */}
                  <ExecutionSlider
                    sliderValues={sliderValues}
                    handleSliderChange={handleSliderChange}
                    unblocked={unblocked}
                    currentEntryScoreData={currentEntryScoreData}
                    stepValue={stepValue}
                    typed={typed}
                    setTyped={setTyped}
                  />

                  {/* performance slider */}
                  <PerformanceSlider
                    sliderValues={sliderValues}
                    handleSliderChange={handleSliderChange}
                    unblocked={unblocked}
                    currentEntryScoreData={currentEntryScoreData}
                    stepValue={stepValue}
                    typed={typed}
                    setTyped={setTyped}
                  />
                  {unblocked && (
                    <div className="flex items-center gap-3">
                      <button
                        onClick={block}
                        className="px-5 py-2 mt-4 rounded-lg text-[.75rem] md:text-[1rem] bg-white/80 hover:bg-white/50 text-black flex justify-between gap-2 items-center whitespace-nowrap"
                      >
                        Cancel
                      </button>
                      <button
                        disabled={submitScorePending || !scoreReady}
                        onClick={handleSaveChanges}
                        className={`px-5 py-2 mt-4 rounded-lg text-[.75rem] md:text-[1rem] bg-blue-600 hover:bg-blue-500 text-white flex justify-between gap-2 items-center whitespace-nowrap ${
                          (submitScorePending || !scoreReady) &&
                          "cursor-not-allowed"
                        }`}
                      >
                        <span className="w-full">
                          {submitScorePending ? "Saving..." : "Save Changes"}
                        </span>
                        {submitScorePending && <ButtonLoadingSpinner />}
                      </button>
                    </div>
                  )}
                </div>
              </>
            }
            <div className="w-full flex md:flex-row flex-col gap-8 justify-end items-center text-[1.75rem] md:text-[2rem] font-bold mt-7">
              {currentEntryScoreData?.length === 0 && (
                <>
                  <div className="mr-auto flex gap-4 h-fit">
                    {sliderValues.creativity !== 0 &&
                      sliderValues.staging !== 0 &&
                      sliderValues.execution !== 0 &&
                      sliderValues.performance !== 0 && (
                        <button
                          onClick={() => {
                            presentScoreState
                              ? toggleUnpresent(currentEntry)
                              : togglePresent(currentEntry);
                            closeAllMod();
                            setUpdateDefault(false);
                          }}
                          disabled={presentScorePending}
                          className={`px-5 py-2 font-normal rounded-lg text-[.75rem] md:text-[1rem] flex justify-between gap-2 items-center whitespace-nowrap ${
                            presentScoreState
                              ? "bg-white/80 hover:bg-white/50 text-black"
                              : "bg-blue-600 hover:bg-blue-500 text-white mr-auto"
                          }`}
                        >
                          {presentScoreState || presentScorePending
                            ? "Undo Present"
                            : "Present Score"}
                          {presentScorePending && <ButtonLoadingSpinner />}
                        </button>
                      )}
                    {presentScoreState && !presentScorePending && (
                      <button
                        onClick={() => {
                          handleSubmitScore(matchingAward?.awardName);
                          closeAllMod();
                        }}
                        disabled={submitScorePending}
                        className="px-5 py-2 font-normal rounded-lg text-[.75rem] md:text-[1rem] mr-auto bg-blue-600 hover:bg-blue-500 text-white flex justify-between gap-2 items-center whitespace-nowrap"
                      >
                        <span className="w-full">
                          {submitScorePending
                            ? "Submitting..."
                            : "Submit Score"}
                        </span>
                        {submitScorePending && <ButtonLoadingSpinner />}
                      </button>
                    )}
                  </div>
                </>
              )}
              <div className="flex gap-7 justify-end w-full">
                {presentScoreState &&
                  currentEntryScoreData?.length === 0 &&
                  !presentScorePending && (
                    <p className="uppercase">{matchingAward?.awardName}</p>
                  )}
                {currentEntryScoreData?.length === 0 && !unblocked && (
                  <p>{isNaN(totalScore) ? 0 : totalScore}</p>
                )}

                {unblocked && (
                  <p>
                    {typed?.length === 0
                      ? currentEntryScoreData[0]?.total
                      : totalChecked}
                  </p>
                )}
                <div className="lg:mr-[-50px] relative font-normal text-[1rem]">
                  <img
                    alt=""
                    src="/images/message.png"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleMessageStudio();
                    }}
                    className="w-10 h-10 cursor-pointer hover:bg-blue-500/20"
                  />

                  {/* the message studio box */}
                  {messageStudio && (
                    <StudioNoteCard
                      toggleMessageStudio={toggleMessageStudio}
                      compId={compId}
                      currentEntry={currentEntry}
                      setUpdateDefault={setUpdateDefault}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {submitScoreSuccess?.success && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-5 md:py-8 md:px-10 bg-[#282929] rounded-lg flex gap-5 justify-start items-center scale shadow-lg relative">
            <div
              onClick={() => {
                dispatch(clearSuccess());
              }}
              className="w-5 h-5 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-3 right-3"
            >
              <img
                alt="user"
                src="/images/icons8-close-50.png"
                className="w-3 h-3"
              />
            </div>
            <img
              alt="success"
              src="/images/icons8-checkmark-64.png"
              className="w-10 h-10"
            />
            <p className="text-[1rem] text-white">
              Score submitted successfully.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default SliderContainer;
