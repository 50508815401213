/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { clearSuccess, editAccount } from "redux/danceStudio/danceStudioSlice";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";
// import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import Spinner from "utils/Spinner";

const SettingsStudioDetails = ({ status, studioData }) => {
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { editAccountSuccess, editAccountPending } = useSelector(
    (state) => state.danceStudio
  );
  const [detailsData, setDetailsData] = useState({
    addressLine1: studioData?.addressLine1,
    addressLine2: studioData?.addressLine2,
    city: studioData?.city,
    state: studioData?.state,
    postalCode: studioData?.postalCode,
    phone: studioData?.phone,
    website: studioData?.website,
  });
  // console.log("detailsData", detailsData);

  useEffect(() => {
    if (studioData) {
      setDetailsData({
        addressLine1: studioData?.addressLine1,
        addressLine2: studioData?.addressLine2,
        city: studioData?.city,
        state: studioData?.state,
        postalCode: studioData?.postalCode,
        phone: studioData?.phone,
        website: studioData?.website,
      });
    }
  }, [studioData]);

  function handleChange(e) {
    setInvalidWebsite(false);
    const { value, id } = e.target;
    setDetailsData((prev) => {
      return {
        ...prev,
        [id]: value,
      };
    });
  }

  const [edit, setEdit] = useState(false);
  function toggleEdit() {
    setEdit((prev) => !prev);
    setDetailsData({
      addressLine1: studioData?.addressLine1,
      addressLine2: studioData?.addressLine2,
      city: studioData?.city,
      state: studioData?.state,
      postalCode: studioData?.postalCode,
      phone: studioData?.phone,
      website: studioData?.website,
    });
  }

  const handleKeyDownWebsite = (e) => {
    const allowedCharacters = /^[a-zA-Z0-9.:/]*$/; // Include numbers (0-9)
    const key = e.key;

    if (!allowedCharacters.test(key) && key !== "Backspace") {
      e.preventDefault();
    }
  };

  const isValidWebsite = (inputValue) => {
    const websitePattern =
      /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/\S*)?$/;
    return websitePattern.test(inputValue);
  };

  const [invalidWebsite, setInvalidWebsite] = useState(false);
  const dispatch = useDispatch();
  function handleSubmit(e) {
    e.preventDefault();
    if (
      detailsData?.addressLine1 &&
      detailsData?.city &&
      detailsData?.state &&
      detailsData?.postalCode &&
      detailsData?.phone &&
      detailsData?.website &&
      isValidWebsite(detailsData?.website)
    ) {
      dispatch(editAccount({ uid: user?.uid, detailsData }));
    } else if (!isValidWebsite(detailsData?.website)) {
      setInvalidWebsite(true);
    }
  }

  useEffect(() => {
    if (editAccountSuccess?.success) {
      setEdit(false);
      setTimeout(() => {
        dispatch(clearSuccess());
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 2000);
    }
  }, [editAccountSuccess]);
  return (
    <div>
      <h1 className="w-full border-b pb-2 font-bold border-white/30 mb-3">
        Studio Details
      </h1>
      <div className="w-full bg-white/10 p-3 rounded-lg mb-3">
        Street address:{" "}
        <span className="font-medium pl-2">
          {" "}
          {status === "loading" ? (
            <Spinner />
          ) : edit ? (
            <input
              type="text"
              id="addressLine1"
              value={detailsData?.addressLine1}
              onChange={handleChange}
              className="w-full px-2 rounded-md outline-none border border-[#94a4fd] bg-inherit"
            />
          ) : (
            studioData?.addressLine1
          )}
        </span>
      </div>

      <div className="w-full bg-white/10 p-3 rounded-lg mb-3">
        Street address line 2:{" "}
        <span className="font-medium pl-2">
          {" "}
          {status === "loading" ? (
            <Spinner />
          ) : edit ? (
            <input
              type="text"
              id="addressLine2"
              value={detailsData?.addressLine2}
              onChange={handleChange}
              className="w-full px-2 rounded-md outline-none border border-[#94a4fd] bg-inherit"
            />
          ) : (
            studioData?.addressLine2
          )}
        </span>
      </div>

      <div className="w-full bg-white/10 p-3 rounded-lg mb-3">
        City:{" "}
        <span className="font-medium pl-2">
          {" "}
          {status === "loading" ? (
            <Spinner />
          ) : edit ? (
            <input
              type="text"
              id="city"
              value={detailsData?.city}
              onChange={handleChange}
              className="w-full px-2 rounded-md outline-none border border-[#94a4fd] bg-inherit"
            />
          ) : (
            studioData?.city
          )}
        </span>
      </div>

      <div className="w-full bg-white/10 p-3 rounded-lg mb-3">
        State/Province:{" "}
        <span className="font-medium pl-2">
          {" "}
          {status === "loading" ? (
            <Spinner />
          ) : edit ? (
            <input
              type="text"
              id="state"
              value={detailsData?.state}
              onChange={handleChange}
              className="w-full px-2 rounded-md outline-none border border-[#94a4fd] bg-inherit"
            />
          ) : (
            studioData?.state
          )}
        </span>
      </div>

      <div className="w-full bg-white/10 p-3 rounded-lg mb-3">
        Postal code:{" "}
        <span className="font-medium pl-2">
          {" "}
          {status === "loading" ? (
            <Spinner />
          ) : edit ? (
            <input
              type="text"
              id="postalCode"
              value={detailsData?.postalCode}
              onChange={handleChange}
              className="w-full px-2 rounded-md outline-none border border-[#94a4fd] bg-inherit"
            />
          ) : (
            studioData?.postalCode
          )}
        </span>
      </div>
      <div className="w-full bg-white/10 p-3 rounded-lg mb-3">
        Phone Number:{" "}
        <span className="font-medium pl-2">
          {" "}
          {status === "loading" ? (
            <Spinner />
          ) : edit ? (
            <input
              type="text"
              id="phone"
              value={detailsData?.phone}
              onChange={handleChange}
              className="w-full px-2 rounded-md outline-none border border-[#94a4fd] bg-inherit"
            />
          ) : (
            studioData?.phone
          )}
        </span>
      </div>

      <div className="w-full bg-white/10 p-3 rounded-lg mb-3">
        Website:{" "}
        <span className="font-medium pl-2 underline text-[#94a4fd]">
          {" "}
          {status === "loading" ? (
            <Spinner />
          ) : edit ? (
            <input
              type="text"
              id="website"
              value={detailsData?.website}
              onChange={handleChange}
              onKeyDown={(e) => handleKeyDownWebsite(e)}
              className="w-full px-2 rounded-md outline-none border border-[#94a4fd] bg-inherit"
            />
          ) : (
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://${studioData?.website.toLowerCase()}`}
            >
              {studioData?.website.toLowerCase()}
            </a>
          )}
        </span>
      </div>

      {editAccountSuccess?.success && (
        <p className="text-[#94a4fd] ">Successfully editted!</p>
      )}
      {invalidWebsite && (
        <p className="text-red-500 text-[.85rem]">Invalid website</p>
      )}
      <div className="flex gap-2 justify-end">
        <button
          onClick={toggleEdit}
          disabled={editAccountPending}
          className="px-4 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[.85rem] hover:opacity-75"
        >
          {edit ? "Cancel Edit" : "Edit Details"}
        </button>

        {edit && (
          <button
            onClick={handleSubmit}
            disabled={editAccountPending}
            className="px-4 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[.85rem] hover:opacity-75"
          >
            {editAccountPending ? (
              <p className="flex gap-2 items-center">
                Submitting.. <ButtonLoadingSpinner />
              </p>
            ) : (
              "Submit"
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default SettingsStudioDetails;
