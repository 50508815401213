import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { Footer, ScrollToTop } from "..";
import AddEntryForm from "./AddEntryForm";

const AddEntryContainer = ({
  closeAll,
  currentEvent,
  eventCategories,
  extractedEntries,
  competitionLevel,
  eligibilityDivisions,
  scheduling,
  entryTypes,
  danceGenres,
  openDancers,
  setOpenDancers,
  openStudios,
  setOpenStudios,
  specialEntryTypes,
  sortedCategories,
}) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        closeAll();
      }}
      className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[330px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col"
    >
      <div className="mb-auto">
        {/* Heading */}
        <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
          <h2>
            {capitalizeFirstLetter(currentEvent?.event_name)}{" "}
            {currentEvent?.year.split(" to ")[0]}
          </h2>
          <img alt="arrow" src="/images/ArrowDown.png" className="w-4 h-4" />
        </div>

        {/* Add entry form */}
        <div className="">
          <AddEntryForm
            eventCategories={eventCategories}
            extractedEntries={extractedEntries}
            competitionLevel={competitionLevel}
            eligibilityDivisions={eligibilityDivisions}
            scheduling={scheduling}
            entryTypes={entryTypes}
            danceGenres={danceGenres}
            openDancers={openDancers}
            setOpenDancers={setOpenDancers}
            openStudios={openStudios}
            setOpenStudios={setOpenStudios}
            closeAll={closeAll}
            specialEntryTypes={specialEntryTypes}
            sortedCategories={sortedCategories}
          />
        </div>
      </div>
      <ScrollToTop />
      <Footer />
    </div>
  );
};

export default AddEntryContainer;
