export default function findSessionEntries(arr, allEntries) {
  const results = [];

  for (const itemArray of arr) {
    const itemArrayEntries = [];

    for (const item of itemArray) {
      const matchingEntry = allEntries?.filter(
        (entry) => entry?.category_name === item?.category_name
      );
      if (matchingEntry?.length > 0) {
        itemArrayEntries?.push(...matchingEntry);
      }
    }

    const entriesSorted = itemArrayEntries?.sort(
      (a, b) => a?.sort_id - b?.sort_id
    );

    results.push(entriesSorted);
  }

  return results;
}
