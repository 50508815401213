import React from 'react';

const SplitCategory = ({ splitCategories, setSplitCategories }) => {
  return (
    <div className="w-full customTags">
      <form className="w-full mt-8 form-control">
        <label className="label w-[75%]">
          <span className="mb-2 text-base font-medium label-text text-[#696F79]">
            What is the maximum number of entries allowed in a single category
            before it gets split?
          </span>
        </label>
        <div className="flex flex-row flex-wrap items-baseline px-1">
          <div className="flex flex-col lg:w-[75%] md:w-[75%] w-full sm:w-[64%] mr-4">
            <input
              value={splitCategories}
              onChange={(e) => setSplitCategories(e.target.value)}
              type="number"
              name="splitCategories"
              placeholder="E.g. 10"
              className={`input input-bordered border-[#959FB1] h-[62px] w-full lg:text-sm md:text-sm text-xs font-medium text-[#8692A6] dark:text-white mr-4 placeholder-[#8692A6]`}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default SplitCategory;
