/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  addNote,
  clearSuccess,
  deleteNote,
  editNote,
} from "redux/judge/judgeSlice";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const StudioNoteCard = ({
  toggleMessageStudio,
  compId,
  currentEntry,
  setUpdateDefault,
}) => {
  const {
    addNotePending,
    addNoteSuccess,
    deleteNotePending,
    deleteNoteSuccess,
    editNoteSuccess,
  } = useSelector((state) => state.judge);

  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  function generateUID() {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uid = "";
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      uid += chars.charAt(randomIndex);
    }
    return uid;
  }

  const [data, setData] = useState({
    compId: compId,
    id: currentEntry?.id,
    doc_id: currentEntry?.doc_id,

    note: {
      judge_id: user?.uid,
      note: "",
      type: "Studio Director Note",
      note_uid: generateUID(),
    },
  });

  const dispatch = useDispatch();

  function handleSend() {
    if (data.note.note) {
      dispatch(addNote(data));
    }
  }

  const [deletingItem, setDeletingItem] = useState("");
  function handleDelete(delData) {
    setDeletingItem(delData);
    if (delData) {
      dispatch(
        deleteNote({ ...data, note: delData, doc_id: currentEntry?.doc_id })
      );
    }
  }

  useEffect(() => {
    if (
      addNoteSuccess?.success ||
      deleteNoteSuccess?.success ||
      editNoteSuccess?.success
    ) {
      setTimeout(() => {
        dispatch(clearSuccess());
        setDeletingItem("");
        setData({
          ...data,
          note: { ...data?.note, note: "", note_uid: generateUID() },
        });
      }, 3000);
    }
  }, [addNoteSuccess, deleteNoteSuccess, editNoteSuccess]);

  // console.log("currentEntry", currentEntry);

  const specialNotes = currentEntry?.notes?.filter((item) => {
    return (
      item?.judge_id === user?.uid && item?.type === "Studio Director Note"
    );
  });
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editingNote, setEditingNote] = useState("");
  function handleEdit(note, updatedNote) {
    if (note && updatedNote) {
      const updatedNotes = specialNotes?.map((item) => {
        if (item?.note_uid === note?.note_uid) {
          return { ...item, note: updatedNote };
        }
        return item;
      });

      dispatch(editNote({ ...data, note: updatedNotes }));
      setEditingIndex(-1);
      setEditingNote("");
    }
  }
  const [showDelete, setShowDelete] = useState("");
  function handleHover(index) {
    setShowDelete(index);
  }
  function handleMouseOut() {
    setShowDelete("");
  }

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="w-[250px] md:w-[350px] h-fit p-5 md:py-8 md:px-10 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-center scale shadow-lg absolute top-[-250px] right-0 z-[3]"
    >
      <div
        onClick={() => {
          toggleMessageStudio();
        }}
        className="w-5 h-5 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-3 right-3"
      >
        <img alt="user" src="/images/icons8-close-50.png" className="w-3 h-3" />
      </div>
      <h3 className="font-normal text-[.85rem]">
        Leave a message for studio director
      </h3>
      <div className="w-full h-fit">
        {specialNotes?.map((item, index) => {
          return (
            <div
              key={item?.note_uid}
              onMouseOver={() => handleHover(index)}
              onMouseOut={handleMouseOut}
              className="relative overflow-hidden mb-3"
            >
              <div
                key={item?.note_uid}
                className={`w-full p-3 text-[.9em] rounded-lg ${
                  (deleteNotePending || deleteNoteSuccess?.success) &&
                  deletingItem === item
                    ? "bg-red-400/20"
                    : "bg-white/10"
                }`}
              >
                {deleteNotePending && deletingItem === item ? (
                  <p>Deleting...</p>
                ) : (
                  <div className={`${showDelete ? "w-[85%]" : "w-full"}`}>
                    {editingIndex === index ? (
                      <div>
                        <p className="text-xs text-white/80">
                          characters: {60 - editingNote?.length}/60
                        </p>
                        <textarea
                          value={editingNote}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            setEditingNote(inputValue);
                          }}
                          maxLength={60}
                          className={`border rounded p-1 bg-inherit outline-none ${
                            editingNote?.length === 60 && "border-red-500"
                          }`}
                        />
                        {editingNote?.length === 60 && (
                          <p className="text-xs text-red-500 dark:text-red-400 mb-1">
                            Max character length reached
                          </p>
                        )}

                        <div className="flex gap-2">
                          <button
                            className="cursor-pointer hover:scale-[1.2] px-2 bg-blue-500 rounded-md text-[.75rem]"
                            onClick={() => {
                              handleEdit(item, editingNote);
                              setUpdateDefault(false);
                            }}
                          >
                            Save
                          </button>
                          <button
                            className="cursor-pointer hover:scale-[1.2] px-2 bg-gray-500 rounded-md text-[.75rem]"
                            onClick={() => {
                              setEditingIndex(-1);
                              setEditingNote("");
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    ) : (
                      capitalizeFirstLetter(item?.note)
                    )}
                  </div>
                )}
                {deleteNoteSuccess?.success && deletingItem === item && (
                  <p>Deleted!</p>
                )}
              </div>
              {showDelete === index && (
                <div className="bg-black p-2 rounded-lg absolute top-2 right-2 flex items-center gap-2">
                  {editingIndex !== index && (
                    <img
                      alt="arrow"
                      src="/images/icons8-edit.png"
                      className="w-5 h-5 cursor-pointer hover:scale-[1.2]"
                      onClick={() => {
                        setEditingIndex(index);
                        setEditingNote(item.note);
                      }}
                    />
                  )}
                  <div className="flex gap-2">
                    <img
                      alt="arrow"
                      src="/images/trash.png"
                      className="w-5 h-5 cursor-pointer hover:scale-[1.2]"
                      onClick={() => handleDelete(item)}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {addNoteSuccess?.success ? (
          <div className="w-full p-2 border border-blue-500/70 text-[.9em] text-center">
            Message sent Successfully!
          </div>
        ) : (
          <form className="w-full h-full text-right">
            {specialNotes?.length > 0 && (
              <h4 className="w-full text-center text-[.85em] mb-2">
                Add another note
              </h4>
            )}
            <p className="text-xs text-white/80">
              characters: {100 - data?.note.note?.length}/100
            </p>
            <textarea
              id="notes"
              value={data?.note.note}
              onChange={(e) => {
                setData({
                  ...data,
                  note: { ...data.note, note: e.target.value },
                });
              }}
              maxLength={100}
              className={`w-full h-[80px] border outline-none text-white bg-inherit ${
                data?.note.note?.length === 100
                  ? "border-red-400"
                  : "border-white/30"
              }`}
            />
            {data?.note.note?.length === 100 && (
              <p className="text-xs text-red-500 dark:text-red-400">
                Max character length reached
              </p>
            )}
            <button
              onClick={(e) => {
                e.preventDefault();
                handleSend();
                setUpdateDefault(false);
              }}
              disabled={addNotePending || !data.note.note}
              className="px-4 py-1 rounded-md bg-blue-500 hover:bg-blue-400 text-[.75rem] flex gap-2 items-center"
            >
              {addNotePending ? "Sending..." : "Send"}
              {addNotePending && <ButtonLoadingSpinner />}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default StudioNoteCard;
