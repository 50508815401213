import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ButtonLoadingSpinner from 'utils/ButtonLoadingSpinner';
import { clearSuccess } from 'redux/eventManagement/eventManagement';
import { db } from 'services/firebase';
import {
  doc,
  updateDoc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
} from 'firebase/firestore';

const SpecialEntryEditForm = ({
  onClose,
  organizerData,
  user,
  specialEntry,
}) => {
  const [loading, setLoading] = useState(false);
  const [formErr, setFormErr] = useState('');

  // Initialize form fields with the data of the selected special entry
  const [formData, setFormData] = useState({
    specialEntryTypeName: specialEntry?.specialEntryTypeName,
    specialMinDancers: specialEntry?.specialMinDancers,
    specialMaxDancers: specialEntry?.specialMaxDancers,
    specialMaxTime: specialEntry?.specialMaxTime,
  });

  // Initialize specialTag state with the data of the selected special entry
  const [specialTag, setSpecialTag] = useState(specialEntry?.tags);

  const handleSpecialTagChange = (event) => {
    const { id, checked } = event.target;
    setSpecialTag((prevTag) => ({
      ...prevTag,
      [id]: checked,
    }));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    setFormErr('');
    dispatch(clearSuccess());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update form data when inputs change
  const handleChange = (e) => {
    setFormErr('');
    dispatch(clearSuccess());
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      !formData.specialEntryTypeName?.trim() &&
      !formData.specialMaxTime &&
      !formData.specialMinDancers &&
      !formData.specialMaxDancers
    ) {
      setFormErr('Please fill all required fields');
      return;
    } else if (organizerData && formData) {
      const organizerDocRef = doc(db, 'organizers', user?.uid);
      const competitionDocRef = doc(db, 'competitions', user?.uid);
      // Get the current document data
      const organizerDocSnap = await getDoc(organizerDocRef);
      const currentData = organizerDocSnap.data();

      // Find the index of the special entry being edited
      const entryIndex = currentData.specialEntryTypes?.tags.findIndex(
        (entry) =>
          entry?.specialEntryTypeName === specialEntry?.specialEntryTypeName
      );

      // Get the category_name of the special entry before making changes
      const previousCategoryName = specialEntry?.specialEntryTypeName;

      // Update the special entry in the array
      const newOne = {
        specialEntryTypeName: formData?.specialEntryTypeName,
        specialMinDancers: formData?.specialMinDancers,
        specialMaxDancers: formData?.specialMaxDancers,
        specialMaxTime:
          parseMinutesToDuration(Number(formData?.specialMaxTime)) ||
          '00:00:00',
        tags: specialTag,
      };

      const tagsFiltered = currentData.specialEntryTypes.tags?.filter(
        (_, ind) => ind !== entryIndex
      );
      const newTags = [...tagsFiltered, newOne];

      // Update the document with the new data
      await updateDoc(organizerDocRef, {
        specialEntryTypes: { tags: newTags },
      });
      await updateDoc(competitionDocRef, {
        specialEntryTypes: { tags: newTags },
      });

      // If the special entry's category_name has changed, delete the old entries
      if (previousCategoryName !== formData?.specialEntryTypeName) {
        // Reference to the "entries" sub-collection
        const entriesRef = collection(db, 'competitions', user?.uid, 'entries');

        // Get all entries with the previous category_name
        const entriesQuery = query(
          entriesRef,
          where('category_name', '==', previousCategoryName)
        );

        // Get the documents that match the query
        const entryQuerySnapshot = await getDocs(entriesQuery);

        // Delete each entry that matches the query
        entryQuerySnapshot?.forEach((doc) => {
          deleteDoc(doc.ref);
        });
      }

      // Close the pop-up
      onClose();
    }

    // Set loading to false after the update is complete
    setLoading(false);
  };

  function parseTime(durationString) {
    // Split the duration string by colons
    const parts = durationString.split(':');

    if (parts.length === 3) {
      // Convert the hours, minutes, and seconds to integers
      const hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      const seconds = parseInt(parts[2], 10);

      // Calculate the total duration in minutes
      const totalMinutes = hours * 60 + minutes + seconds / 60;

      return totalMinutes;
    } else {
      // Handle invalid duration string
      return null;
    }
  }

  function parseMinutesToDuration(minutes) {
    // Ensure 'minutes' is a non-negative whole number
    if (!Number.isInteger(minutes) || minutes < 0) {
      return null; // Invalid input
    }

    // Calculate hours, minutes, and seconds
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const seconds = 0;

    // Format the duration string
    const formattedDuration = `${String(hours).padStart(2, '0')}:${String(
      remainingMinutes
    ).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    return formattedDuration;
  }

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 py-[100px] flex justify-center bg-[#1c1c1c]/90 z-[999]"
    >
      <div className="rounded-lg p-6 w-[40%] h-[410px] mx-auto md:w-1/2 max-w-[700px] min-w-[300px]">
        <div
          onClick={(e) => e.stopPropagation()}
          className="bg-[#282929] rounded-lg p-6 scale"
        >
          <form onSubmit={handleSubmit}>
            <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
              <label htmlFor="description">Special Entry</label>
              <input
                type="text"
                id="specialEntryTypeName"
                placeholder="Eg. Superb, etc."
                value={formData?.specialEntryTypeName}
                onChange={handleChange}
                className="w-full bg-transparent border-none outline-none text-white/80"
              />
            </div>
            <div className="flex items-center gap-2">
              <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
                <label htmlFor="description">Min Dancer</label>
                <input
                  type="number"
                  id="specialMinDancers"
                  placeholder="Eg. #1"
                  value={formData?.specialMinDancers}
                  onChange={handleChange}
                  className="w-full bg-transparent border-none outline-none text-white/80"
                />
              </div>
              <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
                <label htmlFor="description">Max Dancer</label>
                <input
                  type="number"
                  id="specialMaxDancers"
                  placeholder="Eg. #10"
                  value={formData?.specialMaxDancers}
                  onChange={handleChange}
                  className="w-full bg-transparent border-none outline-none text-white/80"
                />
              </div>
            </div>

            <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
              <label htmlFor="description">Special Time (In minutes)</label>
              <input
                type="number"
                id="specialMaxTime"
                placeholder="Eg. 10"
                // value={formData?.specialMaxTime}
                defaultValue={parseTime(formData?.specialMaxTime)}
                onChange={handleChange}
                className="w-full bg-transparent border-none outline-none text-white/80"
              />
            </div>
            {/* tags type */}
            <div className="w-full my-4 md:mt-[1rem] flex md:flex-row flex-wrap justify-start xs:flex-col">
              <div className="flex items-center xs:mx-4">
                <input
                  type="checkbox"
                  id="age"
                  className="mr-2 w-4 h-4"
                  checked={specialTag?.age}
                  onChange={handleSpecialTagChange}
                />
                <label
                  htmlFor="age"
                  className="text-white/80 text-base font-medium"
                >
                  Age
                </label>
              </div>
              <div className="flex items-center mx-4">
                <input
                  type="checkbox"
                  id="classification"
                  className="mr-2 w-4 h-4"
                  checked={specialTag?.classification}
                  onChange={handleSpecialTagChange}
                />
                <label
                  htmlFor="classification"
                  className="text-white/80 text-base font-medium"
                >
                  Classification
                </label>
              </div>
              <div className="flex items-center mx-4">
                <input
                  type="checkbox"
                  id="genre"
                  className="mr-2 w-4 h-4"
                  checked={specialTag?.genre}
                  onChange={handleSpecialTagChange}
                />
                <label
                  htmlFor="genre"
                  className="text-white/80 text-base font-medium"
                >
                  Genre
                </label>
              </div>
              <div className="flex items-center mx-4">
                <input
                  type="checkbox"
                  id="entry_type"
                  className="mr-2 w-4 h-4"
                  checked={specialTag?.entry_type}
                  onChange={handleSpecialTagChange}
                />
                <label
                  htmlFor="entry_type"
                  className="text-white/80 text-base font-medium"
                >
                  Entry Type
                </label>
              </div>
              <div className="flex items-center mx-4">
                <input
                  type="checkbox"
                  id="scored"
                  className="mr-2 w-4 h-4"
                  checked={specialTag?.scored}
                  onChange={handleSpecialTagChange}
                />
                <label
                  htmlFor="scored"
                  className="text-white/80 text-base font-medium"
                >
                  Scored
                </label>
              </div>
            </div>
            {/* tags type */}
            <div className="flex gap-3  mb-6 justify-end">
              <button
                type="button"
                onClick={onClose}
                className="px-8 py-1 bg-white rounded-full text-black/80 text-[0.85rem] hover:opacity-75"
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`px-8 flex items-center rounded-full text-black/80 text-[0.85rem] hover:opacity-75 bg-[#94a4fd] cursor-pointer`}
                disabled={loading}
              >
                <span className="px-10 py-2 w-full">
                  {loading ? 'Processing...' : 'Submit'}
                </span>
                {loading && <ButtonLoadingSpinner />}
              </button>
            </div>
            {formErr && <p className="text-red-500">{formErr}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default SpecialEntryEditForm;
