/* eslint-disable react-hooks/exhaustive-deps */
import { ScrollToTop } from "components/EventManagement";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
// import { useFirestoreCollectionData } from "reactfire";
import localStorage from "redux-persist/es/storage";
import {
  presentScore,
  setEntriesList,
  submitScore,
  unpresentScore,
} from "redux/judge/judgeSlice";
import { db } from "services/firebase";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import findIndexByProperty from "utils/findIndexByProperty";
import findLockedEntryLetter from "utils/findLockedEntryLetter";
import Header from "./components/Header";
import SliderContainer from "./components/SliderContainer";
import { removeDuplicateObjectsByProperty } from "utils/removeDuplicatesByProperty";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";

const JudgeDashboard = () => {
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const dispatch = useDispatch();

  const [sliderValues, setSliderValues] = useState({
    technique: 0,
    creativity: 0,
    staging: 0,
    execution: 0,
    performance: 0,
  });

  const handleSliderChange = (slider, value) => {
    setSliderValues((prevValues) => ({
      ...prevValues,
      [slider]: value,
    }));
  };

  function calculateTotal() {
    const values = Object.values(sliderValues);
    const total = values.reduce((sum, value) => sum + parseFloat(value), 0);
    const totalFormatted = total.toFixed(2); // Format total to two decimal places
    return totalFormatted;
  }

  const { data: allJudges } = useQueryAllDocs("judges");
  const currentJudge = allJudges?.filter((itm) => itm?.uid === user?.uid)[0];

  const { status, data } = useQueryAllDocs("competitions");

  const { competitionName, event_name } = useParams();
  const currentComp = data?.filter(
    (item) => item?.competitionName === competitionName
  )[0];

  const currentEvent = currentComp?.events?.filter(
    (item) => item?.event_name?.trim() === event_name
  )[0];

  const compId = currentComp
    ? currentComp?.compId
    : localStorage.getItem("compId");

  //============================================================================================to get all entries
  const [allEntries, setAllEntries] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [sortedCategories, setSortedCategories] = useState([]);
  const [eventCategories, seteventCategories] = useState([]);
  const [loading, setloading] = useState(false);

  const entriesArrayRaw = allEntries?.filter(
    (ent) => ent?.event_uid === currentEvent?.event_uid
  );
  let entriesArray = entriesArrayRaw?.sort((a, b) => a?.sort_id - b?.sort_id);

  // const { data: allCategories } = useQueryAllSubCollectionDocs(
  //   "competitions",
  //   compId,
  //   "categories"
  // );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setloading(true);
        // Fetch competition data using compId
        // const compDocRef = doc(db, "competitions", compId);
        // const compDocSnapshot = await getDoc(compDocRef);

        // if (compDocSnapshot.exists()) {
        //   const compData = compDocSnapshot.data();
        //   // const { entryTypes } = compData;
        //   // setEntryTypes(entryTypes);
        // } else {
        //   console.log("Competition document not found.");
        // }
        // Fetch and listen for real-time updates to categories
        const categoriesQuery = query(
          collection(db, "competitions", compId, "categories"),
          where("event_uid", "==", currentEvent?.event_uid) // Filter by event_uid
        );

        const unsubscribeCategories = onSnapshot(
          categoriesQuery,
          (snapshot) => {
            const categoriesData = snapshot.docs.map((doc) => doc.data());

            seteventCategories(categoriesData);

            const sortedCategories = categoriesData.sort(
              (a, b) => a?.sort_id - b?.sort_id
            );
            setSortedCategories(sortedCategories);
          }
        );

        // Fetch and listen for real-time updates to entries
        const entriesQuery = query(
          collection(db, "competitions", compId, "entries"),
          where("event_uid", "==", currentEvent?.event_uid) // Filter by event_uid
        );
        const unsubscribeEntries = onSnapshot(entriesQuery, (snapshot) => {
          const entriesData = snapshot.docs.map((doc) => doc.data());

          setAllEntries(entriesData);
        });

        const unsubscribeCat = onSnapshot(
          query(collection(db, "competitions", compId, "categories")),
          (snapshot) => {
            const data = snapshot.docs.map((doc) => doc.data());
            setAllCategories(data);
          }
        );

        return () => {
          unsubscribeCategories();
          unsubscribeEntries();
          unsubscribeCat();
        };
      } catch (error) {
        // console.error("Error fetching data:", error);
      } finally {
        setloading(false);
      }
    };

    fetchData();
  }, [compId, currentEvent]);

  const category_nameArr = allEntries?.map((x) => x?.category_name);

  const categoriesRaw = eventCategories
    ?.filter((y) => category_nameArr?.includes(y?.category_name))
    ?.concat(eventCategories?.filter((z) => z?.type !== "category"));

  let sortedCategoriesRaw = categoriesRaw?.sort(
    (a, b) => a?.sort_id - b?.sort_id
  );

  useEffect(() => {
    if (!loading) {
      let arr = removeDuplicateObjectsByProperty(
        sortedCategoriesRaw,
        "category_name"
      );
      setSortedCategories(arr);
    }
  }, [status, eventCategories, allEntries, loading]);

  // console.log("sortedCategories", sortedCategories);

  // const eventCategories = allCategories?.filter(
  //   (cate) =>
  //     cate?.event_uid === currentEvent?.event_uid && cate?.type === "category"
  // );
  // let sortedCategories = eventCategories?.sort(
  //   (a, b) => a?.sort_id - b?.sort_id
  // );

  //to await the compid before executng
  // useEffect(() => {
  //   const fetchCompId = async () => {
  //     try {
  //       const compIdValue = await compId;
  //       const unsubscribe = onSnapshot(
  //         query(collection(db, "competitions", compIdValue, "entries")),
  //         (snapshot) => {
  //           const data = snapshot.docs.map((doc) => doc.data());
  //           dispatch(setEntriesList(data));
  //           setAllEntries(data);
  //         }
  //       );

  //       const unsubscribeCat = onSnapshot(
  //         query(collection(db, "competitions", compIdValue, "categories")),
  //         (snapshot) => {
  //           const data = snapshot.docs.map((doc) => doc.data());
  //           setAllCategories(data);
  //           setSortedCategories(data);
  //         }
  //       );

  //       return () => {
  //         unsubscribe();
  //         unsubscribeCat();
  //       };
  //     } catch (error) {
  //       console.error("Error fetching compId:", error);
  //     }
  //   };

  //   // Call the fetchCompId function
  //   fetchCompId();
  // }, [compId, status]);

  //to arrange entries based on sortedCategories
  function arrangeEntriesByCategories() {
    const resultArray = [];
    const categorySet = new Set(
      sortedCategories?.map((category) => category?.category_name)
    );

    // First, add entries that match the first category in sortedCategories
    for (const entry of entriesArray) {
      if (entry.category_name === sortedCategories[0]?.category_name) {
        resultArray.push(entry);
      }
    }

    // Then, add entries that match the rest of the categories in sortedCategories
    for (let i = 1; i < sortedCategories?.length; i++) {
      const category = sortedCategories[i]?.category_name;
      const categoryEntries = entriesArray.filter(
        (entry) => entry.category_name === category
      );
      resultArray.push(...categoryEntries);
    }

    // Finally, add any remaining entries that may not have matched any category
    const remainingEntries = entriesArray?.filter(
      (entry) => !categorySet.has(entry.category_name)
    );
    resultArray.push(...remainingEntries);

    return resultArray;
  }

  //===============================================================================to calc dynamic entry count of all entries in routine
  const categories = sortedCategories?.filter((l) => l?.type === "category");

  const allEntriesWithoutLocked = arrangeEntriesByCategories(
    categories,
    allEntries
  )?.filter((k) => k?.locked_entry === false);

  const allEntriesWithLocked = arrangeEntriesByCategories(
    categories,
    allEntries
  );

  // console.log("removeDuplicates", removeDuplicates(allEntriesWithoutLocked));

  function entryCount(itm) {
    const allEntriesBeforeLocked = allEntriesWithLocked
      ?.slice(
        0,
        findIndexByProperty(allEntriesWithLocked, "doc_id", itm?.doc_id)
      )
      ?.filter((m) => m?.locked_entry === false);

    if (itm?.locked_entry) {
      return `${
        allEntriesBeforeLocked?.length === 0
          ? 1
          : allEntriesBeforeLocked?.length
      }${findLockedEntryLetter(allEntriesWithLocked, itm)}`;
    } else {
      return (
        findIndexByProperty(allEntriesWithoutLocked, "doc_id", itm?.doc_id) + 1
      );
    }
  }

  //===================================================================to handle current index

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentEntry, setCurrentEntry] = useState(0);

  useEffect(() => {
    setCurrentEntry(arrangeEntriesByCategories()?.[currentIndex]);
  }, [currentIndex, sortedCategories]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 37) {
        // Left arrow key
        setCurrentIndex((prevIndex) =>
          prevIndex === 0
            ? arrangeEntriesByCategories()?.length - 1
            : prevIndex - 1
        );
      } else if (event.keyCode === 39) {
        // Right arrow key
        setCurrentIndex((prevIndex) =>
          prevIndex === arrangeEntriesByCategories()?.length - 1
            ? 0
            : prevIndex + 1
        );
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [arrangeEntriesByCategories(), setSliderValues]);

  // console.log("arrangeEntriesByCategories()", arrangeEntriesByCategories());
  const [presentScoreState, setPresentScoreState] = useState(false);
  async function togglePresent(item) {
    try {
      dispatch(
        presentScore({
          compId: compId,
          doc_id: item?.doc_id,
          entry_name: item?.entry_name,
          currentEvent,
        })
      );
    } catch (error) {
      console.error("error presenting score", error);
    } finally {
      setPresentScoreState(true);
    }
  }

  async function toggleUnpresent(item) {
    try {
      dispatch(
        unpresentScore({
          compId: compId,
          doc_id: item?.doc_id,
          entry_name: item?.entry_name,
          currentEvent,
        })
      );
    } catch (error) {
      console.error("error unpresenting score", error);
    } finally {
      setPresentScoreState(false);
    }
  }

  useEffect(() => {
    setSliderValues({
      technique: 0,
      creativity: 0,
      staging: 0,
      execution: 0,
      performance: 0,
    });
    setPresentScoreState(false);
  }, [currentIndex]);

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const [organizerData, setOrganizerData] = useState({});

  useEffect(() => {
    if (data) {
      async function getOrganizerData() {
        const organizersRef = doc(db, "organizers", compId);

        try {
          const doc = await getDoc(organizersRef);
          const data = await doc?.data();
          setOrganizerData(data);
        } catch (error) {
          console.log("Error fetching document:", error);
        }
      }

      getOrganizerData();
    }
  }, [data]);

  const [deficit, setDeficit] = useState(null);

  // console.log("deficit", deficit);

  function deductScore(deficit) {
    const score = calculateTotal();
    const newTotal = Number(score) - Number(deficit);
    const rounded = newTotal.toFixed(2);
    return parseFloat(rounded);
  }

  const [totalScore, setTotalScore] = useState(0);
  useEffect(() => {
    deficit
      ? setTotalScore(deductScore(deficit))
      : setTotalScore(calculateTotal());
  }, [deficit, sliderValues]);

  const [deductionKeysArray, setDeductionKeysArray] = useState([]);
  useEffect(() => {
    setDeductionKeysArray([]);
  }, [currentIndex]);

  function handleSubmitScore(award_name) {
    // console.log("currentEntry", currentEntry);
    dispatch(
      submitScore({
        compId,
        sliderValues,
        event_name: currentEvent?.event_name,
        entry_name: currentEntry?.entry_name,
        doc_id: currentEntry?.doc_id,
        total: totalScore,
        judge_id: user?.uid,
        award_name,
        deductions:
          deductionKeysArray?.length > 0 ? deductionKeysArray : "none",
      })
    );
  }

  const performingEntry = arrangeEntriesByCategories()?.filter(
    (item) => item?.is_performing === true
  )[0];

  // console.log("performingEntry", performingEntry);

  const [messageStudio, setMessageStudio] = useState(false);
  const [deduction, setDeduction] = useState(false);
  const [judgeSpecialNote, setJudgeSpecialNote] = useState(false);
  const [choreoAwardNote, setChoreoAwardNote] = useState(false);

  function toggleMessageStudio() {
    setMessageStudio((prev) => !prev);
    setDeduction(false);
    setJudgeSpecialNote(false);
    setChoreoAwardNote(false);
  }

  function toggleDeduction() {
    setDeduction((prev) => !prev);
    setMessageStudio(false);
    setJudgeSpecialNote(false);
    setChoreoAwardNote(false);
  }

  function toggleJudgeSpecial() {
    setJudgeSpecialNote((prev) => !prev);
    setDeduction(false);
    setMessageStudio(false);
    setChoreoAwardNote(false);
  }

  function toggleChoreo() {
    setChoreoAwardNote((prev) => !prev);
    setDeduction(false);
    setMessageStudio(false);
    setJudgeSpecialNote(false);
  }

  function closeAllMod() {
    setChoreoAwardNote(false);
    setDeduction(false);
    setMessageStudio(false);
    setJudgeSpecialNote(false);
  }

  const [unblocked, setUnBlocked] = useState(false);
  const [updateDefault, setUpdateDefault] = useState(true);

  //==========================================================================================to handle jump to entry with entry number
  const [openJumpTo, setOpenJumpTo] = useState(false);

  const [jumpToValue, setJumpToValue] = useState("");
  const [jumpToError, setJumpToError] = useState(false);

  function toggleShowJumpTo() {
    setOpenJumpTo((prev) => !prev);
    setJumpToError(false);
    setJumpToValue("");
  }

  const handleInputChange = (event) => {
    const value = event.target.value;
    const filteredValue = value.replace(/[^a-zA-Z0-9]/g, "");
    setJumpToValue(filteredValue);
    setJumpToError(false);
  };

  const entryCountArray = arrangeEntriesByCategories()?.map((itm) => {
    return entryCount(itm).toString().padStart(3, "0");
  });

  //to handle the onclick jump to function
  const handleJumpToEntry = () => {
    const matchingEntry = entryCountArray?.filter(
      (x) =>
        x?.toLowerCase() ===
        jumpToValue.toString().padStart(3, "0")?.toLowerCase()
    )[0];
    const current = arrangeEntriesByCategories()?.filter(
      (y) => entryCount(y).toString().padStart(3, "0") === matchingEntry
    );

    if (current?.length === 0) {
      setJumpToError(true);
    } else {
      setCurrentIndex(
        findIndexByProperty(
          arrangeEntriesByCategories(),
          "doc_id",
          current[0]?.doc_id
        )
      );
      setOpenJumpTo(false);
    }
  };

  // console.log("entryCountArray", entryCountArray);

  //==========================================================================to check for special entries
  // const { competitionName } = useParams();
  const { data: orgData } = useQueryAllDocs("organizers");

  const selectedCompetitionName = competitionName?.replace(/_/g, " ");
  const allGenres = mergeDanceGenresObjects(orgData, selectedCompetitionName);

  const formattedGenres = {};
  for (const key in allGenres) {
    let formattedKey = key.toLowerCase().replace(/ /g, "_");
    if (formattedKey === "song_&_dance") {
      formattedKey = "song_and_dance";
    }
    formattedGenres[formattedKey] = true;
  }
  const checkboxData = deriveArrayFromMergedGenres(formattedGenres);
  const notSpecialProp = checkboxData?.filter(
    (x) => x?.label === currentEntry?.genre
  )?.length;

  function mergeDanceGenresObjects(objectsArray, competitionName) {
    const selectedCompetition = objectsArray?.find(
      (entry) => entry.competitionName === competitionName
    );

    if (!selectedCompetition) {
      return {}; // Return an empty object if the competition is not found.
    }

    const mergedGenres = { ...selectedCompetition.danceGenres };

    return mergedGenres;
  }

  function deriveArrayFromMergedGenres(mergedGenres) {
    return Object.keys(mergedGenres).map((key) => ({
      id: key?.replace(/ /g, "_").toLowerCase(),
      label: key.charAt(0).toLowerCase() + key.slice(1).replace(/_/g, " "),
    }));
  }

  return (
    <>
      <Header />
      <main
        onClick={closeAllMod}
        className="w-full h-screen overflow-y-auto overflow-x-hidden bg-[#1C1C1C] px-3 md:px-10 py-[100px] relative text-white"
      >
        <button
          onClick={goBack}
          className="px-5 py-1 bg-white/80 hover:bg-white/50 text-black rounded-lg text-[.75rem] md:text-[1rem]"
        >
          Back
        </button>
        <div className="w-full flex flex-col">
          <div className="w-full flex justify-center text-white mt-4">
            {status !== "loading" && (
              <h1 className="text-[1.25rem] md:text-[1.5rem] font-bold">
                Event:{" "}
                {status === "loading" ? (
                  <PulseLoader color="#94a4fd" size={15} />
                ) : (
                  <span>{capitalizeFirstLetter(currentEvent?.event_name)}</span>
                )}
              </h1>
            )}
          </div>
          {performingEntry && (
            <p
              className={`text-[.85rem] mx-auto text-center ${
                performingEntry === currentEntry
                  ? "text-[#94a4fd]"
                  : "text-red-500"
              }`}
            >
              {/* to display which entry is currently performing */}
              {performingEntry === currentEntry
                ? `This entry: "#${entryCount(performingEntry)
                    .toString()
                    .padStart(3, "0")} ${
                    performingEntry?.entry_name
                  }" is currently performing`
                : `You are scoring a routine that isn’t currently performing. Entry: "#${entryCount(
                    performingEntry
                  )
                    .toString()
                    .padStart(3, "0")} ${
                    performingEntry?.entry_name
                  }" is currently performing`}
            </p>
          )}

          {arrangeEntriesByCategories()?.length > 0 && (
            <>
              <div className="flex gap-5 items-center mt-8 mx-auto lg:mx-0">
                <img
                  alt=""
                  src="/images/leftArrow.png"
                  className="w-10 h-10 cursor-pointer hover:bg-white/30 rounded-lg"
                  onClick={() => {
                    setCurrentIndex((prevIndex) =>
                      prevIndex === 0
                        ? arrangeEntriesByCategories()?.length - 1
                        : prevIndex - 1
                    );
                    setUnBlocked(false);
                    setUpdateDefault(true);
                  }}
                />
                {/* the entry number sign */}
                {!openJumpTo ? (
                  <div
                    onClick={toggleShowJumpTo}
                    className="min-w-[150px] hover:bg-white/30 p-2 rounded-lg cursor-pointer"
                  >
                    <h1 className="text-white text-[1.5rem] md:text-[2rem] font-medium">
                      Entry #
                      {(currentEvent?.locked
                        ? currentEntry?.locked_entry_count
                        : entryCount(currentEntry)
                      )
                        ?.toString()
                        .padStart(3, "0")}
                    </h1>
                  </div>
                ) : (
                  <div className="w-[200px] border border-white/20 rounded-lg p-3 flex flex-col gap-2 relative">
                    <p className="text-[1.25rem] font-medium">Jump To Entry</p>
                    <div className="w-full flex gap-3 items-center">
                      <input
                        type="text"
                        ref={(input) => input && input.focus()}
                        className={`w-full p-1 rounded-md bg-white/10 outline-none ${
                          jumpToError
                            ? "border border-red-500/50"
                            : "border border-white/20"
                        }`}
                        placeholder="Enter #"
                        value={jumpToValue}
                        onChange={handleInputChange}
                        inputMode="numeric"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleJumpToEntry();
                          }
                        }}
                      />
                      <button
                        onClick={handleJumpToEntry}
                        disabled={!jumpToValue}
                        className={`w-fit h-fit p-2 rounded-md text-white text-[.75rem] whitespace-nowrap ${
                          jumpToValue
                            ? "bg-blue-600 hover:bg-blue-500/60"
                            : "bg-gray-600 hover:bg-gray-500/60 cursor-not-allowed"
                        }`}
                      >
                        Jump To
                      </button>
                    </div>
                    {jumpToError && (
                      <p className="text-[.75rem] text-red-500 leading-tight">
                        No Entry with number "{jumpToValue}"
                      </p>
                    )}
                    <div
                      onClick={toggleShowJumpTo}
                      className="w-5 h-5 flex justify-center items-center bg-white/80 rounded-full cursor-pointer absolute right-2 top-2"
                    >
                      <img
                        alt="user"
                        src="/images/icons8-close-50.png"
                        className="w-3 h-3"
                      />
                    </div>
                  </div>
                )}
                <img
                  alt=""
                  src="/images/rightArrow.png"
                  className="w-10 h-10 cursor-pointer hover:bg-white/30 rounded-lg"
                  onClick={() => {
                    setCurrentIndex((prevIndex) =>
                      prevIndex === arrangeEntriesByCategories()?.length - 1
                        ? 0
                        : prevIndex + 1
                    );
                    setUnBlocked(false);
                    setUpdateDefault(true);
                  }}
                />
              </div>
              <p className="ml-4 mt-4 text-center lg:text-start mb-4 md:mb-0">
                {`"${capitalizeFirstLetter(currentEntry?.entry_name)}"`} -{" "}
                {/* {!notSpecialProp ? (
                  capitalizeFirstLetter(currentEntry?.category_name)
                ) : (
                  <>
                    {capitalizeFirstLetter(currentEntry?.genre)} -{" "}
                    {capitalizeFirstLetter(currentEntry?.age_division)} -{" "}
                    {capitalizeFirstLetter(currentEntry?.classification)}
                  </>
                )}{" "} */}
                {capitalizeFirstLetter(
                  currentEntry?.category_name
                    ?.replace(/~/g, " - ")
                    ?.replace(/_/g, " ")
                )}
                <br />
                {currentEvent?.studio_visibility &&
                  capitalizeFirstLetter(currentEntry?.studio_letter)}{" "}
                {currentEvent?.studio_visibility && "-"}{" "}
                {currentEvent?.studio_visibility &&
                  capitalizeFirstLetter(currentEntry?.studio_selected[0])}
              </p>

              {/* slider container */}
              <SliderContainer
                handleSliderChange={handleSliderChange}
                calculateTotal={calculateTotal}
                sliderValues={sliderValues}
                togglePresent={togglePresent}
                toggleUnpresent={toggleUnpresent}
                presentScoreState={presentScoreState}
                organizerData={organizerData}
                handleSubmitScore={handleSubmitScore}
                data={data}
                compId={compId}
                currentEvent={currentEvent}
                currentEntry={currentEntry}
                deductScore={deductScore}
                deficit={deficit}
                setDeficit={setDeficit}
                totalScore={totalScore}
                setDeductionKeysArray={setDeductionKeysArray}
                deductionKeysArray={deductionKeysArray}
                messageStudio={messageStudio}
                deduction={deduction}
                judgeSpecialNote={judgeSpecialNote}
                choreoAwardNote={choreoAwardNote}
                toggleMessageStudio={toggleMessageStudio}
                toggleDeduction={toggleDeduction}
                toggleJudgeSpecial={toggleJudgeSpecial}
                toggleChoreo={toggleChoreo}
                closeAllMod={closeAllMod}
                unblocked={unblocked}
                setUnBlocked={setUnBlocked}
                currentIndex={currentIndex}
                setSliderValues={setSliderValues}
                updateDefault={updateDefault}
                setUpdateDefault={setUpdateDefault}
                entriesArray={entriesArray}
                entryCount={entryCount}
                allCategories={allCategories}
                currentJudge={currentJudge}
                notSpecialProp={notSpecialProp}
                sortedCategories={sortedCategories}
              />
            </>
          )}
          {arrangeEntriesByCategories()?.length === 0 &&
            status !== "loading" && (
              <div className="w-full h-[200px] border border-white/20 rounded-lg flex justify-center items-center text-white/50 mt-8">
                <p>No entries in this event...</p>
              </div>
            )}
          {status === "loading" && (
            <div className="w-full h-[200px] border border-white/20 rounded-lg flex justify-center items-center text-white/50 mt-8">
              <PulseLoader color="#94a4fd" size={15} />
            </div>
          )}
        </div>
      </main>
      <ScrollToTop />
    </>
  );
};

export default JudgeDashboard;
