import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ButtonLoadingSpinner from 'utils/ButtonLoadingSpinner';
import { clearSuccess } from 'redux/eventManagement/eventManagement';
import { db } from 'services/firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';

const EditSpecialTagForm = ({ onClose, organizerData, user, specialTag }) => {
  const [loading, setLoading] = useState(false);
  const [formErr, setFormErr] = useState('');

  const [formData, setFormData] = useState({
    customTags: specialTag.customTags,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setFormErr('');
    dispatch(clearSuccess());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update form data when inputs change
  const handleChange = (e) => {
    setFormErr('');
    dispatch(clearSuccess());
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!formData.customTags.trim()) {
      setFormErr('Please fill all required fields');
      setLoading(false);
      return;
    }

    if (organizerData && formData) {
      const organizerDocRef = doc(db, 'organizers', user?.uid);
      const competitionDocRef = doc(db, 'competitions', user?.uid);

      // Get the current document data
      const organizerDocSnap = await getDoc(organizerDocRef);
      const currentData = organizerDocSnap.data();

      // Find the index of the special entry being edited
      const entryIndex = currentData.scheduling?.customTags?.findIndex(
        (entry) => entry?.customTags === specialTag?.customTags
      );

      // Create an object to update the "scheduling" field
      const newOne = {
        customTags: formData.customTags,
      };

      const tagsFiltered = currentData.scheduling?.customTags?.filter(
        (_, ind) => ind !== entryIndex
      );
      const newTags = [...tagsFiltered, newOne];

      // Update the document with the new data
      await updateDoc(organizerDocRef, {
        scheduling: { customTags: newTags },
      });
      await updateDoc(competitionDocRef, {
        scheduling: { customTags: newTags },
      });
    }

    // Set loading to false after the update is complete
    setLoading(false);
    // Close the pop-up
    onClose();
  };

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 py-[100px] flex justify-center bg-[#1c1c1c]/90 z-[999]"
    >
      <div className="rounded-lg p-6 w-[40%] h-[410px] mx-auto md:w-1/2 max-w-[700px] min-w-[300px]">
        <div
          onClick={(e) => e.stopPropagation()}
          className="bg-[#282929] rounded-lg p-6 scale"
        >
          <form onSubmit={handleSubmit}>
            <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
              <label htmlFor="description">Special Tag</label>
              <input
                type="text"
                id="customTags"
                placeholder="Eg. Soloist"
                value={formData.customTags}
                onChange={handleChange}
                className="w-full bg-transparent border-none outline-none text-white/80"
              />
            </div>

            <div className="flex gap-3  mb-6 justify-end">
              <button
                type="button"
                onClick={onClose}
                className="px-8 py-1 bg-white rounded-full text-black/80 text-[0.85rem] hover:opacity-75"
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`px-8 flex items-center rounded-full text-black/80 text-[0.85rem] hover:opacity-75 bg-[#94a4fd] cursor-pointer`}
                disabled={loading}
              >
                <span className="px-10 py-2 w-full">
                  {loading ? 'Processing...' : 'Submit'}
                </span>
                {loading && <ButtonLoadingSpinner />}
              </button>
            </div>
            {formErr && <p className="text-red-500">{formErr}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditSpecialTagForm;
