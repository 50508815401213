import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { setEventsData } from "redux/danceStudio/danceStudioSlice";
import { auth } from "services/firebase";

import EventCard from "./components/EventCard";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import ScrollToTop from "./ScrollToTop";

const EventSelectionStudio = () => {
  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );
  const { compId, competitionName } = danceStudioCompData.value;

  //to get realtime events from firestore
  const { status: allDocsStatus, data: allDocsData } =
    useQueryAllDocs("competitions");
  const currentComp = allDocsData?.filter((item) => item?.compId === compId)[0];
  const realtimeEvents = currentComp?.events;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setEventsData(realtimeEvents));
  }, [dispatch, realtimeEvents]);

  const { eventsData, openNotifsBoard } = useSelector(
    (state) => state.danceStudio
  );

  // functions to sort events by start time
  function compareEventStartTimes(eventA, eventB) {
    const startTimeA = new Date(eventA.days[0]?.startTime);
    const startTimeB = new Date(eventB.days[0]?.startTime);

    if (startTimeA < startTimeB) {
      return -1;
    }
    if (startTimeA > startTimeB) {
      return 1;
    }
    return 0;
  }

  // Sort the eventsData array
  const sortedEvents = eventsData?.slice()?.sort(compareEventStartTimes);

  //getting the user ID from auth
  const user = auth?.currentUser;

  //to get entries submitted by this studio owner only
  const { data: entriesData } = useQueryAllSubCollectionDocs(
    "competitions",
    compId,
    "entries"
  );

  const myEntries = entriesData?.filter((item) => item?.studioId === user?.uid);

  const [popup, setPopup] = useState(null);

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}
      <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[310px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
        <div className="mb-auto">
          <div className="block sm:hidden">
            <h2 className="text-white/80 mb-4 text-end">Event Selection</h2>
            {/* <div className="flex gap-3 items-center justify-end mt-[-12px]">
              <img
                alt="user"
                src="/images/Star.png"
                className="w-4 h-4 cursor-pointer"
              />
            </div> */}
          </div>
          {/* Heading */}
          <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
            <h2>All Events</h2>
            <img alt="arrow" src="/images/ArrowDown.png" className="w-4 h-4" />
          </div>

          {/* Event container */}
          <div className="w-full lg:max-w-[85%] lg:min-w-[400px] grid grid-flow-row gap-5 sm:gap-8 grid-cols-1 md:grid-cols-2 grid-auto-rows-auto mb-auto">
            {sortedEvents?.length > 0 ? (
              sortedEvents?.map((item, index) => {
                return (
                  <EventCard
                    item={item}
                    key={index}
                    competitionName={competitionName}
                    myEntries={myEntries}
                    compId={compId}
                    popup={popup}
                    setPopup={setPopup}
                  />
                );
              })
            ) : (
              <div className="w-full min-h-[200px] flex justify-center items-center text-white/60 border border-white/10 rounded-lg">
                {competitionName} has no events yet!
              </div>
            )}
            {allDocsStatus === "loading" && (
              <div className="bg-[#1c1c1c] w-full min-h-[350px] flex justify-center items-center absolute top-10 left-0">
                <PulseLoader color="#94a4fd" size={10} />
              </div>
            )}
          </div>
        </div>
        <ScrollToTop />
        <Footer />
      </div>
    </>
  );
};

export default EventSelectionStudio;
