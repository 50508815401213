/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import Header from "./components/Header";
import JudgeCompCard from "./components/JudgeCompCard";
import { PulseLoader } from "react-spinners";

const CompetitionSelectJudge = () => {
  const { status, data } = useQueryAllDocs("competitions");

  return (
    <>
      <Header />
      <main className="w-full h-screen overflow-y-auto overflow-x-hidden bg-[#1C1C1C] px-3 md:px-10 pt-[100px] md:pt-[120px] relative text-white">
        <div className="mb-auto">
          <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
            <h2 className="text-[1.25rem]">Competitions</h2>
            <img alt="arrow" src="/images/ArrowDown.png" className="w-4 h-4" />
          </div>

          {/* Competitions container */}
          <div className="w-full md:w-full flex flex-col md:grid grid-cols-3 gap-6 mb-auto text-white/80">
            {status === "success" ? (
              data?.map((item, index) => {
                return <JudgeCompCard item={item} key={index} index={index} />;
              })
            ) : (
              <div className="w-full min-h-[200px] flex justify-center items-center border border-white/10 rounded-lg">
                <PulseLoader color="#94a4fd" size={15} />
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default CompetitionSelectJudge;
