/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import ScrollToTop from "./ScrollToTop";
import AddEventContainer from "./components/AddEventContainer";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import removeDuplicates from "utils/removeDuplicates";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  clearSuccess,
  sendMusicReminder,
} from "redux/eventManagement/eventManagement";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { useEffect } from "react";

const Music = () => {
  const {
    eventsData,
    addEvent,
    openNotifsBoard,
    sendMusicReminderPending,
    sendMusicReminderSuccess,
  } = useSelector((state) => state.eventManagement);
  const { event_name } = useParams();
  const currentEvent = eventsData.filter(
    (item) => item.event_name.replace(/ /g, "-") === event_name
  )[0];
  //getting the user ID from auth
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { status, data: allEntries } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "entries"
  );

  const eventEntries = allEntries?.filter(
    (item) => item?.event_uid === currentEvent?.event_uid
  );

  const noMusicEntries = eventEntries?.filter((x) => !x?.music_url);

  const studiosWithoutMusic = removeDuplicates(
    noMusicEntries?.map((y) => y?.studio_selected[0])
  );

  const [popup, setPopup] = useState(null);
  function togglePopup(item) {
    setPopup((prev) => (prev ? null : item));
  }

  const { data: studiosData } = useQueryAllDocs("studio_owners");
  const myStudio = studiosData?.filter((x) => x?.studioName === popup)[0];

  const dispatch = useDispatch();

  useEffect(() => {
    if (sendMusicReminderSuccess?.success) {
      setTimeout(() => {
        dispatch(clearSuccess());
        setPopup(null);
      }, 3000);
    }
  }, [sendMusicReminderSuccess]);

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}
      {!addEvent && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[310px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}
            <h2 className="mb-6 text-white/80 text-[1.25rem] font-medium">
              Music
            </h2>

            <div className="w-full flex flex-col gap-6">
              <div className="w-full p-3 md:p-5 md:max-w-[85%] lg:max-w-[70%] md:min-w-[450px] bg-[#282929] rounded-lg flex flex-col gap-6 mb-auto text-white/80">
                <h1 className="w-full border-b pb-2 font-bold border-white/30">
                  All Studios with entries without music
                </h1>
                {status === "loading" && (
                  <div className="w-full h-[150px] flex justify-center items-center">
                    <PulseLoader color="#94a4fd" size={10} />
                  </div>
                )}
                {studiosWithoutMusic?.length !== 0 ? (
                  <div className="w-full flex flex-col gap-2">
                    {studiosWithoutMusic?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => togglePopup(item)}
                          className="w-full px-3 py-2 rounded-lg cursor-pointer bg-white/10 hover:bg-white/20"
                        >
                          {capitalizeFirstLetter(item)}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="w-full h-[100px] flex justify-center items-center border border-white/20">
                    Nothing yet..
                  </div>
                )}
              </div>
            </div>
          </div>

          {popup && (
            <div
              onClick={() => {
                togglePopup();
              }}
              className="fixed inset-0 py-[100px] flex justify-center bg-[#1c1c1c]/90 z-[999]"
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="rounded-lg p-6 w-full md:w-1/2 max-w-[700px] min-w-[300px] h-[500px] mx-auto"
              >
                <div className="bg-[#282929] rounded-lg p-6 relative scale">
                  <div
                    onClick={() => {
                      togglePopup();
                    }}
                    className="w-6 h-6 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-3 right-3"
                  >
                    <img
                      alt="user"
                      src="/images/icons8-close-50.png"
                      className="w-4 h-4"
                    />
                  </div>
                  <h3 className="text-lg text-center font-medium mb-4 border-b border-white/30 py-2 text-white/80">
                    Send music upload reminder to studio:{" "}
                    {capitalizeFirstLetter(popup)}?
                  </h3>

                  {sendMusicReminderSuccess && (
                    <p className="text-white text-center">
                      Reminder sent successfully!
                    </p>
                  )}

                  {!sendMusicReminderSuccess && (
                    <div className="w-full flex gap-3 justify-center">
                      <button
                        onClick={() => {
                          togglePopup();
                        }}
                        className="px-5 md:px-12 py-2 text-[.85rem] rounded-md bg-white/50 hover:bg-white/40 text-[#1c1c1c] transition-all duration-300"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => {
                          dispatch(
                            sendMusicReminder({
                              email: myStudio?.email,
                              studioName: capitalizeFirstLetter(popup),
                            })
                          );
                        }}
                        disabled={sendMusicReminderPending}
                        className="px-5 md:px-12 py-2 text-[.85rem] rounded-md bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
                      >
                        {sendMusicReminderPending
                          ? "Sending..."
                          : "Send Reminder"}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {/* the add event pop up page */}

      {addEvent && <AddEventContainer />}
    </>
  );
};

export default Music;
