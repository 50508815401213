// export function capitalizeFirstLetter(str) {
//   return str?.replace(/\b\w/g, (match) => match.toUpperCase());
// }

export function capitalizeFirstLetter(str) {
  if (typeof str !== "string") {
    return str; // Return as is if it's not a string
  }

  let result = "";
  let capitalizeNext = true;

  for (let i = 0; i < str.length; i++) {
    if (str[i] === " " || str[i] === "~") {
      result += str[i];
      capitalizeNext = true;
    } else if (capitalizeNext) {
      result += str[i].toUpperCase();
      capitalizeNext = false;
    } else {
      result += str[i];
    }
  }

  return result;
}
