import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSuccess } from "redux/eventManagement/eventManagement";
import {
  addPayment,
  editPayment,
} from "redux/routineScheduling/routineSchedulingSlice";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";

const AddPaymentForm = ({ onClose, paymentData, studio_id, currentEvent }) => {
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formErr, setFormErr] = useState("");

  const [formData, setFormData] = useState({
    description: "",
    payment_method: "",
    amount: "",
    date: "",
    studio_id: studio_id,
    event_uid: currentEvent?.event_uid,
    comment: "",
  });

  // console.log("formData", formData);

  useEffect(() => {
    setFormErr("");
    dispatch(clearSuccess());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEditMode = Boolean(paymentData);

  useEffect(() => {
    if (isEditMode) {
      setFormData(paymentData);
    }
  }, [paymentData, isEditMode]);

  // Update form data when inputs change
  const handleChange = (e) => {
    setFormErr("");
    dispatch(clearSuccess());
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.description.trim() ||
      !formData.payment_method.trim() ||
      !formData.amount.trim() ||
      !formData.date.trim()
    ) {
      setFormErr("Please fill all required fields");
      return;
    }

    setLoading(true);
    const paymentDataToSubmit = {
      formData,
      compId: user?.uid,
    };

    try {
      if (isEditMode) {
        // Edit mode
        const editResult = await dispatch(editPayment(paymentDataToSubmit));
        if (editResult.payload.success) {
          console.log("Payment edited successfully.");
          onClose();
        } else {
          console.error("Error editing payment:", editResult.message);
        }
      } else {
        // Add mode
        const addResult = await dispatch(addPayment(paymentDataToSubmit));
        if (addResult.payload.success) {
          console.log("Payment added successfully.");
          onClose();
        } else {
          console.error("Error adding payment:", addResult.message);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const buttonLabel = isEditMode ? "Update" : "Add";

  return (
    <div
      // onClick={onClose}
      className="fixed inset-0 py-[100px] flex justify-center bg-[#1c1c1c]/90 z-[999]"
    >
      <div className="rounded-lg w-[40%] h-fit mx-auto md:w-1/2 max-w-[700px] min-w-[300px]">
        <div
          onClick={(e) => e.stopPropagation()}
          className="bg-[#282929] rounded-lg p-6 scale"
        >
          <form
            onSubmit={handleSubmit}
            onClick={(e) => e.stopPropagation()}
            id="add-payment-form"
          >
            <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
              <label htmlFor="description">Description</label>
              <input
                type="text"
                id="description"
                placeholder="Eg. Deposit"
                value={formData.description}
                onChange={handleChange}
                className="w-full bg-transparent border-none outline-none text-white/80"
              />
            </div>
            <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
              <label htmlFor="payment_method">Payment Method</label>
              <input
                type="text"
                id="payment_method"
                placeholder="Eg. Visa"
                value={formData.payment_method}
                onChange={handleChange}
                className="w-full bg-transparent border-none outline-none text-white/80"
              />
            </div>
            <div className="flex flex-wrap">
              <div className="w-full md:w-[49%] bg-black/50 p-3 rounded-lg mr-2 mb-3">
                <label htmlFor="date">Date</label>
                <input
                  type="date"
                  id="date"
                  placeholder="Select date"
                  value={formData.date}
                  onChange={handleChange}
                  className="w-full bg-transparent outline-none text-white/80 border md:border-none border-white/20 rounded-md p-1"
                />
              </div>
              <div className="w-full md:w-[49%] bg-black/50 p-3 rounded-lg mb-3">
                <label htmlFor="amount">Amount</label>
                <input
                  type="number"
                  id="amount"
                  placeholder="Eg. $500.00"
                  value={formData.amount}
                  onChange={handleChange}
                  className="w-full bg-transparent border-none outline-none text-white/80"
                />
              </div>
            </div>
            <div className="w-full bg-black/50 p-3 rounded-lg mb-5">
              <label htmlFor="comment">comment</label>
              <input
                type="text"
                id="comment"
                placeholder="Eg. Note"
                value={formData.comment}
                onChange={handleChange}
                className="w-full bg-transparent border-none outline-none text-white/80"
              />
            </div>
            <div className="flex gap-3  mb-6 justify-end">
              <button
                type="button"
                onClick={onClose}
                className="px-3 md:px-8 py-1 bg-white rounded-full text-black/80 text-[0.85rem] hover:opacity-75"
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`px-3 md:px-8 flex items-center rounded-full text-black/80 text-[0.85rem] hover:opacity-75 ${
                  isEditMode ? "bg-[#94a4fd]" : "bg-[#94a4fd]"
                }`}
                disabled={loading}
              >
                <span className="px-3 md:px-10 py-1 w-full">
                  {loading ? "Processing..." : buttonLabel}
                </span>
                {loading && <ButtonLoadingSpinner />}
              </button>
            </div>
            {formErr && <p className="text-red-500">{formErr}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddPaymentForm;
