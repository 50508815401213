import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { PulseLoader } from "react-spinners";
import { addBreak } from "redux/routineScheduling/routineSchedulingSlice";

const AddBreakContainer = ({
  setBreakExists,
  setBreakNameErr,
  toggleAddBreak,
  breakExists,
  bothExists,
  // setBreakName,
  breakNameErr,
  // breakName,
  // addNewBreak,
  generateUniqueId,
  user,
  assignSortId,
  currentEvent,
}) => {
  const { addBreakPending } = useSelector((state) => state.routineScheduling);
  const dispatch = useDispatch();
  const [breakName, setBreakName] = useState("");

  return (
    <div className="w-screen h-screen fixed top-0 left-0 z-[999] px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
      <div className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-start scale shadow-lg">
        <div className="w-full text-[1rem] text-white text-center pb-3 border-b border-[#c5c4c4]/50 relative">
          <p>Break</p>
          <div
            onClick={() => {
              setBreakExists(false);
              setBreakNameErr(false);
              dispatch(toggleAddBreak());
            }}
            className="p-1 rounded-full bg-white/70 absolute right-2 top-0 cursor-pointer"
          >
            <img alt="" src="/images/icons8-close-50.png" className="w-4 h-4" />
          </div>
        </div>

        {addBreakPending ? (
          <div className="w-full min-h-[200px] flex justify-center items-center">
            <PulseLoader color="#94a4fd" size={10} />
          </div>
        ) : (
          <div className="mt-4 w-full sm:w-2/3 mx-auto flex flex-col items-center gap-4">
            {breakExists || bothExists ? (
              <p className="text-white/80 text-center w-full border border-red-400 bg-red-400/20 p-1 rounded-lg">
                You have added a break already!
              </p>
            ) : (
              <form className="flex flex-col items-center gap-4">
                <input
                  type="text"
                  onChange={(e) => {
                    setBreakName(e.target.value);
                    setBreakNameErr(false);
                  }}
                  className={`w-full p-2 rounded-lg bg-white/10 ${
                    breakNameErr && "border border-red-400"
                  } outline-none text-white`}
                  placeholder="Break name (e.g. Dinner break)"
                />
                {breakNameErr && (
                  <p className="text-red-400 text-[.75rem] mt-1">
                    Please type in a the break name
                  </p>
                )}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (breakName) {
                      dispatch(
                        addBreak({
                          break: breakName,
                          event_uid: currentEvent?.event_uid,
                          compId: user?.uid,
                          break_type: "break",
                        })
                      );
                    } else {
                      setBreakNameErr(true);
                    }
                  }}
                  className="min-w-fit text-[.9rem] px-8 py-2 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
                >
                  Add
                </button>
              </form>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddBreakContainer;
