export function calculateAge(dateOfBirth) {
  // Check if the date string is in "dd-mm-yyyy" or "yyyy-mm-dd" format
  const regex1 = /^(\d{2})-(\d{2})-(\d{4})$/;
  const regex2 = /^(\d{4})-(\d{2})-(\d{2})$/;

  let birthDate;
  if (regex1.test(dateOfBirth)) {
    // If the date string is in "dd-mm-yyyy" format, convert it to a Date object
    const [day, month, year] = dateOfBirth.split("-");
    birthDate = new Date(year, month - 1, day);
  } else if (regex2.test(dateOfBirth)) {
    // If the date string is in "yyyy-mm-dd" format, convert it to a Date object
    const [year, month, day] = dateOfBirth.split("-");
    birthDate = new Date(year, month - 1, day);
  } else {
    // If the date string is not in a recognized format, return null
    return null;
  }

  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
}
