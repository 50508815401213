import { collection, query, where } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

export const useFetchScheduleDocs = (
  collectionName,
  compId,
  subCollection,
  filterField,
  filterValue
) => {
  const firestore = useFirestore();
  const entriesCollection = collection(
    firestore,
    collectionName,
    compId,
    subCollection
  );

  const entriesQuery = query(
    entriesCollection,
    where(filterField, "==", filterValue || "")
  );

  const { status, data } = useFirestoreCollectionData(entriesQuery, {
    idField: "id",
  });

  return { status, data };
};
