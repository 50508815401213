/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import ScrollToTop from "./ScrollToTop";
import { useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { PulseLoader } from "react-spinners";

const OverviewStudio = () => {
  const { eventsData, openNotifsBoard } = useSelector(
    (state) => state.danceStudio
  );
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );
  const { compId } = danceStudioCompData.value;

  const { event_name } = useParams();

  const currentEvent = eventsData?.filter(
    (item) => item.event_name.replace(/ /g, "-") === event_name
  )[0];

  const currentStudio = currentEvent?.access?.filter(
    (itm) => itm?.studio_id === user?.uid
  )[0];

  const { data: allStudios } = useQueryAllDocs("studio_owners");
  const studioData = allStudios?.filter(
    (item) => item?.uid === currentStudio?.studio_id
  )[0];

  const { data: allPayment } = useQueryAllSubCollectionDocs(
    "competitions",
    compId,
    "payment_history"
  );

  const studioPayment = allPayment?.filter(
    (itm) => itm?.studio_id === user?.uid
  );

  //==========================================================================to get all entrytypes & pricing

  const { status: compStatus, data: compData } = useFirestoreQueryDoc(
    "competitions",
    compId
  );

  const [taxRate, setTaxRate] = useState(0.0);

  const [entryTypes, setentryTypes] = useState([]);
  const [pricing, setpricing] = useState([]);

  useEffect(() => {
    if (compData) {
      const { entryTypes, pricing, events } = compData;
      setentryTypes(entryTypes);
      setpricing(pricing?.pricingCategories);

      const currentEve = events?.filter(
        (itm) => itm?.event_uid === currentEvent?.event_uid
      )[0];
      setTaxRate(currentEve?.tax_rate || 0);
    }
  }, [compData, compStatus]);

  const allEntryTypes = entryTypes?.concat(
    compData?.specialEntryTypes?.tags || []
  );

  //====================================================================================to get all entries
  const { status, data: allEntries } = useQueryAllSubCollectionDocs(
    "competitions",
    compId,
    "entries"
  );

  const studioEntries = allEntries?.filter(
    (each) => each?.studio_selected[0] === currentStudio?.studio_name
  );

  function getAllDancers(arr) {
    const allSelected = [];

    if (arr && Array.isArray(arr)) {
      for (const obj of arr) {
        if (obj.dancers_selected && Array.isArray(obj.dancers_selected)) {
          allSelected.push(...obj.dancers_selected);
        }
      }
    }

    return allSelected;
  }

  // ====================================================================================Calculate the totalAmount
  const newTotalAmount = allEntryTypes
    ?.reduce((total, row) => {
      let rowPricingOption;
      if (row?.entryTypeName) {
        rowPricingOption = pricing?.find((obj) =>
          obj.hasOwnProperty(row.entryTypeName)
        );
      } else if (row.specialEntryTypeName) {
        rowPricingOption = pricing?.find((obj) =>
          obj.hasOwnProperty(row.specialEntryTypeName)
        );
      }
      // const rowEntries = studioEntries?.filter(
      //   (itm) =>
      //     itm?.entryType === row?.entryTypeName ||
      //     itm?.entryType === row?.specialEntryTypeName
      // );

      // to calculate special entries and normal entries
      function rowEntries1(arr) {
        const result = arr?.filter(
          (itm) =>
            itm?.entryType === row?.entryTypeName &&
            !itm?.category_name?.includes("impulse") &&
            !itm?.category_name?.includes("remix") &&
            !itm?.category_name?.includes("unity") &&
            !itm?.category_name?.includes("overtime")
        );
        return result;
      }

      function rowEntriesSpecial(arr) {
        const result = arr?.filter((x) =>
          x?.category_name
            ?.toLowerCase()
            ?.includes(row?.specialEntryTypeName?.toLowerCase())
        );
        return result;
      }

      const rowEntries =
        row?.specialEntryTypeName?.includes("impulse") ||
        row?.specialEntryTypeName?.includes("remix") ||
        row?.specialEntryTypeName?.includes("unity") ||
        row?.specialEntryTypeName?.includes("overtime")
          ? rowEntriesSpecial(studioEntries)
          : rowEntries1(studioEntries);

      const flatAmount =
        rowEntries?.length > 0
          ? rowPricingOption?.[row?.entryTypeName] ||
            rowPricingOption?.[row?.specialEntryTypeName] * rowEntries?.length
          : 0.0;

      const rowAmount =
        rowPricingOption?.selectedOption?.toLowerCase() === "flat"
          ? Number(flatAmount)
          : getAllDancers(rowEntries)?.length *
            Number(
              rowPricingOption?.[row?.entryTypeName] ||
                rowPricingOption?.[row?.specialEntryTypeName]
            );

      return total + (rowAmount || 0);
    }, 0)
    .toFixed(2);

  function calcTotalAfterTax(amount, percentage) {
    const result = (amount * percentage) / 100;
    return result.toFixed(2);
  }

  const totalAfterTax =
    Number(newTotalAmount) + Number(calcTotalAfterTax(newTotalAmount, taxRate));

  function calculateTotalPayments(arr) {
    if (!arr || !Array.isArray(arr)) {
      return "Invalid input";
    }

    let totalAmount = 0;

    for (const obj of arr) {
      if (obj.amount) {
        totalAmount += parseFloat(obj.amount);
      }
    }

    return totalAmount.toFixed(2);
  }

  const amountOwing = totalAfterTax - calculateTotalPayments(studioPayment);

  const sortedPayment = studioPayment?.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);

    // Sort in descending order
    return dateA - dateB;
  });

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}
      {status === "loading" && (
        <div className="w-full h-screen bg-[#1c1c1c] px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] pt-[80px] sm:pt-[110px]">
          <div className="w-full h-[200px] flex justify-center items-center gap-6 mb-auto text-white/80">
            <PulseLoader color="#94a4fd" size={10} />
          </div>
        </div>
      )}

      {status === "success" && (
        <div
          className={`w-full min-h-screen text-white/80 px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px]  ${
            openNotifsBoard ? "lg:pr-[310px]" : "lg:pr-[32px]"
          } pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col transition-all duration-700`}
        >
          {" "}
          <div className="mb-auto">
            <div className="flex gap-2 items-center text-white/90 text-[1rem] mb-6">
              <h2>
                {currentEvent?.event_name} {currentEvent?.year.split(" to ")[0]}
              </h2>
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            <div className="w-full min-h-[400px]">
              <div className="p-2 gap-4 flex lg:flex-row flex-col">
                {/* First Card */}
                <div className="w-[325px] h-[200px] bg-[#282929] rounded-lg p-4 text-white/80 text-[.9rem]">
                  <h3 className="text-[1.5rem] font-bold mb-4">
                    {capitalizeFirstLetter(currentStudio?.studio_name)}
                  </h3>
                  <div>
                    {capitalizeFirstLetter(currentStudio?.first_name)}{" "}
                    {capitalizeFirstLetter(currentStudio?.last_name)}
                  </div>
                  <div>{capitalizeFirstLetter(studioData?.addressLine1)}</div>
                  {studioData?.addressLine2 && (
                    <div>{capitalizeFirstLetter(studioData?.addressLine2)}</div>
                  )}

                  <div>
                    {" "}
                    {studioData?.postalCode},{" "}
                    {capitalizeFirstLetter(studioData?.city)},{" "}
                    {studioData?.state}
                  </div>
                  <div>{studioData?.email}</div>
                  <div>{studioData?.website}</div>
                </div>

                {/* Second Card */}
                <div className="w-[202px] h-[112px] bg-white rounded-lg p-4">
                  <div className="text-black text-[1.2rem] font-bold pl-4 mb-2">
                    Amount Owing
                  </div>
                  <div className="text-black text-[1.2rem] font-bold pl-4">
                    ${isNaN(amountOwing) ? "..." : amountOwing?.toFixed(2)}
                  </div>
                </div>
              </div>

              {/* another section */}
              <section className="w-full xl:w-[80%] mt-7 pb-2">
                <h3 className="pl-2 mb-6 text-lg text-white/80">
                  Current submissions
                </h3>
                <div className="w-full overflow-x-auto">
                  <table className="min-w-[600px] w-full text-[.85rem] table2 transition-all duration-300">
                    <thead className="text-white/40 text-left border-b border-white/30">
                      <tr className="whitespace-nowrap">
                        <th>Entry Type</th>
                        <th>Entries</th>
                        <th>Dancers</th>
                        <th>Rate</th>
                        <th>Price</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody className="text-white/80">
                      {allEntryTypes?.map((row, idx) => {
                        const rowEntries = studioEntries?.filter(
                          (itm) =>
                            itm?.entryType === row?.entryTypeName ||
                            itm?.entryType === row?.specialEntryTypeName
                        );

                        let rowPricingOption;

                        if (row.entryTypeName) {
                          rowPricingOption = pricing?.find((obj) =>
                            obj.hasOwnProperty(row?.entryTypeName)
                          );
                        } else if (row?.specialEntryTypeName) {
                          rowPricingOption = pricing?.find((obj) =>
                            obj.hasOwnProperty(row?.specialEntryTypeName)
                          );
                        }

                        const flatAmount =
                          rowEntries?.length > 0
                            ? rowPricingOption?.[
                                row?.entryTypeName || row?.specialEntryTypeName
                              ] * rowEntries?.length
                            : 0.0;

                        const rowAmount =
                          rowPricingOption?.selectedOption?.toLowerCase() ===
                          "flat"
                            ? Number(flatAmount)?.toFixed(2)
                            : (
                                getAllDancers(rowEntries)?.length *
                                Number(
                                  rowPricingOption?.[
                                    row?.entryTypeName ||
                                      row?.specialEntryTypeName
                                  ]
                                )
                              ).toFixed(2);

                        return (
                          <tr
                            key={idx}
                            className="border-b border-white/10 whitespace-nowrap"
                          >
                            <td className="flex items-center">
                              {capitalizeFirstLetter(
                                row?.entryTypeName || row?.specialEntryTypeName
                              )}
                            </td>
                            <td>{rowEntries?.length}</td>
                            <td>{getAllDancers(rowEntries)?.length}</td>
                            <td>
                              {rowPricingOption?.selectedOption === "perDancer"
                                ? "Per Dancer"
                                : "Flat"}
                            </td>
                            <td>
                              $
                              {
                                rowPricingOption?.[
                                  row.entryTypeName || row.specialEntryTypeName
                                ]
                              }
                            </td>
                            <td>${rowAmount}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tr>
                      <td colSpan="4"></td>
                      <td className="text-white text-md font-bold whitespace-nowrap">
                        Sub Total
                      </td>
                      <td className="text-white text-md font-bold whitespace-nowrap">
                        ${newTotalAmount}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4"></td>
                      <td className="text-white text-md font-bold whitespace-nowrap">
                        Tax Rate
                      </td>
                      <td className="relative">
                        <div className="text-white font-bold w-[60px] h-[30px] bg-black/50 border border-white/10 rounded-lg p-4">
                          {taxRate}%
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-white/10 whitespace-nowrap cursor-pointer">
                      <td colSpan="4"></td>
                      <td className="text-white text-md font-bold whitespace-nowrap">
                        Total
                      </td>
                      <td className="text-white text-md font-bold whitespace-nowrap">
                        ${totalAfterTax?.toFixed(2)}
                      </td>
                    </tr>
                  </table>
                </div>
              </section>
              {/* payment details section */}
              <section className="w-full xl:w-[80%] mt-10 pb-[100px]">
                <h3 className="pl-2 mb-6 text-lg text-white/80">
                  Payment History
                </h3>
                <div className="w-full overflow-x-auto">
                  <div className="min-w-[600px] w-full text-[.85rem]">
                    <div className="text-white/40 text-left border-b border-white/30 flex whitespace-nowrap pb-2">
                      <div className="w-[20%]">Date</div>
                      <div className="w-[20%]">Receipt #</div>
                      <div className="w-[20%]">Description</div>
                      <div className="w-[20%]">Payment Method</div>
                      <div className="w-[20%]">Amount</div>
                      <div className="w-[15%]"></div>
                    </div>
                    <div className="text-white/80">
                      {studioPayment?.length === 0 && (
                        <div className="w-full h-[100px] border border-white/10 rounded-lg flex justify-center items-center text-white/20">
                          No Payments Yet...
                        </div>
                      )}
                      {sortedPayment?.map((payment, index) => (
                        <div
                          key={payment?.doc_id}
                          className="border-b border-white/10 p-2 flex items-center relative whitespace-nowrap"
                        >
                          <div className="w-[20%]">{payment?.date}</div>
                          <div className="w-[20%]">
                            {(index + 1).toString().padStart(5, "0")}
                          </div>
                          <div className="w-[20%]">
                            {capitalizeFirstLetter(payment?.description)}
                          </div>
                          <div className="w-[20%]">
                            {capitalizeFirstLetter(payment?.payment_method)}
                          </div>
                          <div className="w-[20%]">
                            $
                            {parseFloat(payment.amount).toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </div>
                          <div
                            className="cursor-pointer hover:bg-white/20 px-2 rounded-md"
                            onClick={() => {
                              window.open(
                                `/studio/event/${event_name.replace(
                                  / /g,
                                  "-"
                                )}/accounting-overview/${
                                  currentStudio?.studio_id
                                }/receipt/${
                                  payment?.doc_id
                                }?studioData=${JSON.stringify(studioData)}`,
                                "_blank",
                                "noopener,noreferrer"
                              );
                            }}
                          >
                            Download receipt
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}
    </>
  );
};

export default OverviewStudio;
