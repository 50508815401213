const RemoveCompButton = ({ item, handleRemoveComp, deleteP, setDeleteP }) => {
  return (
    <>
      {" "}
      <div
        onClick={() => {
          //  handleRemoveComp(item?.competitionName);
          setDeleteP(item);
        }}
        className={`text-[.85rem] px-3 py-1 mr-2 cursor-pointer hover:underline text-red-400 transition-all duration-300`}
      >
        Remove
      </div>
      {deleteP === item && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-start scale shadow-lg">
            <p className="w-full text-[1rem] text-white pb-3 border-b border-[#c5c4c4]/50 text-center">
              Comfirm removal?
            </p>

            <p>
              NOTE: All entries added in this competition will be deleted as
              well.
            </p>

            <div className="mt-4 w-full text-center">
              <button
                onClick={() => setDeleteP(null)}
                className="text-[.85rem] px-8 py-2 mr-4 rounded-md bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleRemoveComp(item?.competitionName);
                }}
                className={`text-[.9rem] px-8 py-2 rounded-md text-[#1c1c1c] transition-all duration-300 bg-red-400 hover:bg-red-400/60`}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RemoveCompButton;
