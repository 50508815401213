import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="w-full min-h-screen bg-[#1c1c1c]  flex flex-col gap-12 p-4 items-center">
      <h1 className=" text-3xl text-center font-bold text-white/80 mt-20">
        404 - Page Not Found!
      </h1>
      <img
        alt="not found"
        src="/images/404-error.png"
        className="w-full md:w-[500px] h-auto"
      />
      <div className="text-center">
        <button
          onClick={goBack}
          className="px-4 py-2 bg-blue-500 rounded-lg text-white"
        >
          Go back
        </button>
      </div>
    </div>
  );
};

export default NotFound;
