import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

// components
import ImgWrap from "../UI/ImgWrapper";
import Container from "../UI/Container";
import BackIcon from "../../assets/arrow_back.png";
import { useNavigate, useLocation } from "react-router-dom";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";

import { loginUser } from "redux/auth/authSlice";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { closeVerifyState } from "redux/danceStudio/danceStudioSlice";

export default function Login() {
  const { verifyEmailSent } = useSelector((state) => state.danceStudio);
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState("");

  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedAccountType = queryParams.get("type");

  const moveToSignUp = () => {
    if (selectedAccountType === "Dance Studio Owner") {
      navigate("/studio");
    } else if (selectedAccountType === "Competition Owner") {
      navigate("/organizer");
    }
  };

  const handleLogin = async (email, password) => {
    localStorage.removeItem("selectedEntry");
    setLoading(true);

    try {
      const response = await dispatch(loginUser({ email, password }));

      const { role, formCompleted, emailVerified, creationTime } =
        unwrapResult(response);

      const userCreationTime = new Date(creationTime);

      // Specify the target date to compare against
      const targetDate = new Date("Thu, 2 Nov 2023 16:00:00 GMT");
      // console.log("creationTime", creationTime);

      if (role === "organizer") {
        if (userCreationTime > targetDate) {
          console.log("This user was created AFTER email verification check!!");
          if (emailVerified) {
            if (formCompleted) {
              // Navigate to organizer dashboard
              navigate("/organizer/event-selection");
            } else {
              navigate("/organizer/genre");
              // Navigate to organizer registration page
            }
          } else {
            navigate("/email-not-verified");
          }
        } else {
          console.log(
            "This user was created BEFORE email verification check!!"
          );
          if (formCompleted) {
            // Navigate to organizer dashboard
            navigate("/organizer/event-selection");
          } else {
            navigate("/organizer/genre");
            // Navigate to organizer registration page
          }
        }
      } else if (role === "danceStudioOwner") {
        if (userCreationTime > targetDate) {
          // console.log("This user was created AFTER email verification check!!");
          if (emailVerified) {
            if (formCompleted) {
              // Navigate to dance studio owner dashboard
              navigate("/studio/competition-selection");
            } else {
              // Navigate to dance studio owner registration page
              navigate("/studio/info");
            }
          } else {
            navigate("/email-not-verified");
          }
        } else {
          console.log(
            "This user was created BEFORE email verification check!!"
          );
          if (formCompleted) {
            // Navigate to dance studio owner dashboard
            navigate("/studio/competition-selection");
          } else {
            // Navigate to dance studio owner registration page
            navigate("/studio/info");
          }
        }
      } else {
        throw new Error("Invalid role");
      }

      setLoading(false);
      return null;
    } catch (error) {
      console.log("ERROR", error);
      toast.error("Invalid Credentials Email or Password", error.message);
      setLoading(false);
      return error.message;
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Enter a valid email address")
        .required("Email is required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
    }),
    onSubmit: async (values) => {
      const error = await handleLogin(values.email, values.password);
      setLoginError(error);
    },
  });

  const passwordVisibility = () => {
    setPasswordShown((prev) => !prev);
  };

  return (
    <div className="flex flex-row flex-wrap h-[100vh]">
      {/* The left content holds logo and background image */}
      <div className="lg:w-[40%] lg:flex hidden">
        <ImgWrap
          className={`${
            selectedAccountType === "Dance Studio Owner"
              ? "bg-[url(../public/studio.png)]"
              : "bg-[url(../public/competition.png)]"
          }`}
        />
      </div>

      {/* the container wrapper */}
      <div className="lg:w-[60%] w-full">
        <Container className="flex flex-col flex-wrap">
          <div className="flex flex-row flex-wrap justify-between w-full relative">
            {verifyEmailSent && (
              <div className="w-full md:w-[500px] p-5 bg-[#282929] absolute top-0 right-0 rounded text-white flex flex-col gap-4">
                <p>
                  Email verification sent. Check your inbox or junk/spam to
                  verify your email.
                </p>
                <button
                  onClick={(e) => {
                    dispatch(closeVerifyState());
                  }}
                  className="w-fit text-[.9rem] px-5 py-1 rounded-lg bg-[#94a4fd] hover:bg-[#94a4fd]/80 text-[#1c1c1c] transition-all duration-300"
                >
                  Close
                </button>
              </div>
            )}
            <div
              className="flex flex-row items-center cursor-pointer"
              onClick={() => navigate("/")}
            >
              <img
                src={BackIcon}
                className="w-[9.73px] h-[16.5px] mr-[14px]"
                alt="backIcon"
              />
              <span className="text-base text-[#8692A6]">Back</span>
            </div>
            {selectedAccountType === "Dance Studio Owner" && (
              <div
                className="text-[#8692A6] text-base font-semibold"
                onClick={moveToSignUp}
              >
                <p className="text-lg font-normal text-[#8692A6] cursor-pointer">
                  Don't have an account?{" "}
                  <span className="text-[#1565D8] hover:underline font-bold">
                    Register
                  </span>
                </p>
              </div>
            )}
          </div>
          <div className="lg:pl-[63px] md:pl-[63px] pt-[14%]">
            <h1 className="text-3xl font-bold text-[#000000] dark:text-white mb-7">
              {selectedAccountType === "Dance Studio Owner"
                ? "Studio Owner Login"
                : "Competition Owner Login"}
            </h1>
            <p className="text-lg font-normal text-[#8692A6]">
              Please enter your login information
            </p>
          </div>

          <form
            onSubmit={formik.handleSubmit}
            className="lg:ml-[63px] md:ml-[63px] flex flex-col mt-[33px] border-t border-base lg:w-[60%] md:w-[60%] border-[#F5F5F5]"
          >
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base font-medium mb-2 text-[#696F79] dark:text-white">
                  Email address*
                </span>
              </label>
              <input
                type="email"
                placeholder="Email Address"
                className="input input-bordered border-[#959FB1] dark:border-white w-full text-sm font-medium text-primary"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email && (
                <p
                  role="alert"
                  className="text-[
                  11px] text-error"
                >
                  {formik.errors.email}
                </p>
              )}
            </div>
            <div className="form-control w-full mt-5">
              <label className="label">
                <span className="label-text text-base font-medium mb-2 text-[#696F79] dark:text-white">
                  Password*
                </span>
              </label>
              <div className="relative">
                <input
                  type={passwordShown ? "true" : "password"}
                  placeholder="Password"
                  className="input input-bordered border-[#959FB1] dark:border-white w-full text-sm font-medium text-primary"
                  {...formik.getFieldProps("password")}
                />
                <span className="password-toggle-icon flex justify-center  items-center mr-[10px] absolute top-0 right-0 bottom-0">
                  {passwordShown ? (
                    <span
                      className="material-symbols-outlined flex cursor-pointer"
                      onClick={passwordVisibility}
                    >
                      <i className="fa-regular fa-eye"></i>
                    </span>
                  ) : (
                    <span
                      className="material-symbols-outlined flex cursor-pointer"
                      onClick={passwordVisibility}
                    >
                      <i className="fa-regular fa-eye-slash"></i>
                    </span>
                  )}
                </span>
              </div>
              {formik.touched.password && formik.errors.password && (
                <p role="alert" className="text-[11px] text-error">
                  {formik.errors.password}
                </p>
              )}
            </div>
            <div className="flex flex-row gap-4 cursor-pointer justify-between form-control mt-6">
              <label className="label cursor-pointer justify-start">
                <input
                  type="checkbox"
                  {...formik.getFieldProps("remember_me")}
                  className="checkbox checkbox-xs rounded-none bg-[#FFFFFF] border-[#1565D8] mr-3 text-white"
                />
                <span className="label-text text-base font-medium info text-[#696F79]">
                  Remember me
                </span>
              </label>
              <span
                onClick={() => navigate("/forgot-password")}
                className="text-base font-medium text-[#696F79] hover:underline"
              >
                Forgot Password?
              </span>
            </div>
            {loginError && (
              <div className="mt-4 text-error">
                <p role="alert">{loginError}</p>
              </div>
            )}

            <div className="items-center mt-10 form-control">
              <button
                className="h-[3rem] bg-[#1565D8] rounded-[5px] text-white capitalize w-80 flex justify-between items-center px-4 text-center"
                type="submit"
                disabled={loading}
              >
                <span className="w-full">
                  {loading ? "Processing..." : "Login"}
                </span>
                {loading && <ButtonLoadingSpinner />}
              </button>
            </div>
          </form>
        </Container>
      </div>
    </div>
  );
}
