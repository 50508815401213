/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import ScrollToTop from "./ScrollToTop";
import { useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import React, { useEffect, useState } from "react";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "services/firebase";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { PulseLoader } from "react-spinners";
import ScoresCategory from "./components/ScoresCategory";
import arrangeEntriesByCategories from "utils/arrangeEntriesByCategories";
import findIndexByProperty from "utils/findIndexByProperty";
import findLockedEntryLetter from "utils/findLockedEntryLetter";
import localStorage from "redux-persist/es/storage";

const Tabulator = () => {
  const { addEvent, openNotifsBoard } = useSelector(
    (state) => state.danceStudio
  );

  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );
  const { compId } = danceStudioCompData.value;

  const { event_name: name } = useParams();

  const event_name = name || localStorage.getItem("event_name") || "";
  //to get realtime events from firestore
  const { data } = useQueryAllDocs("competitions");
  const { data: allOrgs } = useQueryAllDocs("organizers");
  const currentOrg = allOrgs?.filter((item) => item?.uid === compId)[0];

  const currentComp = data?.filter((item) => item?.compId === compId)[0];
  const currentEvent = currentComp?.events?.filter(
    (item) => item.event_name?.replace(/ /g, "-")?.trim() === event_name
  )[0];

  //======================================================to get scores
  const [scoresData, setScoresData] = useState([]);
  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, "competitions", compId, "scores")),
      (snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data());
        setScoresData(data);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [compId]);

  const eventScores = scoresData?.filter(
    (item) => item?.event_name?.trim() === currentEvent?.event_name?.trim()
  );

  //to get competition name
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { data: studioData } = useFirestoreQueryDoc("studio_owners", user?.uid);

  const { status, data: allCategories } = useQueryAllSubCollectionDocs(
    "competitions",
    compId,
    "categories"
  );

  const eventCate = allCategories?.filter(
    (cat) =>
      cat?.event_uid === currentEvent?.event_uid && cat?.type === "category"
  );

  //to get all entries
  const { data: allEntries } = useQueryAllSubCollectionDocs(
    "competitions",
    compId,
    "entries"
  );

  const myEntries = allEntries?.filter(
    (each) =>
      each?.event_uid === currentEvent?.event_uid &&
      each?.studio_selected[0] === studioData?.studioName
  );

  let sorted = eventCate?.sort((a, b) => a?.sort_id - b?.sort_id);

  const sortedCategories = sorted?.filter((category) =>
    myEntries?.some((entry) => entry.category_name === category.category_name)
  );

  const divisionsRaw = sortedCategories?.map(
    (obj) => obj.category_name?.split("~")[0]
  );

  const divisions = removeDuplicates(divisionsRaw);

  // console.log("divisions", divisions);

  function removeDuplicates(array) {
    if (array) {
      const uniqueArray = [];
      const seenObjects = new Set();

      for (const obj of array) {
        // Serialize the object to JSON to use it as a unique identifier
        const serializedObj = JSON.stringify(obj);

        if (!seenObjects.has(serializedObj)) {
          seenObjects.add(serializedObj);
          uniqueArray.push(obj);
        }
      }

      return uniqueArray;
    }
  }

  const calculateAverageScore = (arr, key) => {
    if (Array.isArray(arr)) {
      const filteredArray = arr?.filter((itm) => itm?.technique);
      const array = key === "technique" ? filteredArray : arr;
      const totalSum = array?.reduce((sum, obj) => sum + Number(obj[key]), 0);
      const average = totalSum / array?.length;
      const averageFormatted = Number(average)?.toFixed(2);

      return averageFormatted;
    }
  };

  function getDeduction(arr) {
    const lastCharacters = arr?.map((str) => {
      if (str?.length > 0) {
        return Number(str?.slice(-1));
      } else {
        return "";
      }
    });

    const ded = lastCharacters?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    return ded;
  }

  const calculateTotalScore = (entry) => {
    const scores = eventScores?.filter(
      (item) =>
        item?.doc_id === entry?.doc_id &&
        item?.event_name === currentEvent?.event_name?.trim()
    );

    const allDeductions = scores
      ?.flatMap((obj) => obj.deductions)
      ?.filter((item) => item !== "none");

    const sum =
      [
        Number(calculateAverageScore(scores, "technique")),
        Number(calculateAverageScore(scores, "creativity")),
        Number(calculateAverageScore(scores, "staging")),
        Number(calculateAverageScore(scores, "execution")),
        Number(calculateAverageScore(scores, "performance")),
      ]?.reduce((accumulator, currentValue) => accumulator + currentValue, 0) -
      getDeduction(allDeductions);

    const truncatedScore = sum?.toFixed(2);

    // console.log("truncatedScore", entry?.entry_name, truncatedScore);

    return isNaN(truncatedScore) ? "- -" : truncatedScore;
  };

  const calculateHighestScore = (categoryEntries) => {
    const filteredEntries = categoryEntries?.filter(
      (x) => x?.is_scratched === false
    );
    let highestScore = 0;

    // console.log("filteredEntries", filteredEntries);

    filteredEntries?.forEach((entry) => {
      const scores = eventScores?.filter(
        (item) =>
          item?.doc_id === entry?.doc_id &&
          item?.event_name === currentEvent?.event_name?.trim()
      );

      const totalScoreRaw = scores?.reduce(
        (sum, score) => sum + Number(score?.total || 0),
        0
      );

      const totalScore = totalScoreRaw / scores?.length;

      if (totalScore > highestScore) {
        highestScore = totalScore;
      }
    });

    return highestScore;
  };

  const calculatePosition = (entry, categoryEntries) => {
    const filteredEntries = categoryEntries?.filter(
      (x) => x?.is_scratched === false
    );
    const highestScore = calculateHighestScore(filteredEntries);

    // console.log("highestScore", entry?.entry_name, highestScore);
    const entryScore = calculateTotalScore(entry);
    // Sort the scores in descending order
    const sortedScores = filteredEntries
      .map((entry) => calculateTotalScore(entry))
      .sort((a, b) => b - a);

    // Find the position of the current entry's score in the sorted scores array
    const position = sortedScores?.indexOf(entryScore) + 1;

    if (!highestScore || entryScore === "- -") {
      return "- -";
    } else if (entryScore === highestScore) {
      return `1st`;
    } else if (position === 1) {
      return `1st`;
    } else if (position === 2) {
      return `2nd`;
    } else if (position === 3) {
      return `3rd`;
    } else {
      return `${position}th`;
    }
  };

  const [showNotes, setShowNotes] = useState("");

  const closeDropdownAll = () => {
    setShowNotes("");
  };

  const eventCategories = allCategories?.filter(
    (cat) => cat?.event_uid === currentEvent?.event_uid
  );
  let sortedCategories2 = eventCategories?.sort(
    (a, b) => a?.sort_id - b?.sort_id
  );

  const categories = sortedCategories2?.filter((l) => l?.type === "category");

  const allEntriesWithoutLocked = arrangeEntriesByCategories(
    categories,
    allEntries
  )?.filter((k) => k?.locked_entry === false);

  const allEntriesWithLocked = arrangeEntriesByCategories(
    categories,
    allEntries
  );

  function entryCount(itm) {
    const allEntriesBeforeLocked = allEntriesWithLocked
      ?.slice(
        0,
        findIndexByProperty(allEntriesWithLocked, "doc_id", itm?.doc_id)
      )
      ?.filter((m) => m?.locked_entry === false);

    if (itm?.locked_entry) {
      return `${
        allEntriesBeforeLocked?.length === 0
          ? 1
          : allEntriesBeforeLocked?.length
      }${findLockedEntryLetter(allEntriesWithLocked, itm)}`;
    } else {
      return (
        findIndexByProperty(allEntriesWithoutLocked, "doc_id", itm?.doc_id) + 1
      );
    }
  }

  const [searchTerm, setsearchTerm] = useState("");
  const [searchedDivisions, setSearchedDivisions] = useState([]);
  const [searchEntries, setsearchEntries] = useState([]);

  function handleSearch(e) {
    const term = e.target.value;
    setsearchTerm(term);
    const match = myEntries?.filter(
      (itm) =>
        itm?.entry_name?.toLowerCase()?.includes(term?.toLowerCase()) ||
        entryCount(itm)?.toString()?.includes(term?.toLowerCase())
    );
    setsearchEntries(match);
    const divisionsRaw = match?.map((obj) => obj.category_name?.split("~")[0]);
    setSearchedDivisions(removeDuplicates(divisionsRaw));
  }

  const [showPrint, setShowPrint] = useState(false);

  function handleShowPrint() {
    setShowPrint((prev) => !prev);
  }

  const [checkboxData, setcheckboxData] = useState({
    // division_rank: true,
    entry_number: true,
    technique: true,
    creativity: true,
    staging: true,
    execution: true,
    performance: true,
    deductions: true,
    average_total: true,
    award: true,
    notes: true,
    division_rank: currentEvent?.rank_visibility ? true : false,
  });

  // if (currentEvent?.rank_visibility) {
  //   checkboxData.division_rank = true;
  // }

  const checkboxes = Object.keys(checkboxData);
  const allfalse = Object.values(checkboxData)?.filter(
    (x) => x?.toString() === "true"
  );

  const handleCheckboxChange = (event, item) => {
    const { id, checked } = event.target;

    setcheckboxData((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}

      {/* event details page */}
      {!addEvent && (
        <div
          onClick={closeDropdownAll}
          className={`w-full min-h-screen text-white/80 px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px]  ${
            openNotifsBoard ? "lg:pr-[310px]" : "lg:pr-[32px]"
          } ${
            searchTerm && "overflow-y-hidden"
          } pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col transition-all duration-700`}
        >
          <div className={`mb-auto`}>
            {/* Heading */}
            <div className="flex flex-wrap gap-4 items-center text-white/90 text-[1rem] mb-6 relative">
              <div className="mr-auto">
                <h2>
                  {capitalizeFirstLetter(currentEvent?.event_name?.trim())}{" "}
                  {currentEvent?.year.split(" to ")[0]}
                </h2>
                {!currentEvent?.scores_visibility ? (
                  <p className="text-[#94a4fd]">
                    Score visibility is currently disabled by the competition
                    owner. Please check back later.
                  </p>
                ) : (
                  <p className="text-[#94a4fd]">
                    Scores visibility is enabled by competition owner
                  </p>
                )}
              </div>

              <div className="h-[30px] flex mr-4">
                <div className="h-full w-fit p-1 bg-[#333333] rounded-l-lg">
                  <img
                    alt="user"
                    src="/images/Search.png"
                    className="w-5 h-5 cursor-pointer"
                  />
                </div>
                <input
                  type="text"
                  placeholder="Entry search"
                  onChange={(e) => handleSearch(e)}
                  value={searchTerm}
                  className="w-[130px] text-[.9em] bg-[#333333] rounded-r-lg outline-none pr-2 py-1"
                />
              </div>
              <button
                onClick={handleShowPrint}
                disabled={
                  sortedCategories?.length === 0 ||
                  !currentEvent?.scores_visibility ||
                  searchTerm
                }
                className={`text-[.85rem] px-5 py-1 mr-2 rounded-full text-[#1c1c1c] transition-all duration-300 ${
                  sortedCategories?.length === 0 ||
                  !currentEvent?.scores_visibility ||
                  searchTerm
                    ? "bg-[#94a4fd]/50 cursor-not-allowed"
                    : "bg-[#94a4fd] hover:bg-[#94a4fd]/60"
                }`}
              >
                Print
              </button>
            </div>

            {searchTerm && (
              <div className={`w-full relative`}>
                <div className="overflow-x-hidden">
                  <div
                    onClick={() => setsearchTerm("")}
                    className="w-fit py-1 px-3 text-[.85rem] mb-3 flex justify-center items-center bg-white/80 rounded-full cursor-pointer text-black/80"
                  >
                    Close search
                  </div>
                  <h2 className="p-3 bg-white/10 border border-white/20 rounded-lg">
                    Search result: {searchEntries?.length} matching entries
                  </h2>

                  <div className="w-full mt-8 overflow-x-auto">
                    {searchedDivisions?.map((category, index) => {
                      const categoryEntries = searchEntries?.filter(
                        (itm) =>
                          itm?.age_division.toLowerCase() ===
                          category.toLowerCase()
                      );

                      return (
                        <ScoresCategory
                          key={index}
                          category={category}
                          entries={categoryEntries}
                          studioData={studioData}
                          eventScores={eventScores}
                          calculatePosition={calculatePosition}
                          categoryEntries={categoryEntries}
                          allEntries={allEntries}
                          allCategories={allCategories}
                          currentEvent={currentEvent}
                          showNotes={showNotes}
                          setShowNotes={setShowNotes}
                          calculateAverageScore={calculateAverageScore}
                          calculateTotalScore={calculateTotalScore}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            {!searchTerm && (
              <>
                <div className="w-full flex flex-wrap gap-5 mb-10 mt-12">
                  <div className="">
                    <h2 className="mb-2">Medal Breakdown</h2>
                    <div className="w-full md:min-w-[250px] border border-white/80 rounded-md p-3">
                      {currentOrg?.awards?.map((itm, idx) => {
                        return (
                          <div
                            key={idx}
                            className="w-full flex justify-between"
                          >
                            <p className="w-[100px]">
                              {capitalizeFirstLetter(itm?.awardName)}
                            </p>
                            {/* <p className="w-8">"{itm?.code}"</p> */}
                            <p>
                              [{itm?.minScore} - {itm?.maxScore}]
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="">
                    <h2 className="mb-2">Legend</h2>
                    <div className="w-full md:min-w-[300px] border border-white/80 rounded-md p-3">
                      <p>T/F = Technique and Foundation</p>
                      <p>C/O = Creativity and Originality</p>
                      <p>S/D = Staging and Dynamics</p>
                      <p>E/D = Execution and Difficulty</p>
                      <p>P/E = Performance and Entertainment</p>
                      <p>DED = Deduction</p>
                      <p>DIV RANK = Division Rank</p>
                    </div>
                  </div>
                </div>

                {status === "loading" && (
                  <div className="w-full h-[200px] flex justify-center items-center border border-white/10 rounded-lg">
                    <PulseLoader color="#94a4fd" size={10} />
                  </div>
                )}

                {status !== "loading" && currentEvent?.rank_visibility && (
                  <p className="">
                    NOTE: Division Rank column only shows the ranking of the top
                    20% of each age division. <br /> (e.g. If an age division
                    contains 100 entries, it will only show ranking for those
                    that rank in the top 20)
                  </p>
                )}
                {status !== "loading" && (
                  <section id="scores" className="w-full flex gap-2">
                    <div className="w-full min-h-[400px] text-[.9em] py-3 overflow-auto">
                      {divisions?.length > 0 ? (
                        <div className="min-w-[1100px]">
                          {divisions?.map((category, index) => {
                            const categoryEntries = myEntries?.filter(
                              (itm) =>
                                itm?.age_division.toLowerCase() ===
                                category.toLowerCase()
                            );

                            return (
                              <ScoresCategory
                                key={index}
                                category={category}
                                entries={categoryEntries}
                                studioData={studioData}
                                eventScores={eventScores}
                                calculatePosition={calculatePosition}
                                categoryEntries={categoryEntries}
                                allEntries={allEntries}
                                allCategories={allCategories}
                                currentEvent={currentEvent}
                                showNotes={showNotes}
                                setShowNotes={setShowNotes}
                                calculateAverageScore={calculateAverageScore}
                                calculateTotalScore={calculateTotalScore}
                              />
                            );
                          })}
                        </div>
                      ) : (
                        <div className="w-full h-[150px] flex justify-center items-center border border-white/20 rounded-lg">
                          No entries yet..
                        </div>
                      )}
                    </div>
                  </section>
                )}
              </>
            )}
          </div>

          <ScrollToTop />
          <Footer />
        </div>
      )}

      {showPrint && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start text-white/80">
          <div className="w-full sm:w-[550px] min-h-fit p-4 md:px-5 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-start scale shadow-lg">
            <p className="w-full text-[1.5rem] font-bold text-white pb-3">
              Print Options
            </p>

            <p>Select ALL columns you would like to include in the print out</p>

            <div className="w-full h-[220px] flex flex-col flex-wrap gap-3 my-5">
              {checkboxes?.map((checkbox, idx) => (
                <div className="flex items-center relative" key={idx}>
                  {!currentEvent?.rank_visibility &&
                    checkbox === "division_rank" && (
                      <div className="w-full h-full absolute top-0 left-0 bg-transparent"></div>
                    )}
                  <input
                    type="checkbox"
                    id={checkbox}
                    className="mr-2 cursor-pointer"
                    checked={checkboxData?.[checkbox]}
                    onChange={(e) => {
                      handleCheckboxChange(e, checkboxData[idx]);
                    }}
                  />
                  <label
                    htmlFor={checkbox}
                    className={`cursor-pointer ${
                      !currentEvent?.rank_visibility &&
                      checkbox === "division_rank"
                        ? "text-white/20"
                        : "text-white/50"
                    }`}
                  >
                    {checkbox === "technique"
                      ? "technique and foundation"
                      : checkbox === "creativity"
                      ? "creativity and originality"
                      : checkbox === "staging"
                      ? "staging and dynamics"
                      : checkbox === "execution"
                      ? "execution and difficulty"
                      : checkbox === "performance"
                      ? "performance and entertainment"
                      : checkbox}
                  </label>
                </div>
              ))}
            </div>

            <div className="mt-4 w-full text-right">
              <button
                onClick={handleShowPrint}
                className="text-[.85rem] px-8 py-1 mr-4 rounded-full bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  localStorage.setItem(
                    "checkboxData",
                    JSON.stringify(checkboxData)
                  );
                  window.open(
                    `/studio/event/${event_name.replace(
                      / /g,
                      "-"
                    )}/scores/print`,
                    "_blank"
                  );
                  handleShowPrint();
                }}
                disabled={allfalse?.length === 0}
                className={`text-[.9rem] px-8 py-1 rounded-full text-[#1c1c1c] transition-all duration-300 ${
                  allfalse?.length > 0
                    ? "bg-[#94a4fd] hover:bg-[#94a4fd]/60"
                    : "cursor-not-allowed bg-[#94a4fd]/40 hover:bg-[#94a4fd]/40"
                }`}
              >
                Print
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Tabulator;
