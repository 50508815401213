/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import ScrollToTop from "./ScrollToTop";
import { useEffect, useState } from "react";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { useDispatch } from "react-redux";
import { clearSuccess, sendContact } from "redux/danceStudio/danceStudioSlice";

const Contact = () => {
  const { openNotifsBoard } = useSelector((state) => state.danceStudio);

  const { sendContactSuccess, sendContactPending } = useSelector(
    (state) => state.danceStudio
  );

  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { data: studioData } = useFirestoreQueryDoc("studio_owners", user?.uid);

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });

  function handleChange(e) {
    const { id, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [id]: value,
      };
    });
  }

  function handleDiscard() {
    setFormData({ title: "", description: "" });
  }

  function handleSubmit() {
    const data = {
      senderEmail: studioData?.email,
      studioName: studioData?.studioName,
      title: formData?.title,
      description: formData?.description,
    };
    dispatch(sendContact(data));
  }

  useEffect(() => {
    if (sendContactSuccess?.success) {
      handleDiscard();
      setTimeout(() => {
        dispatch(clearSuccess());
      }, 3000);
    }
  }, [sendContactSuccess, dispatch]);

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}
      <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[310px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
        <div className="mb-auto">
          <div className="w-full p-3 md:p-5 md:max-w-[85%] lg:max-w-[70%] md:min-w-[450px] bg-[#282929] rounded-lg flex flex-col gap-5 mb-auto text-white/80">
            {/* Heading */}
            <h2 className="text-white/80 text-[1.25rem] font-medium">
              Contact
            </h2>

            <p className="">
              If you have any questions please do not hesitate to reach out.
            </p>

            <form>
              <div className="w-full bg-black rounded-lg p-4">
                <input
                  type="text"
                  id="title"
                  className="w-full outline-none px-2 py-1 placeholder:text-white/50 bg-black/80 text-white/80"
                  placeholder="Title"
                  value={formData?.title}
                  onChange={handleChange}
                />
                <textarea
                  type="text"
                  id="description"
                  className="w-full h-[100px] outline-none px-2 py-1 placeholder:text-white/30 bg-black/80 text-white/80"
                  placeholder="Description"
                  value={formData?.description}
                  onChange={handleChange}
                />
              </div>
            </form>
            {sendContactSuccess?.success && (
              <p className="text-white">Message sent Successfully!</p>
            )}
            <div className="w-full mx-auto mt-7 text-end">
              <button
                onClick={handleDiscard}
                className="text-[.85rem] px-5 py-1 mr-4 rounded-lg bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Discard
              </button>
              <button
                onClick={(e) => {
                  handleSubmit(e);
                }}
                disabled={
                  !formData?.title ||
                  !formData?.description ||
                  sendContactPending
                }
                className={`text-[.9rem] px-5 py-1 rounded-lg bg-[#94a4fd] hover:bg-[#94a4fd]/80 text-[#1c1c1c] transition-all duration-300 ${
                  !formData?.title || !formData?.description
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
              >
                {sendContactPending ? "Submitting.." : "Submit"}
              </button>
            </div>
          </div>
        </div>
        <ScrollToTop />
        <Footer />
      </div>
    </>
  );
};

export default Contact;
