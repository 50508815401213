import React from "react";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const UserDancerTableRow = ({
  row,
  calculateAge,
  arranged,
  currentEvent,
  convertDateFormat,
  eventEntries,
  calculateAge2,
}) => {
  // console.log("entries", eventEntries);
  function collectDancersSelectedItems(originalArray) {
    const collectedItems = [];

    originalArray?.forEach((item) => {
      const dancersSelected = item.dancers_selected;
      collectedItems.push(...dancersSelected);
    });

    return collectedItems;
  }

  const myDancerExists = collectDancersSelectedItems(eventEntries)?.filter(
    (it) => it?.id === row?.id
  );

  // console.log("eventEntries", eventEntries);

  return (
    <>
      <tr className="border-y border-white/10 hover:bg-[#94a4fd]/10 relative whitespace-nowrap transition-all duration-500">
        <td className="flex items-center mt-2">
          <div className="w-6 h-6 rounded-full border-4 border-white/60"></div>
          {capitalizeFirstLetter(row?.first_name)}{" "}
          {capitalizeFirstLetter(row?.last_name)}
        </td>
        <td>
          {row?.gender === "female"
            ? "F"
            : row?.gender === "male"
            ? "M"
            : row?.gender === "transgender"
            ? "T"
            : row?.gender === "non-binary"
            ? "NB"
            : "P"}
        </td>
        <td>{row?.classification}</td>
        {/* <td>{row?.special_tags}</td> */}
        <td>{convertDateFormat(row?.date_of_birth)}</td>
        {/* <td>{calculateAge(convertDateFormat(row?.date_of_birth))}</td> */}
        <td>
          {calculateAge2(
            convertDateFormat(row?.date_of_birth),
            currentEvent?.year
          )}
        </td>
        <td>{capitalizeFirstLetter(row?.studio_name)}</td>
        <td>
          <p className="max-w-[200px] whitespace-nowrap truncate">
            {row?.notes ? row?.notes : "N/A"}
          </p>
        </td>
        <td>
          <div className="flex items-center">
            <img
              alt="arrow"
              src="/images/CalendarBlank.png"
              className="w-6 h-6"
            />
            {row?.last_update}
          </div>
        </td>
        <td>
          <div
            className={`flex items-center ${
              myDancerExists?.length === 0
                ? "text-yellow-200"
                : myDancerExists?.length > 0
                ? "text-green-300"
                : "text-red-400"
            } `}
          >
            <div
              className={`w-1 h-1 rounded-full ${
                myDancerExists?.length === 0
                  ? "bg-yellow-200"
                  : myDancerExists?.length > 0
                  ? "bg-green-300"
                  : "bg-red-400"
              }`}
            ></div>
            {/* {myDancerExists?.length > 0 && (
              <span className="font-medium">{myDancerExists?.length}</span>
            )} */}
            {myDancerExists?.length === 0
              ? "Not Scheduled"
              : myDancerExists?.length > 0
              ? myDancerExists?.length + " Scheduled"
              : "Issue"}
          </div>
        </td>
      </tr>
    </>
  );
};

export default UserDancerTableRow;
