// import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import localStorage from "redux-persist/es/storage";
import { addEst } from "redux/danceStudio/danceStudioSlice";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const EventCard = ({ item, competitionName, compId, setPopup, popup }) => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const startTime = moment(item?.days[0].startTime).local().format("MMM D");

  const endTime = moment(item?.days[item?.days?.length - 1].endTime)
    .local()
    .format("MMM D");

  //to get all entries
  const { data: allEntries } = useQueryAllSubCollectionDocs(
    "competitions",
    compId,
    "entries"
  );

  //to get competition name
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { data: studioData } = useFirestoreQueryDoc("studio_owners", user?.uid);

  const eventEntries = allEntries?.filter(
    (each) =>
      each?.event_uid === item?.event_uid &&
      each?.studio_selected[0] === studioData?.studioName
  );

  const entriesWithMusic = eventEntries?.filter((x) => x?.music_url);

  const accessArray = item?.access?.filter(
    (itm) => itm?.studio_id === user?.uid
  );

  const isEstEntriesAsked = !accessArray[0]?.estimated_entries ? false : true;

  function linkToEvent() {
    if (isEstEntriesAsked) {
      navigate(`/studio/event/${item?.event_name.replace(/ /g, "-")}`);
      localStorage.setItem("event_name", item?.event_name);
    } else {
      setPopup(item);
    }
  }

  const [estEntries, setEstEntries] = useState("");
  const dispatch = useDispatch();

  return (
    <>
      {" "}
      <div className="min-w-[200px] min-h-[260px] max-w-full bg-[#282929] pl-6 pr-4 py-4 flex flex-col rounded-lg text-white">
        <p className="w-full text-end text-[1.1rem] font-bold">
          {item?.days?.length === 1
            ? `${startTime}, ${item?.year}`
            : `${startTime} - ${endTime} ${item?.year}`}
        </p>
        <div className={`${item?.locked && "mb-auto"} text-[.85rem]`}>
          <p>
            {capitalizeFirstLetter(competitionName)}:{" "}
            {capitalizeFirstLetter(item?.event_name)}
          </p>
          <p>{capitalizeFirstLetter(item?.venue)}</p>
          <p>{capitalizeFirstLetter(item?.address)}</p>
          <p>
            {capitalizeFirstLetter(item?.city)},{" "}
            {capitalizeFirstLetter(item?.state)}
          </p>
          <p>
            {capitalizeFirstLetter(item?.postal_code)},{" "}
            {capitalizeFirstLetter(item?.country)}
          </p>
        </div>
        {
          <div className="mt-5">
            <div className="text-[.85rem] font-light flex gap-5 items-center mb-3">
              <div className="flex gap-1 items-center">
                <div className="w-[6px] h-[6px] rounded-full bg-[#94a4fd]"></div>
                <p>My Entries Submitted</p>
              </div>
              <p className="text-white/50">{eventEntries?.length}</p>
            </div>
            <div className="text-[.85rem] font-light flex gap-5 items-center mb-3">
              <div className="flex gap-1 items-center">
                <div className="w-[6px] h-[6px] rounded-full bg-indigo-200"></div>
                <p>My Music Submitted</p>
              </div>
              <p>{entriesWithMusic?.length}</p>
            </div>
          </div>
        }

        <div className="w-full flex justify-end items-center mt-auto">
          <button
            onClick={linkToEvent}
            disabled={accessArray?.length === 0}
            className={`text-[.85rem] px-5 py-1 mr-2 bg-[#94a4fd] rounded-full hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300  ${
              accessArray?.length === 0
                ? "bg-[#94a4fd]/60 cursor-not-allowed"
                : "bg-[#94a4fd]"
            }`}
          >
            {accessArray?.length === 0 ? "Locked" : "Select Event"}
          </button>

          {accessArray?.length === 0 ? (
            <img alt="arrow" src="/images/LockSimple.png" className="w-6 h-6" />
          ) : (
            <img
              alt="arrow"
              src="/images/LockSimpleOpen.png"
              className="w-6 h-6"
            />
          )}
        </div>
      </div>
      {popup === item && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-start scale shadow-lg">
            <p className="w-full text-[1rem] text-white pb-3 border-b border-[#c5c4c4]/50 text-center">
              How many entries do you estimate submitting to this competition?
            </p>

            <input
              type="number"
              className="w-full px-3 py-2 border border-[#94a4fd] rounded-md outline-none bg-inherit text-white"
              placeholder="E.g 150"
              value={estEntries}
              onChange={(e) => setEstEntries(e.target.value)}
            />

            <div className="mt-4 w-full text-center">
              <button
                onClick={() => setPopup(null)}
                className="text-[.85rem] px-8 py-2 mr-4 rounded-md bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  dispatch(
                    addEst({
                      compId,
                      event_uid: item?.event_uid,
                      uid: user?.uid,
                      estEntries,
                    })
                  )?.then(() => {
                    navigate(
                      `/studio/event/${item?.event_name.replace(/ /g, "-")}`
                    );
                    localStorage.setItem("event_name", item?.event_name);
                  });
                }}
                disabled={!estEntries}
                className={`text-[.9rem] px-8 py-2 rounded-md text-[#1c1c1c] transition-all duration-300 ${
                  estEntries
                    ? "bg-[#94a4fd] hover:bg-[#94a4fd]/60"
                    : "cursor-not-allowed bg-[#94a4fd]/40 hover:bg-[#94a4fd]/40"
                }`}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EventCard;
