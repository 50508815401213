/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import dancersData from "../dummyData/dancersData.json";
import ReactPaginate from 'react-paginate';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  // getEntries,
  deleteEntries,
  toggleEditEntries,
  getDancers,
  clearSuccess,
  overrideAge,
  // getEntries,
} from 'redux/danceStudio/danceStudioSlice';
import EntriesTableRow from './EntriesTableRow';
import { PulseLoader } from 'react-spinners';
import { deleteEntry } from 'redux/routineScheduling/routineSchedulingSlice';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { sendAgeOverrideEmailService } from 'services/organizerService';

const EntriesTable = ({
  eventEntries,
  searchResults,
  searchQuery,
  status,
  sortedCategories,
  currentEvent,
  hoveredRowIndex,
  setHoveredRowIndex,
  sortDown,
  studioData,
}) => {
  const { deleteEntryPending, deleteEntrySuccess } = useSelector(
    (state) => state.routineScheduling
  );

  const { overrideAgePending, overrideAgeSuccess } = useSelector(
    (state) => state.danceStudio
  );

  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );
  const { compId, eligibilityDivisions, competitionName, email } =
    danceStudioCompData.value;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDancers({ compId: compId.replace(/ /g, ''), uid: user?.uid }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openAge, setOpenAge] = useState(false);
  const [selectedAge, setSelectedAge] = useState('');

  let arranged = eventEntries && [...eventEntries];

  // eslint-disable-next-line array-callback-return
  arranged?.sort((a, b) => {
    const parseCustomDate = (dateString) => {
      // Replace the comma with an empty string to remove it
      const formattedDate = dateString.replace(',', '');

      // Parse the date using the Date constructor
      const date = new Date(formattedDate);

      // Check if the date is valid
      if (!isNaN(date)) {
        return date.getTime();
      } else {
        // Handle cases where the date couldn't be parsed
        return 0;
      }
    };

    const dateA = parseCustomDate(a.last_update);
    const dateB = parseCustomDate(b.last_update);

    if (sortDown === 'oldest') {
      return dateA - dateB; // Oldest to newest
    } else if (sortDown === 'latest') {
      return dateB - dateA; // Newest to oldest
    } else if (sortDown === 'alphabet') {
      const nameA = a.entry_name.toUpperCase();
      const nameB = b.entry_name.toUpperCase();

      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    } else if (sortDown === 'age_div') {
      const age_divA = a.age_division.toUpperCase();
      const age_divB = b.age_division.toUpperCase();

      return age_divA < age_divB ? -1 : age_divA > age_divB ? 1 : 0;
    } else if (sortDown === 'class') {
      const classA = a.classification.toUpperCase();
      const classB = b.classification.toUpperCase();

      return classA < classB ? -1 : classA > classB ? 1 : 0;
    } else if (sortDown === 'entry_type') {
      const entryTypeA = a.entryType.toUpperCase();
      const entryTypeB = b.entryType.toUpperCase();

      return entryTypeA < entryTypeB ? -1 : entryTypeA > entryTypeB ? 1 : 0;
    } else if (sortDown === 'genre') {
      const genreA = a.genre.toUpperCase();
      const genreB = b.genre.toUpperCase();

      return genreA < genreB ? -1 : genreA > genreB ? 1 : 0;
    } else if (sortDown === 'status') {
      // Sort by music_url
      const musicURLA = a.music_url ? 'No issues' : 'Music not uploaded';
      const musicURLB = b.music_url ? 'No issues' : 'Music not uploaded';
      return musicURLA.localeCompare(musicURLB);
    }
  });

  // eslint-disable-next-line no-unused-vars
  const [entryIndex, setEntryIndex] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);

  const [confirmDelete, setConfirmDelete] = useState(false);
  function toggleDelete() {
    setConfirmDelete((prev) => !prev);
  }
  function closeEntryMod() {
    setSuccessDelete(false);
    setConfirmDelete(false);
  }

  const [confirmOverride, setConfirmOverride] = useState(null);
  function toggleOverride(item) {
    setConfirmOverride((prev) => (prev ? null : item));
  }

  useEffect(() => {
    if (deleteEntrySuccess) {
      setConfirmDelete(false);
      setSuccessDelete(true);
      const timeoutId = setTimeout(() => {
        setSuccessDelete(false);
        closeEntryMod();
        dispatch(clearSuccess());
        window.location.reload();
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteEntrySuccess]);

  const [deleteId, setDeleteId] = useState(null);

  const [showNewRow, setShowNewRow] = useState(false);

  // console.log(deleteId);

  //to handle registrations table pagination
  const [eventEntriesPag, seteventEntriesPag] = useState();
  useEffect(() => {
    seteventEntriesPag(arranged);
  }, [eventEntries]);

  const [pageNumber, setPageNumber] = useState(0);

  const rowPerPage = 8;
  const pagesVisited = pageNumber * rowPerPage;

  // console.log("arranged", arranged);

  const displayRows = eventEntriesPag
    ?.slice(pagesVisited, pagesVisited + rowPerPage)
    ?.map((row, index) => {
      return (
        <EntriesTableRow
          key={index}
          row={row}
          setEntryIndex={setEntryIndex}
          toggleDelete={toggleDelete}
          toggleEditEntries={toggleEditEntries}
          confirmDelete={confirmDelete}
          deleteEntries={deleteEntries}
          deleteEntryPending={deleteEntryPending}
          compId={compId}
          setDeleteId={setDeleteId}
          currentEvent={currentEvent}
          sortedCategories={sortedCategories}
          hoveredRowIndex={hoveredRowIndex}
          setHoveredRowIndex={setHoveredRowIndex}
          confirmOverride={confirmOverride}
          toggleOverride={toggleOverride}
          setShowNewRow={setShowNewRow}
        />
      );
    });

  const displaySearchedRows = searchResults
    ?.slice(pagesVisited, pagesVisited + rowPerPage)
    ?.map((row, index) => {
      return (
        <EntriesTableRow
          key={index}
          row={row}
          setEntryIndex={setEntryIndex}
          toggleDelete={toggleDelete}
          toggleEditEntries={toggleEditEntries}
          confirmDelete={confirmDelete}
          deleteEntries={deleteEntries}
          deleteEntryPending={deleteEntryPending}
          compId={compId}
          setDeleteId={setDeleteId}
          currentEvent={currentEvent}
          sortedCategories={sortedCategories}
          hoveredRowIndex={hoveredRowIndex}
          setHoveredRowIndex={setHoveredRowIndex}
          toggleOverride={toggleOverride}
          setShowNewRow={setShowNewRow}
          confirmOverride={confirmOverride}
        />
      );
    });

  const pageCount = Math.ceil(eventEntriesPag?.length / rowPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    if (overrideAgeSuccess?.success) {
      setTimeout(() => {
        dispatch(clearSuccess());
        setConfirmOverride(null);
      }, 3000);
    }
  }, [overrideAgeSuccess]);

  return (
    <>
      <div className="w-full min-h-[150px] h-full mt-5 pb-10 overflow-auto">
        {!searchQuery && (
          <table className="min-w-[600px] w-full text-[.85rem] relative table2">
            <thead className="text-white/40 text-left border-b border-white/30">
              <tr className="whitespace-nowrap">
                <th>Entry Name</th>
                <th>Age Div</th>
                <th>Classification</th>
                <th>Genre</th>
                <th>Entry Type</th>
                <th>Special Tags</th>
                <th>Last Update</th>
                <th>Scheduling Status</th>
              </tr>
            </thead>
            {eventEntries?.length > 8 ? (
              <tbody className="text-white/80">{displayRows}</tbody>
            ) : (
              <tbody>
                {eventEntries?.map((row, index) => {
                  return (
                    <EntriesTableRow
                      key={index}
                      row={row}
                      setEntryIndex={setEntryIndex}
                      toggleDelete={toggleDelete}
                      toggleEditEntries={toggleEditEntries}
                      confirmDelete={confirmDelete}
                      deleteEntries={deleteEntries}
                      deleteEntryPending={deleteEntryPending}
                      compId={compId}
                      setDeleteId={setDeleteId}
                      currentEvent={currentEvent}
                      sortedCategories={sortedCategories}
                      hoveredRowIndex={hoveredRowIndex}
                      setHoveredRowIndex={setHoveredRowIndex}
                      confirmOverride={confirmOverride}
                      toggleOverride={toggleOverride}
                      setShowNewRow={setShowNewRow}
                    />
                  );
                })}
              </tbody>
            )}
          </table>
        )}

        {searchQuery && searchResults && (
          <table className="min-w-[600px] w-full text-[.85rem] relative table2">
            <thead className="text-white/40 text-left border-b border-white/30">
              <tr className="whitespace-nowrap">
                <th>Entry Name</th>
                <th>Age Div</th>
                <th>Classification</th>
                <th>Genre</th>
                <th>Entry Type</th>
                <th>Special Tags</th>
                <th>Last Update</th>
                <th>Scheduling Status</th>
              </tr>
            </thead>
            {arranged?.length > 8 ? (
              <tbody className="text-white/80">{displaySearchedRows}</tbody>
            ) : (
              <tbody>
                {searchResults?.map((row, index) => {
                  return (
                    <EntriesTableRow
                      key={index}
                      row={row}
                      setEntryIndex={setEntryIndex}
                      toggleDelete={toggleDelete}
                      toggleEditEntries={toggleEditEntries}
                      confirmDelete={confirmDelete}
                      deleteEntries={deleteEntries}
                      deleteEntryPending={deleteEntryPending}
                      compId={compId}
                      setDeleteId={setDeleteId}
                      currentEvent={currentEvent}
                      sortedCategories={sortedCategories}
                      hoveredRowIndex={hoveredRowIndex}
                      setHoveredRowIndex={setHoveredRowIndex}
                      confirmOverride={confirmOverride}
                      toggleOverride={toggleOverride}
                      setShowNewRow={setShowNewRow}
                    />
                  );
                })}
              </tbody>
            )}
          </table>
        )}
        {searchQuery && searchResults?.length === 0 && (
          <p className="w-full py-8 text-center">No results found</p>
        )}
        {status === 'loading' && (
          <div className="bg-[#1c1c1c] w-full min-h-[200px] flex justify-center items-center">
            <PulseLoader color="#94a4fd" size={10} />
          </div>
        )}
        {eventEntries?.length < 1 && status !== 'loading' && (
          <p className="text-center pt-[100px] text-[1rem] text-white/50">
            No entries yet...
          </p>
        )}
        {eventEntries?.length > 0 && !searchQuery && (
          <div className="text-[.75rem] text-white/40 mt-4 lg:">
            Scroll horizontally to view more, if needed
          </div>
        )}
      </div>
      {/* pagination */}
      {(eventEntriesPag?.length > 8 || searchResults?.length > 8) && (
        <div className="flex gap-2 mt-4 justify-end">
          <ReactPaginate
            previousLabel={
              <img
                alt="user"
                src="/images/leftArrow.png"
                className="w-6 h-6 cursor-pointer rounded-lg hover:bg-white/10"
              />
            }
            nextLabel={
              <img
                alt="user"
                src="/images/rightArrow.png"
                className="w-6 h-6 cursor-pointer rounded-lg hover:bg-white/10"
              />
            }
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={
              'h-6 flex items-center gap-2 mt-4 justify-end text-[.95rem] pag'
            }
            activeClassName={'rounded-lg bg-inherit border-2 border-[#94a4fd]'}
          />
        </div>
      )}

      {confirmDelete && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/80 flex justify-center mod">
          <div className="w-full sm:w-[450px] h-fit p-5 bg-[#282929] rounded-lg flex flex-col gap-5 justify-center items-center scale shadow-lg box">
            <div className="w-full text-[.85em] sm:text-[1rem] text-white pb-3 border-b border-[#c5c4c4]/50 relative">
              <p>
                Confirm Delete Entry:{' '}
                <span className="font-bold">
                  {capitalizeFirstLetter(deleteId?.entry_name)}
                </span>
              </p>
              <div
                onClick={() => toggleDelete()}
                className="w-5 h-5 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-[-10px] md:top-0 right-[-10px] md:right-3"
              >
                <img
                  alt="user"
                  src="/images/icons8-close-50.png"
                  className="w-3 h-3"
                />
              </div>
            </div>
            <button
              onClick={() => {
                dispatch(
                  deleteEntry({
                    compId: compId,
                    doc_id: deleteId?.doc_id,
                    category_name: deleteId?.category_name,
                    event_uid: deleteId?.event_uid,
                    currentEvent,
                  })
                );
              }}
              className="w-full px-2 py-3 rounded-lg bg-red-500 hover:bg-red-500/60 text-white"
              disabled={deleteEntryPending}
            >
              {deleteEntryPending ? 'Deleting...' : `Delete`}
            </button>
          </div>
        </div>
      )}

      {confirmOverride && (
        <div
          onClick={() => setOpenAge(false)}
          className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/80 flex justify-center mod"
        >
          <div className="w-full sm:w-[450px] h-fit p-5 bg-[#282929] rounded-lg flex flex-col gap-5 justify-center items-center scale shadow-lg box">
            <div className="w-full text-[.85em] sm:text-[1rem] text-white pb-3 border-b border-[#c5c4c4]/50 relative">
              <p>
                Confirm Override Age Division, Entry:{' '}
                <span className="font-bold">
                  {capitalizeFirstLetter(confirmOverride?.entry_name)}
                </span>
              </p>
              <div
                onClick={() => {
                  toggleOverride();
                  setSelectedAge('');
                }}
                className="w-5 h-5 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-[-13px] right-[-10px]"
              >
                <img
                  alt="user"
                  src="/images/icons8-close-50.png"
                  className="w-3 h-3"
                />
              </div>
            </div>
            <p>Select new age division</p>

            <div
              onClick={(e) => {
                e.stopPropagation();
                setOpenAge((prev) => !prev);
              }}
              className="w-full relative bg-black/80 rounded-lg p-3 cursor-pointer"
            >
              <div className="flex justify-between items-center">
                <p>
                  {selectedAge ? capitalizeFirstLetter(selectedAge) : 'Select'}
                </p>
                <img
                  alt=""
                  src="/images/leftArrow.png"
                  className="w-7 h-7 rotate-[270deg]"
                />
              </div>
              {openAge && (
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="w-full p-2 bg-[#0c0c0d] outline-none text-white/80 absolute left-[0px] top-10 rounded-md border border-white/50 z-10"
                >
                  {eligibilityDivisions?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        value={item?.divisionName}
                        onClick={() => {
                          setSelectedAge(item?.divisionName);
                          setOpenAge(false);
                        }}
                        className="w-full hover:bg-blue-500/80 px-2"
                      >
                        {capitalizeFirstLetter(item?.divisionName)}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <button
              onClick={() => {
                if (selectedAge) {
                  dispatch(
                    overrideAge({
                      compId,
                      entry_name: confirmOverride?.entry_name,
                      studio_name: studioData?.studioName,
                      selectedAge,
                    })
                  ).then(() =>
                    sendAgeOverrideEmailService({
                      userEmail: email,
                      compName: capitalizeFirstLetter(competitionName),
                      studioName: capitalizeFirstLetter(studioData?.studioName),
                      studioEmail: studioData?.email,
                      entryName: confirmOverride?.entry_name,
                      age_division: selectedAge,
                    })
                  );
                }
              }}
              className={`w-fit px-6 py-1 rounded-full text-black text-[.85rem] ${
                selectedAge
                  ? 'bg-[#94a4fd] hover:bg-[#94a4fd]/60'
                  : 'cursor-not-allowed bg-[#94a4fd]/60'
              }`}
              disabled={!selectedAge || overrideAgePending}
            >
              {overrideAgePending ? 'Requesting...' : `Request`}
            </button>
            {overrideAgeSuccess && (
              <p className="text-white">Request sent successfully!</p>
            )}
          </div>
        </div>
      )}

      {successDelete && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-5 md:py-8 md:px-10 bg-[#282929] rounded-lg flex gap-5 justify-start items-center scale shadow-lg relative">
            <div
              onClick={() => {
                closeEntryMod();
              }}
              className="w-5 h-5 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-3 right-3"
            >
              <img
                alt="user"
                src="/images/icons8-close-50.png"
                className="w-3 h-3"
              />
            </div>
            <img
              alt="success"
              src="/images/icons8-checkmark-64.png"
              className="w-10 h-10"
            />
            <p className="text-[1rem] text-white">
              Successfully deleted entry!
            </p>
          </div>
        </div>
      )}

      {!currentEvent?.locked && showNewRow && (
        <div className="w-full h-full fixed top-0 left-0 bg-black/80 flex justify-center py-[100px] px-3 z-[50]">
          <div className="w-full md:w-[500px] h-fit rounded-xl bg-[#282929] px-4 py-5 flex flex-col gap-4 text-white scale relative">
            <div
              onClick={() => {
                setShowNewRow(null);
              }}
              className="w-5 h-5 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-3 right-3"
            >
              <img
                alt="user"
                src="/images/icons8-close-50.png"
                className="w-3 h-3"
              />
            </div>
            <h2 className="font-medium text-[1.2rem] text-center border-b border-white/80 pb-2">
              Entry: {`${capitalizeFirstLetter(showNewRow?.entry_name)}`}
            </h2>
            <div
              onClick={() => {
                dispatch(
                  toggleEditEntries({
                    editID: showNewRow?.doc_id,
                    // catID: myCategory?.doc_id,
                  })
                );
                setShowNewRow(null);
              }}
              className="w-full p-3 text-[#94a4fd] whitespace-nowrap cursor-pointer hover:bg-[#94a4fd]/30 bg-white/10 rounded-lg"
            >
              Edit Entry
            </div>
            <div
              onClick={() => {
                toggleOverride(showNewRow);
                setShowNewRow(null);
              }}
              className="w-full p-3 text-white/80 whitespace-nowrap cursor-pointer hover:bg-white/30 bg-white/10 rounded-lg"
            >
              Request Age Override
            </div>
            <div
              onClick={() => {
                toggleDelete();
                setDeleteId({
                  entry_name: showNewRow?.entry_name,
                  doc_id: showNewRow?.doc_id,
                  category_name: showNewRow?.category_name,
                  event_uid: showNewRow?.event_uid,
                });
                setShowNewRow(null);
              }}
              className="w-full p-3 text-red-400 whitespace-nowrap cursor-pointer hover:bg-red-500/30 bg-white/10 rounded-lg"
            >
              Delete Entry
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EntriesTable;
