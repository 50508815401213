import Footer from './components/Footer';
import Header from './components/Header';
import LeftSidebar from './components/LeftSidebar';
import RightSidebar from './components/RightSidebar';
import ScrollToTop from './ScrollToTop';
// import Spinner from "./components/Spinner";
import { useQueryAllDocs } from 'components/hooks/useQueryAllDocs';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setCompetitionssData } from 'redux/danceStudio/danceStudioSlice';
import AddDancerForm from './components/AddDancerForm';
import { useFirestoreQueryDoc } from 'components/hooks/useFirestoreQueryDoc';
import SettingsStudioOwner from './components/SettingsStudioOwner';
import SettingsStudioDetails from './components/SettingsStudioDetails';

const Settings = () => {
  const { addDancer, openNotifsBoard } = useSelector(
    (state) => state.danceStudio
  );

  // to call the query  all docs hook
  const { status, data } = useQueryAllDocs('competitions');

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCompetitionssData(data));
  }, [dispatch, data]);

  //to get studio data
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { data: studioData } = useFirestoreQueryDoc('studio_owners', user?.uid);

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}
      {!addDancer && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[310px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
              <h2>Account Settings</h2>
            </div>

            {/* Settings container */}
            <div className="w-full p-3 md:p-5 md:max-w-[85%] lg:max-w-[70%] md:min-w-[450px] min-h-[300px] bg-[#282929] rounded-lg flex flex-col gap-6 mb-auto text-white/80">
              <SettingsStudioOwner status={status} studioData={studioData} />

              <SettingsStudioDetails status={status} studioData={studioData} />
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {addDancer && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[330px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            {/* Add event form */}
            <div className="">
              <AddDancerForm />
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}
    </>
  );
};

export default Settings;
