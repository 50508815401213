/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { PulseLoader } from "react-spinners";
import {
  addBreak,
  toggleAddScheduleOverall,
} from "redux/routineScheduling/routineSchedulingSlice";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const AddScheduleOverallContainer = ({
  setBreakExists,
  setBreakNameErr,
  breakExists,
  bothExists,
  setOverallName,
  breakNameErr,
  overallName,
  user,
  currentEvent,
  allReports,
}) => {
  const { addBreakPending } = useSelector((state) => state.routineScheduling);
  const dispatch = useDispatch();

  // all grand presets
  const grandPresets = allReports?.filter(
    (itm) => itm?.id === "grand_preset"
  )[0]?.presets;

  const checkIfSelectionIsGrand = grandPresets?.filter(
    (i) => i?.grand_name === overallName
  )?.length;

  //all overall presets
  const overallPresets = allReports?.filter(
    (itm) => itm?.id === "overall_preset"
  )[0]?.presets;

  const checkIfSelectionIsOverall = overallPresets?.filter(
    (i) => i?.overall_name === overallName
  )?.length;

  //all custom presets
  const customPresets = allReports?.filter(
    (itm) => itm?.id === "custom_preset"
  )[0]?.presets;

  const checkIfSelectionIsCustom = customPresets?.filter(
    (i) => i?.custom_name === overallName
  )?.length;

  function setGrandOrOverall() {
    if (overallName) {
      return checkIfSelectionIsGrand === 1
        ? "grand"
        : checkIfSelectionIsOverall === 1
        ? "overall"
        : checkIfSelectionIsCustom === 1
        ? "custom"
        : "";
    }
  }

  return (
    <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
      <div className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-start scale shadow-lg">
        <div className="w-full text-[1rem] text-white text-center pb-3 border-b border-[#c5c4c4]/50 relative">
          <p>Add Overalls</p>
          <div
            onClick={() => {
              setBreakExists(false);
              setBreakNameErr(false);
              dispatch(toggleAddScheduleOverall());
            }}
            className="p-1 rounded-full bg-white/70 absolute right-2 top-0 cursor-pointer"
          >
            <img alt="" src="/images/icons8-close-50.png" className="w-4 h-4" />
          </div>
        </div>

        {addBreakPending ? (
          <div className="w-full min-h-[200px] flex justify-center items-center">
            <PulseLoader color="#94a4fd" size={10} />
          </div>
        ) : (
          <div className="mt-4 w-full sm:w-2/3 mx-auto flex flex-col items-center gap-4">
            {breakExists || bothExists ? (
              <p className="text-white/80 text-center w-full border border-red-400 bg-red-400/20 p-1 rounded-lg">
                You have added a break already!
              </p>
            ) : (
              <form className="w0full flex flex-col items-center gap-4">
                <div className="w-full bg-black/20 rounded-lg p-4 text0center">
                  <p className="text-white text-[.85rem] text-center mb-2">
                    Select Overalls
                  </p>
                  <select
                    id="overallSelect"
                    onChange={(e) => {
                      setOverallName(e.target.value);
                      setBreakNameErr(false);
                    }}
                    value={overallName}
                    className={`w-[250px] p-2 rounded-lg bg-black/10 ${
                      breakNameErr ? "border border-red-400" : ""
                    } outline-none text-white text-[.85rem]`}
                    style={{ backgroundColor: "#1c1c1c" }}
                  >
                    <option value="" hidden>
                      Select an option
                    </option>
                    {overallPresets?.map((item, index) => {
                      return (
                        <option
                          key={index}
                          value={
                            item?.grand_name
                              ? item?.grand_name
                              : item?.overall_name
                              ? item?.overall_name
                              : item?.custom_name
                          }
                        >
                          {capitalizeFirstLetter(
                            item?.grand_name
                              ? item?.grand_name
                              : item?.overall_name
                              ? item?.overall_name
                              : item?.custom_name
                          )}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="w-full bg-black/20 rounded-lg p-4 text0center">
                  <p className="text-white text-[.85rem] text-center mb-2">
                    Select Grand Overalls
                  </p>
                  <select
                    id="overallSelect"
                    onChange={(e) => {
                      setOverallName(e.target.value);
                      setBreakNameErr(false);
                    }}
                    value={overallName}
                    className={`w-[250px] p-2 rounded-lg bg-black/10 ${
                      breakNameErr ? "border border-red-400" : ""
                    } outline-none text-white text-[.85rem]`}
                    style={{ backgroundColor: "#1c1c1c" }}
                  >
                    <option value="" hidden>
                      Select an option
                    </option>
                    {grandPresets?.map((item, index) => {
                      return (
                        <option
                          key={index}
                          value={
                            item?.grand_name
                              ? item?.grand_name
                              : item?.overall_name
                              ? item?.overall_name
                              : item?.custom_name
                          }
                        >
                          {capitalizeFirstLetter(
                            item?.grand_name
                              ? item?.grand_name
                              : item?.overall_name
                              ? item?.overall_name
                              : item?.custom_name
                          )}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="w-full bg-black/20 rounded-lg p-4 text0center">
                  <p className="text-white text-[.85rem] text-center mb-2">
                    Select Custom Report
                  </p>
                  <select
                    id="overallSelect"
                    onChange={(e) => {
                      setOverallName(e.target.value);
                      setBreakNameErr(false);
                    }}
                    value={overallName}
                    className={`w-[250px] p-2 rounded-lg bg-black/10 ${
                      breakNameErr ? "border border-red-400" : ""
                    } outline-none text-white text-[.85rem]`}
                    style={{ backgroundColor: "#1c1c1c" }}
                  >
                    <option value="" hidden>
                      Select an option
                    </option>
                    {customPresets?.map((item, index) => {
                      return (
                        <option
                          key={index}
                          value={
                            item?.grand_name
                              ? item?.grand_name
                              : item?.overall_name
                              ? item?.overall_name
                              : item?.custom_name
                          }
                        >
                          {capitalizeFirstLetter(
                            item?.grand_name
                              ? item?.grand_name
                              : item?.overall_name
                              ? item?.overall_name
                              : item?.custom_name
                          )}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {breakNameErr && (
                  <p className="text-red-400 text-[.75rem] mt-1">
                    Please select an overall
                  </p>
                )}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (overallName) {
                      dispatch(
                        addBreak({
                          break: overallName,
                          event_uid: currentEvent?.event_uid,
                          compId: user?.uid,
                          break_type: `overalls_${setGrandOrOverall()}`,
                        })
                      );
                    } else {
                      setBreakNameErr(true);
                    }
                  }}
                  className="min-w-fit text-[.9rem] px-8 py-2 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
                >
                  Add
                </button>
              </form>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddScheduleOverallContainer;
