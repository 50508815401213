import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { PulseLoader } from "react-spinners";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const ViewAwardsCont = ({ toggleView, currentEvent, eventEntries }) => {
  const { status, data } = useQueryAllDocs("judges");

  const studios = currentEvent?.access;

  const entriesWithNotes = eventEntries?.filter(
    (x) =>
      Array.isArray(x?.notes) &&
      x?.notes?.length > 0 &&
      x?.notes?.filter((y) => y?.type === "judge special")?.length > 0
  );

  return (
    <div className="w-full md:w-[70%] md:min-w-[500px] lg:min-w-[600px]">
      <div className="w-full min-h-[200px] flex flex-col gap-4 bg-[#282929] px-4 md:px-7 py-10 rounded-xl text-white overflow-x-auto sm:overflow-x-hidden lg:overflow-x-auto">
        <h4 className="font-bold text-[1.1rem]">Special Awards Count</h4>

        {status === "loading" ? (
          <div className="w-full h-[100px] flex justify-center items-center">
            <PulseLoader color="#94a4fd" size={10} />
          </div>
        ) : (
          <table className="w-full text-[.85rem] lg:text-[.9rem]">
            <thead className="text-white/40 text-left border-b border-white/30 text-[.85rem]">
              <tr>
                <th>Studio Name</th>
                <th>
                  <p className="w-[50px]">Studio Code</p>
                </th>
                {data?.map((x) => (
                  <th key={x?.uid}>{capitalizeFirstLetter(x?.first_name)}</th>
                ))}
                <th>Total</th>
              </tr>
            </thead>
            <tbody className="text-white/80 text-[.85rem] lg:text-[.9rem] font-medium">
              {studios?.map((row, index) => {
                const assignedStudioLetter = (() => {
                  const matchingStudioIndex = studios?.findIndex(
                    (item) => item?.studio_name === row?.studio_name
                  );
                  if (matchingStudioIndex >= 0) {
                    return String.fromCharCode(65 + matchingStudioIndex);
                  } else {
                    return undefined;
                  }
                })();

                const myEntriesWithNote = entriesWithNotes?.filter(
                  (itm) =>
                    itm?.studio_selected[0]?.toLowerCase() ===
                    row?.studio_name?.toLowerCase()
                );

                function calcTotal() {
                  let arr = [];
                  myEntriesWithNote?.forEach((item) => {
                    const notes = item?.notes;
                    const special = notes?.filter(
                      (x) => x?.type === "judge special" && x?.awarded
                    );
                    arr.push(...special);
                  });
                  return arr;
                }

                return (
                  <tr
                    key={index}
                    className={`relative border-b border-white/10`}
                  >
                    <td>{capitalizeFirstLetter(row?.studio_name)}</td>
                    <td>{assignedStudioLetter}</td>
                    {data?.map((x) => {
                      const judgeNote = calcTotal()?.filter(
                        (y) => y?.judge_id === x?.uid
                      );
                      return (
                        <th key={x?.uid} className="">
                          <p className="font-medium text-left">
                            {judgeNote?.length}
                          </p>
                        </th>
                      );
                    })}
                    <td>{calcTotal()?.length}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      <div className="text-right">
        <button
          onClick={() => toggleView()}
          className="text-[.85rem] px-8 py-1 mt-10 whitespace-nowrap rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ViewAwardsCont;
