import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ScrollToTop } from ".";
import ReceiptPage from "./components/ReceiptPage";

const ReceiptPageContainer = () => {
  const { eventsData } = useSelector((state) => state.eventManagement);
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { event_name, studio_id } = useParams();

  const currentEvent = eventsData.filter(
    (item) => item.event_name.replace(/ /g, "-") === event_name
  )[0];

  const currentStudio = currentEvent?.access?.filter(
    (itm) => itm?.studio_id === studio_id
  )[0];

  const { data: allStudios } = useQueryAllDocs("studio_owners");
  const studioData = allStudios?.filter(
    (item) => item?.uid === currentStudio?.studio_id
  )[0];

  const { data } = useFirestoreQueryDoc("organizers", user?.uid);

  const { data: allPayment } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "payment_history"
  );

  const { data: compData } = useFirestoreQueryDoc("competitions", user?.uid);

  const [billingDetails, setbillingDetails] = useState([]);

  useEffect(() => {
    if (compData) {
      const { billing_details } = compData;
      setbillingDetails(billing_details);
    }
  }, [compData]);

  return (
    <>
      {/* the invoice page overlay */}
      <section className="w-full h-screen fixed top-0 left-0">
        <ReceiptPage
          compName={data?.competitionName}
          studioData={studioData}
          allPayment={allPayment}
          billingDetails={billingDetails}
        />
      </section>

      <ScrollToTop />
    </>
  );
};

export default ReceiptPageContainer;
