import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import {
  addScheduleCustomTag,
  removeScheduleCustomTag,
} from '../../../redux/organizerRegistration/organizerSlices';
import RemoveImg from '../../../assets/remove.png';

const ScheduleSchema = Yup.object().shape({
  customTags: Yup.string()
    .required('custom tag is Required')
    .matches(/^[^0-9]*$/, 'custom tag should not contain numbers'),
});

const CustomTag = () => {
  const dispatch = useDispatch();
  const { scheduling } = useSelector((state) => state.persistedReducer);

  const formik = useFormik({
    initialValues: {
      customTags: '',
    },
    validationSchema: ScheduleSchema,
    onSubmit: (values, { resetForm }) => {
      if (formik.isValid) {
        // Convert customTags to lowercase
        values.customTags = values.customTags.toLowerCase();
        dispatch(addScheduleCustomTag(values));
        resetForm();
      }
    },
  });

  // check if the customtag already exists
  const index = scheduling.customTags.findIndex(
    (customTag) => customTag.customTags === formik.values.customTags
  );

  if (index !== -1) {
    formik.errors.customTags = 'Custom tag already exists';
  }

  const handleRemove = (index) => {
    dispatch(removeScheduleCustomTag(index));
  };

  return (
    <div className="w-full form-control ">
      <form
        className="w-full mt-8 form-control md:mt-6"
        onSubmit={formik.handleSubmit}
      >
        <label className="label w-[75%]">
          <span className="mb-2 text-base font-medium label-text text-[#696F79] dark:text-white">
            Do you have any custom tags that entries can be marked as?
          </span>
        </label>
        <div className="flex flex-row flex-wrap items-baseline px-1">
          <div className="flex flex-col lg:w-[75%] md:w-[75%] w-full sm:w-[64%] mb-4 md:mb-0 mr-4">
            <input
              type="text"
              name="customTags"
              placeholder="E.g. First-time soloist"
              onChange={formik.handleChange}
              value={formik.values.customTags}
              className={`input input-bordered border-[#959FB1] h-[62px] w-full lg:text-sm md:text-sm text-xs font-medium text-[#8692A6] dark:text-white mr-4 placeholder-[#8692A6] ${
                formik.errors.customTags &&
                formik.touched.customTags &&
                'input-error border-[#fc8181]'
              }`}
            />
            {formik.errors.customTags && formik.touched.customTags && (
              <span className="text-xs text-red-500">
                {formik.errors.customTags}
              </span>
            )}
          </div>
          <button
            type="submit"
            className="max-h-[3rem] bg-[#1565D8] rounded-[5px] text-white capitalize h-[31px] px-[4rem] md:ml-2 md:px-9 text-[1rem]"
          >
            add
          </button>
        </div>
      </form>
      <div className="flex flex-row flex-wrap w-fit justify-start items-center py-2 rounded-xl cursor-pointer">
        {scheduling.customTags.map((level, index) => (
          <div
            key={index}
            className="flex flex-row flex-wrap w-fit mb-[8px] mr-[10px] bg-[#FFE600] items-center p-[10px] py-2 rounded-md cursor-pointer"
          >
            <span className="text-sm font-medium text-[#8692A6]">
              {`${level.customTags}`}
            </span>
            <img
              src={RemoveImg}
              alt="remove"
              className="h-4 w-4 ml-[10px]"
              onClick={() => handleRemove(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomTag;
