/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useState } from "react";
// import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const DeductionCard = ({
  toggleDeduction,
  organizerData,
  currentEntryScoreData,
  sliderValues,
  setDeficit,
  calculateTotal,
  deductionKeysArray,
  setDeductionKeysArray,
  unblocked,
  currentIndex,
}) => {
  const deductionsArr = organizerData?.deductionCriteria;

  // const [checkboxValues, setCheckboxValues] = useState({});
  const initialCheckboxValues =
    JSON.parse(localStorage.getItem("checkboxValues")) || {};
  const [checkboxValues, setCheckboxValues] = useState(initialCheckboxValues);

  // Handle checkbox change
  const handleCheckboxChange = (event, percent) => {
    const { id, checked } = event.target;
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [id]: checked,
    }));
  };

  useEffect(() => {
    localStorage.setItem("checkboxValues", JSON.stringify(checkboxValues));
  }, [checkboxValues]);

  useEffect(() => {
    const filteredKeys = Object.entries(checkboxValues)
      .filter(([key, value]) => value)
      .map(([key]) => key);
    setDeductionKeysArray(filteredKeys);
  }, [checkboxValues]);

  const [deductionPointArray, setdeductionPointArray] = useState([]);

  useEffect(() => {
    setdeductionPointArray(
      deductionKeysArray?.map((item) => {
        return item?.split("_")[item?.split("_").length - 1];
      })
    );
  }, [deductionKeysArray]);

  const sumDeductionPoints = (deductionPointArray) => {
    let sum = 0;

    for (let i = 0; i < deductionPointArray?.length; i++) {
      const item = deductionPointArray[i];
      const parsedItem = parseInt(item, 10);

      if (!isNaN(parsedItem)) {
        sum += parsedItem;
      }
    }

    return sum;
  };

  const findPercentage = (a, b) => {
    const percentage = (a / 100) * b;
    const roundedPercentage = percentage.toFixed(2);
    return parseFloat(roundedPercentage);
  };

  const totalToSubtract = findPercentage(
    sumDeductionPoints(deductionPointArray),
    calculateTotal()
  );

  const totalDeductions = sumDeductionPoints(deductionPointArray);

  useEffect(() => {
    if (Object.values(checkboxValues).some(Boolean)) {
      setDeficit(totalDeductions);
    } else {
      setDeficit(null);
    }
  }, [totalToSubtract, checkboxValues, totalDeductions]);

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="w-[250px] md:w-[350px] h-fit p-5 md:py-8 md:px-10 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-center scale shadow-lg absolute bottom-0 md:left-10 left-0 z-10"
    >
      <div
        onClick={() => {
          toggleDeduction();
        }}
        className="w-5 h-5 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-3 right-3"
      >
        <img alt="user" src="/images/icons8-close-50.png" className="w-3 h-3" />
      </div>
      <h3 className="font-normal text-[.85rem]">Deductions</h3>
      {currentEntryScoreData?.length > 0 && !unblocked ? (
        <div className="w-full h-full border border-white/20 flex justify-center items-center rounded-md text-white/60 p-3 text-[.9rem]">
          Deductions can only be applied before presenting a score or making
          changes
        </div>
      ) : sliderValues.creativity < 1 ||
        sliderValues.staging < 1 ||
        sliderValues.execution < 1 ||
        sliderValues.performance < 1 ? (
        <div className="w-full h-full border border-white/20 flex justify-center items-center rounded-md text-white/60 p-3 text-[.9rem]">
          Deductions can only be applied when a total score is present
        </div>
      ) : (
        <form className="w-full h-fit text-right">
          <div className="border border-white/30 p-3">
            {deductionsArr?.map((item, index) => {
              const checkboxId = `${item?.deductionCriteria}_${item?.deductionPercent}`;
              const isChecked = checkboxValues[checkboxId] || false;

              return (
                <div key={index} className="flex items-center gap-4">
                  <label
                    htmlFor={checkboxId}
                    className="text-white/80 cursor-pointer w-[150px] text-start"
                  >
                    {capitalizeFirstLetter(item?.deductionCriteria)} -
                    {item?.deductionPercent}%
                  </label>
                  <input
                    type="checkbox"
                    id={checkboxId}
                    className="mr-2 cursor-pointer"
                    checked={isChecked}
                    onChange={(e) =>
                      handleCheckboxChange(e, item?.deductionPercent)
                    }
                  />
                </div>
              );
            })}
          </div>

          {/* <button
            onClick={(e) => e.preventDefault()}
            className="px-4 py-1 rounded-md bg-blue-500 hover:bg-blue-400 text-[.75rem] mt-4"
          >
            Deduct
          </button> */}
        </form>
      )}
    </div>
  );
};

export default DeductionCard;
