import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Footer from './components/Footer';
import Header from './components/Header';
import LeftSidebar from './components/LeftSidebar';
import RightSidebar from './components/RightSidebar';
import AddEventForm from './components/AddEventForm';
import ScrollToTop from './ScrollToTop';
import { useParams } from 'react-router-dom';
import OverviewTable from './components/OverviewTable';
import { useFirestoreQueryDoc } from 'components/hooks/useFirestoreQueryDoc';

const Overview = () => {
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { eventsData, addEvent, openNotifsBoard } = useSelector(
    (state) => state.eventManagement
  );
  const { event_name } = useParams();
  const currentEvent = eventsData.filter(
    (item) => item.event_name.replace(/ /g, '-') === event_name
  )[0];

  const { data: dataOrg } = useFirestoreQueryDoc('organizers', user?.uid);
  const regData = dataOrg?.studio_registrations;
  const studiosWithAccess = regData?.filter((item1) =>
    currentEvent?.access?.some((item2) => item2?.studio_id === item1.studio_id)
  );

  const [searchTerm, setSearchTerm] = useState('');
  const [searchedStudios, setSearchedStudios] = useState([]);

  function handleSearch(e) {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);

    if (newSearchTerm) {
      const result = studiosWithAccess?.filter((itm) =>
        itm?.studio_name?.toLowerCase()?.includes(newSearchTerm)
      );
      setSearchedStudios(result);
    } else {
      setSearchedStudios([]);
    }
  }

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}

      {/* event details page */}
      {!addEvent && (
        <div
          className={`w-full min-h-screen text-white/80 px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px]  ${
            openNotifsBoard ? 'lg:pr-[310px]' : 'lg:pr-[32px]'
          } pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col transition-all duration-700`}
        >
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/90 text-[1rem] mb-6">
              <h2>
                Overview: {currentEvent?.event_name}{' '}
                {currentEvent?.year.split(' to ')[0]}
              </h2>
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            <div className="w-full min-h-[400px]">
              <div className="w-full p-2 bg-[#282929] rounded-lg flex justify-between">
                <div className="flex gap-2 items-center">
                  <img
                    alt="arrow"
                    src="/images/plus.png"
                    className="w-7 h-7 cursor-pointer"
                  />
                </div>
                {/* search */}
                <div
                  className={`h-[30px] flex mr-2 ml-auto border rounded-lg ${
                    searchTerm ? 'border-[#94a4fd]' : 'border-white/10'
                  }`}
                >
                  <div className="h-full w-fit p-1 bg-[#333333] rounded-l-lg">
                    <img
                      alt="user"
                      src="/images/Search.png"
                      className="w-5 h-5 cursor-pointer"
                    />
                  </div>
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => handleSearch(e)}
                    placeholder="Search"
                    className="w-[130px] text-[.9em] bg-[#333333] rounded-r-lg outline-none pr-2 py-1"
                  />
                </div>
              </div>

              <OverviewTable
                searchedStudios={searchedStudios}
                handleSearch={handleSearch}
                searchTerm={searchTerm}
                studiosWithAccess={studiosWithAccess}
                currentEvent={currentEvent}
              />

              {searchTerm && (
                <p className="w-full mt-5 text-sm text-white/80 text-center">
                  Found {searchedStudios?.length} studios matching "{searchTerm}
                  "
                </p>
              )}
            </div>
          </div>

          <ScrollToTop />
          <Footer />
        </div>
      )}

      {/* the add event pop up page */}

      {addEvent && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[330px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
              <h2>Add Event</h2>
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            {/* Add event form */}
            <div className="">
              <AddEventForm />
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}
    </>
  );
};

export default Overview;
