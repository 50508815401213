const ReportSortContainer = ({ setSortDown, sortDown }) => {
  return (
    <ul className="absolute top-10 bg-[#282929] w-[150px] rounded-lg p-3 border-white/20 text-[.75rem] z-[2]">
      <li
        onClick={(e) => {
          e.stopPropagation();
          setSortDown('class');
        }}
        className="w-full py-1 hover:bg-[#94a4fd]/20 border-b border-white/20 cursor-pointer flex gap-2 justify-between items-center"
      >
        <p>By Classification</p>
        {sortDown === 'class' && (
          <img
            alt="success"
            src="/images/icons8-checkmark-64.png"
            className="w-4 h-4"
          />
        )}
      </li>
      <li
        onClick={(e) => {
          e.stopPropagation();
          setSortDown('entry_type');
        }}
        className="w-full py-1 hover:bg-[#94a4fd]/20 border-b border-white/20 cursor-pointer flex gap-2 justify-between items-center"
      >
        <p>By Entry Type</p>
        {sortDown === 'entry_type' && (
          <img
            alt="success"
            src="/images/icons8-checkmark-64.png"
            className="w-4 h-4"
          />
        )}
      </li>
      <li
        onClick={(e) => {
          e.stopPropagation();
          setSortDown('genre');
        }}
        className="w-full py-1 hover:bg-[#94a4fd]/20 border-b border-white/20 cursor-pointer flex gap-2 justify-between items-center"
      >
        <p>By Genre</p>
        {sortDown === 'genre' && (
          <img
            alt="success"
            src="/images/icons8-checkmark-64.png"
            className="w-4 h-4"
          />
        )}
      </li>
      <li
        onClick={(e) => {
          e.stopPropagation();
          setSortDown('award');
        }}
        className="w-full py-1 hover:bg-[#94a4fd]/20 border-b border-white/20 cursor-pointer flex gap-2 justify-between items-center"
      >
        <p>By Award Name</p>
        {sortDown === 'award' && (
          <img
            alt="success"
            src="/images/icons8-checkmark-64.png"
            className="w-4 h-4"
          />
        )}
      </li>
      <li
        onClick={(e) => {
          e.stopPropagation();
          setSortDown('studio_tag');
        }}
        className="w-full py-1 hover:bg-[#94a4fd]/20 border-b border-white/20 cursor-pointer flex gap-2 justify-between items-center"
      >
        <p>By Studios</p>
        {sortDown === 'studio_tag' && (
          <img
            alt="success"
            src="/images/icons8-checkmark-64.png"
            className="w-4 h-4"
          />
        )}
      </li>
    </ul>
  );
};

export default ReportSortContainer;
