import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReceiptStudioPage from "./components/ReceiptStudioPage";

const ReceiptStudioContainer = () => {
  const { eventsData } = useSelector((state) => state.danceStudio);
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { event_name } = useParams();

  const currentEvent = eventsData.filter(
    (item) => item.event_name.replace(/ /g, "-") === event_name
  )[0];

  const currentStudio = currentEvent?.access?.filter(
    (itm) => itm?.studio_id === user?.uid
  )[0];

  const { data: allStudios } = useQueryAllDocs("studio_owners");
  const studioData = allStudios?.filter(
    (item) => item?.uid === currentStudio?.studio_id
  )[0];

  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );
  const { compId } = danceStudioCompData.value;

  const { data } = useFirestoreQueryDoc("competitions", compId);

  const { data: allPayment } = useQueryAllSubCollectionDocs(
    "competitions",
    compId,
    "payment_history"
  );

  const { data: compData } = useFirestoreQueryDoc("competitions", compId);

  const [billingDetails, setbillingDetails] = useState([]);

  useEffect(() => {
    if (compData) {
      const { billing_details } = compData;
      setbillingDetails(billing_details);
    }
  }, [compData]);

  return (
    <>
      {/* the invoice page overlay */}
      <section className="w-full h-screen fixed top-0 left-0">
        <ReceiptStudioPage
          compName={data?.competitionName}
          studioData={studioData}
          allPayment={allPayment}
          billingDetails={billingDetails}
          compId={compId}
        />
      </section>
    </>
  );
};

export default ReceiptStudioContainer;
