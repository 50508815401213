/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import {
  clearSuccess,
  updateAwardName,
} from '../../../redux/eventManagement/eventManagement';
import ButtonLoadingSpinner from 'utils/ButtonLoadingSpinner';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { db } from 'services/firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import AwardForm from './AwardForm';

const AwardUpdate = ({ organizerData, user, status }) => {
  const { updateAwardNameSuccess, updateAwardNamePending } = useSelector(
    (state) => state.eventManagement
  );
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  function toggleEdit() {
    setEdit((prev) => !prev);
  }

  const awardData = organizerData?.awards || [];
  const award = awardData?.map((award) => award.awardName);
  const [updateAward, setUpdateAward] = useState(award);

  useEffect(() => {
    setUpdateAward(award);
  }, [edit]);

  function handleSubmit(e) {
    e.preventDefault();
    if (!updateAward?.includes('')) {
      const updatedArray = awardData?.map((obj, index) => ({
        ...obj,
        awardName: updateAward[index],
      }));

      dispatch(updateAwardName({ compId: user?.uid, updatedArray }));
    }
  }

  useEffect(() => {
    if (updateAwardNameSuccess?.success) {
      setEdit(false);
      setTimeout(() => {
        dispatch(clearSuccess());
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 2000);
    }
  }, [updateAwardNameSuccess, dispatch]);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedAward, setSelectedAward] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  const openForm = () => {
    setIsOpen(true); // Close the edit form
  };

  const closeForm = () => {
    setIsOpen(false);
  };

  const openDeleteConfirmation = (selectedAward) => {
    setSelectedAward(selectedAward);
    setDeleteConfirmation(true);
  };

  const handleDelete = async () => {
    setLoading(true);
    const organizerDocRef = doc(db, 'organizers', user?.uid);
    const competitionDocRef = doc(db, 'competitions', user?.uid);

    // Get the current document data
    const organizerDocSnap = await getDoc(organizerDocRef);
    const currentData = organizerDocSnap.data();

    // Remove the selected Award data from organizerData awards
    const updatedAwards = currentData?.awards?.filter(
      (award) => award?.awardName !== selectedAward?.awardName
    );

    // Update the document
    await updateDoc(organizerDocRef, {
      awards: updatedAwards,
    });

    // Update the competition document
    await updateDoc(competitionDocRef, {
      awards: updatedAwards,
    });
    // Set loading to false after the update is complete
    setLoading(false);

    // Close the confirmation dialog
    setDeleteConfirmation(false);
  };

  return (
    <div className="w-full p-3 md:p-5 md:max-w-[85%] lg:max-w-[70%] md:min-w-[450px] bg-[#282929] rounded-lg flex flex-col gap-6 mb-auto text-white/80">
      <h1 className="w-full border-b pb-2 font-bold border-white/30">
        All Awards Values
      </h1>
      {status === 'loading' && (
        <div className="w-full h-[150px] flex justify-center items-center">
          <PulseLoader color="#94a4fd" size={10} />
        </div>
      )}

      <div className="grid grid-cols-2 lg:grid-cols-3 gap-3">
        {award?.map((awardName, index) => (
          <div
            key={awardName}
            className="bg-[#1c1c1c] rounded-lg p-2 text-sm text-white flex items-center justify-between"
          >
            {status === 'loading' ? (
              <PulseLoader color="#94a4fd" size={10} />
            ) : (
              <div>
                {edit ? (
                  <input
                    type="text"
                    value={updateAward?.[index] || ''}
                    onChange={(e) => {
                      const updatedAwards = updateAward ? [...updateAward] : [];
                      updatedAwards[index] = e.target.value;
                      setUpdateAward(updatedAwards);
                    }}
                    className="bg-[#1c1c1c] rounded-lg px-2 py-1 text-sm text-white flex items-center justify-center w-[120px] border"
                  />
                ) : (
                  capitalizeFirstLetter(awardName)
                )}
              </div>
            )}
            <div className="flex items-center gap-3">
              <img
                src="/images/deleteCat.png"
                alt="delete"
                className="w-full h-4 cursor-pointer"
                onClick={() => openDeleteConfirmation(awardData[index])}
              />
            </div>
          </div>
        ))}
      </div>
      {updateAwardNameSuccess?.success && (
        <p className="text-[#94a4fd] ">Award Updated Successfully!!</p>
      )}
      <div className="flex gap-2 justify-end">
        {!edit && (
          <button
            onClick={openForm}
            className="px-4 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[.85rem] hover:opacity-75 cursor-pointer"
          >
            Add
          </button>
        )}
        <button
          onClick={toggleEdit}
          disabled={updateAwardNamePending}
          className={`px-4 py-1 rounded-full text-black text-[.85rem] hover:opacity-75 ${
            edit ? 'bg-white/50' : 'bg-[#94a4fd]'
          }`}
        >
          {edit ? 'Cancel Edit' : 'Edit Values'}
        </button>

        {edit && (
          <button
            onClick={handleSubmit}
            disabled={updateAwardNamePending}
            className="px-4 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[.85rem] hover:opacity-75"
          >
            {updateAwardNamePending ? (
              <p className="flex gap-2 items-center">
                Submitting.. <ButtonLoadingSpinner />
              </p>
            ) : (
              'Submit'
            )}
          </button>
        )}
      </div>
      {/* Show confirmation dialog if isConfirmationOpen is true for deletion*/}
      {deleteConfirmation && selectedAward !== null && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-start scale shadow-lg">
            <p className="w-full text-[1rem] text-white pb-3 border-b border-[#c5c4c4]/50">
              Confirm Delete Award Name:
              <br />
              <span className="font-bold">{`${selectedAward?.awardName}`}</span>
              ?
            </p>

            <p className="text-white/70">
              NOTE: Once you Delete this Award it will be deleted from the
              events and take note this process is irreversible.
              <br />
              Are you sure you want to proceed with the changes?
            </p>

            <div className="mt-4 w-full text-center flex items-center justify-center">
              <button
                onClick={() => setDeleteConfirmation(false)}
                className="text-[.85rem] px-8 py-2 mr-4 rounded-full bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Cancel
              </button>
              <button
                className={`px-8 flex items-center rounded-full text-black/80 text-[0.85rem] hover:opacity-75 bg-[#94a4fd] cursor-pointer`}
                onClick={handleDelete}
                disabled={loading}
              >
                <span className="px-10 py-2 w-full">
                  {loading ? 'Processing...' : 'Delete'}
                </span>
                {loading && <ButtonLoadingSpinner />}
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Show Popup if isClassificationOpen is true */}
      {isOpen && (
        <AwardForm
          onClose={closeForm}
          user={user}
          organizerData={organizerData}
        />
      )}
    </div>
  );
};

export default AwardUpdate;
