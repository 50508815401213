import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  addLevel,
  removeLevel,
} from '../../../redux/organizerRegistration/organizerSlices';
import RemoveImg from '../../../assets/remove.png';

const CompetitionLevelSchema = Yup.object().shape({
  levelName: Yup.string()
    .required('level name is required')
    .matches(/^[^0-9]*$/, 'levelName should not contain numbers'),
  description: Yup.string().required('description is required'),
});

const CompetitionLevel = () => {
  const dispatch = useDispatch();
  const { competitionLevel } = useSelector((state) => state.persistedReducer);

  const formik = useFormik({
    initialValues: {
      levelName: '',
      description: '',
    },
    validationSchema: CompetitionLevelSchema,
    onSubmit: (values, { resetForm }) => {
      const level = {
        ...values,
        levelName: values.levelName.toLowerCase(),
        description: values.description.toLowerCase(),
      };
      dispatch(addLevel(level));
      resetForm();
    },
  });

  // check if the levelName is already added
  const index = competitionLevel.findIndex(
    (level) => level.levelName === formik.values.levelName
  );

  // if the levelName is already added don't allow to add it again
  if (index !== -1) {
    formik.errors.levelName = 'Level Name already exists';
  }

  const handleRemove = (index) => {
    dispatch(removeLevel(index));
  };

  return (
    <div className="w-full mt-8 form-control">
      <label className="label w-[75%]">
        <span className="mb-2 text-base font-medium label-text text-[#696F79]">
          What levels/classifications do you have at your competition and what
          are the criteria?
        </span>
      </label>
      <div className="flex flex-row flex-wrap items-baseline px-1 mb-[0.75rem]">
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col w-full lg:w-[75%] md:w-[75%] mr-4"
        >
          <div className="flex flex-col md:flex-row items-center justify-between w-full gap-3">
            <div className="w-full flex flex-col gap-2">
              <input
                name="levelName"
                placeholder="Level Name"
                value={formik.values.levelName}
                onChange={formik.handleChange}
                className={`input input-bordered border-[#959FB1] h-[38px] w-[100%] text-sm font-medium text-[#9FA8B8] dark:text-white mr-[7px] placeholder-[#8692A6] ${
                  formik.errors.levelName &&
                  formik.touched.levelName &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {formik.errors.levelName && formik.touched.levelName && (
                <span className="text-xs text-red-500">
                  {formik.errors.levelName}
                </span>
              )}
            </div>

            <div className="w-full flex flex-col gap-2">
              <input
                name="description"
                placeholder="Description"
                onChange={formik.handleChange}
                value={formik.values.description}
                className={`input input-bordered border-[#959FB1] h-[38px] w-[100%] text-sm font-medium text-[#9FA8B8] dark:text-white mr-[7px] placeholder-[#8692A6] ${
                  formik.errors.description &&
                  formik.touched.description &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {formik.errors.description && formik.touched.description && (
                <span className="text-xs text-red-500">
                  {formik.errors.description}
                </span>
              )}
            </div>

            <button
              type="submit"
              // check if the form is valid before submitting
              className="max-h-[3rem] bg-[#1565D8] rounded-[5px] text-white capitalize h-[31px] px-[4rem] ml-2 md:px-9 text-[1rem]"
            >
              add
            </button>
          </div>
        </form>
      </div>
      <div className="flex flex-row flex-wrap w-fit justify-start items-center py-2 rounded-xl cursor-pointer">
        {competitionLevel.map((level, index) => (
          <div
            key={index}
            className="flex flex-row flex-wrap w-fit mb-[8px] mr-[10px] bg-[#FFE600] items-center p-[10px] py-2 rounded-md cursor-pointer"
          >
            <span className="text-sm font-medium text-[#8692A6] ">
              {`${level.levelName}, ${level.description}`}
            </span>
            <img
              src={RemoveImg}
              alt="remove"
              className="h-4 w-4 ml-[10px]"
              onClick={() => handleRemove(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompetitionLevel;
