import React from "react";

export default function CheckList({ id, genre, checked, handCheck }) {
 
  return (
    <li className="form-control" key={id}>
      <label className="justify-start cursor-pointer label">
        <input
          name={genre}
          type="checkbox"
          checked={checked}
          onChange={handCheck}
          className="rounded-none bg-[#FFFFFF] checkbox border-[#1565D8] checkbox-xs"
        />
        <span className="ml-3 text-base font-medium label-text text-[#696F79]">
          {genre}
        </span>
      </label>
    </li>
  );
}
