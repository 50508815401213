import React from "react";
import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import AddEventContainer from "./components/AddEventContainer";
import ScrollToTop from "./ScrollToTop";
import SettingsAccountDetails from "./components/SettingsAccountDetails";

const Invoice = () => {
  const { addEvent, openNotifsBoard } = useSelector(
    (state) => state.eventManagement
  );
  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}

      {/* event details page */}
      {!addEvent && (
        <div
          className={`w-full min-h-screen text-white/80 px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px]  ${
            openNotifsBoard ? "lg:pr-[310px]" : "lg:pr-[32px]"
          } pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col transition-all duration-700`}
        >
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/90 text-[1rem] mb-6">
              <h2>Accounting Details Settings</h2>
            </div>

            {/* Settings container */}
            <div className="w-full p-3 md:p-5 md:max-w-[85%] lg:max-w-[60%] md:min-w-[450px] min-h-[300px] bg-[#282929] rounded-lg flex flex-col gap-6 mb-auto text-white/80">
              <SettingsAccountDetails />
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {/* the add event pop up page */}
      {addEvent && <AddEventContainer />}
    </>
  );
};

export default Invoice;
