const DancerSortContainer = ({ setSortDown, sortDown }) => {
  return (
    <ul className="absolute top-10 bg-[#282929] w-[150px] rounded-lg p-3 border-white/20 text-[.75rem] z-[2]">
      <li
        onClick={(e) => {
          e.stopPropagation();
          setSortDown("latest");
        }}
        className="w-full py-1 hover:bg-[#94a4fd]/20 border-b border-white/20 cursor-pointer flex gap-2 justify-between items-center"
      >
        <p>Latest to Oldest</p>
        {sortDown === "latest" && (
          <img
            alt="success"
            src="/images/icons8-checkmark-64.png"
            className="w-4 h-4"
          />
        )}
      </li>
      <li
        onClick={(e) => {
          e.stopPropagation();
          setSortDown("oldest");
        }}
        className="w-full py-1 hover:bg-[#94a4fd]/20 border-b border-white/20 cursor-pointer flex gap-2 justify-between items-center"
      >
        <p> Oldest to Latest</p>
        {sortDown === "oldest" && (
          <img
            alt="success"
            src="/images/icons8-checkmark-64.png"
            className="w-4 h-4"
          />
        )}
      </li>
      <li
        onClick={(e) => {
          e.stopPropagation();
          setSortDown("alphabet");
        }}
        className="w-full py-1 hover:bg-[#94a4fd]/20 border-b border-white/20 cursor-pointer flex gap-2 justify-between items-center"
      >
        <p>Alphabetically</p>
        {sortDown === "alphabet" && (
          <img
            alt="success"
            src="/images/icons8-checkmark-64.png"
            className="w-4 h-4"
          />
        )}
      </li>
      <li
        onClick={(e) => {
          e.stopPropagation();
          setSortDown("gender");
        }}
        className="w-full py-1 hover:bg-[#94a4fd]/20 border-b border-white/20 cursor-pointer flex gap-2 justify-between items-center"
      >
        <p>By Gender</p>
        {sortDown === "gender" && (
          <img
            alt="success"
            src="/images/icons8-checkmark-64.png"
            className="w-4 h-4"
          />
        )}
      </li>
      <li
        onClick={(e) => {
          e.stopPropagation();
          setSortDown("class");
        }}
        className="w-full py-1 hover:bg-[#94a4fd]/20 border-b border-white/20 cursor-pointer flex gap-2 justify-between items-center"
      >
        <p>By Classification</p>
        {sortDown === "class" && (
          <img
            alt="success"
            src="/images/icons8-checkmark-64.png"
            className="w-4 h-4"
          />
        )}
      </li>
      <li
        onClick={(e) => {
          e.stopPropagation();
          setSortDown("age");
        }}
        className="w-full py-1 hover:bg-[#94a4fd]/20 border-b border-white/20 cursor-pointer flex gap-2 justify-between items-center"
      >
        <p>By Age</p>
        {sortDown === "age" && (
          <img
            alt="success"
            src="/images/icons8-checkmark-64.png"
            className="w-4 h-4"
          />
        )}
      </li>
      <li
        onClick={(e) => {
          e.stopPropagation();
          setSortDown("studio");
        }}
        className="w-full py-1 hover:bg-[#94a4fd]/20 border-b border-white/20 cursor-pointer flex gap-2 justify-between items-center"
      >
        <p>By Studio</p>
        {sortDown === "studio" && (
          <img
            alt="success"
            src="/images/icons8-checkmark-64.png"
            className="w-4 h-4"
          />
        )}
      </li>
      <li
        onClick={(e) => {
          e.stopPropagation();
          setSortDown("note");
        }}
        className="w-full py-1 hover:bg-[#94a4fd]/20 border-b border-white/20 cursor-pointer flex gap-2 justify-between items-center"
      >
        <p>By Note</p>
        {sortDown === "note" && (
          <img
            alt="success"
            src="/images/icons8-checkmark-64.png"
            className="w-4 h-4"
          />
        )}
      </li>
    </ul>
  );
};

export default DancerSortContainer;
