import React, { useState } from 'react';

const PricingDetails = ({
  pricingCategories,
  setPricingCategories,
  combineEntries,
}) => {
  console.log('combineEntries', combineEntries);
  const handleInputChange = (entryTypeName, value) => {
    if (value < 0) {
      return;
    }

    // Ensure that the value is always formatted with .00
    const formattedValue = parseFloat(value).toFixed(2);

    setPricingCategories((prevPricingCategories) => {
      const index = prevPricingCategories.findIndex((item) =>
        item.hasOwnProperty(entryTypeName)
      );
      if (index !== -1) {
        const updatedEntry = {
          ...prevPricingCategories[index],
          [entryTypeName]: formattedValue,
        };
        const newCategories = [...prevPricingCategories];
        newCategories[index] = updatedEntry;
        return newCategories;
      }
      return [
        ...prevPricingCategories,
        {
          [entryTypeName]: formattedValue,
          selectedOption: selectedOptions[entryTypeName] || 'perDancer',
        },
      ];
    });
  };

  const [selectedOptions, setSelectedOptions] = useState({});

  const handleSelectChange = (entryTypeName, selectedOption) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [entryTypeName]: selectedOption,
    }));

    setPricingCategories((prevPricingCategories) => {
      const updatedCategories = prevPricingCategories.map((category) => {
        const key = Object.keys(category)[0];
        if (key === entryTypeName) {
          return {
            [entryTypeName]: category[key],
            selectedOption: selectedOption,
          };
        }
        return category;
      });

      return updatedCategories;
    });
  };

  return (
    <div className="w-full md:w-[80%]">
      <div className="flex md:justify-between items-center gap-4">
        <h3 className="text-sm md:text-xl font-bold text-[#696F79] dark:text-white mr-auto md:mr-0">
          Entry Type
        </h3>
        <ul className="flex items-center gap-8">
          <li className="text-[#696F79] dark:text-white text-sm md:text-xl xs:mr-[80px]">
            Price
          </li>
          <li className="text-[#696F79] dark:text-white text-sm md:text-xl xs:mr-[20px]">
            Rate
          </li>
        </ul>
      </div>
      {combineEntries?.map((item, index) => (
        <div
          className="w-full flex justify-between items-center gap-4 pt-4"
          key={index}
        >
          <p className="w-[200px] md:w-full font-medium text-sm md:text-xl whitespace-nowrap truncate mr-auto md:mr-0">
            {item?.entryTypeName || item?.specialEntryTypeName}
          </p>
          <div className="flex items-center gap-2 md:justify-end ml-auto">
            {item?.entryTypeName && (
              <>
                <input
                  value={
                    pricingCategories
                      ? pricingCategories[item?.entryTypeName]
                      : ''
                  }
                  onChange={(e) =>
                    handleInputChange(item?.entryTypeName, e.target.value)
                  }
                  type="number"
                  name={`pricingCategories_${item?.entryTypeName}`}
                  placeholder="89.00"
                  className="input input-bordered border-[#959FB1] w-[80%] md:w-[60%] font-medium text-[#8692A6] dark:text-white mr-3 placeholder-[#8692A6] text-[.75rem] md:text-[1.1rem]"
                  step="0.01"
                />
                <select
                  name=""
                  className="border border-gray-600 rounded-md px-1 py-1 bg-transparent h-[45px] w-[40%] xs:w-fit"
                  value={selectedOptions[item?.entryTypeName] || 'perDancer'}
                  onChange={(e) =>
                    handleSelectChange(item?.entryTypeName, e.target.value)
                  }
                >
                  <option value="perDancer">Per Dancer</option>
                  <option value="Flat">Flat</option>
                </select>
              </>
            )}
            {item?.specialEntryTypeName && (
              <>
                {/* Render special entry type fields */}
                <input
                  value={
                    pricingCategories
                      ? pricingCategories[item?.specialEntryTypeName]
                      : ''
                  }
                  onChange={(e) =>
                    handleInputChange(
                      item?.specialEntryTypeName,
                      e.target.value
                    )
                  }
                  type="number"
                  name={`pricingCategories_${item?.specialEntryTypeName}`}
                  placeholder="89.00"
                  className="input input-bordered border-[#959FB1] w-[80%] md:w-[60%] font-medium text-[#8692A6] dark:text-white mr-3 placeholder-[#8692A6] text-[.75rem] md:text-[1.1rem]"
                  step="0.01"
                />
                <select
                  name=""
                  className="border border-gray-600 rounded-md px-1 py-1 bg-transparent h-[45px] w-[40%] xs:w-fit"
                  value={
                    selectedOptions[item?.specialEntryTypeName] || 'perDancer'
                  }
                  onChange={(e) =>
                    handleSelectChange(
                      item?.specialEntryTypeName,
                      e.target.value
                    )
                  }
                >
                  <option value="perDancer">Per Dancer</option>
                  <option value="Flat">Flat</option>
                </select>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PricingDetails;
