import { doc, updateDoc } from "firebase/firestore";
import { db } from "services/firebase";

export const updateEntriesAfterLock = async (compId, docId, time, count) => {
  try {
    const docRef = doc(db, "competitions", compId, "entries", docId);

    await updateDoc(docRef, {
      locked_entry_time: time,
      locked_entry_count: count,
    });

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};
