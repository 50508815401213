/* eslint-disable no-loop-func */
import { doc, getDocs, updateDoc } from "firebase/firestore";
import moment from "moment";

export const updateAllDocs = async (
  collectionRef,
  entryTypes,
  currentEvent
) => {
  const querySnapshotAll = await getDocs(collectionRef);
  const documents = querySnapshotAll.docs;

  // Remove documents with the "break" key
  const documentsWithoutBreak = documents.filter(
    (document) => !document.data().break
  );

  // Sort documents based on the sort_id in ascending order
  documentsWithoutBreak.sort((a, b) => {
    const sortIdA = parseInt(a.data().sort_id, 10);
    const sortIdB = parseInt(b.data().sort_id, 10);
    return sortIdA - sortIdB;
  });

  let currentEntryCount = 1;
  let previousTime = null;

  for (let i = 0; i < documentsWithoutBreak?.length; i++) {
    const document = documentsWithoutBreak[i];

    const entries = document.data().entries;
    const buffer = document.data().buffer;
    const isCategory = document.data().category;

    if (document.data().event_id === currentEvent?.event_name) {
      const incrementMinutes = entryTypes?.filter((item) => {
        return item?.entryTypeName === document.data()?.entryType;
      })[0]?.maxTime;

      if (entries && isCategory) {
        const updatedEntries = entries?.map((entry, index) => {
          const updatedEntry = { ...entry };

          if (!previousTime && updatedEntry?.entry_count === 1) {
            previousTime = currentEvent?.days[0]?.startTime;
            const time = moment(previousTime).utc().format("hh:mm A");
            updatedEntry.time = time;
            previousTime = time;
          } else {
            const entryTime = moment(previousTime, "HH:mm A")
              .add(
                documents[i - 1]?.data()?.entries
                  ? incrementMinutes
                  : !documents[i - 1]
                  ? incrementMinutes
                  : 0,
                "minutes"
              )
              .format("HH:mm A");
            updatedEntry.time = entryTime;
            previousTime = entryTime;
          }

          updatedEntry.entry_count = currentEntryCount;
          currentEntryCount++;

          return updatedEntry;
        });

        await updateDoc(doc(collectionRef, document.id), {
          entries: updatedEntries,
        });
      } else if (!isCategory) {
        let newTimeFirst = currentEvent?.days[0]?.startTime;
        const firsTime = moment(newTimeFirst).utc().format("hh:mm A");

        const prevTime = previousTime ? previousTime : firsTime;
        const decrementMinutes = entryTypes?.filter((item) => {
          return (
            item?.entryTypeName ===
            documentsWithoutBreak[i + 1]?.data()?.entryType
          );
        })[0]?.maxTime;

        const isFirstItemTime =
          documentsWithoutBreak[i] !== documentsWithoutBreak[0]
            ? 0
            : decrementMinutes;

        const time = moment(prevTime, "HH:mm A")
          .add(Number(buffer), "minutes")
          .subtract(timeToMinutes(isFirstItemTime), "minutes")
          .format("HH:mm A");
        previousTime = time;
        // console.log("buffer here");
      }
    }
  }

  return { success: true, message: "All categories updated" };
};

function timeToMinutes(timeString) {
  console.log("timeString", timeString);
  if (timeString === 0) {
    return 0;
  }

  const [hours, minutes] = timeString?.split(":")?.map(Number);
  return hours * 60 + minutes;
}
