/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { addNote, clearSuccess, deleteNote } from "redux/judge/judgeSlice";
import { updateNoteAwardService } from "services/judgeService";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const JudgeSpecialCard = ({
  toggleJudgeSpecial,
  compId,
  currentEntry,
  entriesArray,
  entryCount,
  allCategories,
  currentEvent,
  setUpdateDefault,
}) => {
  const {
    addNotePending,
    addNoteSuccess,
    // deleteNotePending,
    deleteNoteSuccess,
  } = useSelector((state) => state.judge);

  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  function generateUID() {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uid = "";
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      uid += chars.charAt(randomIndex);
    }
    return uid;
  }

  const [data, setData] = useState({
    compId: compId,
    id: currentEntry?.id,
    doc_id: currentEntry?.doc_id,
    note: {
      judge_id: user?.uid,
      note: "",
      type: "judge special",
      note_uid: generateUID(),
      awarded: false,
    },
  });

  const dispatch = useDispatch();

  function handleSend() {
    dispatch(addNote(data));
  }

  function handleDelete(delData, entry) {
    if (delData) {
      dispatch(deleteNote({ ...data, note: delData, doc_id: entry?.doc_id }));
    }
  }

  useEffect(() => {
    if (addNoteSuccess?.success || deleteNoteSuccess?.success) {
      setTimeout(() => {
        dispatch(clearSuccess());
        setData({
          ...data,
          note: { ...data?.note, note: "", note_uid: generateUID() },
        });
      }, 3000);
    }
  }, [addNoteSuccess, deleteNoteSuccess]);

  const specialNotes = currentEntry?.notes?.filter((item) => {
    return item?.judge_id === user?.uid && item?.type === "judge special";
  });

  //====================================================================================================to display list
  const [showList, setShowList] = useState(false);
  function toggleShowList() {
    setShowList((prev) => !prev);
  }

  const filteredEntries = entriesArray?.filter((entry) => {
    const notes = entry?.notes;

    // Check if notes is an array and contains at least one object with type === "judge special"
    return (
      Array.isArray(notes) &&
      notes.some(
        (note) => note?.type === "judge special" && note?.judge_id === user?.uid
      )
    );
  });

  const studios = currentEvent?.access;
  // console.log(studios, 'studios');

  const [displayNote, setDisplayNote] = useState(null);
  function toggleNote(note) {
    setDisplayNote((prev) => (prev ? null : note));
  }

  const allNotes = currentEntry?.notes;

  const otherJudgesNote = allNotes?.filter(
    (item) => item?.judge_id !== user?.uid && item?.type === "judge special"
  );

  //=======================================================to handles sessions
  const eventCategories = allCategories?.filter(
    (cate) => cate?.event_uid === currentEvent?.event_uid
  );
  let sortedCategories = eventCategories?.sort(
    (a, b) => a?.sort_id - b?.sort_id
  );

  function breakArrayBysession() {
    const result = [];
    let currentSubArray = [];

    for (const item of sortedCategories) {
      if (item.category_name === "session") {
        if (currentSubArray.length > 0) {
          result.push(currentSubArray);
        }
        currentSubArray = [];
      }

      currentSubArray.push(item);
    }

    if (currentSubArray.length > 0) {
      result.push(currentSubArray);
    }

    return result;
  }

  // console.log("breakArrayBysession", breakArrayBysession()?.slice(1));
  const [dropDown, setDropDown] = useState(null);
  function toggleDropDown(item) {
    setDropDown((prev) => (prev ? null : item));
  }

  const dropdownJudgesNote = dropDown?.notes?.filter(
    (item) => item?.judge_id === user?.uid && item?.type === "judge special"
  );

  return (
    <>
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-[250px] md:w-[350px] h-fit p-5 md:py-8 md:px-10 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-center scale shadow-lg absolute bottom-0 md:left-10 left-0 z-[2]"
      >
        <div
          onClick={() => {
            toggleJudgeSpecial();
          }}
          className="w-5 h-5 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-3 right-3"
        >
          <img
            alt="user"
            src="/images/icons8-close-50.png"
            className="w-3 h-3"
          />
        </div>
        <h3 className="font-normal text-[.85rem]">Judge Special Note</h3>
        {otherJudgesNote?.length > 0 && (
          <p className="text-[#94a4fd] text-[.85rem]">
            {otherJudgesNote?.length} judge(s) made notes on this entry
          </p>
        )}
        <div className="w-full h-fit">
          <button
            onClick={toggleShowList}
            className="w-full bg-white/20 hover:bg-white/40 rounded-lg p-2 flex gap-2 text-center mb-2"
          >
            <img alt="" src="/images/icons8-list-64.png" className="w-6 h-6" />
            List
          </button>

          {addNoteSuccess?.success && (
            <div className="w-full p-2 border border-blue-500/70 text-[.9em] text-center">
              Note Added Successfully!
            </div>
          )}

          {specialNotes?.length === 0 || specialNotes === undefined ? (
            <form className="w-full h-full text-right">
              <p className="text-xs text-white/80">
                characters: {100 - data?.note.note?.length}/100
              </p>
              <textarea
                id="notes"
                value={data?.note.note}
                onChange={(e) => {
                  setData({
                    ...data,
                    note: { ...data.note, note: e.target.value },
                  });
                }}
                maxLength={100}
                className={`w-full h-[80px] border outline-none text-white bg-inherit ${
                  data?.note.note?.length === 100
                    ? "border-red-400"
                    : "border-white/30"
                }`}
              />
              {data?.note.note?.length === 100 && (
                <p className="text-xs text-red-500 dark:text-red-400">
                  Max character length reached
                </p>
              )}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleSend();
                  setUpdateDefault(false);
                }}
                disabled={addNotePending}
                className="px-4 py-1 rounded-md bg-blue-500 hover:bg-blue-400 text-[.75rem] flex gap-2 items-center"
              >
                {addNotePending ? "Sending..." : "Send"}
                {addNotePending && <ButtonLoadingSpinner />}
              </button>
            </form>
          ) : (
            <p className="text-[.85rem] border border-white/20 rounded-md p-1 text-center">
              You have added a note for this entry.
            </p>
          )}
        </div>
      </div>

      {/* the list popup page */}
      {showList && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            setDropDown(null);
            setDisplayNote(null);
          }}
          className="w-full h-screen fixed top-0 left-0 bg-black/80  flex justify-center pt-[150px] pb-10 px-3 z-[3]"
        >
          <div className="w-full md:w-[600px] md:min-w-[500px] min-h-[500px] overflow-y-auto bg-[#1C1C1C] rounded-lg px-3 py-6 flex flex-col gap-6 items-center scale">
            <h2>Judges Special Notes</h2>

            <div className="w-full text-[.85rem]">
              {breakArrayBysession()
                ?.slice(1)
                ?.map((itm, idx) => {
                  const sessionCategories = itm?.filter(
                    (x) => x?.type === "category"
                  );

                  const sessionEntries = () => {
                    let arr = [];
                    sessionCategories?.forEach((y) => {
                      const entries = entriesArray?.filter(
                        (z) => z?.category_name === y?.category_name
                      );
                      arr?.push(...entries);
                    });
                    return arr;
                  };

                  const filteredEntries2 = sessionEntries()?.filter((entry) => {
                    const notes = entry?.notes;

                    // Check if notes is an array and contains at least one object with type === "judge special"
                    return (
                      Array.isArray(notes) &&
                      notes.some(
                        (note) =>
                          note?.type === "judge special" &&
                          note?.judge_id === user?.uid
                      )
                    );
                  });

                  return (
                    <div key={idx} className="w-full">
                      <div className="w-1/2 min-w-[200px] mx-auto bg-[#6709A1] p-3 text-center rounded-lg font-bold mb-3">
                        Session {idx + 1}
                      </div>
                      {filteredEntries2?.length > 0 &&
                        studios?.map((each, idx, arr) => {
                          const myEntries = filteredEntries2
                            ?.filter(
                              (itm) =>
                                itm?.studio_selected[0] === each?.studio_name
                            )
                            ?.sort((a, b) => entryCount(a) - entryCount(b));

                          return (
                            <div
                              key={idx}
                              className={`${
                                idx === arr?.length - 1 ? "mb-8" : "mb-2"
                              }`}
                            >
                              <div
                                className={`
                                bg-[#42125f] w-full bg-white/10 mx-auto rounded-md p-3 flex justify-center items-center font-bold ${
                                  myEntries?.length === 0 && "hidden"
                                }`}
                              >
                                {myEntries[0]?.studio_letter} -{" "}
                                {capitalizeFirstLetter(each?.studio_name)}
                              </div>

                              {myEntries?.map((q, index) => {
                                // to get note item to delete
                                const specialNoteItem = q?.notes?.filter(
                                  (x) =>
                                    x?.type === "judge special" &&
                                    x?.judge_id === user?.uid
                                )[0];

                                const allNotes = q?.notes;

                                const otherJudgesNote = allNotes?.filter(
                                  (item) =>
                                    item?.judge_id !== user?.uid &&
                                    item?.type === "judge special"
                                );

                                const currentJudgesNote = allNotes?.filter(
                                  (item) =>
                                    item?.judge_id === user?.uid &&
                                    item?.type === "judge special"
                                );

                                return (
                                  <React.Fragment key={index}>
                                    <div
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setDisplayNote(null);
                                        toggleDropDown(q);
                                      }}
                                      className={`w-[80%] mx-auto border-b border-white/10 p-2 flex justify-between hover:bg-[#94a4fd]/20 cursor-pointer ${
                                        dropDown?.doc_id === q?.doc_id &&
                                        "bg-white/30"
                                      }`}
                                    >
                                      <div className="w-fit flex gap-3 items-center">
                                        <div className="w-12 flex gap-2 items-center">
                                          {otherJudgesNote?.length > 0 && (
                                            <img
                                              alt="arrow"
                                              src="/images/alert-octagon.png"
                                              className="w-5 h-5"
                                            />
                                          )}
                                          {currentJudgesNote[0]?.awarded && (
                                            <img
                                              alt="arrow"
                                              src="/images/diamond.png"
                                              className="w-5 h-5"
                                            />
                                          )}
                                        </div>
                                        <p>
                                          {entryCount(q)
                                            .toString()
                                            .padStart(3, "0")}
                                        </p>
                                      </div>
                                      <div className="whitespace-nowrap">
                                        "{capitalizeFirstLetter(q?.entry_name)}"
                                      </div>

                                      <div className="flex gap-3 items-center">
                                        <img
                                          alt="arrow"
                                          src="/images/trash.png"
                                          className="w-4 h-4 opacity-80 cursor-pointer hover:scale-[1.2]"
                                          onClick={() =>
                                            handleDelete(specialNoteItem, q)
                                          }
                                        />
                                        <img
                                          alt="arrow"
                                          src="/images/notebook.png"
                                          className="w-5 h-5 opacity-80 cursor-pointer hover:scale-[1.2]"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            toggleNote(q);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {displayNote === q && (
                                      <div className="w-[200px] h-[100px] overflow-y-auto bg-white/10 border-[#94a4fd] rounded-md flex flex-col gap-3 p-3 text-[.85rem] ml-auto">
                                        <p className="font-bold border-b border-white/20 text-center">
                                          Note
                                        </p>
                                        <p>{currentJudgesNote[0]?.note}</p>
                                      </div>
                                    )}
                                    {dropDown?.doc_id === q?.doc_id && (
                                      <ul className="w-[200px] px-3 py-2 border border-white/30 rounded-sm ml-[10%]">
                                        <li
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            updateNoteAwardService({
                                              compId,
                                              doc_id: q?.doc_id,
                                              ...dropdownJudgesNote[0],
                                            })?.then(() => setDropDown(null));
                                          }}
                                          className="p-1 hover:bg-white/20 cursor-pointer text-[.85rem]"
                                        >
                                          {dropdownJudgesNote[0]?.awarded
                                            ? "Remove award"
                                            : "Give award to"}
                                        </li>
                                      </ul>
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          );
                        })}
                    </div>
                  );
                })}

              {filteredEntries?.length === 0 && (
                <div className="w-full h-[100px] border border-white/10 flex justify-center items-center rounded-lg text-white/50">
                  Nothing yet..
                </div>
              )}
            </div>

            <button
              onClick={toggleShowList}
              className="w-fit px-10 py-1 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-black mt-auto"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default JudgeSpecialCard;
