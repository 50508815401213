import { AddEventForm, Footer, ScrollToTop } from "..";

const AddEventContainer = () => {
  return (
    <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[330px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
      <div className="mb-auto">
        {/* Heading */}
        <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
          <h2>Add Event</h2>
          <img alt="arrow" src="/images/ArrowDown.png" className="w-4 h-4" />
        </div>

        {/* Add event form */}
        <div className="">
          <AddEventForm />
        </div>
      </div>
      <ScrollToTop />
      <Footer />
    </div>
  );
};

export default AddEventContainer;
