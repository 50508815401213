/* eslint-disable react-hooks/exhaustive-deps */
import Footer from './components/Footer';
import Header from './components/Header';
import LeftSidebar from './components/LeftSidebar';
import RightSidebar from './components/RightSidebar';
import ScrollToTop from './ScrollToTop';
import DancersFeeTable from './components/DancersFeeTable';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAddDancer } from 'redux/danceStudio/danceStudioSlice';
import { useState } from 'react';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { useFirestoreQueryDoc } from 'components/hooks/useFirestoreQueryDoc';
import { useQueryAllDocs } from 'components/hooks/useQueryAllDocs';
import { useQueryAllSubCollectionDocs } from 'components/hooks/useQueryAllSubCollectionDocs';
import AddDancerContainer from './components/AddDancerContainer';
import { useParams } from 'react-router-dom';
import DancersFeeContainer from './components/DancersFeeContainer';

const DancersFee = () => {
  const { openNotifsBoard, addDancer } = useSelector(
    (state) => state.danceStudio
  );
  const dispatch = useDispatch();

  const { event_name: name } = useParams();

  const event_name = name || localStorage.getItem('event_name') || '';
  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );
  const { compId } = danceStudioCompData.value;

  const { data: cpmpData } = useQueryAllDocs('competitions');
  const currentComp = cpmpData?.filter((item) => item?.compId === compId)[0];

  const currentEvent = currentComp?.events?.filter(
    (item) => item?.event_name.replace(/ /g, '-') === event_name
  )[0];

  //to get competition name
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { data: studio_data } = useFirestoreQueryDoc(
    'studio_owners',
    user?.uid
  );

  // to get all dancers
  const { status, data: allDancers } = useQueryAllSubCollectionDocs(
    'competitions',
    compId,
    'dancers'
  );

  const myDancers = allDancers?.filter((item) => item?.studioId === user?.uid);

  const [addDancerMod, setAddDancerMod] = useState(false);

  // handle the search input change for dancers
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const result = myDancers?.filter(
      (itm) =>
        itm?.first_name?.toLowerCase()?.includes(query?.toLowerCase()) ||
        itm?.last_name?.toLowerCase()?.includes(query?.toLowerCase())
    );
    setSearchResults(result);
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [sortDown, setSortDown] = useState('latest');

  const handleSortClick = () => {
    setShowDropdown((prev) => !prev);
  };

  const { data } = useQueryAllDocs('competitions');
  const availableComps = data?.filter((item) => item?.eligibilityDivisions);

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}

      {/* event details page */}
      {!addDancer && (
        <div
          onClick={() => setShowDropdown(false)}
          className={`w-full min-h-screen text-white/80 px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px]  ${
            openNotifsBoard ? 'lg:pr-[310px]' : 'lg:pr-[32px]'
          } pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col transition-all duration-700`}
        >
          <div className="flex gap-2 items-center text-white/90 text-[1rem] mb-10">
            <h2>
              {capitalizeFirstLetter(currentEvent?.event_name?.trim())}{' '}
              {currentEvent?.year.split(' to ')[0]}
            </h2>
            <img alt="arrow" src="/images/ArrowDown.png" className="w-4 h-4" />
          </div>
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/90 text-[1rem] mb-6">
              <h2>{capitalizeFirstLetter('Dancer Fees')} </h2>
            </div>

            <div className="w-full min-h-[200px]">
              <div className="w-[80%] p-2 bg-[#282929] rounded-lg flex justify-between">
                <div className="flex gap-2 items-center">
                  <img
                    alt="arrow"
                    src="/images/plus.png"
                    className="w-7 h-7 cursor-pointer"
                    onClick={() => dispatch(toggleAddDancer())}
                  />

                  <div className="relative">
                    <img
                      alt="arrow"
                      src="/images/updown.png"
                      className="w-7 h-7 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSortClick();
                      }}
                    />
                    {/* the sorting mechanism */}
                    {showDropdown && (
                      <DancersFeeContainer
                        setSortDown={setSortDown}
                        sortDown={sortDown}
                      />
                    )}
                  </div>
                </div>
                {/* search */}
                <div
                  className={`h-[30px] flex mr-2 ml-auto border rounded-lg ${
                    searchQuery ? 'border-[#94a4fd]' : 'border-white/10'
                  }`}
                >
                  <div className="h-full w-fit p-1 bg-[#333333] rounded-l-lg">
                    <img
                      alt="user"
                      src="/images/Search.png"
                      className="w-5 h-5 cursor-pointer"
                    />
                  </div>
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="w-[130px] text-[.9em] bg-[#333333] rounded-r-lg outline-none pr-2 py-1"
                  />
                </div>
              </div>
              {/* registration table */}

              <DancersFeeTable
                allDancers={myDancers}
                searchResults={searchResults}
                searchQuery={searchQuery}
                currentEvent={currentEvent}
                availableComps={availableComps}
                status={status}
                sortDown={sortDown}
              />
              {searchQuery ? (
                <div className="flex justify-center items-center text-white/80 text-[.85rem] mt-2">
                  <p> Showing {searchResults?.length} results</p>
                </div>
              ) : null}
            </div>
          </div>

          <ScrollToTop />
          <Footer />
        </div>
      )}

      {/* the add dancer pop up page */}
      {addDancer && <AddDancerContainer studio_data={studio_data} />}

      {addDancerMod && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/80 flex justify-center">
          <div className="w-full sm:w-[450px] h-fit p-5 bg-[#282929] rounded-lg flex flex-col gap-5 justify-center items-center scale shadow-lg">
            <div className="w-full text-[.85em] sm:text-[1rem] text-white pb-3 border-b border-[#c5c4c4]/50 relative">
              <p>Please add at lease one dancer!</p>
            </div>
            <button
              className="w-full px-2 py-3 rounded-lg bg-gray-500 hover:bg-gray-400 text-white"
              onClick={() => {
                setAddDancerMod(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default DancersFee;
