import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from '../redux/auth/authSlice';
import danceStudioSlice, {
  competitionsSliceReducer,
} from './danceStudio/danceStudioSlice';
import eventManagement from './eventManagement/eventManagement';
import judgeSlice from './judge/judgeSlice';
import emceeSlice from './emcee/emceeSlice';
import {
  awardReducer,
  competitionLevelReducer,
  danceGenresReducer,
  deductionCriteriaReducer,
  eligibilityDivisionReducer,
  entryTypeReducer,
  specialEntryReducer,
  formCompletedReducer,
  judgeCriteriaReducer,
  organizerCustomClaimReducer,
  scheduleReducer,
  pricingReducer,
} from './organizerRegistration/organizerSlices';
import organizerSchedulingSlice from './organizerScheduling/organizerSchedulingSlice';
import routineSchedulingSlice from './routineScheduling/routineSchedulingSlice';

// Define the ignored action types as constants
const FLUSH = 'persist/FLUSH';
const REHYDRATE = 'persist/REHYDRATE';
const PAUSE = 'persist/PAUSE';
const PERSIST = 'persist/PERSIST';
const PURGE = 'persist/PURGE';
const REGISTER = 'persist/REGISTER';
const SERIALIZE = 'danceStudio/setCompetitionssData';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['persistReducer'], // Exclude the slice from being persisted
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    auth: authReducer,
    danceGenres: danceGenresReducer,
    eligibilityDivisions: eligibilityDivisionReducer,
    competitionLevel: competitionLevelReducer,
    entryTypes: entryTypeReducer,
    specialEntryTypes: specialEntryReducer,
    judgeCriteria: judgeCriteriaReducer,
    deductionCriteria: deductionCriteriaReducer,
    awards: awardReducer,
    scheduling: scheduleReducer,
    pricing: pricingReducer,
    organizerCustomClaim: organizerCustomClaimReducer,
    danceStudioCompData: competitionsSliceReducer,

    // Add other reducers for other microservices as needed
  })
);

const store = configureStore({
  reducer: {
    persistedReducer,
    eventManagement: eventManagement,
    danceStudio: danceStudioSlice,
    organizerScheduling: organizerSchedulingSlice,
    judge: judgeSlice,
    emcee: emceeSlice,
    routineScheduling: routineSchedulingSlice,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          SERIALIZE,
        ],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
