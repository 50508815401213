/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import {
  clearSuccess,
  getEvents,
  updateJudgeSettings,
} from "../../redux/eventManagement/eventManagement";
import { useDispatch } from "react-redux";
import ScrollToTop from "./ScrollToTop";
import { useEffect, useState } from "react";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";
import { PulseLoader } from "react-spinners";
import AddEventContainer from "./components/AddEventContainer";

const DefaultScoringValues = () => {
  const {
    addEvent,
    updateJudgeSettingsSuccess,
    deleteEventSuccess,
    editEventSuccess,
    updateJudgeSettingsPending,
    addToFaveSuccess,
    removeFromFaveSuccess,
    openNotifsBoard,
  } = useSelector((state) => state.eventManagement);

  //getting the user ID from auth
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEvents(user?.uid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deleteEventSuccess,
    editEventSuccess,
    addToFaveSuccess,
    removeFromFaveSuccess,
  ]);

  const { status, data: organizerData } = useFirestoreQueryDoc(
    "organizers",
    user?.uid
  );

  const settingsFromDb = organizerData?.judge_settings;

  const [settingsData, setSettingsData] = useState({
    default_value: 0,
    increment_value: 0,
  });

  useEffect(() => {
    setSettingsData({
      default_value: settingsFromDb?.default_value,
      increment_value: settingsFromDb?.increment_value,
    });
  }, [status]);

  function handleSubmit(e) {
    e.preventDefault();
    if (
      settingsData?.default_value >= 0 &&
      settingsData?.increment_value >= 0
    ) {
      dispatch(updateJudgeSettings({ compId: user?.uid, settingsData }));
    }
  }

  const [edit, setEdit] = useState(false);
  function toggleEdit() {
    setEdit((prev) => !prev);
  }

  useEffect(() => {
    if (updateJudgeSettingsSuccess?.success) {
      setEdit(false);
      setTimeout(() => {
        dispatch(clearSuccess());
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 2000);
    }
  }, [updateJudgeSettingsSuccess]);

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}
      {!addEvent && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[310px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}
            <h2 className="mb-6 text-white/80 text-[1.25rem] font-medium">
              Judge Settings
            </h2>

            {/* Settings container */}
            <div className="w-full p-3 md:p-5 md:max-w-[85%] lg:max-w-[70%] md:min-w-[450px] bg-[#282929] rounded-lg flex flex-col gap-6 mb-auto text-white/80">
              <h1 className="w-full border-b pb-2 font-bold border-white/30">
                Default Scoring Values
              </h1>

              <div className="w-full flex bg-white/10 p-3 rounded-lg text-[.85rem]">
                Default Starting Value:{" "}
                <div className="font-medium pl-2">
                  {" "}
                  {status === "loading" ? (
                    <PulseLoader color="#94a4fd" size={10} />
                  ) : (
                    <div>
                      {edit ? (
                        <input
                          type="text"
                          inputMode="numeric"
                          pattern="^([0-9]\d{0,1}|1\d{0,1}|20)(\.\d{0,2})?$"
                          defaultValue={settingsData?.default_value}
                          onChange={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9.]/g,
                              ""
                            );
                            if (e.target.value > 20) {
                              e.target.value = "20";
                            }
                            setSettingsData((prev) => {
                              return {
                                ...prev,
                                default_value: Number(e.target.value),
                              };
                            });
                          }}
                          className="w-[60px] px-2 rounded-md outline-none border border-[#94a4fd] bg-inherit"
                        />
                      ) : (
                        settingsFromDb?.default_value
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full flex bg-white/10 p-3 rounded-lg text-[.85rem]">
                Score Increment Value:{" "}
                <div className="font-medium pl-2">
                  {" "}
                  {status === "loading" ? (
                    <PulseLoader color="#94a4fd" size={10} />
                  ) : (
                    <div>
                      {edit ? (
                        <input
                          type="text"
                          inputMode="numeric"
                          pattern="^([0-9]\d{0,1}|1\d{0,1}|20)(\.\d{0,2})?$"
                          defaultValue={settingsData?.increment_value}
                          onChange={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9.]/g,
                              ""
                            );
                            if (e.target.value > 20) {
                              e.target.value = "20";
                            }

                            setSettingsData((prev) => {
                              return {
                                ...prev,
                                increment_value: Number(e.target.value),
                              };
                            });
                          }}
                          className="w-[60px] px-2 rounded-md outline-none border border-[#94a4fd] bg-inherit"
                        />
                      ) : (
                        settingsFromDb?.increment_value
                      )}
                    </div>
                  )}
                </div>
              </div>

              {updateJudgeSettingsSuccess?.success && (
                <p className="text-[#94a4fd] ">Values Updated Successfully!!</p>
              )}

              <div className="flex gap-2 justify-end">
                <button
                  onClick={toggleEdit}
                  disabled={updateJudgeSettingsPending}
                  className="px-4 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[.85rem] hover:opacity-75"
                >
                  {edit ? "Cancel Edit" : "Edit Values"}
                </button>

                {edit && (
                  <button
                    onClick={handleSubmit}
                    disabled={updateJudgeSettingsPending}
                    className="px-4 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[.85rem] hover:opacity-75"
                  >
                    {updateJudgeSettingsPending ? (
                      <p className="flex gap-2 items-center">
                        Submitting.. <ButtonLoadingSpinner />
                      </p>
                    ) : (
                      "Submit"
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {/* the add event pop up page */}

      {addEvent && <AddEventContainer />}
    </>
  );
};

export default DefaultScoringValues;
