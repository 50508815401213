import { PDFViewer } from "@react-pdf/renderer";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { useParams } from "react-router-dom";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";

const ReceiptStudioPage = ({ allPayment, billingDetails, compId }) => {
  // Get the location to access the query parameters
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const studioDataParam = queryParams.get("studioData");
  // studioData = JSON.parse(studioDataParam);

  // const { user } = useSelector((state) => state.persistedReducer.auth.user);

  //to get realtime events from firestore
  const { data: comps } = useQueryAllDocs("competitions");
  const organizerComp = comps?.filter((item) => item?.compId === compId)[0];
  const realtimeEvents = organizerComp?.events;
  const { event_name, studio_id } = useParams();

  const currentEvent = realtimeEvents?.filter(
    (item) => item.event_name.replace(/ /g, "-") === event_name
  )[0];

  const currentStudio = currentEvent?.access?.filter(
    (itm) => itm?.studio_id === studio_id
  )[0];

  const { data: allStudios } = useQueryAllDocs("studio_owners");
  const studioData = allStudios?.filter(
    (item) => item?.uid === currentStudio?.studio_id
  )[0];

  //to get and format today's date
  function getFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  //to generate invoice number
  function generateInvNumber() {
    return Math.floor(1000 + Math.random() * 9000);
  }

  const { doc_id } = useParams();
  const filteredPayment = allPayment?.filter((item) => item?.doc_id === doc_id);

  return (
    <>
      <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <Document>
          <Page size="A4" style={styles.page}>
            <Text style={styles.titleInv}>RECEIPT</Text>
            <Text style={styles.title}>
              {billingDetails?.name?.toUpperCase()}
            </Text>

            <View style={styles.sectionDate}>
              <Text style={styles.date}>Date: {getFormattedDate()}</Text>
              <Text style={styles.date}>Receipt#: {generateInvNumber()}</Text>
            </View>

            <View style={styles.sectionSender}>
              <Text style={styles.sender}>
                {capitalizeFirstLetter(billingDetails?.street_address1)}
              </Text>
              <Text style={styles.sender}>
                {billingDetails?.postal_code} ,
                {capitalizeFirstLetter(billingDetails?.city)},{" "}
                {capitalizeFirstLetter(billingDetails?.state_province)}
              </Text>
              <Text style={styles.sender}>
                Tel: {billingDetails?.phone_number}
              </Text>
              <Text style={styles.sender}>
                Tax Number: {billingDetails?.tax_number}
              </Text>
              <Text style={styles.sender}>{billingDetails?.website}</Text>
            </View>

            <View style={styles.sectionSender}>
              <Text style={styles.billto}>Bill To:</Text>
              <Text style={styles.sender}>
                {capitalizeFirstLetter(studioData?.studioName)}
              </Text>
              <Text style={styles.sender}>
                {capitalizeFirstLetter(studioData?.addressLine1)}
              </Text>
              <Text style={styles.sender}>
                {studioData?.postalCode},{" "}
                {capitalizeFirstLetter(studioData?.city)}, {studioData?.state}
              </Text>
              <Text style={styles.sender}>
                {capitalizeFirstLetter(studioData?.ownerFirstName)}{" "}
                {capitalizeFirstLetter(studioData?.ownerLastName)}
              </Text>
              <Text style={styles.sender}>{studioData?.email}</Text>
              <Text style={styles.sender}>{studioData?.website}</Text>
            </View>

            {/* the table section */}
            <View style={styles.sectionTable}>
              <View style={styles.tableRow1}>
                <Text style={styles.tableHeader}>Description</Text>
                <Text style={styles.tableHeader}>Payment Method</Text>
                <Text style={styles.tableHeader}>Amount</Text>
              </View>

              {/* Table rows */}
              {filteredPayment?.map((row, idx) => {
                return (
                  <View key={idx} style={styles.tableRow}>
                    <Text style={styles.tableCell}>
                      {capitalizeFirstLetter(row?.description)}
                    </Text>

                    <Text style={styles.tableCell}>{row?.payment_method}</Text>

                    <Text style={styles.tableCell2}>${row?.amount}</Text>
                  </View>
                );
              })}
            </View>

            {/* the table section */}
            <View style={styles.sectionTable2}>
              <View style={styles.tableRow2}>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={{ ...styles.tableCellTotal, textAlign: "start" }}>
                  SUBTOTAL
                </Text>
                <Text style={styles.tableCellTotal}>
                  {" "}
                  ${filteredPayment?.[0]?.amount}
                </Text>
              </View>

              <View style={styles.tableRow2}>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCellTotal}>.</Text>
                <Text style={styles.tableCellTotal}>.</Text>
              </View>

              <View style={styles.tableRow2}>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={{ ...styles.tableCellTotal, textAlign: "start" }}>
                  TOTAL
                </Text>
                <Text style={styles.tableCellTotal}>
                  ${filteredPayment?.[0]?.amount}
                </Text>
              </View>
            </View>
            <Text
              style={{
                textAlign: "start",
                marginBottom: "10px",
              }}
            >
              Comments
            </Text>
            <View style={styles.sectionComment}>
              <Text style={styles.sender}>
                {/* {capitalizeFirstLetter(billingDetails?.other_comments)} */}
                {capitalizeFirstLetter(filteredPayment?.[0]?.comment)}
              </Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
};

export default ReceiptStudioPage;

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 20,
  },
  section: {
    marginTop: 10,
    marginBottom: 10,
    padding: 10,
    flexGrow: 1,
    border: "1pt solid #000000",
  },
  sectionDate: {
    width: "170px",
    marginBottom: 10,
    marginLeft: "auto",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
  },
  titleInv: {
    fontSize: 30,
    fontWeight: "bold",
    marginBottom: 10,
    color: "#f97316",
    textAlign: "right",
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 5,
  },
  billto: {
    fontSize: 14,
    marginBottom: 5,
    backgroundColor: "#f97316",
    color: "white",
    width: "50px",
    letterSpacing: ".85px",
  },
  date: {
    fontSize: "12px",
    padding: 5,
    marginTop: 8,
    borderBottom: "1pt solid #475569",
  },
  sectionSender: {
    width: "300px",
    marginBottom: 10,
    marginRight: "auto",
  },

  sectionComment: {
    width: "300px",
    height: "100px",
    padding: "10px",
    marginBottom: 10,
    marginRight: "auto",
    border: "1px solid black",
  },
  sender: {
    fontSize: "12px",
    letterSpacing: ".85px",
  },

  sectionTable: {
    marginTop: 10,
    border: "1pt solid #000000",
  },

  sectionTable2: {
    marginBottom: 10,
  },

  tableRow1: {
    flexDirection: "row",
    borderBottom: "1pt solid #000000",
    alignItems: "center",
    padding: 5,
    backgroundColor: "#f97316",
    color: "white",
  },

  tableRow: {
    flexDirection: "row",
    borderBottom: "1pt solid #000000",
    alignItems: "center",
  },

  tableRow2: {
    flexDirection: "row",
    alignItems: "center",
  },

  tableHeader: {
    fontSize: 12,
    fontWeight: "bold",
    flex: 1,
    textAlign: "center",
  },

  tableCell: {
    fontSize: 12,
    flex: 1,
    textAlign: "center",
    padding: 5,
  },

  tableCellTotal: {
    fontSize: 12,
    flex: 1,
    textAlign: "center",
    padding: 5,
    borderBottom: "1pt solid #9ca3af",
  },

  tableCell2: {
    fontSize: 12,
    flex: 1,
    textAlign: "center",
    backgroundColor: "#fdba74",
    padding: 5,
  },
});
