import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const TabulatorUpdateTable = ({
  currentUpdateEntryScores,
  currentEntryScores,
  calculateAverageScore,
  eventScores,
  allLiveDeductions,
}) => {
  const sum =
    [
      Number(calculateAverageScore(currentUpdateEntryScores, "technique")),
      Number(calculateAverageScore(currentUpdateEntryScores, "creativity")),
      Number(calculateAverageScore(currentUpdateEntryScores, "staging")),
      Number(calculateAverageScore(currentUpdateEntryScores, "execution")),
      Number(calculateAverageScore(currentUpdateEntryScores, "performance")),
    ]?.reduce((accumulator, currentValue) => accumulator + currentValue, 0) -
    getDeduction();

  function getDeduction() {
    const lastCharacters = allLiveDeductions?.map((str) => {
      if (str?.length > 0) {
        return Number(str?.slice(-1));
      } else {
        return "";
      }
    });

    const ded = lastCharacters?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    return ded;
  }

  const { data: allJudges } = useQueryAllDocs("judges");

  function getJudgeName(id) {
    const judge = allJudges?.filter((x) => x?.uid === id)[0]?.first_name;
    return judge;
  }

  return (
    <table className="min-w-[360px] w-full text-[.8em]">
      <thead className="text-white/40 text-left border-b border-white/30">
        <tr>
          <th></th>
          <th>Total</th>
          <th>T/F</th>
          <th>C/O</th>
          <th>S/D</th>
          <th>E/D</th>
          <th>P/E</th>
        </tr>
      </thead>
      <tbody className="text-white/80">
        <tr>
          <td>All</td>
          {/* <td>{calculateAverageScore(currentUpdateEntryScores, "total")}</td> */}
          <td>{sum?.toFixed(2)}</td>
          <td>
            {calculateAverageScore(currentUpdateEntryScores, "technique")}
          </td>
          <td>
            {calculateAverageScore(currentUpdateEntryScores, "creativity")}
          </td>
          <td>{calculateAverageScore(currentUpdateEntryScores, "staging")}</td>
          <td>
            {calculateAverageScore(currentUpdateEntryScores, "execution")}
          </td>
          <td>
            {calculateAverageScore(currentUpdateEntryScores, "performance")}
          </td>
        </tr>
        {currentUpdateEntryScores
          ?.sort((a, b) => {
            const nameA = getJudgeName(a?.judge_id)?.toLowerCase();
            const nameB = getJudgeName(b?.judge_id)?.toLowerCase();

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
          ?.map((item, index) => {
            return (
              <tr key={index}>
                <td className="whitespace-nowrap">
                  {capitalizeFirstLetter(getJudgeName(item?.judge_id))}
                </td>
                <td>{item?.total}</td>
                <td>{item?.technique || "N/Q"}</td>
                <td>{item?.creativity}</td>
                <td>{item?.staging}</td>
                <td>{item?.execution}</td>
                <td>{item?.performance}</td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default TabulatorUpdateTable;
