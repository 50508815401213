/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import UserDancerTableRow from "./UserDancerTableRow";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import moment from "moment";

const UserDancersTable = ({
  allDancers,
  searchQuery,
  currentEvent,
  status,
  sortDown,
  searchResults,
}) => {
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  // const { data: studioData } = useFirestoreQueryDoc("studio_owners", user?.uid);

  //to get all entries
  const { data: allEntries } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "entries"
  );

  const eventEntries = allEntries?.filter(
    (each) => each?.event_uid === currentEvent?.event_uid
  );

  function calculateAge(dateOfBirth) {
    // Check if the date string is in "dd-mm-yyyy" or "yyyy-mm-dd" format
    const regex1 = /^(\d{2})-(\d{2})-(\d{4})$/;
    const regex2 = /^(\d{4})-(\d{2})-(\d{2})$/;

    let birthDate;
    if (regex1.test(dateOfBirth)) {
      // If the date string is in "dd-mm-yyyy" format, convert it to a Date object
      const [day, month, year] = dateOfBirth.split("-");
      birthDate = new Date(year, month - 1, day);
    } else if (regex2.test(dateOfBirth)) {
      // If the date string is in "yyyy-mm-dd" format, convert it to a Date object
      const [year, month, day] = dateOfBirth.split("-");
      birthDate = new Date(year, month - 1, day);
    } else {
      // If the date string is not in a recognized format, return null
      return null;
    }

    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  }

  function calculateAge2(dateOfBirth, currentYear) {
    // Split the dateOfBirth string into year, month, and day parts
    const dobParts = dateOfBirth.split("-");
    const dobYear = parseInt(dobParts[0], 10);
    const dobMonth = parseInt(dobParts[1], 10);
    const dobDay = parseInt(dobParts[2], 10);

    // Create Date objects for the date of birth and January 1st of the current year
    const birthDate = new Date(dobYear, dobMonth - 1, dobDay);
    const januaryFirst = new Date(currentYear, 0, 1);

    // Calculate the age by subtracting the birthDate from January 1st
    const ageInMilliseconds = januaryFirst - birthDate;
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Taking leap years into account

    return Math.floor(ageInYears);
  }

  function convertDateFormat(dateString) {
    // Check if the date string is in "yyyy-mm-dd" format
    const regex = /^(\d{4})-(\d{2})-(\d{2})$/;
    if (regex.test(dateString)) {
      return dateString; // Return the date as is if it's already in the desired format
    }

    // Try to parse the date in different formats
    const dateFormats = ["M/D/YY", "YYYY-MM-DD", "MM-DD-YYYY"];
    for (const format of dateFormats) {
      const parsedDate = moment(dateString, format, true); // Parse the date with strict parsing
      if (parsedDate.isValid()) {
        return parsedDate.format("YYYY-MM-DD");
      }
    }

    return dateString; // Return the original date string if none of the formats match
  }

  // function calculateAge(dateOfBirth) {
  //   if (dateOfBirth) {
  //     const today = new Date();
  //     const birthDate = new Date(dateOfBirth);
  //     let age = today.getFullYear() - birthDate.getFullYear();
  //     const monthDifference = today.getMonth() - birthDate.getMonth();
  //     if (
  //       monthDifference < 0 ||
  //       (monthDifference === 0 && today.getDate() < birthDate.getDate())
  //     ) {
  //       age--;
  //     }
  //     return age;
  //   }
  // }

  // function convertDateFormat(dateString) {
  //   const regex = /^(\d{2})-(\d{2})-(\d{4})$/;
  //   if (!regex.test(dateString)) {
  //     return dateString; // Return the original string if not in "mm-dd-yyyy" format
  //   }

  //   const parts = dateString.split("-");
  //   const formattedDate = `${parts[2]}-${parts[0].padStart(
  //     2,
  //     "0"
  //   )}-${parts[1].padStart(2, "0")}`;
  //   return formattedDate;
  // }

  let arranged = allDancers && [...allDancers];

  // eslint-disable-next-line array-callback-return
  arranged?.sort((a, b) => {
    const dateA = new Date(a.last_update.replace(",", ""));
    const dateB = new Date(b.last_update.replace(",", ""));

    if (sortDown === "oldest") {
      return dateA - dateB; // Oldest to newest
    } else if (sortDown === "latest") {
      return dateB - dateA; // Newest to oldest
    } else if (sortDown === "alphabet") {
      const nameA = a.first_name.toUpperCase();
      const nameB = b.first_name.toUpperCase();

      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    } else if (sortDown === "gender") {
      const genderA = a.gender.toUpperCase();
      const genderB = b.gender.toUpperCase();

      return genderA < genderB ? -1 : genderA > genderB ? 1 : 0;
    } else if (sortDown === "class") {
      const classA = a.classification.toUpperCase();
      const classB = b.classification.toUpperCase();

      return classA < classB ? -1 : classA > classB ? 1 : 0;
    } else if (sortDown === "studio") {
      const classA = a.studio_name.toUpperCase();
      const classB = b.studio_name.toUpperCase();

      return classA < classB ? -1 : classA > classB ? 1 : 0;
    } else if (sortDown === "age") {
      const ageA = calculateAge(convertDateFormat(a?.date_of_birth));
      const ageB = calculateAge(convertDateFormat(b?.date_of_birth));

      return ageA < ageB ? -1 : ageA > ageB ? 1 : 0;
    } else if (sortDown === "note") {
      const noteA = a?.notes?.toUpperCase();
      const noteB = b?.notes?.toUpperCase();

      return noteA < noteB ? -1 : noteA > noteB ? 1 : 0;
    }
  });
  //to handle registrations table pagination
  // eslint-disable-next-line no-unused-vars

  const [dancersDataPag, setdancersDataPag] = useState();
  useEffect(() => {
    setdancersDataPag(arranged);
  }, [status, sortDown, allDancers]);

  const [pageNumber, setPageNumber] = useState(0);

  const rowPerPage = 8;
  const pagesVisited = pageNumber * rowPerPage;

  const displayRows = dancersDataPag
    ?.slice(pagesVisited, pagesVisited + rowPerPage)
    ?.map((row, index) => {
      return (
        <UserDancerTableRow
          row={row}
          key={index}
          calculateAge={calculateAge}
          calculateAge2={calculateAge2}
          arranged={arranged}
          currentEvent={currentEvent}
          convertDateFormat={convertDateFormat}
          eventEntries={eventEntries}
        />
      );
    });

  const displaySearchedRows = searchResults
    ?.slice(pagesVisited, pagesVisited + rowPerPage)
    ?.map((row, index) => {
      return (
        <UserDancerTableRow
          row={row}
          key={index}
          calculateAge={calculateAge}
          calculateAge2={calculateAge2}
          arranged={arranged}
          currentEvent={currentEvent}
          convertDateFormat={convertDateFormat}
          eventEntries={eventEntries}
        />
      );
    });

  const pageCount = Math.ceil(dancersDataPag?.length / rowPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // console.log("event_uid", currentEvent);

  return (
    <>
      {
        <div className="w-full h-full mt-5 pb-10 overflow-auto border border-white/20 px-3 rounded-lg">
          {!searchQuery && (
            <table className="min-w-[600px] w-full text-[.85rem] table2">
              <thead className="text-white/40 text-left border-b border-white/30">
                <tr className="whitespace-nowrap">
                  <th>Dancer Name</th>
                  <th>Gender</th>
                  <th>Classification</th>
                  <th>Date of birth</th>
                  <th>Comp. Age</th>
                  <th>Studio</th>
                  <th>Notes</th>
                  <th>Last Update</th>
                  <th>Scheduling Status</th>
                </tr>
              </thead>
              {arranged?.length > 8 ? (
                <tbody className="text-white/80">{displayRows}</tbody>
              ) : (
                <tbody className="transition-all duration-500">
                  {arranged?.map((row, index) => {
                    return (
                      <UserDancerTableRow
                        row={row}
                        key={index}
                        calculateAge={calculateAge}
                        calculateAge2={calculateAge2}
                        arranged={arranged}
                        currentEvent={currentEvent}
                        convertDateFormat={convertDateFormat}
                        eventEntries={eventEntries}
                      />
                    );
                  })}
                </tbody>
              )}
            </table>
          )}

          {searchQuery && searchResults && (
            <table className="min-w-[600px] w-full text-[.85rem] table2">
              <thead className="text-white/40 text-left border-b border-white/30">
                <tr className="whitespace-nowrap">
                  <th>Dancer Name</th>
                  <th>Gender</th>
                  <th>Classification</th>
                  <th>Date of birth</th>
                  <th>Comp. Age</th>
                  <th>Number of Entries</th>
                  <th>Last Update</th>
                  <th>Scheduling Status</th>
                </tr>
              </thead>
              {searchResults.length > 8 ? (
                <tbody className="text-white/80">{displaySearchedRows}</tbody>
              ) : (
                <tbody className="transition-all duration-500">
                  {searchResults?.map((row, index) => {
                    return (
                      <UserDancerTableRow
                        row={row}
                        key={index}
                        calculateAge={calculateAge}
                        calculateAge2={calculateAge2}
                        arranged={arranged}
                        currentEvent={currentEvent}
                        convertDateFormat={convertDateFormat}
                        eventEntries={eventEntries}
                      />
                    );
                  })}
                </tbody>
              )}
            </table>
          )}
          {searchQuery && searchResults?.length === 0 && (
            <p className="w-full py-8 text-center">No results found</p>
          )}

          {arranged?.length < 1 && status === "success" && (
            <p className="w-full h-[100px] flex items-center justify-center text-center mt-10 text-[1rem] text-white/50 border border-white/10 rounded-lg">
              Dancers are not yet registered for this competition..{" "}
            </p>
          )}
          {arranged?.length > 0 && (
            <div className="text-[.75rem] text-white/40 mt-4 lg:">
              Scroll horizontally to view more, if needed
            </div>
          )}
        </div>
      }
      {/* pagination */}
      {dancersDataPag?.length > 8 && (
        <div className="flex gap-2 mt-4 justify-end">
          <ReactPaginate
            previousLabel={
              <img
                alt="user"
                src="/images/leftArrow.png"
                className="w-6 h-6 cursor-pointer rounded-lg hover:bg-white/10"
              />
            }
            nextLabel={
              <img
                alt="user"
                src="/images/rightArrow.png"
                className="w-6 h-6 cursor-pointer rounded-lg hover:bg-white/10"
              />
            }
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={
              "h-6 flex items-center gap-2 mt-4 justify-end text-[.95rem] pag"
            }
            activeClassName={"rounded-lg bg-white/10"}
          />
        </div>
      )}
    </>
  );
};

export default UserDancersTable;
