/* eslint-disable react-hooks/exhaustive-deps */
import { useFirestoreQueryDoc } from 'components/hooks/useFirestoreQueryDoc';
import { useQueryAllSubCollectionDocs } from 'components/hooks/useQueryAllSubCollectionDocs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DancersFeePrint from './components/DancersFeePrint';
import ScrollToTop from './ScrollToTop';
import { useQueryAllDocs } from 'components/hooks/useQueryAllDocs';

const DancersFeePageContainer = () => {
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );
  const { compId } = danceStudioCompData.value;

  const { event_name: name, dancer_uid } = useParams();

  const event_name = name || localStorage.getItem('event_name') || '';
  //to get realtime events from firestore
  const { data } = useQueryAllDocs('competitions');
  const currentComp = data?.filter((item) => item?.compId === compId)[0];
  const currentEvent = currentComp?.events?.filter(
    (item) => item.event_name?.replace(/ /g, '-') === event_name
  )[0];

  const { data: studioData } = useFirestoreQueryDoc('studio_owners', user?.uid);

  //==========================================================================to get all entrytypes & pricing

  const { status: compStatus, data: compData } = useFirestoreQueryDoc(
    'competitions',
    compId
  );

  const [taxRate, setTaxRate] = useState(0.0);

  const [entryTypes, setentryTypes] = useState([]);
  const [pricing, setpricing] = useState([]);
  const [billingDetails, setbillingDetails] = useState([]);

  useEffect(() => {
    if (compData) {
      const { entryTypes, pricing, billing_details, events } = compData;
      setentryTypes(entryTypes);
      setpricing(pricing?.pricingCategories);

      const currentEve = events?.filter(
        (itm) => itm?.event_uid === currentEvent?.event_uid
      )[0];
      setTaxRate(currentEve?.tax_rate || 0);
      setbillingDetails(billing_details);
    }
  }, [compData, compStatus]);

  const allEntryTypes = entryTypes?.concat(
    compData?.specialEntryTypes?.tags || []
  );

  //====================================================================================to get all entries
  const { data: allEntries } = useQueryAllSubCollectionDocs(
    'competitions',
    compId,
    'entries'
  );

  const studioEntries = allEntries?.filter(
    (each) =>
      each?.event_uid === currentEvent?.event_uid &&
      each?.studio_selected[0] === studioData?.studioName
  );

  const studioEntriesWithDancer = studioEntries?.filter((entry) =>
    entry?.dancers_selected.some(
      (dancerSelected) => dancerSelected?.dancer_uid === dancer_uid
    )
  );

  // ====================================================================================Calculate the totalAmount for the specific dancer
  const newTotalAmountForDancer = allEntryTypes
    ?.reduce((total, row) => {
      let rowPricingOption;
      if (row?.entryTypeName) {
        rowPricingOption = pricing?.find((obj) =>
          obj.hasOwnProperty(row.entryTypeName)
        );
      } else if (row?.specialEntryTypeName) {
        rowPricingOption = pricing?.find((obj) =>
          obj.hasOwnProperty(row.specialEntryTypeName)
        );
      }
      const rowEntriesForDancer = studioEntriesWithDancer?.filter(
        (itm) =>
          itm?.entryType === row?.entryTypeName ||
          itm?.entryType === row?.specialEntryTypeName
      );

      const flatAmount =
        rowEntriesForDancer?.length > 0
          ? rowPricingOption?.[row?.entryTypeName] ||
            rowPricingOption?.[row?.specialEntryTypeName] *
              rowEntriesForDancer?.length
          : 0.0;

      const rowAmount =
        rowPricingOption?.selectedOption?.toLowerCase() === 'flat'
          ? Number(flatAmount)
          : rowEntriesForDancer?.length *
            Number(
              rowPricingOption?.[row?.entryTypeName] ||
                rowPricingOption?.[row?.specialEntryTypeName]
            );

      return total + (rowAmount || 0);
    }, 0)
    .toFixed(2);

  function calcTotalAfterTax(amount, percentage) {
    const result = (amount * percentage) / 100;
    return result.toFixed(2);
  }
  const tax = Number(
    calcTotalAfterTax(newTotalAmountForDancer, taxRate)
  ).toFixed(2);
  const totalAfterTaxForDancer =
    Number(newTotalAmountForDancer) +
    Number(calcTotalAfterTax(newTotalAmountForDancer, taxRate));

  return (
    <>
      {/* the invoice page overlay */}
      <section className="w-full h-screen fixed top-0 left-0">
        <DancersFeePrint
          entryTypes={entryTypes}
          allEntryTypes={allEntryTypes}
          studioEntries={studioEntries}
          pricing={pricing}
          newTotalAmountForDancer={newTotalAmountForDancer}
          taxRate={taxRate}
          totalAfterTaxForDancer={totalAfterTaxForDancer}
          tax={tax}
          billingDetails={billingDetails}
          studioEntriesWithDancer={studioEntriesWithDancer}
        />

        <ScrollToTop />
      </section>
    </>
  );
};

export default DancersFeePageContainer;
