/* eslint-disable react-hooks/exhaustive-deps */
import Footer from './components/Footer';
import Header from './components/Header';
import LeftSidebar from './components/LeftSidebar';
import RightSidebar from './components/RightSidebar';
import ScrollToTop from './ScrollToTop';
import DancersTable from './components/DancersTable';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAddDancer } from 'redux/danceStudio/danceStudioSlice';
import { useState } from 'react';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { useFirestoreQueryDoc } from 'components/hooks/useFirestoreQueryDoc';
import { useQueryAllDocs } from 'components/hooks/useQueryAllDocs';
import { useQueryAllSubCollectionDocs } from 'components/hooks/useQueryAllSubCollectionDocs';
import AddDancerContainer from './components/AddDancerContainer';
import EditDancerContainer from './components/EditDancerContainer';
import { useParams } from 'react-router-dom';
import DancerSortContainer from './components/DancerSortContainer';

const Dancers = () => {
  const { openNotifsBoard, addDancer, editIdDancers, editDancers } =
    useSelector((state) => state.danceStudio);
  const dispatch = useDispatch();

  const { event_name: name } = useParams();

  const event_name = name || localStorage.getItem('event_name') || '';
  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );
  const { compId } = danceStudioCompData.value;

  const { data: cpmpData } = useQueryAllDocs('competitions');
  const currentComp = cpmpData?.filter((item) => item?.compId === compId)[0];

  const currentEvent = currentComp?.events?.filter(
    (item) => item?.event_name.replace(/ /g, '-') === event_name
  )[0];

  // console.log("currentEvent", currentEvent);

  //to get competition name
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { data: studio_data } = useFirestoreQueryDoc(
    'studio_owners',
    user?.uid
  );

  // to get all dancers
  const { status, data: allDancers } = useQueryAllSubCollectionDocs(
    'competitions',
    compId,
    'dancers'
  );

  const myDancers = allDancers?.filter((item) => item?.studioId === user?.uid);

  //to get current event being editted
  const dancerToEdit = editIdDancers
    ? myDancers?.filter((item) => item?.dancer_uid === editIdDancers)[0]
    : null;

  const [addDancerMod, setAddDancerMod] = useState(false);
  function handleAddDancers() {
    dispatch(toggleAddDancer());
  }
  // handle the search input change for dancers
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const result = myDancers?.filter(
      (itm) =>
        itm?.first_name?.toLowerCase()?.includes(query?.toLowerCase()) ||
        itm?.last_name?.toLowerCase()?.includes(query?.toLowerCase())
    );
    setSearchResults(result);
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [sortDown, setSortDown] = useState('latest');

  const handleSortClick = () => {
    setShowDropdown((prev) => !prev);
  };

  const { data } = useQueryAllDocs('competitions');
  const availableComps = data?.filter((item) => item?.eligibilityDivisions);

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}

      {/* event details page */}
      {!addDancer && !editDancers && (
        <div
          onClick={() => setShowDropdown(false)}
          className={`w-full min-h-screen text-white/80 px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px]  ${
            openNotifsBoard ? 'lg:pr-[310px]' : 'lg:pr-[32px]'
          } pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col transition-all duration-700`}
        >
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/90 text-[1rem] mb-6">
              <h2>
                {capitalizeFirstLetter(studio_data?.studioName)}{' '}
                {/* {currentEvent?.year.split(" to ")[0]} */}
              </h2>
            </div>

            <div className="w-full min-h-[200px]">
              <div className="w-full p-2 bg-[#282929] rounded-lg flex justify-between">
                <div className="flex gap-2 items-center">
                  <img
                    alt="arrow"
                    src="/images/plus.png"
                    className="w-7 h-7 cursor-pointer"
                    onClick={() => dispatch(toggleAddDancer())}
                  />

                  <div className="relative">
                    <img
                      alt="arrow"
                      src="/images/updown.png"
                      className="w-7 h-7 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSortClick();
                      }}
                    />
                    {/* the sorting mechanism */}
                    {showDropdown && (
                      <DancerSortContainer
                        setSortDown={setSortDown}
                        sortDown={sortDown}
                      />
                    )}
                  </div>
                </div>
                {/* search */}
                <div
                  className={`h-[30px] flex mr-2 ml-auto border rounded-lg ${
                    searchQuery ? 'border-[#94a4fd]' : 'border-white/10'
                  }`}
                >
                  <div className="h-full w-fit p-1 bg-[#333333] rounded-l-lg">
                    <img
                      alt="user"
                      src="/images/Search.png"
                      className="w-5 h-5 cursor-pointer"
                    />
                  </div>
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="w-[130px] text-[.9em] bg-[#333333] rounded-r-lg outline-none pr-2 py-1"
                  />
                </div>
              </div>
              {/* registration table */}

              <DancersTable
                allDancers={myDancers}
                searchResults={searchResults}
                searchQuery={searchQuery}
                currentEvent={currentEvent}
                availableComps={availableComps}
                status={status}
                sortDown={sortDown}
              />
              {searchQuery ? (
                <div className="flex justify-center items-center text-white/80 text-[.85rem] mt-2">
                  <p> Showing {searchResults?.length} results</p>
                </div>
              ) : null}
            </div>
            <button
              onClick={() => handleAddDancers()}
              className="text-[.85rem] px-5 py-1 mr-2 mt-5 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
            >
              Add Dancers
            </button>
          </div>

          <ScrollToTop />
          <Footer />
        </div>
      )}

      {/* the add dancer pop up page */}
      {addDancer && <AddDancerContainer studio_data={studio_data} />}

      {/* the edit dancer pop up page */}

      {editDancers && (
        <EditDancerContainer
          studio_data={studio_data}
          dancerToEdit={dancerToEdit}
          currentEvent={currentEvent}
        />
      )}
      {addDancerMod && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/80 flex justify-center">
          <div className="w-full sm:w-[450px] h-fit p-5 bg-[#282929] rounded-lg flex flex-col gap-5 justify-center items-center scale shadow-lg">
            <div className="w-full text-[.85em] sm:text-[1rem] text-white pb-3 border-b border-[#c5c4c4]/50 relative">
              <p>Please add at lease one dancer!</p>
            </div>
            <button
              className="w-full px-2 py-3 rounded-lg bg-gray-500 hover:bg-gray-400 text-white"
              onClick={() => {
                setAddDancerMod(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Dancers;
