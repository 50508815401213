/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import {
  clearSuccess,
  getEvents,
  toggleRank,
  toggleScoresVisibility,
  toggleStudioVisibility,
  updateJudgeSettings,
} from "../../redux/eventManagement/eventManagement";
import { useDispatch } from "react-redux";
import ScrollToTop from "./ScrollToTop";
import { useEffect, useState } from "react";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { PulseLoader } from "react-spinners";
import AddEventContainer from "./components/AddEventContainer";
import TopScorersSettingsModal from "./components/TopScorersSettingsModal";

const CompetitonSettings = () => {
  const {
    addEvent,
    updateJudgeSettingsSuccess,
    deleteEventSuccess,
    editEventSuccess,
    updateJudgeSettingsPending,
    addToFaveSuccess,
    removeFromFaveSuccess,
    openNotifsBoard,
    updateNowPending,
  } = useSelector((state) => state.eventManagement);

  //getting the user ID from auth
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEvents(user?.uid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deleteEventSuccess,
    editEventSuccess,
    addToFaveSuccess,
    removeFromFaveSuccess,
  ]);

  const { status, data: organizerData } = useFirestoreQueryDoc(
    "organizers",
    user?.uid
  );

  const settingsFromDb = organizerData?.judge_settings;

  const [settingsData, setSettingsData] = useState({
    default_value: 0,
    increment_value: 0,
  });

  useEffect(() => {
    setSettingsData({
      default_value: settingsFromDb?.default_value,
      increment_value: settingsFromDb?.increment_value,
    });
  }, [status]);

  function handleSubmit(e) {
    e.preventDefault();
    if (
      settingsData?.default_value >= 0 &&
      settingsData?.increment_value >= 0
    ) {
      dispatch(updateJudgeSettings({ compId: user?.uid, settingsData }));
    }
  }

  const [edit, setEdit] = useState(false);
  function toggleEdit() {
    setEdit((prev) => !prev);
  }

  useEffect(() => {
    if (updateJudgeSettingsSuccess?.success) {
      setEdit(false);
      setTimeout(() => {
        dispatch(clearSuccess());
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 2000);
    }
  }, [updateJudgeSettingsSuccess]);

  //=====================================================================================to get realtime events from firestore
  const { data } = useQueryAllDocs("competitions");
  const organizerComp = data?.filter((item) => item?.compId === user?.uid)[0];
  const realtimeEvents = organizerComp?.events;

  const [selectEvent, setSelectEvent] = useState(null);
  const selectedEvent = realtimeEvents?.filter(
    (x) => x?.event_uid === selectEvent?.event_uid
  )[0];
  function toggleSelect(item) {
    setSelectEvent((prev) => (prev ? null : item));
  }

  const [checkboxData, setcheckboxData] = useState({});

  // console.log("selectedEvent", selectedEvent);

  useEffect(() => {
    if (organizerComp) {
      selectEvent?.genre_visibility
        ? setcheckboxData(selectEvent?.genre_visibility)
        : setcheckboxData(organizerComp?.danceGenres);
    }
  }, [organizerComp, selectEvent]);

  const checkboxes = Object.keys(checkboxData);
  // const allfalse = Object.values(checkboxData)?.filter(
  //   (x) => x?.toString() === "true"
  // );

  const handleCheckboxChange = (event, item) => {
    const { id, checked } = event.target;

    setcheckboxData((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  //=========================================================to handle select all and select none
  function selectAll() {
    setAllPropertiesToTrue(checkboxData);
  }
  function selectNone() {
    setAllPropertiesToFalse(checkboxData);
  }

  function setAllPropertiesToTrue(inputObject) {
    const resultObject = {};
    for (const key in inputObject) {
      if (inputObject?.hasOwnProperty(key)) {
        resultObject[key] = true;
      }
    }
    setcheckboxData(resultObject);
  }
  function setAllPropertiesToFalse(inputObject) {
    const resultObject = {};
    for (const key in inputObject) {
      if (inputObject?.hasOwnProperty(key)) {
        resultObject[key] = false;
      }
    }
    setcheckboxData(resultObject);
  }

  //======================================================to handle update now
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function getCurrentFormattedTime() {
    const currentDate = new Date();
    const month = months[currentDate.getMonth()];
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();

    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const amOrPm = hours >= 12 ? "pm" : "am";

    // Convert 24-hour time to 12-hour time
    const formattedHours = hours % 12 || 12;
    const formattedTime = `${month} ${day}, ${year} ${formattedHours}:${String(
      minutes
    ).padStart(2, "0")}${amOrPm}`;

    return formattedTime;
  }

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}
      {!addEvent && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[310px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}
            <h2 className="mb-6 text-white/80 text-[1.25rem] font-medium">
              Score Settings
            </h2>
            <div className="w-full p-3 md:p-5 md:max-w-[85%] lg:max-w-[70%] md:min-w-[450px] min-h-[200px] bg-[#282929] rounded-lg flex flex-col gap-6 mb-6 text-white/80">
              <h1 className="w-full border-b pb-2 font-bold border-white/30">
                Event Scores Visibility
              </h1>

              <div className="w-full flex flex-col gap-3">
                <div className="w-full flex justify-between items-center px-2">
                  <p>Event</p>
                  <p>Visibility</p>
                </div>

                {status === "loading" && (
                  <div className="w-full h-[150px] flex justify-center items-center">
                    <PulseLoader color="#94a4fd" size={10} />
                  </div>
                )}
                {realtimeEvents?.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className="w-full flex justify-between items-center bg-white/10 p-3 rounded-lg text-[.85rem]"
                    >
                      <p>{item?.event_name}</p>
                      <button
                        onClick={() =>
                          dispatch(
                            toggleScoresVisibility({
                              uid: user?.uid,
                              event_uid: item?.event_uid,
                            })
                          )
                        }
                        // disabled={updateJudgeSettingsPending}
                        className={`px-4 rounded-full text-[.85rem] hover:opacity-75 ${
                          item?.scores_visibility
                            ? "bg-red-500 text-white"
                            : "bg-[#94a4fd] text-black"
                        }`}
                      >
                        {item?.scores_visibility ? "Hide" : "Show"}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="w-full p-3 md:p-5 mt-8 md:max-w-[85%] lg:max-w-[70%] md:min-w-[450px] min-h-[200px] bg-[#282929] rounded-lg flex flex-col gap-6 mb-6 text-white/80">
              <h1 className="w-full border-b pb-2 font-bold border-white/30">
                Event Studio Code & Name Visibility
              </h1>

              <div className="w-full flex flex-col gap-3">
                <div className="w-full flex justify-between items-center px-2">
                  <p>Event</p>
                  <p>Visibility</p>
                </div>

                {status === "loading" && (
                  <div className="w-full h-[150px] flex justify-center items-center">
                    <PulseLoader color="#94a4fd" size={10} />
                  </div>
                )}
                {realtimeEvents?.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className="w-full flex justify-between items-center bg-white/10 p-3 rounded-lg text-[.85rem]"
                    >
                      <p>{item?.event_name}</p>
                      <button
                        onClick={() =>
                          dispatch(
                            toggleStudioVisibility({
                              uid: user?.uid,
                              event_uid: item?.event_uid,
                            })
                          )
                        }
                        // disabled={updateJudgeSettingsPending}
                        className={`px-4 rounded-full text-[.85rem] hover:opacity-75 ${
                          item?.studio_visibility
                            ? "bg-red-500 text-white"
                            : "bg-[#94a4fd] text-black"
                        }`}
                      >
                        {item?.studio_visibility ? "Hide" : "Show"}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="w-full p-3 md:p-5 mt-8 md:max-w-[85%] lg:max-w-[70%] md:min-w-[450px] min-h-[200px] bg-[#282929] rounded-lg flex flex-col gap-6 mb-6 text-white/80">
              <h1 className="w-full border-b pb-2 font-bold border-white/30">
                Div Rank Visibility
              </h1>

              <div className="w-full flex flex-col gap-3">
                <div className="w-full flex justify-between items-center px-2">
                  <p>Event</p>
                  <p>Visibility</p>
                </div>

                {status === "loading" && (
                  <div className="w-full h-[150px] flex justify-center items-center">
                    <PulseLoader color="#94a4fd" size={10} />
                  </div>
                )}
                {realtimeEvents?.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className="w-full flex justify-between items-center bg-white/10 p-3 rounded-lg text-[.85rem]"
                    >
                      <p>{item?.event_name}</p>
                      <button
                        onClick={() =>
                          dispatch(
                            toggleRank({
                              uid: user?.uid,
                              event_uid: item?.event_uid,
                            })
                          )
                        }
                        // disabled={updateJudgeSettingsPending}
                        className={`px-4 rounded-full text-[.85rem] hover:opacity-75 ${
                          item?.rank_visibility
                            ? "bg-red-500 text-white"
                            : "bg-[#94a4fd] text-black"
                        }`}
                      >
                        {item?.rank_visibility ? "Hide" : "Show"}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Heading */}
            {/* <h2 className="mb-6 text-white/80 text-[1.25rem] font-medium">
              Judge Settings
            </h2> */}

            {/* Settings container */}
            <div className="w-full p-3 md:p-5 md:max-w-[85%] lg:max-w-[70%] md:min-w-[450px] bg-[#282929] rounded-lg flex flex-col gap-6 mb-auto text-white/80">
              <h1 className="w-full border-b pb-2 font-bold border-white/30">
                Default Scoring Values
              </h1>

              <div className="w-full flex bg-white/10 p-3 rounded-lg text-[.85rem]">
                Default Starting Value:{" "}
                <div className="font-medium pl-2">
                  {" "}
                  {status === "loading" ? (
                    <PulseLoader color="#94a4fd" size={10} />
                  ) : (
                    <div>
                      {edit ? (
                        <input
                          type="text"
                          inputMode="numeric"
                          pattern="^([0-9]\d{0,1}|1\d{0,1}|20)(\.\d{0,2})?$"
                          defaultValue={settingsData?.default_value}
                          onChange={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9.]/g,
                              ""
                            );
                            if (e.target.value > 20) {
                              e.target.value = "20";
                            }
                            setSettingsData((prev) => {
                              return {
                                ...prev,
                                default_value: Number(e.target.value),
                              };
                            });
                          }}
                          className="w-[60px] px-2 rounded-md outline-none border border-[#94a4fd] bg-inherit"
                        />
                      ) : (
                        settingsFromDb?.default_value
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full flex bg-white/10 p-3 rounded-lg text-[.85rem]">
                Score Increment Value:{" "}
                <div className="font-medium pl-2">
                  {" "}
                  {status === "loading" ? (
                    <PulseLoader color="#94a4fd" size={10} />
                  ) : (
                    <div>
                      {edit ? (
                        <input
                          type="text"
                          inputMode="numeric"
                          pattern="^([0-9]\d{0,1}|1\d{0,1}|20)(\.\d{0,2})?$"
                          defaultValue={settingsData?.increment_value}
                          onChange={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9.]/g,
                              ""
                            );
                            if (e.target.value > 20) {
                              e.target.value = "20";
                            }

                            setSettingsData((prev) => {
                              return {
                                ...prev,
                                increment_value: Number(e.target.value),
                              };
                            });
                          }}
                          className="w-[60px] px-2 rounded-md outline-none border border-[#94a4fd] bg-inherit"
                        />
                      ) : (
                        settingsFromDb?.increment_value
                      )}
                    </div>
                  )}
                </div>
              </div>

              {updateJudgeSettingsSuccess?.success && (
                <p className="text-[#94a4fd] ">Values Updated Successfully!!</p>
              )}

              <div className="flex gap-2 justify-end">
                <button
                  onClick={toggleEdit}
                  disabled={updateJudgeSettingsPending}
                  className="px-4 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[.85rem] hover:opacity-75"
                >
                  {edit ? "Cancel Edit" : "Edit Values"}
                </button>

                {edit && (
                  <button
                    onClick={handleSubmit}
                    disabled={updateJudgeSettingsPending}
                    className="px-4 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[.85rem] hover:opacity-75"
                  >
                    {updateJudgeSettingsPending ? (
                      <p className="flex gap-2 items-center">
                        Submitting.. <ButtonLoadingSpinner />
                      </p>
                    ) : (
                      "Submit"
                    )}
                  </button>
                )}
              </div>
            </div>

            <div className="w-full p-3 md:p-5 mt-8 md:max-w-[85%] lg:max-w-[70%] md:min-w-[450px] min-h-[200px] bg-[#282929] rounded-lg flex flex-col gap-6 mb-6 text-white/80">
              <h1 className="w-full border-b pb-2 font-bold border-white/30">
                Top Scores by Genre Visibility
              </h1>

              <div className="w-full flex flex-col gap-3">
                <div className="w-full flex justify-between items-center px-2">
                  <p>Event</p>
                  <p>Action</p>
                </div>

                {status === "loading" && (
                  <div className="w-full h-[150px] flex justify-center items-center">
                    <PulseLoader color="#94a4fd" size={10} />
                  </div>
                )}
                {realtimeEvents?.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className="w-full flex justify-between items-center bg-white/10 p-3 rounded-lg text-[.85rem]"
                    >
                      <p>{item?.event_name}</p>
                      <button
                        onClick={() => toggleSelect(item)}
                        // disabled={updateJudgeSettingsPending}
                        className={`px-4 rounded-full text-[.85rem] hover:opacity-75 bg-[#94a4fd] text-black`}
                      >
                        Make Changes
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {selectedEvent && (
        <TopScorersSettingsModal
          toggleSelect={toggleSelect}
          selectedEvent={selectedEvent}
          user={user}
          getCurrentFormattedTime={getCurrentFormattedTime}
          updateNowPending={updateNowPending}
          selectAll={selectAll}
          selectNone={selectNone}
          checkboxes={checkboxes}
          checkboxData={checkboxData}
          handleCheckboxChange={handleCheckboxChange}
          setSelectEvent={setSelectEvent}
        />
      )}

      {/* the add event pop up page */}

      {addEvent && <AddEventContainer />}
    </>
  );
};

export default CompetitonSettings;
