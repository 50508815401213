/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
// import ReactPaginate from "react-paginate";

const ScoresTable = ({
  entries,
  eventScores,
  calculatePosition,
  // categoryEntries,
  entryCount,
  currentEvent,
  allEntries,
  category,
  setShowNotes,
  showNotes,
  calculateAverageScore,
  calculateTotalScore,
}) => {
  //to dynamically calculate the overall entry counts by getting the overall index
  const myEntries = allEntries?.filter(
    (each) => each?.event_uid === currentEvent?.event_uid
  );

  const categoryEntries = myEntries?.filter(
    (itm) => itm?.age_division.toLowerCase() === category?.toLowerCase()
  );
  const toggleNotes = (entry) => {
    setShowNotes(showNotes ? "" : entry);
  };

  //===============================================================================================to display rows
  const sortedEntries = entries?.sort((a, b) => entryCount(a) - entryCount(b));
  const entriesWithScores = categoryEntries?.filter(
    (y) => calculateTotalScore(y) !== "- -"
  );

  const rankedEntries = entriesWithScores?.sort(
    (a, b) =>
      calculatePosition(a, entriesWithScores) -
      calculatePosition(b, entriesWithScores)
  );

  // console.log("rankedEntries", rankedEntries);

  function getFirst20Percent() {
    if (Array.isArray(rankedEntries)) {
      const length = rankedEntries?.length;
      const startIndex = 0;
      const endIndex = Math.ceil(length * 0.2);

      return rankedEntries?.slice(startIndex, endIndex);
    }
  }

  // console.log("getFirst20Percent", getFirst20Percent());

  //==============================================================to display row
  const displayRows = sortedEntries?.map((row, uselessIdx) => {
    const judgeNote = row?.notes?.filter(
      (note) => note?.type === "Studio Director Note"
    );

    const entryScores = eventScores?.filter(
      (item) => item?.doc_id === row?.doc_id
    )[0];

    const entryScoresAll = eventScores?.filter(
      (item) => item?.doc_id === row?.doc_id
    );

    const deductionAll = entryScoresAll?.map((item) => item?.deductions);

    const deductions = deductionAll?.filter((item) => item !== "none");

    const myDivRankArray = getFirst20Percent()?.filter(
      (x) => x?.id === row?.id
    );

    // console.log("rankedEntries", rankedEntries);
    // console.log(row?.entry_name, calculatePosition(row, entriesWithScores));

    return (
      <React.Fragment key={uselessIdx}>
        <tr
          className={`border-b border-white/10 whitespace-nowrap font-medium ${
            row?.is_scratched && "bg-stone-500/10 text-white/30"
          }`}
        >
          {currentEvent?.rank_visibility && (
            <td className="">
              {currentEvent?.scores_visibility ? (
                <p className="w-[30px]">
                  {myDivRankArray?.length > 0
                    ? calculatePosition(row, rankedEntries)?.replace(
                        /(st|nd|rd|th)/g,
                        ""
                      )
                    : "-"}
                </p>
              ) : (
                <img
                  alt=""
                  className="opacity-50 w-7 h-auto"
                  src="/images/scratchCat.png"
                />
              )}
            </td>
          )}
          <td className="">
            {currentEvent?.locked ? row?.locked_entry_count : entryCount(row)}
          </td>
          <td className="">
            <p className="w-[200px] whitespace-normal">
              {capitalizeFirstLetter(row?.entry_name)}
            </p>
          </td>
          <td>
            {currentEvent?.scores_visibility ? (
              <p className="w-[60px]">
                {isNaN(calculateAverageScore(entryScoresAll, "technique"))
                  ? "- -"
                  : calculateAverageScore(entryScoresAll, "technique")}
              </p>
            ) : (
              <img
                alt=""
                className="opacity-50 w-7 h-auto"
                src="/images/scratchCat.png"
              />
            )}
          </td>
          <td>
            {currentEvent?.scores_visibility ? (
              <p className="w-[60px]">
                {isNaN(calculateAverageScore(entryScoresAll, "creativity"))
                  ? "- -"
                  : calculateAverageScore(entryScoresAll, "creativity")}
              </p>
            ) : (
              <img
                alt=""
                className="opacity-50 w-7 h-auto"
                src="/images/scratchCat.png"
              />
            )}
          </td>
          <td>
            {currentEvent?.scores_visibility ? (
              <p className="w-[60px]">
                {isNaN(calculateAverageScore(entryScoresAll, "staging"))
                  ? "- -"
                  : calculateAverageScore(entryScoresAll, "staging")}
              </p>
            ) : (
              <img
                alt=""
                className="opacity-50 w-7 h-auto"
                src="/images/scratchCat.png"
              />
            )}
          </td>
          <td>
            {currentEvent?.scores_visibility ? (
              <p className="w-[60px]">
                {isNaN(calculateAverageScore(entryScoresAll, "execution"))
                  ? "- -"
                  : calculateAverageScore(entryScoresAll, "execution")}
              </p>
            ) : (
              <img
                alt=""
                className="opacity-50 w-7 h-auto"
                src="/images/scratchCat.png"
              />
            )}
          </td>
          <td>
            {currentEvent?.scores_visibility ? (
              <p className="w-[60px]">
                {isNaN(calculateAverageScore(entryScoresAll, "performance"))
                  ? "- -"
                  : calculateAverageScore(entryScoresAll, "performance")}
              </p>
            ) : (
              <img
                alt=""
                className="opacity-50 w-7 h-auto"
                src="/images/scratchCat.png"
              />
            )}
          </td>
          <td className="w-[100px]">
            {currentEvent?.scores_visibility ? (
              deductions[0]?.length > 0 ? (
                deductions[0]?.length
              ) : (
                "- -"
              )
            ) : (
              <img
                alt=""
                className="opacity-50 w-7 h-auto"
                src="/images/scratchCat.png"
              />
            )}
          </td>
          <td className="w-[100px]">
            {currentEvent?.scores_visibility ? (
              calculateTotalScore(row) === 0 ? (
                "- -"
              ) : (
                calculateTotalScore(row)
              )
            ) : (
              <img
                alt=""
                className="opacity-50 w-7 h-auto"
                src="/images/scratchCat.png"
              />
            )}
          </td>
          <td className="">
            {currentEvent?.scores_visibility ? (
              <p className="w-[100px]">
                {capitalizeFirstLetter(entryScores?.award_name)
                  ? capitalizeFirstLetter(entryScores?.award_name)
                  : "- -"}
              </p>
            ) : (
              <img
                alt=""
                className="opacity-50 w-7 h-auto"
                src="/images/scratchCat.png"
              />
            )}
          </td>
          <td className="">
            {currentEvent?.scores_visibility ? (
              judgeNote?.length > 0 && (
                <img
                  alt="note"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleNotes(row);
                  }}
                  src="/images/NotePencil.png"
                  className={`note w-8 h-8 cursor-pointer`}
                />
              )
            ) : (
              <img
                alt=""
                className="opacity-50 w-7 h-auto"
                src="/images/scratchCat.png"
              />
            )}
          </td>
        </tr>
        {/* to display the notes dropdown */}
        {judgeNote?.length > 0 && showNotes === row && (
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-[280px] bg-[#282929] p-3 rounded-lg absolute top-0 right-0 font-normal shadow-lg z-[3] border border-white/10 flex flex-col gap-3 scale"
          >
            <h3 className="text-[.85rem] text-center">
              Notes From Judge ~ {showNotes?.entry_name}
            </h3>
            <div
              onClick={(e) => {
                e.stopPropagation();
                toggleNotes(row);
              }}
              className="w-4 h-4 flex justify-center items-center rounded-full cursor-pointer absolute top-1 right-1 text-white"
            >
              X
            </div>
            {judgeNote?.map((note, idx) => {
              return (
                <div
                  key={idx}
                  className="note text-[.85rem] my-1 mx-3 rounded-lg bg-white/10 border border-white/10"
                >
                  <p className="h-fit">{capitalizeFirstLetter(note?.note)}</p>
                </div>
              );
            })}
          </div>
        )}
      </React.Fragment>
    );
  });

  return (
    <>
      <div className="w-full h-full mt-5 pb-2">
        <table className="min-w-[600px] w-full text-[.85rem]">
          <thead className="text-white/40 text-left border-b border-white/30">
            <tr className="whitespace-nowrap uppercase">
              {currentEvent?.rank_visibility && <th>Div rank</th>}
              <th>Entry #</th>
              <th>Entry Name</th>
              <th>T/F</th>
              <th>C/O</th>
              <th>S/D</th>
              <th>E/D</th>
              <th>P/E</th>
              <th>Ded</th>
              <th>Avg total</th>
              <th>Award</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody className="text-white/80">{displayRows}</tbody>
        </table>
      </div>
    </>
  );
};

export default ScoresTable;
