import { useNavigate } from "react-router-dom";

const SearchResultTray = ({
  openNotifsBoard,
  currentPage,
  searchResults,
  searchTerm,
  clearSearch,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={`w-full h-screen bg-[#1c1c1c]/90 fixed top-[64px] md:top-[80px] left-0 text-white/80 sm:pl-[202px] lg:pl-[252px] pr-[2px] overflow-auto ${
        openNotifsBoard && currentPage.includes("/registrations")
          ? "lg:pr-[282px]"
          : openNotifsBoard && currentPage.includes("/accounting-overview")
          ? "lg:pr-[282px]"
          : openNotifsBoard && currentPage.includes("/tabulator")
          ? "lg:pr-[282px]"
          : !openNotifsBoard &&
            !currentPage.includes("/registrations") &&
            !currentPage.includes("/accounting-overview") &&
            !currentPage.includes("/tabulator")
          ? "lg:pr-[282px]"
          : "lg:pr-[2px]"
      }`}
    >
      <div className="w-full min-h-[300px] bg-[#1c1c1c] rounded-b-xl shadow-lg flex flex-col overflow-auto border-b border-white/20">
        <div className="border-b border-[#282929] p-5 text-white uppercase flex justify-between">
          <h2>Event search</h2>
          <div
            onClick={clearSearch}
            className="w-6 h-6 flex justify-center items-center bg-white/80 rounded-full cursor-pointer"
          >
            <img
              alt="user"
              src="/images/icons8-close-50.png"
              className="w-4 h-4"
            />
          </div>
        </div>
        <div className="w-full flex flex-col text-[.9em] mb-auto">
          {searchResults.length > 0 ? (
            searchResults.map((event, index) => (
              <div
                key={index}
                className="w-full p-5 cursor-pointer hover:bg-[#94a4fd]/40"
                onClick={() => {
                  navigate(
                    `/organizer/event/${event?.event_name.replace(/ /g, "-")}`
                  );
                  clearSearch();
                }}
              >
                {event.event_name}
              </div>
            ))
          ) : (
            <p className="text-center pt-[80px] text-white/60 text-[1rem]">
              No results for keyword "
              <span className="font-bold">{searchTerm}</span>"
            </p>
          )}
        </div>
        {searchResults.length > 0 && (
          <h1 className="border-t border-[#282929] p-5 text-[#94a4fd] text-[.9em]">
            All results for "<span className="font-bold">{searchTerm}</span>"
          </h1>
        )}
      </div>
    </div>
  );
};

export default SearchResultTray;
