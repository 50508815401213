import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import ScrollToTop from "./ScrollToTop";
import { useNavigate, useParams } from "react-router-dom";
import AddPaymentForm from "./components/AddPaymentForm";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { PulseLoader } from "react-spinners";
import {
  deletePayment,
  clearSuccess,
} from "redux/routineScheduling/routineSchedulingSlice";
import { saveTaxRate } from "redux/organizerScheduling/organizerSchedulingSlice";
import AddEventContainer from "./components/AddEventContainer";

const OverviewDetails = () => {
  const { eventsData, addEvent, openNotifsBoard } = useSelector(
    (state) => state.eventManagement
  );
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { event_name, studio_id } = useParams();
  const navigate = useNavigate();

  const currentEvent = eventsData.filter(
    (item) => item.event_name.replace(/ /g, "-") === event_name
  )[0];

  const currentStudio = currentEvent?.access?.filter(
    (itm) => itm?.studio_id === studio_id
  )[0];

  const { data: allStudios } = useQueryAllDocs("studio_owners");
  const studioData = allStudios?.filter(
    (item) => item?.uid === currentStudio?.studio_id
  )[0];

  const { data: allPayment } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "payment_history"
  );

  const studioPayment = allPayment
    ?.filter(
      (itm) =>
        itm?.studio_id === studio_id &&
        itm?.event_uid === currentEvent?.event_uid
    )
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  const [isAddPaymentOpen, setIsAddPaymentOpen] = useState(false);

  const openAddPaymentForm = () => {
    setIsAddPaymentOpen(true);
  };

  const closeAddPaymentForm = () => {
    setIsAddPaymentOpen(false);
  };

  const [selectedPayment, setSelectedPayment] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  useEffect(() => {
    if (selectedPayment) {
      const selectedPaymentData = allPayment?.find(
        (payment) => payment.doc_id === selectedPayment
      );
      setPaymentData(selectedPaymentData);
    }
  }, [selectedPayment, allPayment]);

  const dispatch = useDispatch();

  //==========================================================================to get all entrytypes & pricing
  const { status: compStatus, data: compData } = useFirestoreQueryDoc(
    "competitions",
    user?.uid
  );

  //========== billing check
  const [billingDetailsSetupPopupOpen, setBillingDetailsSetupPopupOpen] =
    useState(false);
  const userBillingDetails = compData?.billing_details;
  const billingDetailsAvailable =
    userBillingDetails && Object?.keys(userBillingDetails)?.length > 0;
  const openBillingDetailsSetupPopup = () => {
    setBillingDetailsSetupPopupOpen(true);
  };

  const closeBillingDetailsSetupPopup = () => {
    setBillingDetailsSetupPopupOpen(false);
  };

  const [taxRate, setTaxRate] = useState(0.0);
  const [taxRateFromDb, setTaxRateFromDb] = useState(0.0);

  const [entryTypes, setentryTypes] = useState([]);
  const [pricing, setpricing] = useState([]);

  useEffect(() => {
    if (compData) {
      const { entryTypes, pricing, events } = compData;
      setentryTypes(entryTypes);
      setpricing(pricing?.pricingCategories);

      const currentEve = events?.filter(
        (itm) => itm?.event_uid === currentEvent?.event_uid
      )[0];
      setTaxRate(currentEve?.tax_rate || 0);
      setTaxRateFromDb(currentEve?.tax_rate || 0);
    }
  }, [compData, currentEvent, compStatus]);

  const allEntryTypes = entryTypes?.concat(
    compData?.specialEntryTypes?.tags || []
  );

  //====================================================================================to get all entries
  const { status, data: allEntries } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "entries"
  );

  const studioEntries = allEntries?.filter(
    (each) =>
      each?.studio_selected[0]?.toLowerCase() ===
        currentStudio?.studio_name?.toLowerCase() &&
      each?.event_uid === currentEvent?.event_uid
  );

  // const specialStudioEntries = studioEntries?.filter((x) =>
  //   x?.category_name?.includes("impulse")
  // );
  // console.log("specialStudioEntries", specialStudioEntries);

  function getAllDancers(arr) {
    const allSelected = [];

    if (arr && Array.isArray(arr)) {
      for (const obj of arr) {
        if (obj.dancers_selected && Array.isArray(obj.dancers_selected)) {
          allSelected.push(...obj.dancers_selected);
        }
      }
    }

    return allSelected;
  }

  // ====================================================================================Calculate the totalAmount
  const newTotalAmount = allEntryTypes
    ?.reduce((total, row) => {
      let rowPricingOption;
      if (row?.entryTypeName) {
        rowPricingOption = pricing?.find((obj) =>
          obj.hasOwnProperty(row.entryTypeName)
        );
      } else if (row.specialEntryTypeName) {
        rowPricingOption = pricing?.find((obj) =>
          obj.hasOwnProperty(row.specialEntryTypeName)
        );
      }
      // const rowEntries = studioEntries?.filter(
      //   (itm) =>
      //     itm?.entryType === row?.entryTypeName ||
      //     itm?.entryType === row?.specialEntryTypeName
      // );

      // to calculate special entries and normal entries
      function rowEntries1(arr) {
        const result = arr?.filter(
          (itm) =>
            itm?.entryType === row?.entryTypeName &&
            !itm?.category_name?.includes("impulse") &&
            !itm?.category_name?.includes("remix") &&
            !itm?.category_name?.includes("unity") &&
            !itm?.category_name?.includes("overtime")
        );
        return result;
      }

      function rowEntriesSpecial(arr) {
        const result = arr?.filter((x) =>
          x?.category_name
            ?.toLowerCase()
            ?.includes(row?.specialEntryTypeName?.toLowerCase())
        );
        return result;
      }

      const rowEntries =
        row?.specialEntryTypeName?.includes("impulse") ||
        row?.specialEntryTypeName?.includes("remix") ||
        row?.specialEntryTypeName?.includes("unity") ||
        row?.specialEntryTypeName?.includes("overtime")
          ? rowEntriesSpecial(studioEntries)
          : rowEntries1(studioEntries);

      const flatAmount =
        rowEntries?.length > 0
          ? rowPricingOption?.[row?.entryTypeName] ||
            rowPricingOption?.[row?.specialEntryTypeName] * rowEntries?.length
          : 0.0;

      const rowAmount =
        rowPricingOption?.selectedOption?.toLowerCase() === "flat"
          ? Number(flatAmount)
          : getAllDancers(rowEntries)?.length *
            Number(
              rowPricingOption?.[row?.entryTypeName] ||
                rowPricingOption?.[row?.specialEntryTypeName]
            );

      return total + (rowAmount || 0);
    }, 0)
    .toFixed(2);

  function calcTotalAfterTax(amount, percentage) {
    const result = (amount * percentage) / 100;
    return result.toFixed(2);
  }

  const totalAfterTax =
    Number(newTotalAmount) + Number(calcTotalAfterTax(newTotalAmount, taxRate));

  function calculateTotalPayments(arr) {
    if (!arr || !Array.isArray(arr)) {
      return "Invalid input";
    }

    let totalAmount = 0;

    for (const obj of arr) {
      if (obj.amount) {
        totalAmount += parseFloat(obj.amount);
      }
    }

    return totalAmount.toFixed(2);
  }

  const amountOwing = totalAfterTax - calculateTotalPayments(studioPayment);

  const { deletePaymentPending, deletePaymentSuccess } = useSelector(
    (state) => state.routineScheduling
  );
  const [deleteEntryMod, setDeleteEntryMod] = useState(false);

  const toggleDeleteEntryMod = () => {
    setDeleteEntryMod((prev) => !prev);
  };

  useEffect(() => {
    if (deletePaymentSuccess?.success) {
      setDeleteEntryMod(false);
      dispatch(clearSuccess());
    }
  }, [deletePaymentSuccess, dispatch]);

  const sortedPayment = studioPayment?.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);

    // Sort in descending order
    return dateA - dateB;
  });

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}

      {status === "loading" && (
        <div className="w-full h-screen bg-[#1c1c1c] px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] pt-[80px] sm:pt-[110px]">
          <div className="w-full h-[200px] flex justify-center items-center gap-6 mb-auto text-white/80">
            <PulseLoader color="#94a4fd" size={10} />
          </div>
        </div>
      )}

      {/* event details page */}
      {!addEvent && status !== "loading" && (
        <div
          onClick={() => setSelectedPayment(null)}
          className={`w-full min-h-screen text-white/80 px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px]  ${
            openNotifsBoard ? "lg:pr-[310px]" : "lg:pr-[32px]"
          } pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col transition-all duration-700`}
        >
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/90 text-[1rem] mb-6">
              <h2>
                {currentEvent?.event_name} {currentEvent?.year.split(" to ")[0]}
              </h2>
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            <div className="w-full min-h-[400px]">
              <div className="p-2 gap-4 flex lg:flex-row flex-col">
                {/* First Card */}
                <div className="w-[325px] bg-[#282929] rounded-lg p-4 text-white/80 text-[.9rem]">
                  <h3 className="text-[1.5rem] font-bold mb-4">
                    {capitalizeFirstLetter(currentStudio?.studio_name)}
                  </h3>
                  <div>
                    {capitalizeFirstLetter(currentStudio?.first_name)}{" "}
                    {capitalizeFirstLetter(currentStudio?.last_name)}
                  </div>
                  <div>{capitalizeFirstLetter(studioData?.addressLine1)}</div>
                  {studioData?.addressLine2 && (
                    <div>{capitalizeFirstLetter(studioData?.addressLine2)}</div>
                  )}

                  <div>
                    {" "}
                    {studioData?.postalCode},{" "}
                    {capitalizeFirstLetter(studioData?.city)},{" "}
                    {studioData?.state}
                  </div>
                  <div>{studioData?.email}</div>
                  <div>{studioData?.website}</div>
                </div>

                {/* Second Card */}
                <div className="w-[202px] h-[112px] bg-white rounded-lg p-4">
                  <div className="text-black text-[1.2rem] font-bold pl-4 mb-2">
                    Amount Owing
                  </div>
                  <div className="text-black text-[1.2rem] font-bold pl-4">
                    ${amountOwing?.toFixed(2)}
                  </div>
                </div>
              </div>

              {/* another section */}
              <section className="w-full xl:w-[80%] mt-7 pb-2">
                <h3 className="pl-2 mb-6 text-lg text-white/80">
                  Current submissions
                </h3>
                <div className="w-full overflow-x-auto">
                  <table className="min-w-[600px] w-full text-[.85rem] table2 transition-all duration-300">
                    <thead className="text-white/40 text-left border-b border-white/30">
                      <tr className="whitespace-nowrap">
                        <th>Entry Type</th>
                        <th>Entries</th>
                        <th>Dancers</th>
                        <th>Rate</th>
                        <th>Price</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody className="text-white/80">
                      {allEntryTypes?.map((row, idx) => {
                        // to calculate special entries and normal entries
                        function rowEntries1(arr) {
                          const result = arr?.filter(
                            (itm) =>
                              itm?.entryType === row?.entryTypeName &&
                              !itm?.category_name?.includes("impulse") &&
                              !itm?.category_name?.includes("remix") &&
                              !itm?.category_name?.includes("unity") &&
                              !itm?.category_name?.includes("overtime")
                          );
                          return result;
                        }

                        function rowEntriesSpecial(arr) {
                          const result = arr?.filter((x) =>
                            x?.category_name
                              ?.toLowerCase()
                              ?.includes(
                                row?.specialEntryTypeName?.toLowerCase()
                              )
                          );
                          return result;
                        }

                        const rowEntries =
                          row?.specialEntryTypeName?.includes("impulse") ||
                          row?.specialEntryTypeName?.includes("remix") ||
                          row?.specialEntryTypeName?.includes("unity") ||
                          row?.specialEntryTypeName?.includes("overtime")
                            ? rowEntriesSpecial(studioEntries)
                            : rowEntries1(studioEntries);

                        let rowPricingOption;

                        if (row.entryTypeName) {
                          rowPricingOption = pricing?.find((obj) =>
                            obj.hasOwnProperty(row?.entryTypeName)
                          );
                        } else if (row?.specialEntryTypeName) {
                          rowPricingOption = pricing?.find((obj) =>
                            obj.hasOwnProperty(row?.specialEntryTypeName)
                          );
                        }

                        const flatAmount =
                          rowEntries?.length > 0
                            ? rowPricingOption?.[
                                row.entryTypeName || row.specialEntryTypeName
                              ] * rowEntries?.length
                            : 0.0;

                        const rowAmount =
                          rowPricingOption?.selectedOption?.toLowerCase() ===
                          "flat"
                            ? Number(flatAmount)?.toFixed(2)
                            : (
                                getAllDancers(rowEntries)?.length *
                                Number(
                                  rowPricingOption?.[
                                    row.entryTypeName ||
                                      row.specialEntryTypeName
                                  ]
                                )
                              ).toFixed(2);

                        return (
                          <tr
                            key={idx}
                            className="border-b border-white/10 whitespace-nowrap"
                          >
                            <td className="flex items-center">
                              {capitalizeFirstLetter(
                                row?.entryTypeName || row?.specialEntryTypeName
                              )}
                            </td>
                            <td>{rowEntries?.length}</td>
                            <td>{getAllDancers(rowEntries)?.length}</td>
                            <td>
                              {rowPricingOption?.selectedOption === "perDancer"
                                ? "Per Dancer"
                                : "Flat"}
                            </td>
                            <td>
                              $
                              {Number(
                                rowPricingOption?.[
                                  row.entryTypeName || row.specialEntryTypeName
                                ]
                              )?.toFixed(2)}
                            </td>
                            <td>${rowAmount}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tr>
                      <td colSpan="4"></td>
                      <td className="text-white text-md font-bold whitespace-nowrap">
                        Sub Total
                      </td>
                      <td className="text-white text-md font-bold">
                        ${newTotalAmount}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4"></td>
                      <td className="text-white text-md font-bold whitespace-nowrap">
                        Tax Rate
                      </td>
                      <td className="whitespace-nowrap">
                        <input
                          type="text"
                          className="text-white font-bold w-[60px] h-[30px] bg-black/50 border border-white/10 rounded-lg p-4 outline-none placeholder:text-white/30"
                          placeholder="13.00"
                          value={taxRate}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const sanitizedValue = inputValue.replace(
                              /[^0-9.]/g,
                              ""
                            );
                            setTaxRate(sanitizedValue);
                          }}
                        />
                        %
                        {Number(taxRateFromDb) !== Number(taxRate) && (
                          <button
                            onClick={() =>
                              dispatch(
                                saveTaxRate({
                                  uid: user?.uid,
                                  tax_rate: taxRate,
                                  event_uid: currentEvent?.event_uid,
                                })
                              )
                            }
                            className="bg-[#94a4fd] ml-3 text-[.75rem] text-black rounded-full saveTax"
                          >
                            Save
                          </button>
                        )}
                      </td>
                    </tr>
                    <tr className="border-b border-white/10 whitespace-nowrap cursor-pointer">
                      <td colSpan="4"></td>
                      <td className="text-white text-md font-bold whitespace-nowrap">
                        Total
                      </td>
                      <td className="text-white text-md font-bold whitespace-nowrap">
                        ${totalAfterTax?.toFixed(2)}
                      </td>
                    </tr>
                  </table>
                </div>
                {/* Buttons */}
                <div className="flex gap-4 items-center justify-end">
                  <button
                    onClick={() => {
                      if (billingDetailsAvailable) {
                        window.open(
                          `/organizer/event/${event_name.replace(
                            / /g,
                            "-"
                          )}/accounting-overview/${
                            currentStudio?.studio_id
                          }/invoice`,
                          "_blank"
                        );
                      } else {
                        openBillingDetailsSetupPopup();
                      }
                    }}
                    className="px-8 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[0.85rem] hover:opacity-75 mt-5"
                  >
                    View Invoice
                  </button>

                  {/* Billing Details Setup Popup */}
                  {billingDetailsSetupPopupOpen && (
                    <div
                      onClick={closeBillingDetailsSetupPopup}
                      className="fixed inset-0 py-[100px] flex justify-center bg-[#1c1c1c]/90 z-[999]"
                    >
                      <div className="rounded-lg p-6 w-full md:w-1/2 max-w-[700px] min-w-[300px] h-[200px] mx-auto">
                        <div
                          onClick={(e) => e.stopPropagation()}
                          className="bg-[#282929] rounded-lg p-6 relative scale"
                        >
                          <div
                            className="w-6 h-6 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-3 right-3"
                            onClick={closeBillingDetailsSetupPopup}
                          >
                            <img
                              alt="user"
                              src="/images/icons8-close-50.png"
                              className="w-4 h-4"
                            />
                          </div>
                          <h2>Set Up Billing Details</h2>
                          <p>
                            You need to set up your billing details before
                            viewing the invoice.
                          </p>
                          <button
                            onClick={() => {
                              navigate(
                                `/organizer/event/${event_name.replace(
                                  / /g,
                                  "-"
                                )}/accounting-invoice
                                    `
                              );
                            }}
                            className="px-8 py-1 bg-[#94a4fd] rounded-full text-black text-[0.85rem] hover:opacity-75 mt-5"
                          >
                            Submit Billing
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </section>
              {/* payment details section */}
              <section className="w-full xl:w-[80%] mt-10 pb-[100px]">
                <h3 className="pl-2 mb-6 text-lg text-white/80">
                  Payment History
                </h3>
                <div
                  className={`w-full overflow-x-auto overflow-y-hidden pb-10`}
                >
                  <div className="min-w-[600px] text-[.85rem]">
                    <div className="text-white/40 text-left border-b border-white/30 flex whitespace-nowrap mb-3 pb-2">
                      <div className="w-[20%]">Date</div>
                      <div className="w-[20%]">Receipt #</div>
                      <div className="w-[20%]">Description</div>
                      <div className="w-[20%]">Payment Method</div>
                      <div className="w-[20%]">Amount</div>
                    </div>
                    <div className="text-white/80">
                      {sortedPayment?.length === 0 && (
                        <div className="w-full h-[100px] border border-white/10 rounded-lg flex justify-center items-center text-white/20">
                          No Payments Yet...
                        </div>
                      )}
                      {sortedPayment?.map((payment, index) => (
                        <div
                          key={payment?.doc_id}
                          className={`border-b border-white/10 mb-3 p-2 whitespace-nowrap cursor-pointer ${
                            selectedPayment === payment?.doc_id
                              ? "bg-[#94a4fd]/40 mb-[100px]"
                              : ""
                          }  flex items-center relative`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedPayment(payment?.doc_id);
                          }}
                        >
                          <div className="w-[20%]">{payment?.date}</div>
                          <div className="w-[20%]">
                            {(index + 1).toString().padStart(5, "0")}
                          </div>
                          <div className="w-[20%]">
                            {capitalizeFirstLetter(payment?.description)}
                          </div>
                          <div className="w-[20%]">
                            {capitalizeFirstLetter(payment?.payment_method)}
                          </div>
                          <div className="w-[20%]">
                            $
                            {parseFloat(payment.amount).toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </div>

                          {selectedPayment === payment?.doc_id && (
                            <div className="w-[200px] bg-[#282929] p-3 rounded-lg absolute top-[40px] left-[20px] font-normal shadow-lg z-[1]">
                              <ul className="flex flex-col gap-1">
                                <li
                                  className="flex gap-2 items-center py-1 px-2 rounded-lg hover:bg-[#94a4fd]/60"
                                  onClick={() => {
                                    const selectedPaymentData =
                                      allPayment?.find(
                                        (item) => item.doc_id === payment.doc_id
                                      );
                                    setPaymentData(selectedPaymentData);
                                    openAddPaymentForm();
                                  }}
                                >
                                  <img
                                    alt=""
                                    src="/images/editCat.png"
                                    className="w-3 h-3"
                                  />
                                  Edit
                                </li>
                                <li
                                  className="flex gap-2 items-center py-1 px-2 rounded-lg hover:bg-[#94a4fd]/60"
                                  onClick={() => {
                                    billingDetailsAvailable
                                      ? window.open(
                                          `/organizer/event/${event_name.replace(
                                            / /g,
                                            "-"
                                          )}/accounting-overview/${
                                            currentStudio?.studio_id
                                          }/receipt/${selectedPayment}?studioData=${JSON.stringify(
                                            studioData
                                          )}`,
                                          "_blank",
                                          "noopener,noreferrer"
                                        )
                                      : openBillingDetailsSetupPopup();
                                  }}
                                >
                                  <img
                                    alt=""
                                    src="/images/editCat.png"
                                    className="w-3 h-3"
                                  />
                                  Download
                                </li>
                                <li
                                  className="flex gap-2 items-center py-1 px-2 rounded-lg hover:bg-[#94a4fd]/60"
                                  onClick={toggleDeleteEntryMod}
                                >
                                  <img
                                    alt=""
                                    src="/images/deleteCat.png"
                                    className="w-3 h-3"
                                  />
                                  Delete
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Buttons */}
                <div className="flex gap-4 items-center justify-end">
                  <button
                    className="px-8 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[0.85rem] hover:opacity-75 mt-5"
                    onClick={() => {
                      setPaymentData(null);
                      openAddPaymentForm();
                    }}
                  >
                    Add Payment
                  </button>
                </div>
              </section>
              {/* Show AddPaymentPopup if isAddPaymentOpen is true */}
              {isAddPaymentOpen && (
                <AddPaymentForm
                  onClose={closeAddPaymentForm}
                  paymentData={paymentData}
                  studio_id={studio_id}
                  currentEvent={currentEvent}
                />
              )}
            </div>
          </div>
          {/* delete entry modal */}
          {deleteEntryMod && (
            <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
              <div className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-start scale shadow-lg">
                <p className="w-full text-[1rem] text-white pb-3 border-b border-[#c5c4c4]/50 text-center">
                  Confirm Delete Payment:{" "}
                  <span className="font-bold">
                    {capitalizeFirstLetter(
                      studioPayment?.filter(
                        (it) => it?.doc_id === selectedPayment
                      )[0]?.description
                    )}
                  </span>
                  ?
                </p>

                <p className="text-white/70">
                  NOTE: This is permanent. <br />
                  Are you sure you want to delete this payment history?
                </p>

                <div className="mt-4 w-full text-center">
                  <button
                    onClick={() => {
                      toggleDeleteEntryMod();
                    }}
                    className="text-[.85rem] px-8 py-2 mr-4 rounded-md bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() =>
                      dispatch(
                        deletePayment({
                          compId: user?.uid,
                          doc_id: selectedPayment,
                        })
                      )
                    }
                    className="text-[.9rem] px-8 py-2 rounded-md bg-red-400 hover:bg-red-400/60 text-[#1c1c1c] transition-all duration-300"
                    disabled={deletePaymentPending}
                  >
                    {deletePaymentPending ? "Deleting..." : "Delete"}
                  </button>
                </div>
              </div>
            </div>
          )}
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {/* the add event pop up page */}

      {addEvent && <AddEventContainer />}
    </>
  );
};

export default OverviewDetails;
