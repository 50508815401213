/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Footer from './components/Footer';
import Header from './components/Header';
import LeftSidebar from './components/LeftSidebar';
import RightSidebar from './components/RightSidebar';
import { getEvents } from '../../redux/eventManagement/eventManagement';
import ScrollToTop from './ScrollToTop';
import { PulseLoader } from 'react-spinners';
import AddEventContainer from './components/AddEventContainer';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { useQueryAllDocs } from 'components/hooks/useQueryAllDocs';

const UserEmcees = () => {
  const { addEvent, openNotifsBoard } = useSelector(
    (state) => state.eventManagement
  );

  //getting the user ID from auth
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEvents(user?.uid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  //=====================================================================================to get realtime events from firestore
  const { status, data } = useQueryAllDocs('emcees');
  const emceeData = data?.filter((itx) => itx?.role === 'emcee');

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}
      {!addEvent && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[310px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}
            <h2 className="mb-6 text-white/80 text-[1.25rem] font-medium">
              All Emcees
            </h2>
            <div className="w-full p-3 md:p-5 md:max-w-[85%] lg:max-w-[70%] md:min-w-[450px] min-h-[200px] bg-[#282929] rounded-lg flex flex-col gap-6 mb-6 text-white/80">
              <h1 className="w-full border-b pb-2 font-bold border-white/30">
                Emcees
              </h1>

              <div className="w-full flex flex-col gap-3">
                {status === 'loading' && (
                  <div className="w-full h-[150px] flex justify-center items-center">
                    <PulseLoader color="#94a4fd" size={10} />
                  </div>
                )}
                {emceeData?.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className="w-full flex justify-between items-center bg-white/10 p-3 rounded-lg text-[.85rem] hover:bg-white/20"
                    >
                      <p>
                        {capitalizeFirstLetter(
                          `${item.first_name} ${item.last_name}`
                        )}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {/* the add event pop up page */}

      {addEvent && <AddEventContainer />}
    </>
  );
};

export default UserEmcees;
