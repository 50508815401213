/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector, useDispatch } from 'react-redux';
import Footer from './components/Footer';
import Header from './components/Header';
import LeftSidebar from './components/LeftSidebar';
import RightSidebar from './components/RightSidebar';
import ScrollToTop from './ScrollToTop';
import { useParams } from 'react-router-dom';
import {
  toggleAddEntries,
  // searchEntries,
  setEntriesData,
  updateEntriesData,
} from 'redux/danceStudio/danceStudioSlice';
import AddEntriesForm from './components/AddEntriesForm';
import EntriesTable from './components/EntriesTable';
import { useState, useEffect } from 'react';
import EditEntriesForm from './components/EditEntriesForm';
import AddDancerForm from './components/AddDancerForm';
import { useQueryAllDocs } from 'components/hooks/useQueryAllDocs';
import { useQueryAllSubCollectionDocs } from 'components/hooks/useQueryAllSubCollectionDocs';
import { useFirestoreQueryDoc } from 'components/hooks/useFirestoreQueryDoc';
import { collection, onSnapshot, query } from 'firebase/firestore';
import { db } from 'services/firebase';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import localStorage from 'redux-persist/es/storage';
import EntriesSortContainer from './components/EntriesSortContainer';

const Entries = () => {
  const {
    openNotifsBoard,
    addEntries,
    editEntries,
    editID,
    dancersData,
    // searchResults,
    addDancer,
    // entriesData,
  } = useSelector((state) => state.danceStudio);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { event_name: name } = useParams();
  const event_name = name || localStorage.getItem('event_name') || '';

  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );
  const { compId } = danceStudioCompData.value;

  //to get realtime events from firestore
  const { data } = useQueryAllDocs('competitions');
  const currentComp = data?.filter((item) => item?.compId === compId)[0];
  const currentEvent = currentComp?.events?.filter(
    (item) => item.event_name?.replace(/ /g, '-') === event_name
  )[0];

  //to get studio data
  const { data: studioData } = useFirestoreQueryDoc('studio_owners', user?.uid);

  //to get all entries
  const { status, data: allEntries } = useQueryAllSubCollectionDocs(
    'competitions',
    compId,
    'entries'
  );

  const eventEntries = allEntries?.filter(
    (each) =>
      each?.event_uid === currentEvent?.event_uid &&
      each?.studio_selected[0] === studioData?.studioName
  );

  useEffect(() => {
    dispatch(setEntriesData(eventEntries));
  }, [status, allEntries]);

  const [entries, setEntries] = useState([]);

  //to get realtime updates of entries
  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, 'competitions', compId, 'entries')),
      (snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data());
        setEntries(data);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [compId, status]);

  useEffect(() => {
    if (status === 'success') {
      dispatch(updateEntriesData(eventEntries));
      dispatch(updateEntriesData(entries));
    }
  }, [status]);

  //to get current event being editted
  const entryToEdit = editID
    ? eventEntries?.filter((item) => item.doc_id === editID)[0]
    : null;

  const [addDancerMod, setAddDancerMod] = useState(false);
  function handleAddEntries() {
    dancersData?.length > 0
      ? dispatch(toggleAddEntries())
      : setAddDancerMod(true);
  }

  // handle the search input change for dancers
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const result = eventEntries?.filter((itm) =>
      itm?.entry_name?.toLowerCase()?.includes(query?.toLowerCase())
    );
    setSearchResults(result);
  };

  //to manage selecting and removing dancers
  const [openDancers, setOpenDancers] = useState(false);
  function toggleOpenDancers() {
    setOpenDancers((prev) => !prev);
  }
  const [dancerInput, setDancerInput] = useState('');

  function closeAll() {
    setOpenDancers(false);
    setDancerInput('');
  }

  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  const [showDropdown, setShowDropdown] = useState(false);
  const [sortDown, setSortDown] = useState('latest');

  const handleSortClick = () => {
    setShowDropdown((prev) => !prev);
  };

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}

      {/* event details page */}
      {!addEntries && !editEntries && !addDancer && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            setHoveredRowIndex(null);
            setShowDropdown(false);
          }}
          className={`w-full min-h-screen text-white/80 px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px]  ${
            openNotifsBoard ? 'lg:pr-[310px]' : 'lg:pr-[32px]'
          } pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col transition-all duration-700`}
        >
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/90 text-[1rem] mb-6">
              <h2>
                {capitalizeFirstLetter(currentEvent?.event_name?.trim())}{' '}
                {currentEvent?.year.split(' to ')[0]}
              </h2>
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>
            {currentEvent?.locked && (
              <>
                <div className="text-[.9rem] text-[#94a4fd]">
                  Schedule is locked.
                </div>
                <div className="text-[.9rem] text-[#94a4fd] mb-3">
                  Entries cannot be added, edited or deleted by you.
                  {/* <span className="underline cursor-pointer">Learn more?</span> */}
                </div>
              </>
            )}

            <div className="w-full">
              <div className="w-full p-2 bg-[#282929] rounded-lg flex justify-between">
                <div className="flex gap-2 items-center relative">
                  {!currentEvent?.locked && (
                    <img
                      alt="arrow"
                      src="/images/plus.png"
                      className="w-7 h-7 cursor-pointer hover:bg-white/20 rounded-lg"
                      onClick={() => dispatch(toggleAddEntries())}
                    />
                  )}
                  <div className="relative">
                    <img
                      alt="arrow"
                      src="/images/updown.png"
                      className="w-7 h-7 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSortClick();
                      }}
                    />
                    {/* the sorting mechanism */}
                    {showDropdown && (
                      <EntriesSortContainer
                        setSortDown={setSortDown}
                        sortDown={sortDown}
                      />
                    )}
                  </div>
                </div>
                {/* search */}
                <div
                  className={`h-[30px] flex mr-2 ml-auto border rounded-lg ${
                    searchQuery ? 'border-[#94a4fd]' : 'border-white/10'
                  }`}
                >
                  {' '}
                  <div className="h-full w-fit p-1 bg-[#333333] rounded-l-lg">
                    <img
                      alt="user"
                      src="/images/Search.png"
                      className="w-5 h-5 cursor-pointer"
                    />
                  </div>
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="w-[130px] text-[.9em] bg-[#333333] rounded-r-lg outline-none pr-2 py-1"
                  />
                </div>
              </div>

              {/* registration table */}
              <EntriesTable
                eventEntries={eventEntries}
                searchResults={searchResults}
                searchQuery={searchQuery}
                status={status}
                currentEvent={currentEvent}
                hoveredRowIndex={hoveredRowIndex}
                setHoveredRowIndex={setHoveredRowIndex}
                sortDown={sortDown}
                studioData={studioData}
              />
              {searchQuery ? (
                <div className="flex justify-center items-center text-white/80 text-[.85rem] mt-2">
                  <p> Showing {searchResults?.length} results</p>
                </div>
              ) : null}
            </div>
            {!currentEvent?.locked && (
              <button
                onClick={() => handleAddEntries()}
                className="text-[.85rem] px-5 py-1 mr-2 mt-4 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Add Entries{' '}
              </button>
            )}
          </div>

          <ScrollToTop />
          <Footer />
        </div>
      )}

      {/* the add dancer pop up page */}

      {addEntries && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            closeAll();
          }}
          className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[330px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col"
        >
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
              <h2>
                {capitalizeFirstLetter(currentEvent?.event_name?.trim())}{' '}
                {currentEvent?.year.split(' to ')[0]}
              </h2>{' '}
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            {/* Add entries form */}
            <div className="">
              <AddEntriesForm
                currentEvent={currentEvent}
                openDancers={openDancers}
                toggleOpenDancers={toggleOpenDancers}
                dancerInput={dancerInput}
                setOpenDancers={setOpenDancers}
                setDancerInput={setDancerInput}
              />
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {editEntries && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            closeAll();
          }}
          className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[330px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col"
        >
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
              <h2>
                {capitalizeFirstLetter(currentEvent?.event_name?.trim())}{' '}
                {currentEvent?.year.split(' to ')[0]}
              </h2>{' '}
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            {/* Add entries form */}
            <div className="">
              <EditEntriesForm
                entryToEdit={entryToEdit}
                currentEvent={currentEvent}
                openDancers={openDancers}
                toggleOpenDancers={toggleOpenDancers}
                dancerInput={dancerInput}
                setOpenDancers={setOpenDancers}
                setDancerInput={setDancerInput}
              />
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {addDancer && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[330px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
              <h2>
                {capitalizeFirstLetter(currentEvent?.event_name?.trim())}{' '}
                {currentEvent?.year.split(' to ')[0]}
              </h2>{' '}
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            {/* Add event form */}
            <div className="">
              <AddDancerForm />
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {addDancerMod && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/80 flex justify-center">
          <div className="w-full sm:w-[450px] h-fit p-5 bg-[#282929] rounded-lg flex flex-col gap-5 justify-center items-center scale shadow-lg">
            <div className="w-full text-[.85em] sm:text-[1rem] text-white pb-3 border-b border-[#c5c4c4]/50 relative">
              <p>Please add at least one dancer!</p>
            </div>
            <button
              className="w-full px-2 py-3 rounded-lg bg-gray-500 hover:bg-gray-400 text-white"
              onClick={() => {
                setAddDancerMod(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Entries;
