import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

// components that can reused
import ImgWrap from '../UI/ImgWrapper';
import Container from '../UI/Container';
import Logo from '../../assets/Logo.png';
import Button from '../Form/Button';
import AgeDivision from './Eligibility/AgeDivision';
import CompetitionLevel from './Eligibility/CompetitionLevel';
import EntryType from './Eligibility/EntryType';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import SpecialEntry from './Eligibility/SpecialEntry';

const Eligibility = () => {
  const navigate = useNavigate();
  const { eligibilityDivisions, competitionLevel, entryTypes } = useSelector(
    (state) => state.persistedReducer
  );

  const goToPreviousPage = () => {
    navigate('/organizer/genre');
  };

  const goToNextPage = () => {
    // logic to check if all the fields are filled
    if (
      eligibilityDivisions.length > 0 &&
      competitionLevel.length > 0 &&
      entryTypes.length > 0
    ) {
      navigate('/organizer/scoring');
    } else {
      toast.info('Please fill all the fields correctly.');
    }
  };

  return (
    <div className="flex flex-row flex-wrap h-[100vh] w-full">
      <div className="lg:flex hidden lg:w-[40%]">
        {/* Left Components With Background Image */}
        <ImgWrap className="bg-[url('../public/Frame5.png')]" />
      </div>
      <div className="lg:w-[60%] w-full">
        {/* Container Component */}
        <Container className="flex flex-wrap lg:px-[6%]">
          <div className="flex flex-row justify-between w-full lg:justify-end">
            <div className="block lg:hidden">
              <Link to="/">
                <img
                  src={Logo}
                  alt="Logo"
                  className="cursor-pointer w-[90px] h-[25px]"
                />
              </Link>
            </div>
            <div className="flex flex-col items-end">
              <span className="text-sm font-medium text-[#BDBDBD]">
                STEP 03/06
              </span>
              <h4 className="text-base font-semibold text-[#8692A6]">
                Organizer Registration
              </h4>
            </div>
          </div>

          <div className="flex flex-col flex-wrap pt-[5%] w-full">
            <div className="lg:pl-[5%] mt-[3rem] md:mt-[2rem]">
              <h1 className="mb-3 text-3xl font-bold text-[#000000]">
                Eligibility
              </h1>
              <p className="text-lg font-normal text-[#8692A6]">
                Determining how to classify your entries.
              </p>
              <div className="border-t border-base w-[60%] mt-5"></div>
            </div>

            <div className="flex flex-wrap mt-5 lg:pl-[5%]">
              {/* The segment pertaining to age divisions */}

              <AgeDivision />

              {/* End of the segment pertaining to age divisions */}

              {/* The segment pertaining to age divisions */}

              <CompetitionLevel />

              {/* End of the segment pertaining to age divisions */}

              {/* The segment pertaining to age divisions */}

              <EntryType />

              {/* End of the segment pertaining to age divisions */}
              {/* The segment pertaining to age divisions */}

              <SpecialEntry />

              {/* End of the segment pertaining to age divisions */}
              <div className="flex flex-row justify-end mt-[25%] mb-[15px] w-full">
                {/* customs button component */}
                <Button
                  text="Previous"
                  handleOnClick={goToPreviousPage}
                  className="bg-[#1565D8] border-[#1565D8] text-white rounded-[5px] btn-sm capitalize text-base font-medium mr-3 h-8"
                />
                <Button
                  text="Next"
                  handleOnClick={goToNextPage}
                  className="bg-[#1565D8] border-[#1565D8] text-white rounded-[5px] btn-sm capitalize text-base font-medium h-8"
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
export default Eligibility;
