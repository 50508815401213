import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import leftIcon from '../../assets/Union.png';

export default function CustomBtn({
  title,
  text,
  userIcon,
  className,
  url,
  id,
}) {
  const [btnId, setBtnId] = useState(null);

  return (
    <Link
      to={`${url}?type=${encodeURIComponent(title)}`}
      onClick={() => setBtnId(id)}
    >
      <div
        className={`bg-[#F5F9FF] rounded-md lg:w-[85%] md:w-[85%] shadow-[0_3px_10px_-5px_rgba(0,0,0,0.3)] py-[26px] pl-[28px] flex flex-row ${
          className ? className : ''
        } ${btnId === id ? 'border border-[#1565D8]' : ''}`}
      >
        <img
          src={userIcon}
          alt="userIcon"
          className="w-[55px] h-[55px] mr-[6%] "
        />
        <div className="flex flex-col">
          <h4 className="text-base font-medium text-[#000000] mb-[2px]">
            {title}
          </h4>
          <div className="flex flex-row">
            <p className="font-normal text-sm text-[#8692A6] pr-[10px]">
              {text}
            </p>
            {btnId === id ? (
              <img
                src={leftIcon}
                className="w-[13px] h-[13px]"
                alt="leftIcon"
              />
            ) : null}
          </div>
        </div>
      </div>
    </Link>
  );
}
