export default function findMaxValueKey(obj) {
  let maxKey = null;
  let maxValue = -Infinity;

  for (const key in obj) {
    if (obj?.hasOwnProperty(key)) {
      const value = obj[key];

      if (typeof value === "number" && value > maxValue) {
        maxKey = key;
        maxValue = value;
      }
    }
  }

  if (maxKey !== null) {
    const result = {};
    result[maxKey] = maxValue;
    return result;
  }

  return null;
}
