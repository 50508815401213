import { useNavigate } from "react-router-dom";
import { collection, doc, setDoc, Timestamp } from "firebase/firestore";
import {
  setCompetitionsData,
  selectComp,
  removeComp,
  clearCompetitionsData,
} from "redux/danceStudio/danceStudioSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import SelectCompButton from "./SelectCompButton";
import RemoveCompButton from "./RemoveCompButton";
import ViewCompButton from "./ViewCompButton";
import { auth, db } from "services/firebase";
import { useState } from "react";
import { sendSubscriptionEmailService } from "services/organizerService";

const CompetitionCard = ({ item, index, popup, setPopup }) => {
  const { selectCompPending, removeCompPending } = useSelector(
    (state) => state.danceStudio
  );
  //getting the user ID from auth
  const user = auth?.currentUser;

  const navigate = useNavigate(); // Use navigation hook
  const dispatch = useDispatch();

  const { data } = useFirestoreQueryDoc("studio_owners", user?.uid);
  const selectedComps = data?.selected_competitions || [];
  const competitionSelected = selectedComps.includes(item.competitionName);

  const timestamp = new Date().getTime();

  function formatTimeStamp(timestamp) {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const month = date.toLocaleString("default", { month: "long" });
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedTime = `${hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }, ${month} ${day}, ${year}`;
    return formattedTime;
  }

  //=========================================to generate uid
  function generateUID() {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uid = "";
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      uid += chars.charAt(randomIndex);
    }
    return uid;
  }
  function generateTimestamp() {
    const now = new Date();
    const isoString = now.toISOString();
    return isoString;
  }

  async function updateNotifs() {
    const notifsCollectionRef = collection(db, "notifications");

    const notif = {
      id: generateUID(),
      message: `New studio subscription. Studio: ${data?.studioName} just subscribed to your competition.`,
      read: false,
      timestamp: generateTimestamp(),
      type: "subscription",
      user_id: item?.compId,
    };

    await setDoc(doc(notifsCollectionRef), notif);
  }

  const [estEntries, setEstEntries] = useState("");

  const handleSelectComp = (competitionName) => {
    dispatch(
      selectComp({
        competitionName: competitionName,
        uid: user?.uid,
        compId: item?.compId,
        studioRegDetails: {
          studio_id: user?.uid,
          first_name: data?.ownerFirstName,
          last_name: data?.ownerLastName,
          email: data?.email,
          studio_name: data?.studioName,
          submitted_entries: 0,
          estimated_entries: Number(estEntries),
          last_update: formatTimeStamp(timestamp),
          last_payment: "random date",
          scheduling_status: "Open",
          status: "Unpaid",
          account_status: "Inprogress",
          paid: "$0",
          owing: "$0",
        },
      })
    ).then(() =>
      sendSubscriptionEmailService({
        userEmail: item?.email,
        studioName: data?.studioName,
        studioEmail: data?.email,
        compName: competitionName,
      })
    );
    updateNotifs();
    setPopup(null);
  };

  async function updateRemove() {
    const notifsCollectionRef = collection(db, "notifications");

    const notif = {
      id: generateUID(),
      message: `Subscription cancelled. Studio: ${data?.studioName} just unsubscribed to your competition.`,
      read: false,
      timestamp: generateTimestamp(),
      type: "subscription",
      user_id: item?.compId,
    };

    await setDoc(doc(notifsCollectionRef), notif);
  }

  const handleRemoveComp = (competitionName) => {
    dispatch(
      removeComp({
        competitionName: competitionName,
        uid: user?.uid,
        compId: item?.compId,
        studioName: data?.studioName,
      })
    );
    dispatch(clearCompetitionsData());
    updateRemove();
  };

  const handleViewComp = () => {
    let modifiedItem = { ...item }; // Create a new object by spreading the properties of `item`

    if (modifiedItem.timestamp instanceof Timestamp) {
      modifiedItem.timestamp = modifiedItem.timestamp.toDate().toISOString();
    }

    dispatch(setCompetitionsData(modifiedItem));
    navigate(`/studio/event-selection`);
    localStorage.removeItem("event_name");
  };

  // console.log(item);

  const [deleteP, setDeleteP] = useState(null);

  return (
    <>
      {" "}
      <div className="w-full flex flex-wrap md:flex-row flex-col items-center gap-3 bg-[#282929] p-4 rounded-xl">
        <div className="flex mr-auto">
          <img
            alt="user"
            src={"/images/logoComp.png"}
            className="w-8 h-8 mr-6"
          />
          <h3 className="max-w-[200px] lg:max-w-[300px] whitespace-nowrap truncate font-medium mr-auto">
            {item?.competitionName}
          </h3>
        </div>
        <div className="flex ml-auto">
          {!competitionSelected && (
            <SelectCompButton
              setPopup={setPopup}
              selectCompPending={selectCompPending}
              item={item}
              handleSelectComp={handleSelectComp}
            />
          )}
          {competitionSelected && (
            <ViewCompButton
              handleViewComp={handleViewComp}
              competitionName={item.competitionName}
              item={item}
            />
          )}
          {competitionSelected && (
            <RemoveCompButton
              handleRemoveComp={handleRemoveComp}
              removeCompPending={removeCompPending}
              item={item}
              setDeleteP={setDeleteP}
              deleteP={deleteP}
            />
          )}
        </div>
      </div>
      {/* {popup === item && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-start scale shadow-lg">
            <p className="w-full text-[1rem] text-white pb-3 border-b border-[#c5c4c4]/50 text-center">
              How many entries do you estimate submitting to this competition?
            </p>

            <input
              type="number"
              className="w-full px-3 py-2 border border-[#94a4fd] rounded-md outline-none bg-inherit"
              placeholder="E.g 150"
              value={estEntries}
              onChange={(e) => setEstEntries(e.target.value)}
            />

            <div className="mt-4 w-full text-center">
              <button
                onClick={() => setPopup(null)}
                className="text-[.85rem] px-8 py-2 mr-4 rounded-md bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleSelectComp(popup?.competitionName);
                }}
                disabled={!estEntries}
                className={`text-[.9rem] px-8 py-2 rounded-md text-[#1c1c1c] transition-all duration-300 ${
                  estEntries
                    ? "bg-[#94a4fd] hover:bg-[#94a4fd]/60"
                    : "cursor-not-allowed bg-[#94a4fd]/40 hover:bg-[#94a4fd]/40"
                }`}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default CompetitionCard;
