import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

function AuthGuard({ children, ...rest }) {
  const isAuthenticated = useSelector(
    (state) => state.persistedReducer.auth.user
  );
  const formCompleted = useSelector(
    (state) => state.persistedReducer.auth.user?.formCompleted
  );

  // Define the unprotected routes
  const unprotectedRoutes = ['/login', '/'];
  // Check the user's role
  const userRole = isAuthenticated?.role;

  return (
    <Routes>
      <Route {...rest}>
        {isAuthenticated ||
        unprotectedRoutes.includes(rest.path) ||
        !formCompleted ? (
          children
        ) : userRole === 'organizer' ? (
          <Navigate to="/organizer/genre" />
        ) : userRole === 'danceStudioOwner' ? (
          <Navigate to="/studio/info" />
        ) : (
          <Navigate to="/login" />
        )}
      </Route>
    </Routes>
  );
}

export default AuthGuard;
