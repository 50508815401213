/* eslint-disable react-hooks/exhaustive-deps */
import { useFirestoreQueryDoc } from 'components/hooks/useFirestoreQueryDoc';
import { useQueryAllDocs } from 'components/hooks/useQueryAllDocs';
import { useQueryAllSubCollectionDocs } from 'components/hooks/useQueryAllSubCollectionDocs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ScrollToTop } from '.';
import InvoicePage from './components/InvoicePage';

const InvoicePageContainer = () => {
  // const { eventsData } = useSelector((state) => state.eventManagement);
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  //to get realtime events from firestore
  const { data: comps } = useQueryAllDocs('competitions');
  const organizerComp = comps?.filter((item) => item?.compId === user?.uid)[0];
  const realtimeEvents = organizerComp?.events;

  // console.log("realtimeEvents", realtimeEvents);

  const { event_name, studio_id } = useParams();

  const currentEvent = realtimeEvents?.filter(
    (item) => item.event_name.replace(/ /g, '-') === event_name
  )[0];

  const currentStudio = currentEvent?.access?.filter(
    (itm) => itm?.studio_id === studio_id
  )[0];

  const { data: allStudios } = useQueryAllDocs('studio_owners');
  const studioData = allStudios?.filter(
    (item) => item?.uid === currentStudio?.studio_id
  )[0];

  // console.log("currentEvent", currentEvent);

  //==========================================================================to get all entrytypes & pricing
  const { status, data: compData } = useFirestoreQueryDoc(
    'competitions',
    user?.uid
  );

  const [taxRate, setTaxRate] = useState(0.0);
  const [entryTypes, setentryTypes] = useState([]);
  const [pricing, setpricing] = useState([]);
  const [billingDetails, setbillingDetails] = useState([]);

  useEffect(() => {
    if (compData) {
      const { entryTypes, pricing, billing_details, events } = compData;
      setentryTypes(entryTypes);
      setpricing(pricing?.pricingCategories);

      const currentEve = events?.filter(
        (itm) => itm?.event_uid === currentEvent?.event_uid
      )[0];
      setTaxRate(currentEve?.tax_rate || 0);
      setbillingDetails(billing_details);
    }
  }, [status]);

  const allEntryTypes = entryTypes?.concat(
    compData?.specialEntryTypes?.tags || []
  );

  //====================================================================================to get all entries
  const { data: allEntries } = useQueryAllSubCollectionDocs(
    'competitions',
    user?.uid,
    'entries'
  );

  const studioEntries = allEntries?.filter(
    (each) =>
      each?.studio_selected[0] === currentStudio?.studio_name &&
      each?.event_uid === currentEvent?.event_uid
  );

  function getAllDancers(arr) {
    const allSelected = [];

    for (const obj of arr) {
      if (obj.dancers_selected && Array.isArray(obj.dancers_selected)) {
        allSelected.push(...obj.dancers_selected);
      }
    }

    return allSelected;
  }

  // ====================================================================================Calculate the totalAmount
  const newTotalAmount = allEntryTypes
    ?.reduce((total, row) => {
      // const rowPricingOption = pricing?.find((obj) =>
      //   obj.hasOwnProperty(row?.entryTypeName)
      // );
      let rowPricingOption;
      if (row?.entryTypeName) {
        rowPricingOption = pricing?.find((obj) =>
          obj.hasOwnProperty(row.entryTypeName)
        );
      } else if (row.specialEntryTypeName) {
        rowPricingOption = pricing?.find((obj) =>
          obj.hasOwnProperty(row.specialEntryTypeName)
        );
      }
      const rowEntries = studioEntries?.filter(
        (itm) => itm?.entryType === row?.entryTypeName
      );

      const flatAmount =
        rowEntries?.length > 0
          ? rowPricingOption?.[row?.entryTypeName] * rowEntries?.length
          : 0.0;

      const rowAmount =
        rowPricingOption?.selectedOption?.toLowerCase() === 'flat'
          ? Number(flatAmount)
          : getAllDancers(rowEntries)?.length *
            Number(rowPricingOption?.[row?.entryTypeName]);

      return total + (rowAmount || 0);
    }, 0)
    .toFixed(2);

  function calcTotalAfterTax(amount, percentage) {
    const result = (amount * percentage) / 100;
    return result.toFixed(2);
  }

  const tax = Number(calcTotalAfterTax(newTotalAmount, taxRate)).toFixed(2);

  const totalAfterTax =
    Number(newTotalAmount) + Number(calcTotalAfterTax(newTotalAmount, taxRate));

  const { data } = useFirestoreQueryDoc('organizers', user?.uid);

  return (
    <>
      {/* the invoice page overlay */}
      <section className="w-full h-screen fixed top-0 left-0">
        <InvoicePage
          compName={data?.competitionName}
          studioData={studioData}
          entryTypes={entryTypes}
          allEntryTypes={allEntryTypes}
          studioEntries={studioEntries}
          pricing={pricing}
          getAllDancers={getAllDancers}
          newTotalAmount={newTotalAmount}
          taxRate={taxRate}
          totalAfterTax={totalAfterTax}
          tax={tax}
          billingDetails={billingDetails}
        />
      </section>

      <ScrollToTop />
    </>
  );
};

export default InvoicePageContainer;
