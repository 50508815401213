/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import { getEvents } from "../../redux/eventManagement/eventManagement";
import ScrollToTop from "./ScrollToTop";
import { PulseLoader } from "react-spinners";
import AddEventContainer from "./components/AddEventContainer";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import UserDancersTable from "./components/UserDancersTable";
import UserDancerSortCont from "components/DanceStudio/components/UserDancerSortCont";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const RegDancers = () => {
  const { addEvent, openNotifsBoard, currentEvent } = useSelector(
    (state) => state.eventManagement
  );

  //getting the user ID from auth
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEvents(user?.uid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  //=====================================================================================to get realtime events from firestore

  // const { status, data: allEntries } = useQueryAllSubCollectionDocs(
  //   "competitions",
  //   user?.uid,
  //   "entries"
  // );

  // const eventEntries = allEntries?.filter(
  //   (item) => item?.event_uid === currentEvent?.event_uid
  // );
  // const allDancers1 = (() => {
  //   let arr = [];
  //   eventEntries?.forEach((x) => arr?.push(...x?.dancers_selected));
  //   return removeDuplicatesByProperty(arr, "dancer_uid");
  // })();

  // to get all dancers
  const { status, data: allDancers2 } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "dancers"
  );

  const accessStudiosNamesStr = currentEvent?.access?.map((x) =>
    x?.studio_name?.toLowerCase()
  );

  const allDancers = allDancers2?.filter((dancer) =>
    accessStudiosNamesStr?.some((d) => d === dancer.studio_name?.toLowerCase())
  );

  // console.log("allDancers", allDancers);

  // console.log("allDancers", allDancers);

  // function removeDuplicatesByProperty(arr, property) {
  //   const uniqueObjects = {};
  //   const result = [];

  //   for (const item of arr) {
  //     const key = item[property];

  //     if (!uniqueObjects[key]) {
  //       uniqueObjects[key] = true;
  //       result.push(item);
  //     }
  //   }

  //   return result;
  // }

  const [showDropdown, setShowDropdown] = useState(false);
  const [sortDown, setSortDown] = useState("latest");

  const handleSortClick = () => {
    setShowDropdown((prev) => !prev);
  };

  const [hideTable, setHideTable] = useState(false);
  function toggleHideTable() {
    setHideTable((prev) => !prev);
  }

  // handle the search input change for dancers
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const result = allDancers?.filter(
      (itm) =>
        itm?.first_name?.toLowerCase()?.includes(query?.toLowerCase()) ||
        itm?.last_name?.toLowerCase()?.includes(query?.toLowerCase())
    );
    setSearchResults(result);
  };

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}
      {!addEvent && (
        <div
          onClick={() => setShowDropdown(false)}
          className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[30px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col"
        >
          <div className="mb-auto">
            {/* Heading */}
            <h2 className="mb-6 text-white/80 text-[1.25rem] font-medium">
              {capitalizeFirstLetter(currentEvent?.event_name)}{" "}
              {currentEvent?.year.split(" to ")[0]} Dancers
            </h2>
            <div className="w-full md:min-w-[450px] min-h-[200px] rounded-lg flex flex-col gap-6 mb-6 text-white/80">
              <div className="w-full flex flex-col gap-3">
                <div className="w-full p-2 bg-[#282929] rounded-lg flex justify-between">
                  <div className="flex gap-2 items-center">
                    <img
                      alt="arrow"
                      src="/images/list.png"
                      className="w-7 h-7 cursor-pointer"
                      onClick={toggleHideTable}
                    />
                    <div className="relative">
                      <img
                        alt="arrow"
                        src="/images/updown.png"
                        className="w-7 h-7 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSortClick();
                        }}
                      />
                      {/* the sorting mechanism */}
                      {showDropdown && (
                        <UserDancerSortCont
                          setSortDown={setSortDown}
                          sortDown={sortDown}
                        />
                      )}
                    </div>
                  </div>
                  {/* search */}
                  <div
                    className={`h-[30px] flex mr-2 ml-auto border rounded-lg ${
                      searchQuery ? "border-[#94a4fd]" : "border-white/10"
                    }`}
                  >
                    <div className="h-full w-fit p-1 bg-[#333333] rounded-l-lg">
                      <img
                        alt="user"
                        src="/images/Search.png"
                        className="w-5 h-5 cursor-pointer"
                      />
                    </div>
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="w-[130px] text-[.9em] bg-[#333333] rounded-r-lg outline-none pr-2 py-1"
                    />
                  </div>
                </div>
                {status === "loading" && (
                  <div className="w-full h-[150px] flex justify-center items-center">
                    <PulseLoader color="#94a4fd" size={10} />
                  </div>
                )}
                {status === "success" && !hideTable && (
                  <UserDancersTable
                    allDancers={allDancers}
                    currentEvent={currentEvent}
                    status={status}
                    sortDown={sortDown}
                    searchResults={searchResults}
                    searchQuery={searchQuery}
                  />
                )}
              </div>
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {/* the add event pop up page */}

      {addEvent && <AddEventContainer />}
    </>
  );
};

export default RegDancers;
