/* eslint-disable react-hooks/exhaustive-deps */
import { collection, query, where } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

export const useFetchEntries = (
  collectionName,
  compId,
  subCollection,
  filterField1,
  filterValue1,
  filterField2,
  filterValueArray
) => {
  const firestore = useFirestore();
  const entriesCollection = collection(
    firestore,
    collectionName,
    compId,
    subCollection
  );

  let entriesQuery = query(
    entriesCollection,
    where(filterField1, "==", filterValue1 || "")
  );

  if (filterField2 && filterValueArray && filterValueArray.length > 0) {
    entriesQuery = query(
      entriesQuery,
      where(filterField2, "in", filterValueArray)
    );
  }

  const { status, data } = useFirestoreCollectionData(entriesQuery, {
    idField: "id",
  });

  return { status, data };
};
