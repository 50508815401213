import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ButtonLoadingSpinner from 'utils/ButtonLoadingSpinner';
import { clearSuccess } from 'redux/eventManagement/eventManagement';
import { db } from 'services/firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';

const EntryAddForm = ({ onClose, organizerData, user }) => {
  const [loading, setLoading] = useState(false);
  const [formErr, setFormErr] = useState('');

  const [formData, setFormData] = useState({
    entryTypeName: '',
    minDancers: '',
    maxDancers: '',
    maxTime: '',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setFormErr('');
    dispatch(clearSuccess());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update form data when inputs change
  const handleChange = (e) => {
    setFormErr('');
    dispatch(clearSuccess());
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      !formData.entryTypeName?.trim() &&
      !formData.maxTime &&
      !formData.minDancers &&
      !formData.maxDancers
    ) {
      setFormErr('Please fill all required fields');
      return;
    } else if (organizerData && formData) {
      const organizerDocRef = doc(db, 'organizers', user?.uid);
      const competitionDocRef = doc(db, 'competitions', user?.uid);
      // Get the current document data
      const organizerDocSnap = await getDoc(organizerDocRef);
      const currentData = organizerDocSnap.data();

      // Convert decimal value to the desired format (hh:mm:ss)
      const hours = Math.floor(formData.maxTime / 60);
      const minutes = Math.floor(formData.maxTime % 60);
      const seconds = Math.floor((formData.maxTime % 1) * 60);
      formData.maxTime = `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

      // Update the special entry types array
      const newOne = {
        entryTypeName: formData?.entryTypeName.toLowerCase(),
        minDancers: formData?.minDancers,
        maxDancers: formData?.maxDancers,
        maxTime: formData?.maxTime,
      };

      const newTags = [...currentData.entryTypes, newOne];

      if (formData?.entryTypeName) {
        // Create a copy of the pricingCategories array from the current data
        const currentPricingCategories = [
          ...currentData.pricing.pricingCategories,
        ];

        // Add the new special entry to the pricingCategories
        currentPricingCategories.push({
          [formData.entryTypeName]: '0.00',
          selectedOption: 'Flat',
        });

        // Update the "pricing" object within the document
        const updatedPricing = {
          ...currentData.pricing,
          pricingCategories: currentPricingCategories,
        };

        // Update the document with the new data
        await updateDoc(organizerDocRef, {
          entryTypes: newTags,
          pricing: updatedPricing,
        });
        await updateDoc(competitionDocRef, {
          entryTypes: newTags,
          pricing: updatedPricing,
        });
      }

      // Clear the input field
      setFormData({
        entryTypeName: '',
        minDancers: '',
        maxDancers: '',
        maxTime: '',
      });
    }
    // Set loading to false after the update is complete
    setLoading(false);

    // Close the pop-up
    onClose();
  };

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 py-[100px] flex justify-center bg-[#1c1c1c]/90 z-[999]"
    >
      <div className="rounded-lg p-6 w-[40%] h-[410px] mx-auto md:w-1/2 max-w-[700px] min-w-[300px]">
        <div
          onClick={(e) => e.stopPropagation()}
          className="bg-[#282929] rounded-lg p-6 scale"
        >
          <form onSubmit={handleSubmit}>
            <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
              <label htmlFor="description">Entry Types</label>
              <input
                type="text"
                id="entryTypeName"
                placeholder="Eg. Superb, etc."
                value={formData.entryTypeName}
                onChange={handleChange}
                className="w-full bg-transparent border-none outline-none text-white/80"
              />
            </div>
            <div className="flex items-center gap-2">
              <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
                <label htmlFor="description">Min Dancer</label>
                <input
                  type="number"
                  id="minDancers"
                  placeholder="Eg. #1"
                  value={formData.minDancers}
                  onChange={handleChange}
                  className="w-full bg-transparent border-none outline-none text-white/80"
                />
              </div>
              <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
                <label htmlFor="description">Max Dancer</label>
                <input
                  type="number"
                  id="maxDancers"
                  placeholder="Eg. #10"
                  value={formData.maxDancers}
                  onChange={handleChange}
                  className="w-full bg-transparent border-none outline-none text-white/80"
                />
              </div>
            </div>

            <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
              <label htmlFor="description">Entry Time</label>
              <input
                type="number"
                id="maxTime"
                placeholder="Eg. 10"
                value={formData.maxTime}
                onChange={handleChange}
                className="w-full bg-transparent border-none outline-none text-white/80"
              />
            </div>
            <div className="flex gap-3  mb-6 justify-end">
              <button
                type="button"
                onClick={onClose}
                className="px-8 py-1 bg-white rounded-full text-black/80 text-[0.85rem] hover:opacity-75"
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`px-8 flex items-center rounded-full text-black/80 text-[0.85rem] hover:opacity-75 bg-[#94a4fd] cursor-pointer`}
                disabled={loading}
              >
                <span className="px-10 py-2 w-full">
                  {loading ? 'Processing...' : 'Submit'}
                </span>
                {loading && <ButtonLoadingSpinner />}
              </button>
            </div>
            {formErr && <p className="text-red-500">{formErr}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default EntryAddForm;
